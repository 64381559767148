"use strict";
import { isString, sortBy } from "lodash";
import { createSelector } from "../util";
export const selectAppConfig = createSelector((state) => state.appConfig);
export const selectMeasurementsDownloadUrl = createSelector((state) => {
  var _a;
  let url = ((_a = state.appConfig.features.measurements) == null ? void 0 : _a.downloadUrl) || "";
  if (!url || !isString(url))
    return null;
  if (url.slice(-1) !== "/")
    url = `${url}/`;
  return url;
});
export const selectIsPreviewEnabled = createSelector((state) => state.appConfig.features.preview);
export const selectIsAltasigmaIntegrationEnabled = createSelector((state) => state.appConfig.features.altasigmaIntegration);
export const selectIsOeeHidden = createSelector((state) => state.appConfig.features.hideOee || false);
export const selectAppLocales = createSelector((state) => selectAppConfig(state).locales);
export const selectAllRoles = createSelector((state) => sortBy(selectAppConfig(state).allRoles || []));
