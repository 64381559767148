"use strict";
import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as api from "../../core/api";
import notificationMessage from "../../messages/notification.message";
import { NOTIFICATION_ERROR } from "../constants/notification.constants";
import { getDatatron } from "../selectors/datatron.selector";
import { setCallbackCommandId } from "./configRepo.callback.receive.module";
import { quickCommandFailure, quickCommandSuccess } from "./datatron.devices.quickCommands.module";
import { sendNotification } from "./notifications.module";
import { transformErrorToMessageString } from "../../../../common/utils";
export const importDevice = createAction("import datatron device", (deviceId) => ({
  payload: deviceId
}));
export function* importDeviceSaga({ payload: deviceId }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const { response, error } = yield call(api.datatrons.importDevice, datatron.id, deviceId);
  if (response) {
    yield put(setCallbackCommandId(response.commandId));
    yield put(quickCommandSuccess(deviceId));
    yield put(sendNotification(notificationMessage.device_import_success_title));
  } else {
    yield put(setCallbackCommandId(-1));
    const errorString = transformErrorToMessageString(error);
    yield put(quickCommandFailure(deviceId, errorString));
    yield put(
      sendNotification(
        notificationMessage.device_import_failed_title,
        errorString,
        NOTIFICATION_ERROR
      )
    );
  }
}
export function* watchImportDevice() {
  yield takeEvery(importDevice, importDeviceSaga);
}
