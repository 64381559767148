import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DP_UNARCHIVE_DATA_POINT } from '../../../redux/constants/modals.constants';
import { unarchiveDataPoint } from '../../../redux/modules/datatron.devices.dataPoints.unarchive.module';
import { closeModal } from '../../../redux/modules/modals.module';
import { getModalPayload, isModalOpen } from '../../../redux/selectors/modals.selector';
import Modal from '../../_tools/modal/Modal';

const modalSelector = (state: any) => ({
  isOpen: isModalOpen(state, DP_UNARCHIVE_DATA_POINT),
  payload: getModalPayload(state, DP_UNARCHIVE_DATA_POINT),
});

export const UnarchiveDataPointModal: React.FC = () => {
  const { isOpen, payload } = useSelector(modalSelector);
  const dispatch = useDispatch();

  const close = useCallback(() => dispatch(closeModal(DP_UNARCHIVE_DATA_POINT)), [dispatch]);

  const handleSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (payload) {
        dispatch(unarchiveDataPoint(payload.deviceId, payload.dataPointId));
        close();
      }
    },
    [dispatch, close, payload],
  );

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={close} contentLabel='Unarchive Data Point'>
      <div className='modal--header'>
        <button className='modal--close' onClick={close}>
          &times;
        </button>
      </div>
      <div className='modal--content'>
        <FormattedMessage
          defaultMessage='Do you really want to unarchive this Data Point?'
          id='datatron.modals.unarchive_data_point.text'
          tagName='h3'
        />

        <div className='line' />

        <div className='text-center'>
          <button type='button' className='button__cancel' onClick={close}>
            <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
          </button>
          <button type='submit' className='button__save' onClick={handleSubmit}>
            <FormattedMessage
              defaultMessage='Unarchive Data Point'
              id='datatron.modals.archive_data_point.buttons.unarchive'
            />
          </button>
        </div>
      </div>
    </Modal>
  );
};
