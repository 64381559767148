"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import {
  MACHINES_MANAGER
} from "../constants/permissions";
class MachinesPermissions {
  constructor(roles) {
    __publicField(this, "isMachinesManager");
    this.isMachinesManager = roles.indexOf(MACHINES_MANAGER) !== -1;
  }
  canAddAssistants() {
    return this.isMachinesManager;
  }
  canDeleteAssistants() {
    return this.isMachinesManager;
  }
  canCreateMachines() {
    return this.isMachinesManager;
  }
  canUpdateMachines() {
    return this.isMachinesManager;
  }
  canDeleteMachines() {
    return this.isMachinesManager;
  }
  canSubscribeMachinesToDataPoints() {
    return this.isMachinesManager;
  }
  canUpdateMachinesSubscriptionsToDataPoints() {
    return this.isMachinesManager;
  }
  canDeleteMachinesSubscriptionsToDataPoints() {
    return this.isMachinesManager;
  }
}
export default MachinesPermissions;
