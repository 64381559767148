// TODO: to be delete when old package is removed
import { createAction } from 'redux-act';

export const beginDrag = createAction('begin event drag');

export const endDrag = createAction('end event drag');

export const reducer = {
  [beginDrag]: (state) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      dnd: {
        ...state.maintenance.dnd,
        isDragging: true,
      },
    },
  }),

  [endDrag]: (state) => ({
    ...state,
    maintenance: {
      ...state.maintenance,
      dnd: {
        ...state.maintenance.dnd,
        isDragging: false,
      },
    },
  }),
};
