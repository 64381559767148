"use strict";
import toolStates from "../../../../common/enums/toolStates";
export const getTools = (state) => state.tools.all.list;
export const getToolsPagination = (state) => state.tools.all.pagination;
export const isLoading = (state) => state.tools.all.loading;
export const isLoaded = (state) => state.tools.all.loaded;
export const getFilters = (state) => state.tools.all.filters;
export const getQueryFilterValue = (state) => getFilters(state).query;
export const filtersApplied = (state) => {
  const filters = getFilters(state);
  return !(!filters.query && (filters.state.length === 0 || filters.state.length === toolStates.length));
};
