import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

type ToggleArchiveProps = {
  onToggle: (showArchived: boolean) => void;
};

export const ToggleArchive: React.FC<ToggleArchiveProps> = ({ onToggle }) => {
  const [showArchived, setShowArchived] = useState(false);

  const handleToggle = (isArchived: boolean) => {
    if (isArchived === showArchived) return;
    const newShowArchived = !showArchived;
    setShowArchived(newShowArchived);
    onToggle(newShowArchived);
  };

  return (
    <div className='subheader--right'>
      <div className='button-group white-group'>
        <a className={`${showArchived ? 'active ' : ''}`} onClick={() => handleToggle(true)}>
          <FormattedMessage defaultMessage='Archived' id='common.archived' />
        </a>
        <a className={`${!showArchived ? 'active ' : ''}`} onClick={() => handleToggle(false)}>
          <FormattedMessage defaultMessage='Unarchived' id='common.unarchived' />
        </a>
      </div>
    </div>
  );
};
