import React from 'react';
import { SignalStateDisplay } from './SignalStateDisplay';

/**
 * A chart that displays a loading animation.
 *
 * This component is a convenience wrapper around the [[SignalStateDisplay]]
 * component, and is used to display a loading state in a chart.
 *
 * @example
 * <LoadingChart />
 */
export const LoadingChart: React.FC = () => (
  <SignalStateDisplay
    type='loading'
    icon='fa-spinner fa-spin'
    messageId='datatron.modals.loading'
    className='custom-class  pulse'
  />
);
