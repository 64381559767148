"use strict";
import { useCallback, useEffect, useRef } from "react";
const LISTEN_EVENTS = [
  "click",
  // fires when mouse releases, use 'mousedown' for the beginning of a click
  "touchstart"
  // fires when a finger touches a touchscreen
];
export const useClickOutside = (handler, eventTypes) => {
  const clickRef = useRef(null);
  const handleClick = useCallback((ev) => {
    var _a;
    if (!((_a = clickRef.current) == null ? void 0 : _a.contains(ev.target))) {
      handler();
    }
  }, [handler]);
  useEffect(() => {
    (eventTypes != null ? eventTypes : LISTEN_EVENTS).forEach((eventName) => {
      document.addEventListener(eventName, handleClick);
    });
    return () => {
      (eventTypes != null ? eventTypes : LISTEN_EVENTS).forEach((eventName) => {
        document.removeEventListener(eventName, handleClick);
      });
    };
  }, [eventTypes, handleClick]);
  return clickRef;
};
