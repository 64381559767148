"use strict";
import { IDLE } from "../../schema/fetched";
import { initial } from "../../schema/normalized";
export const analyzerState = {
  panels: initial(),
  sessions: initial(),
  anomalies: initial(),
  panelSignals: initial(),
  main: IDLE,
  signals: IDLE
};
export const analyzerTestState = {
  panels: initial(),
  sessions: initial(),
  anomalies: initial(),
  panelSignals: initial(),
  main: IDLE,
  signals: IDLE
};
