"use strict";
import PropTypes from "prop-types";
import { DATE_FROM, DATE_TO } from "../../../../common/constants";
export const datePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.object]);
export const relativeDatePropType = PropTypes.shape({
  count: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired
});
export const dateFilters = PropTypes.shape({
  [DATE_FROM]: datePropType,
  [DATE_TO]: datePropType
});
export const measurement = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired
});
export const intlMessage = PropTypes.shape({
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired
});
