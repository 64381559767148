"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import socket from "socket.io-client";
import * as socketTypes from "../../common/socketTypes";
import { commandJournalMessageSchema } from "../../common/types/api/callback";
import { token } from "./auth/proxy";
import { receivedCommandJournalMessage } from "./redux/modules/configRepo.callback.receive.module";
import { receiveComment as receiveDashboardEventComment } from "./redux/modules/dashboard.event.comment.receive.module";
import { fetchEvents } from "./redux/modules/dashboard.fetch.events.module";
import { receiveDatatron } from "./redux/modules/inventory.datatron.receive.module";
import { receiveMachine } from "./redux/modules/inventory.machine.receive.module";
import { receiveMachineEventComment } from "./redux/modules/machine.event.comment.receive.module";
import { receiveComment as receiveMaintenanceEventComment } from "./redux/modules/maintenance.event.comment.receive.module";
import { receiveRoles } from "./redux/modules/profile.roles.receive.module";
import store from "./redux/store";
export const initializeSocketClient = () => {
  const authToken = token();
  const socketClient = socket(__spreadValues({
    path: "/socket.io"
  }, authToken && {
    extraHeaders: {
      "x-auth-token": `Bearer ${authToken}`
    }
  }));
  socketClient.on(socketTypes.newComment, (comment) => {
    store.dispatch(receiveDashboardEventComment(comment));
    store.dispatch(receiveMaintenanceEventComment(comment));
    store.dispatch(receiveMachineEventComment(comment));
  });
  socketClient.on(socketTypes.dashboardUpdate, () => {
    const state = store.getState();
    if (state.routing.locationBeforeTransitions.pathname === "/app/dashboard") {
      store.dispatch(fetchEvents(true));
    }
  });
  socketClient.on(socketTypes.newMachine, (machine) => store.dispatch(receiveMachine(machine)));
  socketClient.on(socketTypes.newDatatron, (datatron) => store.dispatch(receiveDatatron(datatron)));
  socketClient.on(socketTypes.userRolesUpdate, (roles) => store.dispatch(receiveRoles(roles)));
  socketClient.on(socketTypes.configRepositoryCallback, (message) => {
    const parsed = commandJournalMessageSchema.safeParse(message);
    if (!parsed.success) {
      console.error("parse command journal message: ", parsed.error);
      return;
    }
    store.dispatch(receivedCommandJournalMessage(parsed.data));
  });
};
