import React from 'react';
import { Resizable, ResizeCallback } from 're-resizable';
import { MIN_SIGNAL_HEIGHT, MAX_SIGNAL_HEIGHT } from '../../../../../common/constants/analyzer';
import { WrapperContainer, ResizeIconStyled } from './ResizableWrapper.styled';

interface ResizableWrapperProps {
  defaultHeight: number;
  minHeight?: number;
  maxHeight?: number;
  onResizeStart?: (e: React.MouseEvent | React.TouchEvent) => void;
  onResizeStop?: ResizeCallback;
  children: React.ReactNode;
}

export const ResizableWrapper: React.FC<ResizableWrapperProps> = ({
  defaultHeight,
  minHeight = MIN_SIGNAL_HEIGHT,
  maxHeight = MAX_SIGNAL_HEIGHT,
  onResizeStart,
  onResizeStop,
  children,
}) => (
  <WrapperContainer>
    <Resizable
      defaultSize={{
        width: '100%',
        height: defaultHeight,
      }}
      minHeight={minHeight}
      maxHeight={maxHeight}
      enable={{
        top: false,
        right: false,
        bottom: true,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      handleComponent={{
        bottom: <ResizeIconStyled />,
      }}
      onResizeStart={onResizeStart}
      onResizeStop={onResizeStop}
      grid={[1, 10]}
    >
      {children}
    </Resizable>
  </WrapperContainer>
);
