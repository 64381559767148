import React from 'react';
import { useSelector } from 'react-redux';

import {
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ScatterChart,
  Scatter,
  ReferenceLine,
  ReferenceArea,
} from 'recharts';
import {
  chartConfig,
  useXAxisProps,
  useCursorProps,
  useMouseProps,
  usePanelZoom,
  useTooltipProps,
  useYAxisProps,
  zoomAreaProps,
} from './Helper';
import { useBitRulerBoxes } from './useBitRulerBoxes';
import { InterpolationType } from '../../../../../common/validation/analyzer.validation';
import { usePanelAtom } from '../../../atom/panelHelper';
import { getPanelSettingsByPanelId } from '../../../redux/selectors/analyzer.selector';

interface Props {
  data: any[];
  mainSignalAttr: {
    id: number;
    type: 'integer' | 'double' | 'bool';
    color: string;
    interpolationType: InterpolationType;
  };
  fromUnixNano: number;
  toUnixNano: number;
  panelId: number;
}

export const CustomScatterChart: React.FC<Props> = ({
  data,
  mainSignalAttr,
  fromUnixNano,
  toUnixNano,
  panelId,
}) => {
  const xAxisProps = useXAxisProps(fromUnixNano, toUnixNano);
  const yAxisProps = useYAxisProps(mainSignalAttr.type);
  const { color } = mainSignalAttr;
  const { showTooltips } = useSelector((state) => getPanelSettingsByPanelId(state, panelId));
  const tooltipProps = useTooltipProps(showTooltips);
  const [panelAtom] = usePanelAtom(panelId);
  const { time, tool } = panelAtom;

  const { cursorProps, highlightProps } = useCursorProps(time.cursorUnixNano, tool);
  const bitRulerBoxes = useBitRulerBoxes(mainSignalAttr, { data });
  const mouseProps = useMouseProps(panelId, mainSignalAttr.id);
  const zooming = usePanelZoom(panelId, tool);

  return (
    <ResponsiveContainer {...chartConfig.styles.responsiveContainer}>
      <ScatterChart data={data} margin={chartConfig.margin} {...mouseProps}>
        <CartesianGrid {...chartConfig.grid} />
        <XAxis {...xAxisProps} />
        <YAxis {...yAxisProps} />
        <Tooltip {...tooltipProps} />
        <Scatter dataKey='value' fill={color} stroke={color} strokeWidth={5} shape='square' />
        {zooming.secondUnixNano !== null && (
          <ReferenceArea
            x1={zooming.firstUnixNano}
            x2={zooming.secondUnixNano}
            {...zoomAreaProps}
          />
        )}
        {highlightProps && <ReferenceLine {...highlightProps} />}
        {cursorProps && <ReferenceLine {...cursorProps} />}
        {bitRulerBoxes.map(([addonId, bitRulerProps]) => (
          <ReferenceArea key={addonId} {...bitRulerProps} />
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  );
};
