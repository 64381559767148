"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { isEmpty } from "lodash";
import { scroller } from "react-scroll";
import { startSubmit, stopSubmit, touch } from "redux-form";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getInventoryLink } from "../../components/index/routes";
import * as datatronsAPI from "../../core/api/datatrons";
import { findFirstErrorField } from "../../core/common/machine";
import { validatePhoto } from "../../core/validation/datatrons.form.validation";
import * as dfc from "../constants/datatron.form.constants";
import { NO_FILE } from "../constants/fileUpload.component.constants";
import { getFormFields } from "../selectors/newDatatron.selector";
import { fetchInventory } from "./inventory.fetch.module";
import { AppReducerMapBuilder } from "./util";
export const createDatatron = createAction("create datatron");
export const createDatatronSuccess = createAction(
  "create datatron - success",
  (payload) => ({ payload })
);
export const createDatatronFail = createAction(
  "create datatron - fail",
  (error) => ({ payload: error })
);
export const reducer = AppReducerMapBuilder.new().add(createDatatron, (state) => __spreadProps(__spreadValues({}, state), {
  newDatatron: __spreadProps(__spreadValues({}, state.newDatatron), {
    creating: true
  })
})).add(createDatatronSuccess, (state) => __spreadProps(__spreadValues({}, state), {
  newDatatron: __spreadProps(__spreadValues({}, state.newDatatron), {
    creating: false
  })
})).add(createDatatronFail, (state, error) => __spreadProps(__spreadValues({}, state), {
  newDatatron: __spreadProps(__spreadValues({}, state.newDatatron), {
    creating: false,
    error
  })
})).build();
export function* touchAllFieldsSaga() {
  yield put(touch(dfc.DATATRON_FORM_NAME, ...dfc.DATATRON_PROPS));
}
export function* getFields() {
  const state = yield select();
  const fields = getFormFields(state);
  if (fields[dfc.ORGANIZATION_FIELD]) {
    fields[dfc.ORGANIZATION_FIELD] = fields[dfc.ORGANIZATION_FIELD].value;
  }
  if (fields[dfc.PHOTO_FIELD] === NO_FILE) {
    delete fields[dfc.PHOTO_FIELD];
  }
  return fields;
}
export function* handleErrors(errors) {
  yield put(createDatatronFail(errors));
  yield put(stopSubmit(dfc.DATATRON_FORM_NAME, errors));
  const firstErrorField = yield findFirstErrorField(dfc.DATATRON_PROPS, errors);
  if (firstErrorField) {
    yield scroller.scrollTo(firstErrorField, {
      offset: dfc.DATATRON_FORM_ERROR_FIELD_OFFSET
    });
  }
}
export function* createDatatronSaga() {
  yield call(touchAllFieldsSaga);
  const fields = yield call(getFields);
  yield put(startSubmit(dfc.DATATRON_FORM_NAME));
  const preRequestValidationErrors = yield call(validatePhoto, fields);
  if (!isEmpty(preRequestValidationErrors)) {
    yield handleErrors(preRequestValidationErrors);
    return;
  }
  const { response, error } = yield call(datatronsAPI.createDatatron, fields);
  if (response) {
    yield put(createDatatronSuccess(response));
    yield put(fetchInventory());
    yield put(stopSubmit(dfc.DATATRON_FORM_NAME));
    yield put(push(getInventoryLink()));
  } else {
    yield handleErrors(error);
  }
}
export function* watchCreateDatatron() {
  yield takeLatest(createDatatron, createDatatronSaga);
}
