"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import { updateLocationInTree } from "../../core/common/locationPicker";
import { getLocationParentTree } from "../../core/api/locations";
import { fetchLocationPicker } from "./locationPicker.fetch.module";
export const initLocationPicker = createAction(
  "init location picker with location",
  (initLocationId) => ({ payload: initLocationId })
);
export const initLocationPickerSuccess = createAction(
  "init location picker with location success",
  (newTree, initLocationId) => ({ payload: { newTree, initLocationId } })
);
export const initLocationPickerFail = createAction(
  "init location picker with location fail",
  (error) => ({ payload: error })
);
export const reducer = {
  [initLocationPicker.type]: (state) => __spreadProps(__spreadValues({}, state), {
    locationPicker: __spreadProps(__spreadValues({}, state.locationPicker), {
      loading: true,
      loaded: false,
      error: null
    })
  }),
  [initLocationPickerSuccess.type]: (state, { newTree, initLocationId }) => __spreadProps(__spreadValues({}, state), {
    locationPicker: __spreadProps(__spreadValues({}, state.locationPicker), {
      tree: newTree,
      loading: false,
      loaded: true,
      error: null,
      selectedLocationId: initLocationId
    })
  }),
  [initLocationPickerFail.type]: (state, error) => __spreadProps(__spreadValues({}, state), {
    locationPicker: __spreadProps(__spreadValues({}, state.locationPicker), {
      loading: false,
      loaded: false,
      error
    })
  })
};
export function* initLocationPickerSaga({ payload: initLocationId }) {
  if (initLocationId === void 0 || initLocationId === "undefined") {
    yield put(fetchLocationPicker(null));
  }
  const { response, error } = yield call(getLocationParentTree, initLocationId);
  if (response) {
    const tree = yield call(
      updateLocationInTree,
      [response],
      initLocationId,
      { selected: true },
      { selected: false }
    );
    yield put(initLocationPickerSuccess(tree, initLocationId));
    yield put(fetchLocationPicker(null));
  } else {
    yield put(initLocationPickerFail(error));
  }
}
export function* watchInitLocationPicker() {
  yield takeEvery(initLocationPicker, initLocationPickerSaga);
}
