"use strict";
export const ouInitialState = {
  name: "",
  members: [],
  tags: [],
  locations: [],
  loading: false,
  loaded: false
};
export const ouTestState = {
  name: "Team",
  members: [{
    id: 1,
    firstName: "John",
    lastName: "Smith",
    account: {
      name: "AltaSigma"
    },
    email: "a@a.com"
  }],
  tags: [{
    name: "tag1"
  }],
  locations: [{
    id: 1,
    accountId: 1,
    name: "asd",
    type: "sdf",
    parentId: 1
  }],
  loading: false,
  loaded: false
};
