import React, { useMemo } from 'react';
import * as RadixContextMenu from '@radix-ui/react-context-menu';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';
import { MenuItem, MenuLabel } from '.';
import * as helper from './helper';
import { PanelContextMenuItems, PanelContextMenuItemsProps } from './PanelContextMenu';
import { ColorMenu } from './ColorMenu';
import { ChartTypeMenu } from './ChartTypeMenu';
import { InterpolationTypeMenu } from './InterpolationTypeMenu';
import { ChartType, InterpolationType } from '../../../../../common/validation/analyzer.validation';
import { PanelSignal } from '../../../schema/type/panelSignal';
import analyzerMessages from '../../../messages/analyzer.messages';
import commonMessages from '../../../messages/common.messages';

interface Props extends PanelContextMenuItemsProps {
  signalType: PanelSignal['type'];
  color: string;
  setColor: (color: string) => void;
  chartType: ChartType;
  setChartType: (chartType: ChartType) => void;
  interpolationType: InterpolationType;
  setInterpolationType: (interpolationType: InterpolationType) => void;
  removeSignal: () => void;
  children: React.ReactNode;
}

const signalsWithChartAndInterpolationType = new Set<PanelSignal['type']>([
  'bool',
  'double',
  'integer',
]);

export const BasePanelSignalContextMenu: React.FC<Props> = ({
  signalType,
  color,
  setColor,
  chartType,
  setChartType,
  interpolationType,
  setInterpolationType,
  removeSignal,
  panelSettings,
  setPanelSettings,
  children,
}) => {
  const { formatMessage } = useIntl();
  // Debounced remove signal with error handling
  const debouncedRemoveSignal = useMemo(
    () =>
      debounce(() => {
        try {
          removeSignal();
        } catch (error) {
          console.error('Error removing signal:', error);
        }
      }, 0), // 0ms delay to exit current render cycle
    [removeSignal],
  );

  const debouncedSetColor = useMemo(
    () =>
      debounce((newColor: string) => {
        setColor(newColor);
      }, 0),
    [setColor],
  );

  const debouncedSetChartType = useMemo(
    () =>
      debounce((newChartType: ChartType) => {
        setChartType(newChartType);
      }, 0),
    [setChartType],
  );

  const debouncedSetInterpolationType = useMemo(
    () =>
      debounce((newInterpolationType: InterpolationType) => {
        setInterpolationType(newInterpolationType);
      }, 0),
    [setInterpolationType],
  );

  const debouncedSetPanelSettings = useMemo(
    () =>
      debounce((newPanelSettings) => {
        setPanelSettings(newPanelSettings);
      }, 0),
    [setPanelSettings],
  );

  return (
    <RadixContextMenu.Root>
      <RadixContextMenu.Trigger>{children}</RadixContextMenu.Trigger>
      <RadixContextMenu.Portal>
        <RadixContextMenu.Content className={helper.contentClass}>
          <MenuLabel>{formatMessage(analyzerMessages.signalOptions)}</MenuLabel>
          <MenuItem onSelect={debouncedRemoveSignal}>
            {formatMessage(commonMessages.remove)}
          </MenuItem>
          <ColorMenu color={color} formatMessage={formatMessage} setColor={debouncedSetColor} />
          {signalsWithChartAndInterpolationType.has(signalType) && (
            <ChartTypeMenu
              formatMessage={formatMessage}
              chartType={chartType}
              setChartType={debouncedSetChartType}
            />
          )}
          {signalsWithChartAndInterpolationType.has(signalType) && (
            <InterpolationTypeMenu
              formatMessage={formatMessage}
              interpolationType={interpolationType}
              setInterpolationType={debouncedSetInterpolationType}
            />
          )}
          <RadixContextMenu.Separator className='h-px bg-verdigris m-1' />
          <PanelContextMenuItems
            panelSettings={panelSettings}
            setPanelSettings={debouncedSetPanelSettings}
          />
        </RadixContextMenu.Content>
      </RadixContextMenu.Portal>
    </RadixContextMenu.Root>
  );
};
