"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import { setCallbackCommandId } from "./configRepo.callback.receive.module";
import { AppReducerMapBuilder } from "./util";
import {
  archiveAnomaly,
  archiveSession,
  deleteSession,
  unarchiveAnomaly,
  unarchiveSession
} from "../../core/api/analyzer";
import * as norm from "../../schema/normalized";
import { succeeded } from "../../schema/fetched";
import { getAnalyzerLink } from "../../components/index/routes";
import { transformErrorToMessageString } from "../../../../common/utils";
export const submitSessionQuickCommand = createAction(
  "session quick command submitted",
  (sessionId, command, comment, classification) => ({
    payload: { sessionId, command, comment, classification }
  })
);
export const sessionQuickCommandSuccess = createAction(
  "session quick command success",
  (sessionId) => ({
    payload: { sessionId }
  })
);
export const sessionQuickCommandFailure = createAction(
  "session quick command failure",
  (sessionId, error) => ({ payload: { sessionId, error } })
);
export const addNewSessionPanel = createAction("add new session panel", (panel) => ({
  payload: panel
}));
export const reducer = AppReducerMapBuilder.new().add(submitSessionQuickCommand, (state, { sessionId }) => __spreadProps(__spreadValues({}, state), {
  modals: __spreadProps(__spreadValues({}, state.modals), {
    commandState: __spreadProps(__spreadValues({}, state.modals.commandState), {
      sessionId,
      submitting: true
    })
  })
})).add(sessionQuickCommandSuccess, (state, { sessionId }) => __spreadProps(__spreadValues({}, state), {
  modals: __spreadProps(__spreadValues({}, state.modals), {
    commandState: {
      submitting: false,
      success: true,
      sessionId
    }
  })
})).add(sessionQuickCommandFailure, (state, { sessionId, error }) => __spreadProps(__spreadValues({}, state), {
  modals: __spreadProps(__spreadValues({}, state.modals), {
    commandState: {
      submitting: false,
      success: false,
      sessionId,
      error
    }
  })
})).add(addNewSessionPanel, (state, panel) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    panels: __spreadValues({}, norm.upsert(state.analyzer.panels, panel.id, succeeded(panel)))
  })
})).build();
const sessionQuickCommandEndpoint = (command) => {
  switch (command) {
    case "ARCHIVE_SESSION":
      return archiveSession;
    case "UNARCHIVE_SESSION":
      return unarchiveSession;
    case "DELETE_SESSION":
      return deleteSession;
    case "ARCHIVE_ANOMALY":
      return archiveAnomaly;
    case "UNARCHIVE_ANOMALY":
      return unarchiveAnomaly;
    default:
      return () => ({
        response: null
      });
  }
};
export function* sessionQuickCommandSaga({
  payload: { sessionId, command, comment, classification }
}) {
  const endpoint = sessionQuickCommandEndpoint(command);
  const { response, error } = yield call(endpoint, sessionId, comment, classification);
  if (response) {
    yield put(setCallbackCommandId(response.commandId));
    yield put(sessionQuickCommandSuccess(sessionId));
    yield put(push(getAnalyzerLink()));
  } else {
    yield put(setCallbackCommandId(-1));
    const errorString = transformErrorToMessageString(error);
    yield put(sessionQuickCommandFailure(sessionId, errorString));
  }
}
export function* watchSessionQuickCommand() {
  yield takeEvery(submitSessionQuickCommand, sessionQuickCommandSaga);
}
