import React, { useEffect, useState } from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import * as form from '../../../../redux/constants/analyzer.form.constants';
import formsPlaceholdersMessages from '../../../../messages/forms.placeholders.messages';
import commonCoreMessages from '../../../../messages/common.core.messages';
import ModalLine from '../../core/ModalLine';
import AddModalFooter from '../../core/footers/AddModalFooter';
import EditModalFooter from '../../core/footers/EditModalFooter';
import { FormField } from '../../../_tools';
import { getDevices } from '../../../../core/api/datatrons';
import { reduxForm } from 'redux-form';
import { ANALYZER_SESSION_FORM } from '../../../../redux/constants/analyzer.form.constants';
import { DatatronSelect } from './details/DatatronSelect';
import { DevicesSelect } from './details/DevicesSelect';

interface FormSession {
  [form.DATATRON_SELECT_FIELD]: string;
  [form.DEVICES_SELECT_FIELD]: string[];
  [form.NAME_FIELD]: string;
}

interface Props {
  handleCancel: () => void;
  isEditForm: boolean;
}
interface SessionFormProps extends InjectedFormProps<FormSession, Props> {
  handleCancel: () => void;
  isEditForm: boolean;
}

const SessionForm: React.FC<SessionFormProps> = ({
  handleSubmit,
  handleCancel,
  submitting,
  pristine,
  isEditForm,
}) => {
  const [selectedDatatronId, setSelectedDatatronId] = useState<string | null>(null);
  const [devices, setDevices] = useState<any[]>([]);
  const [isLoadingDevices, setIsLoadingDevices] = useState(false);

  useEffect(() => {
    const fetchDevices = async () => {
      if (selectedDatatronId) {
        setIsLoadingDevices(true);
        try {
          const { response } = await getDevices(selectedDatatronId);
          if (Array.isArray(response)) {
            setDevices(response);
          }
        } catch (error) {
          console.error('Error fetching devices:', error);
        } finally {
          setIsLoadingDevices(false);
        }
      } else {
        setDevices([]);
      }
    };

    fetchDevices();
  }, [selectedDatatronId]);

  return (
    <form className='form' onSubmit={handleSubmit}>
      <div className='modal-form-in'>
        <label>
          <FormattedMessage {...commonCoreMessages.datatron} tagName='span' />
          <Field
            component={DatatronSelect}
            name={form.DATATRON_SELECT_FIELD}
            tabIndex={2}
            iconClassName='icon icon-search'
            disabled={isEditForm}
            onDatatronChange={setSelectedDatatronId}
          />
        </label>
        <label>
          <FormattedMessage {...commonCoreMessages.devices} tagName='span' />
          <Field
            component={DevicesSelect}
            name={form.DEVICES_SELECT_FIELD}
            tabIndex={3}
            disabled={!selectedDatatronId || isEditForm}
            devices={devices}
            isLoading={isLoadingDevices}
          />
        </label>

        <FormField
          label={commonCoreMessages.name.id}
          name={form.NAME_FIELD}
          placeholder={formsPlaceholdersMessages.name.id}
          tabIndex={1}
          maxLength='255'
          autoFocus
        />
      </div>
      <ModalLine />
      {isEditForm ? (
        <EditModalFooter
          onCancelClick={handleCancel}
          onSaveClick={handleSubmit}
          isAddButtonDisabled={pristine || submitting}
          isAddButtonLoading={submitting}
          cancelButtonTabIndex={7}
          addButtonTabIndex={8}
        />
      ) : (
        <AddModalFooter
          onCancelClick={handleCancel}
          onAddClick={handleSubmit}
          isAddButtonDisabled={pristine || submitting}
          isAddButtonLoading={submitting}
          cancelButtonTabIndex={7}
          addButtonTabIndex={8}
        />
      )}
    </form>
  );
};

const currentForm = {
  form: ANALYZER_SESSION_FORM,
  destroyOnUnmount: true,
};
export default reduxForm<FormSession, Props>(currentForm)(SessionForm);
