"use strict";
import { z } from "zod";
import { errorSchema } from "./authorized";
export const locationTypeSchema = z.enum([
  "organization",
  "country",
  "state",
  "city",
  "district",
  "factory",
  "hall",
  "floor",
  "assembly",
  "line",
  "production"
]);
export const locationSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
  type: locationTypeSchema,
  path: z.array(z.string().min(1)).min(1).nullable()
});
const paginationSchema = z.object({
  total: z.number(),
  current_page: z.number().min(1),
  per_page: z.number().min(1),
  total_pages: z.number().min(1)
});
export const getLocationsSchema = z.union([
  errorSchema,
  z.object({
    pagination: paginationSchema,
    list: z.array(locationSchema)
  })
]);
