"use strict";
import * as eventCategories from "./eventCategory";
export const availability = "availability";
export const quality = "quality";
export const performance = "performance";
export const maintenancePlanned = "planned";
export const maintenanceUnplanned = "unplanned";
export const eventInfo = eventCategories.info;
export const eventFatal = eventCategories.fatal;
export const eventError = eventCategories.error;
export const eventAnomaly = eventCategories.anomaly;
export const eventWarning = eventCategories.warning;
export const maintenanceTypes = [
  maintenancePlanned,
  maintenanceUnplanned
];
export const events = [
  eventInfo,
  eventFatal,
  eventError,
  eventAnomaly,
  eventWarning
];
export const oee = [
  availability,
  performance,
  quality
];
export const PERIOD_TYPES = [
  ...maintenanceTypes,
  ...events,
  ...oee
];
export default PERIOD_TYPES;
