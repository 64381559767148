"use strict";
export const initial = {
  listening: false,
  commandId: null,
  messages: []
};
export const testState = {
  listening: true,
  commandId: 1,
  messages: [
    {
      commandId: 1,
      commandName: "config",
      commandJournalId: 17,
      state: "CREATED",
      status: "PENDING",
      timestamp: /* @__PURE__ */ new Date("2021-01-01T00:00:00.000Z")
    }
  ]
};
