import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Modal from '../../_tools/modal/Modal';
import { ANALYZER_ANOMALY_PANEL_ADD } from '../../../redux/constants/modals.constants';
import { closeModal } from '../../../redux/modules/modals.module';
import commonMessages from '../../../messages/common.messages';
import analyzerMessages from '../../../messages/analyzer.messages';
import { createAnomalyPanel } from '../../../redux/modules/analyzer.sessions.panel.update.module';

export const AnomalyPanelCreateModal: React.FC = () => {
  const [name, setName] = useState('');
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const close = useCallback(() => {
    dispatch(closeModal(ANALYZER_ANOMALY_PANEL_ADD));
  }, [dispatch]);

  const handleSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      dispatch(createAnomalyPanel(name));
      dispatch(closeModal(ANALYZER_ANOMALY_PANEL_ADD));
    },
    [name, dispatch],
  );

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const isSubmitDisabled = !name.trim();

  return (
    <Modal isOpen onClose={close} contentLabel=''>
      <div className='modal--header' onClick={close}>
        <span className='modal--close'>&times;</span>
      </div>
      <div className='modal--content'>
        <h3>{formatMessage(analyzerMessages.addNewPanelAnomalyTitle)}</h3>
        <div className='form'>
          <label htmlFor='panelName'>
            <span>{formatMessage(commonMessages.name)}:</span>
            <input
              type='text'
              id='panelName'
              name='panelName'
              className='input-group white-group'
              value={name}
              onChange={handleInputChange}
              placeholder={formatMessage(commonMessages.name)}
              required
              maxLength={100}
            />
          </label>
        </div>
        <div className='line' />
        <div className='text-center'>
          <button type='button' className='button__cancel' onClick={close}>
            {formatMessage(commonMessages.cancel)}
          </button>
          <button
            type='submit'
            className='button__save'
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            {formatMessage(commonMessages.save)}
          </button>
        </div>
      </div>
    </Modal>
  );
};
