import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DP_QUICK_COMMAND } from '../../../redux/constants/modals.constants';
import { submitQuickCommand } from '../../../redux/modules/datatron.devices.quickCommands.module';
import { closeModal } from '../../../redux/modules/modals.module';
import {
  selectCommandStateDeviceId,
  selectCommandStateError,
  selectCommandStateIsSubmitting,
  selectCommandStateIsSuccess,
} from '../../../redux/selectors/modal.commandState.selector';
import { getModalPayload, selectCallbackCommandId } from '../../../redux/selectors/modals.selector';
import { AppState } from '../../../redux/state/app.state';
import { Loader } from '../../_tools';
import Modal from '../../_tools/modal/Modal';
import { CommandJournalEntries } from './CommandJournalEntries';
import commonMessages from '../../../messages/common.messages';
import modalMessages from '../../../messages/command.modal.messages';
import { IntlMessage } from '../../../core/common/intl';

type CommandState = 'default' | 'submitting' | 'success' | 'error';

const modalPayloadSelector = (state: AppState) => getModalPayload(state, DP_QUICK_COMMAND);

type Command = 'collect' | 'stop' | 'publish';

interface ModalPayload {
  deviceId: string;
  command: Command;
}

const TITLE: Record<Command, IntlMessage> = {
  collect: modalMessages.collectModalTitle,
  stop: modalMessages.stopModalTitle,
  publish: modalMessages.publishModalTitle,
};

export const QuickCommandModal: React.FC = () => {
  const [commandState, commandStateSet] = useState<CommandState>('default');
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const { deviceId, command }: ModalPayload = useSelector(modalPayloadSelector);

  const commandStateDeviceId = useSelector(selectCommandStateDeviceId);
  const commandSuccess = useSelector(selectCommandStateIsSuccess);
  const commandError = useSelector(selectCommandStateError);
  const submitting = useSelector(selectCommandStateIsSubmitting);

  const dispatch = useDispatch();

  const close = useCallback(() => {
    dispatch(closeModal(DP_QUICK_COMMAND));
  }, [dispatch]);

  const handleSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      timeout.current = setTimeout(commandStateSet, 30 * 1000, 'error');
      commandStateSet('submitting');
      event.preventDefault();
      dispatch(submitQuickCommand(deviceId, command));
    },
    [command, deviceId, dispatch],
  );

  const commandId = useSelector(selectCallbackCommandId);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (commandId !== null && commandId < 0) {
      commandStateSet('error');
    }

    if (commandState === 'submitting' && !submitting && commandStateDeviceId === deviceId) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
      if (commandSuccess) {
        commandStateSet('success');
      } else if (commandError) {
        commandStateSet('error');
      }
    }
  }, [
    commandError,
    commandId,
    commandState,
    commandStateDeviceId,
    commandSuccess,
    deviceId,
    submitting,
  ]);

  const setDone = useCallback(() => commandStateSet('success'), []);

  return (
    <Modal isOpen onClose={close} contentLabel=''>
      <div className='modal--header' onClick={close}>
        <span className='modal--close'>&times;</span>
      </div>
      <div className='modal--content'>
        <h3>{formatMessage(TITLE[command])}</h3>
        {commandState === 'submitting' && (
          <div style={{ height: '100%', width: '100%' }}>
            <Loader show fullPage={false} />
          </div>
        )}
        <CommandJournalEntries setDone={setDone} />
        {commandState === 'error' && (
          <h4 className='flexed error-text-red'>
            {formatMessage(modalMessages.error)}: {commandError}
          </h4>
        )}
        <div className='line' />
        <div className='text-center'>
          {(commandState === 'default' || commandState === 'submitting') && (
            <button type='button' className='button__cancel' onClick={close}>
              {formatMessage(commonMessages.cancel)}
            </button>
          )}
          {commandState === 'default' && (
            <button type='submit' className='button__save' onClick={handleSubmit}>
              {formatMessage(commonMessages.start)}
            </button>
          )}
          {commandState === 'success' && (
            <button type='submit' className='button__save' onClick={close}>
              {formatMessage(commonMessages.close)}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
