import React, { useEffect } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import { useSelector } from 'react-redux';
import { PanelList } from './panel/PanelList';
import { AnalyzerSessionEditorHeader } from './header/AnalyzerSessionEditorHeader';
import AnomalyActions from './anomaly/AnomalyActions';
import { Loader } from '../_tools';
import { ErrorPlaceholder } from '../inventory/list/ErrorPlaceholder';
import { onAnalyzerAnomalyEditorEnter, onAnalyzerAnomalyEditorLeave } from '../index/routeHandlers';
import { decodeId } from '../../redux/util';
import {
  getAnomaly,
  getAnomalyLocation,
  anomalyError,
  anomalyLoading,
} from '../../redux/selectors/analyzer.selector';

interface AnalyzerProps extends RouteConfigComponentProps {}

const useAnomalyId = (match: any): number => {
  const { anomalyId } = match.params as { anomalyId: string };
  return decodeId(anomalyId);
};

export const AnalyzerAnomalyEditorPage: React.FC<AnalyzerProps> = ({ match }) => {
  const anomalyId = useAnomalyId(match);
  useEffect(() => {
    if (anomalyId && match.isExact) {
      onAnalyzerAnomalyEditorEnter(anomalyId);
    }

    return () => {
      onAnalyzerAnomalyEditorLeave();
    };
  }, [anomalyId, match.isExact]);

  const isLoading = useSelector((state) => anomalyLoading(state, anomalyId));
  const error = useSelector((state) => anomalyError(state, anomalyId));
  const location = useSelector((state) => getAnomalyLocation(state, anomalyId));
  const anomaly = useSelector((state) => getAnomaly(state, anomalyId));

  return (
    <>
      <Loader show={isLoading} />
      {error && <ErrorPlaceholder />}
      {!error && anomaly && (
        <>
          <div className='col--1'>
            <AnalyzerSessionEditorHeader location={location} />
          </div>
          <div className='col--1 location-page'>
            <div className='analyzer-container-edit-page'>
              <AnomalyActions anomaly={anomaly} />
              <div className='analyzer-container-wrap'>
                <PanelList />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
