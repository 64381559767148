"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { selectAllReports, getReportById } from "../selectors/reports.selector";
import { replaceObjectInList, compareById } from "../../../../common/helpers/object";
export const setReportChartType = createAction(
  "set report chart type",
  (reportId, chartType) => ({ payload: { reportId, chartType } })
);
export const reducer = {
  [setReportChartType.toString()]: (state, { reportId, chartType }) => {
    const allReports = selectAllReports(state);
    const report = getReportById(reportId, allReports);
    if (!report)
      return state;
    return __spreadProps(__spreadValues({}, state), {
      reports: __spreadProps(__spreadValues({}, state.reports), {
        data: replaceObjectInList(
          allReports,
          __spreadProps(__spreadValues({}, report), { chartType }),
          compareById
        )
      })
    });
  }
};
