"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { SORT_WAY_ASC, SORT_WAY_DESC } from "../../../../common/constants";
import { getDeviceById } from "../selectors/datatron.selector";
import { compareById, replaceObjectInList } from "../../../../common/helpers/object";
export const sort = createAction(
  "sort datapoints list",
  (option, deviceId, archived = false) => ({ payload: { deviceId, archived, option } })
);
export const filterByQuery = createAction(
  "filter datapoints list by query",
  (query, deviceId, archived = false) => ({ payload: { deviceId, archived, query } })
);
export const filterByField = createAction(
  "filter datapoints list by field value",
  (field, value, deviceId, archived = false) => ({ payload: { deviceId, archived, field, value } })
);
const getListName = (archived) => {
  if (!archived)
    return "dataPoints";
  return "archivedDataPoints";
};
export const reducer = {
  [sort.type]: (state, { deviceId, archived, option }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) {
      return state;
    }
    const listName = getListName(archived);
    const prevOption = device[listName].sort.option;
    const prevWay = device[listName].sort.way;
    let newOption = null;
    let newWay = null;
    if (prevOption === option && prevWay === SORT_WAY_ASC) {
      newOption = prevOption;
      newWay = SORT_WAY_DESC;
    } else if (prevOption !== option) {
      newOption = option;
      newWay = SORT_WAY_ASC;
      if (option === "enabled" && prevWay === null) {
        newWay = SORT_WAY_DESC;
      }
    }
    const newDevice = __spreadProps(__spreadValues({}, device), {
      [listName]: __spreadProps(__spreadValues({}, device[listName]), {
        sort: { option: newOption, way: newWay }
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById
          )
        })
      })
    });
  },
  [filterByQuery.type]: (state, { deviceId, archived, query }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) {
      return state;
    }
    const listName = getListName(archived);
    const newDevice = __spreadProps(__spreadValues({}, device), {
      [listName]: __spreadProps(__spreadValues({}, device[listName]), {
        query
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById
          )
        })
      })
    });
  },
  [filterByField.type]: (state, { deviceId, archived, field, value }) => {
    const device = getDeviceById(state, deviceId);
    if (!device) {
      return state;
    }
    const listName = getListName(archived);
    const newDevice = __spreadProps(__spreadValues({}, device), {
      [listName]: __spreadProps(__spreadValues({}, device[listName]), {
        filters: __spreadProps(__spreadValues({}, device[listName].filters), { [field]: value })
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById
          )
        })
      })
    });
  }
};
