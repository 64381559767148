"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import {
  EVENTS_MANAGER
} from "../constants/permissions";
class EventsPermissions {
  constructor(roles) {
    __publicField(this, "isEventsManager");
    this.isEventsManager = roles.indexOf(EVENTS_MANAGER) !== -1;
  }
  canCreateActivities() {
    return this.isEventsManager;
  }
  canUpdateActivities() {
    return this.isEventsManager;
  }
  canAddEventToActivities() {
    return this.isEventsManager;
  }
  canCreateClustersByMachiche() {
    return this.isEventsManager;
  }
  canCreateEventsByMachine() {
    return this.isEventsManager;
  }
  canUpdateEvents() {
    return this.isEventsManager;
  }
  canCreateNewComments() {
    return this.isEventsManager;
  }
}
export default EventsPermissions;
