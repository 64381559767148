import React from 'react';
import { useSelector } from 'react-redux';
import { getPanels } from '../../../redux/selectors/analyzer.selector';
import { Panel } from './Panel';

export const PanelList: React.FC = () => {
  const panels = useSelector(getPanels);
  return (
    <>
      <div className='container  mb-4'>
        {panels.map((panelId) => (
          <Panel panelId={panelId} key={`${panelId}-panel`} />
        ))}
      </div>
    </>
  );
};
