"use strict";
const machines = [
  {
    id: 1,
    name: "Milling Robot 1",
    status: "alert",
    serialNumber: "1"
  },
  {
    id: 2,
    name: "Milling Robot 2",
    status: "",
    serialNumber: "2"
  }
];
const locations = [
  {
    id: "LOCATION1",
    name: "Location",
    path: ["Berlin", "Floor #1"],
    type: "city",
    INVENTORY_MACHINES: machines,
    INVENTORY_DATATRONS: []
  }
];
export const inventoryInitialState = {
  locations: [],
  machines: [],
  datatrons: [],
  loading: false,
  loaded: false,
  error: "",
  preview: {
    loading: false,
    loaded: false,
    error: null,
    itemId: null,
    itemType: null,
    item: null
  }
};
export const inventoryTestState = {
  locations,
  machines,
  datatrons: [],
  loading: false,
  loaded: true,
  error: "",
  preview: {
    loading: false,
    loaded: false,
    error: null,
    itemId: null,
    itemType: null,
    item: null
  }
};
