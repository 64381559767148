import React from 'react';
import classNames from 'classnames';

import ImageContainer from '../../_tools/image/Image.container';
import CardPhotoDetails from './details/CardPhotoDetails';

interface Props {
  hasAlert?: boolean;
  imageUrl?: string;
  detailsLink?: string;
  canOpenDetails?: boolean;
}

const CardPhoto: React.FC<Props> = ({
  hasAlert = false,
  imageUrl = '',
  canOpenDetails = false,
  detailsLink = '',
}) => {
  const itemClassName = classNames('card--position', 'card--position2', { alert: hasAlert });

  return (
    <div className={itemClassName}>
      <div className='image--wrap'>
        <ImageContainer url={imageUrl || ''} className='card--image' />
        {canOpenDetails && <CardPhotoDetails link={detailsLink} />}
      </div>
    </div>
  );
};

export default CardPhoto;
