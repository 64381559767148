"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { getInstance, initKeycloak } from "./keycloakClient";
const isKeycloakEnabled = () => window.Keycloak !== void 0;
export const initializeAuthorization = (accessToken, refreshToken) => {
  if (isKeycloakEnabled()) {
    return initKeycloak(accessToken, refreshToken);
  }
  return Promise.resolve(true);
};
export const authorized = () => __async(void 0, null, function* () {
  if (isKeycloakEnabled()) {
    return getInstance().updateToken(30);
  }
  return Promise.resolve(true);
});
export const logout = (redirectUri) => {
  if (isKeycloakEnabled()) {
    return getInstance().logout({ redirectUri });
  }
  window.location.href = `/api/auth/logout`;
  return Promise.resolve();
};
export const token = () => {
  var _a;
  if (isKeycloakEnabled()) {
    return (_a = getInstance().token) != null ? _a : "";
  }
  return "";
};
