"use strict";
import { isNumber } from "../../../../common/validation/helpers";
export const getLocations = (state) => state.admin.locations.list;
export const getPagination = (state) => state.admin.locations.pagination;
export const getTotalCount = (state) => {
  const pagination = getPagination(state);
  if (pagination && isNumber(pagination.total))
    return pagination.total;
  return null;
};
export const areLocationsLoaded = (state) => state.admin.locations.loaded;
export const getLocation = (state) => state.admin.locations.selected.item;
export const isLocationLoaded = (state) => state.admin.locations.selected.loaded;
export const getRemoveError = (state) => {
  const location = getLocation(state);
  if (location && location._remove && location._remove.error)
    return location._remove.error;
  return null;
};
export const getFilters = (state) => state.admin.locations.filters;
export const getSort = (state) => getFilters(state).sort;
export const getNameFilterValue = (state) => getFilters(state).q;
export const getFiltersQuery = (state) => {
  const query = { q: getNameFilterValue(state) };
  const sort = getSort(state);
  if (sort.option && sort.way) {
    query[sort.option] = sort.way;
  }
  return query;
};
