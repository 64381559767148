"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "redux-act";
import { put, call, takeLatest, select } from "redux-saga/effects";
import * as aiAppsApi from "../../core/api/aiApps";
import { replaceObjectInList, compareById } from "../../../../common/helpers/object";
import { sendNotification } from "./notifications.module";
import notificationMessage from "../../messages/notification.message";
import { NOTIFICATION_EVENT, NOTIFICATION_ERROR } from "../../core/notifications";
import { stringifyObject } from "../../../../common/helpers/string.helper";
import { getAiAppsDetailsId } from "../selectors/aiApps.selector";
import { fetchAiAppDetails } from "./aiApps.details.fetch.module";
export const triggerCycleDetection = createAction(
  "trigger cycleDetection",
  (cycleDetection) => cycleDetection
);
export const triggerCycleDetectionSuccess = createAction(
  "trigger cycleDetection - success",
  (cycleDetection) => cycleDetection
);
export const triggerCycleDetectionFail = createAction(
  "trigger cycleDetection - failed",
  (error) => error
);
export const reducer = {
  [triggerCycleDetection.getType()]: (state) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      loaded: false,
      loading: true,
      error: null
    })
  }),
  [triggerCycleDetectionSuccess.getType()]: (state, cycleDetection) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      details: __spreadProps(__spreadValues({}, state.aiApps.details), {
        cycleDetections: replaceObjectInList(
          state.aiApps.details.cycleDetections,
          cycleDetection,
          compareById
        )
      }),
      cycleDetectionDetails: cycleDetection,
      loaded: true,
      loading: false,
      error: null
    })
  }),
  [triggerCycleDetectionFail.getType()]: (state, error) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      loaded: false,
      loading: false,
      error
    })
  })
};
export function* triggerCycleDetectionSaga(action) {
  const state = yield select();
  const aiAppId = yield call(getAiAppsDetailsId, state);
  const { response, error } = yield call(aiAppsApi.triggerCycleDetection, action.payload);
  if (response) {
    yield put(fetchAiAppDetails(aiAppId));
    yield put(triggerCycleDetectionSuccess(response));
    yield put(
      sendNotification(
        notificationMessage.successfully_triggered_event,
        notificationMessage.successfully_triggered_event_description,
        NOTIFICATION_EVENT
      )
    );
  } else {
    yield put(triggerCycleDetectionFail(error));
    yield put(
      sendNotification(
        notificationMessage.server_error,
        __spreadProps(__spreadValues({}, notificationMessage.something_happened), {
          values: { error: stringifyObject(error) || "" }
        }),
        NOTIFICATION_ERROR
      )
    );
  }
}
export function* watchTriggerCycleDetection() {
  yield takeLatest(triggerCycleDetection, triggerCycleDetectionSaga);
}
