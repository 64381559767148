import { merge, cloneDeep, forEach, find, filter } from 'lodash';

export const compareById = (objectOne, objectTwo) => objectOne.id === objectTwo.id;

export const compareByItself = (parameterOne, parameterTwo) => parameterOne === parameterTwo;

export const mergeObjectInList = (list, objectToMerge, compareFunc) =>
  list.reduce(
    (final, current) => [
      ...final,
      compareFunc(current, objectToMerge)
        ? merge(cloneDeep(current), cloneDeep(objectToMerge))
        : current,
    ],
    [],
  );

export const replaceObjectInList = (list, objectToReplace, compareFunc) =>
  list.reduce(
    (final, current) => [
      ...final,
      compareFunc(current, objectToReplace) ? objectToReplace : current,
    ],
    [],
  );

export const removeObjectFromList = (list, objectToRemove, compareFunc) =>
  list.reduce((final, current) => {
    if (compareFunc(current, objectToRemove)) return final;
    return [...final, current];
  }, []);

const defaultGetKey = (key) => key;

export const applyCustomOrder = (array = [], order = [], getKey = defaultGetKey) => {
  const sorted = [];

  forEach(order, (orderKey) => {
    const found = find(array, (item) => getKey(item) === orderKey);

    if (found) {
      sorted.push(found);
    }
  });

  const unsorted = filter(array, (item) => order.indexOf(getKey(item)) === -1);

  return [...sorted, ...unsorted];
};

export function arraysAreIdentical(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((element, index) => {
    if (typeof element === 'object' && element !== null) {
      return JSON.stringify(element) === JSON.stringify(arr2[index]);
    }

    return element === arr2[index];
  });
}
