import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { CursorDateInputInputSegment } from './CursorDateInputInputSegment';
import { CURSOR_DATE_TIME_UNITS, CursorDateTimeUnit } from './helper';

import { Tooltip } from '../../_tools/tooltip/TooltipV2';
import commonMessages from '../../../messages/common.messages';

interface DateTimeCursorInputProps {
  value: Date;
  onChange: (newDate: Date) => void;
}

const UNIT_TO_MODIFIER: Record<CursorDateTimeUnit, (date: Date, delta: number) => Date> = {
  yyyy: (date, delta) => {
    const newDate = new Date(date);
    newDate.setFullYear(date.getFullYear() + delta);
    return newDate;
  },
  mm: (date, delta) => {
    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + delta);
    return newDate;
  },
  dd: (date, delta) => {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + delta);
    return newDate;
  },
  h: (date, delta) => {
    const newDate = new Date(date);
    newDate.setHours(date.getHours() + delta);
    return newDate;
  },
  min: (date, delta) => {
    const newDate = new Date(date);
    newDate.setMinutes(date.getMinutes() + delta);
    return newDate;
  },
  s: (date, delta) => {
    const newDate = new Date(date);
    newDate.setSeconds(date.getSeconds() + delta);
    return newDate;
  },
};

const extractDateParts = (date: Date) => ({
  yyyy: date.getFullYear(),
  mm: date.getMonth() + 1,
  dd: date.getDate(),
  h: date.getHours(),
  min: date.getMinutes(),
  s: date.getSeconds(),
});

const prevCursorDateTimeUnit = (unit: CursorDateTimeUnit) => {
  const index = CURSOR_DATE_TIME_UNITS.indexOf(unit);
  return index === 0 ? undefined : CURSOR_DATE_TIME_UNITS[index - 1];
};

const nextCursorDateTimeUnit = (unit: CursorDateTimeUnit) => {
  const index = CURSOR_DATE_TIME_UNITS.indexOf(unit);
  return index === CURSOR_DATE_TIME_UNITS.length - 1
    ? undefined
    : CURSOR_DATE_TIME_UNITS[index + 1];
};

export const DateTimeCursorInput: React.FC<DateTimeCursorInputProps> = ({ value, onChange }) => {
  const { formatMessage } = useIntl();

  const [localDate, setLocalDate] = useState(value);
  useEffect(() => {
    setLocalDate(value);
  }, [value]);

  const dateParts = extractDateParts(localDate);

  const unitRef = useRef(
    new Map<CursorDateTimeUnit, React.RefObject<HTMLDivElement>>(
      CURSOR_DATE_TIME_UNITS.map((unit) => [unit, createRef()]),
    ),
  );

  const incCursorDate = useCallback(
    (unit: CursorDateTimeUnit, delta: number) => {
      const modifier = UNIT_TO_MODIFIER[unit];
      const newDate = modifier(localDate, delta);
      setLocalDate(newDate);
      onChange(newDate);
    },
    [localDate, onChange],
  );

  const onKeyLeft = useCallback((unit: CursorDateTimeUnit) => {
    const prevUnit = prevCursorDateTimeUnit(unit);
    if (!prevUnit) return;
    unitRef.current.get(prevUnit)?.current?.focus();
  }, []);

  const onKeyRight = useCallback((unit: CursorDateTimeUnit) => {
    const nextUnit = nextCursorDateTimeUnit(unit);
    if (!nextUnit) return;
    unitRef.current.get(nextUnit)?.current?.focus();
  }, []);

  const unitProps = useMemo(
    () =>
      new Map(
        CURSOR_DATE_TIME_UNITS.map((unit) => {
          const props = {
            unit,
            ref: unitRef.current.get(unit),
            incCursorUnixNano: (deltaMs: number) => incCursorDate(unit, deltaMs),
            onKeyLeft: () => onKeyLeft(unit),
            onKeyRight: () => onKeyRight(unit),
          };
          return [unit, props];
        }),
      ),
    [onKeyLeft, onKeyRight, incCursorDate],
  );

  return (
    <Tooltip
      position='top'
      data={{ [formatMessage(commonMessages.date)]: formatMessage(commonMessages.startDate) }}
    >
      <div className='input-cursor input-cursor-container'>
        <div className='input-cursor-wrapper'>
          <div className='input-2-digits'>
            <CursorDateInputInputSegment value={dateParts.h} {...unitProps.get('h')!} />
          </div>
          {':'}
          <div className='input-2-digits'>
            <CursorDateInputInputSegment value={dateParts.min} {...unitProps.get('min')!} />
          </div>
          {':'}
          <div className='input-2-digits'>
            <CursorDateInputInputSegment value={dateParts.s} {...unitProps.get('s')!} />
          </div>
          <span className='date-separator'>,</span>
          {/* Date section */}
          <div className='input-2-digits'>
            <CursorDateInputInputSegment value={dateParts.dd} {...unitProps.get('dd')!} />
          </div>
          {'.'}
          <div className='input-2-digits'>
            <CursorDateInputInputSegment value={dateParts.mm} {...unitProps.get('mm')!} />
          </div>
          {'.'}
          <div className='input-4-digits'>
            <CursorDateInputInputSegment value={dateParts.yyyy} {...unitProps.get('yyyy')!} />
          </div>
        </div>
      </div>
    </Tooltip>
  );
};
