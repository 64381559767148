"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { updateLocationInTree } from "../../core/common/locationPicker";
import { fetchLocationPicker } from "./locationPicker.fetch.module";
import { getLatestLoadedParentId } from "../selectors/locationPicker.selector";
export const selectLocation = createAction(
  "select location (location picker)",
  (selectedLocationId) => ({ payload: selectedLocationId })
);
export const reducer = {
  [selectLocation.type]: (state, selectedLocationId) => __spreadProps(__spreadValues({}, state), {
    locationPicker: __spreadProps(__spreadValues({}, state.locationPicker), {
      tree: updateLocationInTree(
        state.locationPicker.tree,
        selectedLocationId,
        { selected: true },
        { selected: false }
      ),
      loading: false,
      loaded: true,
      error: null,
      selectedLocationId
    })
  })
};
export function* selectLocationSaga({ payload: selectedLocationId }) {
  const state = yield select();
  const latestLoadedParentId = yield call(getLatestLoadedParentId, state);
  if (latestLoadedParentId === selectedLocationId)
    return;
  yield put(fetchLocationPicker(selectedLocationId));
}
export function* watchSelectLocation() {
  yield takeEvery(selectLocation, selectLocationSaga);
}
