import React from 'react';

import { useBoundingClientRect } from '../../../_tools/useBoundingClientRect';
import { DATA_POINTS } from '../../../../../../common/constants/entities';
import { dataPoints } from '../../../../../../common/enums/reportTypes';
import { isReportEmpty } from '../../../../../../common/helpers/report';
import { ReportT } from '../../../../../types/report';
import { getDatesByReport } from '../../../../core/common/report.chart.v2';
import { getReportChartData } from '../../../../core/common/reportChart';
import {
  isReportOutputEmpty,
  isTabsBarVisible,
} from '../../../../redux/selectors/reports.selector';
import LabelsWrapper from '../../../_tools/labelsWrapper/LabelsWrapper';
import ReportGraphControlsContainer from './controls/ReportGraphControls.container';
import LegendContainer from './legend/LegendContainer';
import { ReportGraphTabsContainer } from './ReportGraphTabsContainer';
import KpiChartContainer from './ReportKpiChartContainer';
import { ReportSliderContainer } from './ReportSliderContainer';
import { ChartV2Container } from './v2/ChartV2Container';
import { SliderV2Container } from './v2/SliderV2Container';

interface Props {
  report: ReportT;
}

export const ReportGraph: React.FC<Props> = ({ report }) => {
  const newChart = report.data.chartVersion === 'v2';
  const missingReportInput = isReportEmpty(report);
  const noData = isReportOutputEmpty(report);
  const dates = getDatesByReport(report);
  const { allowedProps, data } = getReportChartData({
    report,
    startDateTime: dates.dateFrom,
    endDateTime: dates.dateTo,
  });
  const tabsBarIsVisible = isTabsBarVisible(report);

  const [chartRef, chartRect] = useBoundingClientRect<HTMLDivElement>();
  const [sliderRef, sliderRect] = useBoundingClientRect<HTMLDivElement>();

  return (
    <div className='graph-medium'>
      <div className='tabs-block'>
        {tabsBarIsVisible && (
          <ReportGraphTabsContainer id={report.id} chartType={report.chartType} />
        )}
        <ReportGraphControlsContainer report={report} />

        <div className='tabs-content'>
          <div className='tabs-panel is-active'>
            <div className='graph--wrap'>
              <LabelsWrapper
                showMissingReportDetails={missingReportInput}
                showNoData={noData && !missingReportInput}
              >
                <div ref={chartRef} style={{ height: 200 }}>
                  {!newChart ? (
                    <KpiChartContainer
                      id={report.id}
                      chartType={report.chartType}
                      data={data}
                      allowedProps={allowedProps}
                      {...chartRect}
                    />
                  ) : (
                    <ChartV2Container report={report} {...chartRect} />
                  )}
                </div>
              </LabelsWrapper>
            </div>
            <div className='graph--wrap'>
              <div ref={sliderRef} style={{ height: 80 }}>
                {!newChart ? (
                  <ReportSliderContainer data={data} id={report.id} {...sliderRect} />
                ) : (
                  <SliderV2Container report={report} {...sliderRect} />
                )}
              </div>
            </div>
          </div>
        </div>

        {report.type === dataPoints && (
          <LegendContainer
            reportId={report.id}
            dataPoints={report.filters[DATA_POINTS]}
            inactiveEntriesIds={report.disabledProps}
          />
        )}
      </div>
    </div>
  );
};
