"use strict";
import _ from "lodash";
import config from "../config/public";
import { REQUIRED, INVALID, INVALID_UUID, INVALID_IP } from "./constants/errors";
export const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
export const sequelizeToPlain = (object) => {
  if (object && object.get && typeof object.get === "function") {
    return _.cloneDeep(object.get({ plain: true }));
  }
  return _.cloneDeep(object);
};
export const diffInSec = (from, to) => (to.getTime() - from.getTime()) / 1e3;
export const isPreviewEnabled = () => config.features.preview;
export const bytesToMega = (bytes) => {
  const megabytes = (bytes / (1024 * 1024)).toFixed(2);
  return megabytes;
};
const transformZodErrorToMessageString = (zodError) => {
  if (!zodError.issues || zodError.issues.length === 0) {
    return "Unknown error";
  }
  const messages = zodError.issues.map((issue) => {
    const path = issue.path.join(".");
    switch (issue.code) {
      case "invalid_type":
        return `Error in "${path}": expected ${issue.expected} but received ${issue.received}. ${issue.message}`;
      case "unrecognized_keys":
        return `Unrecognized keys in "${path}": ${issue.message}`;
      case "too_small":
      case "too_big":
        return `Error in "${path}": ${issue.message}`;
      default:
        return `Error in "${path}": ${issue.message}`;
    }
  });
  return messages.join("\n");
};
export const transformErrorToMessageString = (err) => {
  if (err.name === "ZodError") {
    return transformZodErrorToMessageString(err);
  }
  if (typeof err === "string") {
    return err;
  }
  if (typeof err === "object") {
    return JSON.stringify(err);
  }
  return "Unknown error";
};
export function transformZodError(zodError) {
  const transformedError = {};
  for (const issue of zodError.issues) {
    const path = issue.path.join(".");
    let errorMessage = "";
    switch (issue.message) {
      case "Required":
        errorMessage = REQUIRED;
        break;
      case "Invalid":
        errorMessage = INVALID;
        break;
      case "Expected number, received null":
        errorMessage = INVALID;
        break;
      case "Invalid ip":
        errorMessage = INVALID_IP;
        break;
      case "Invalid uuid":
        errorMessage = INVALID_UUID;
        break;
      case "Invalid discriminator value. Expected 'ids_range_input' | 'ids_list_input'":
        errorMessage = REQUIRED;
        break;
      default:
        errorMessage = issue.message;
    }
    transformedError[path] = errorMessage;
  }
  return transformedError;
}
