import React from 'react';
import * as RadixContextMenu from '@radix-ui/react-context-menu';

import * as helper from './helper';

export const MenuLabel: React.FC<RadixContextMenu.ContextMenuLabelProps> = ({
  onSelect,
  children,
  className,
  ...props
}) => (
  <RadixContextMenu.Label
    onSelect={onSelect}
    className={`${className ?? helper.labelClass}`}
    {...props}
  >
    {children}
  </RadixContextMenu.Label>
);
