"use strict";
export const MACHINES = "machines";
export const LOCATIONS = "locations";
export const TAGS = "tags";
export const ORGANIZATIONS = "organizations";
export const DATATRONS = "datatrons";
export const DEVICES = "devices";
export const DATA_POINTS = "dataPoints";
export const CATEGORIES = "categories";
export const EXPERIMENTS = "experiments";
export const reportsEntities = [MACHINES, DATA_POINTS];
export default [
  MACHINES,
  DEVICES,
  DATATRONS,
  LOCATIONS,
  TAGS,
  ORGANIZATIONS,
  DATA_POINTS,
  CATEGORIES,
  EXPERIMENTS,
  EXPERIMENTS
];
