"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import moment from "moment";
const validDate = (date) => {
  if (date instanceof Date)
    return date;
  if (typeof date === "string") {
    const newDate = new Date(date);
    return isNaN(newDate.getTime()) ? null : newDate;
  }
  return null;
};
export const encode = (id, createdAt) => {
  const date = validDate(createdAt);
  if (date === null) {
    throw new Error(`indexProtector: Invalid createdAt provided: ${createdAt}`);
  }
  const code = Buffer.from(`${id}${date.getTime()}`, "utf8");
  return code.toString("base64").replace(/=/g, "");
};
export const decode = (value) => {
  try {
    const decoded = String(
      Buffer.from(String(value), "base64").toString("utf8")
    );
    if (decoded.length < 14)
      return null;
    const id = decoded.substring(0, decoded.length - 13);
    const timestamp = Number(decoded.substring(id.length));
    return {
      id: Number(id),
      timestamp: moment(timestamp).utc().toDate()
    };
  } catch (err) {
    return null;
  }
};
export const isValid = (value, noDecode = false) => {
  let valueToCheck;
  if (noDecode) {
    valueToCheck = value;
  } else {
    const decoded = decode(value);
    valueToCheck = decoded && decoded.id ? decoded.id : null;
  }
  return typeof valueToCheck === "number" && valueToCheck > 0;
};
export const addVirtualCodeField = (thing) => __spreadProps(__spreadValues({}, thing), {
  code: encode(thing.id, thing.createdAt)
});
export const encodeParentId = (dataPoint, index, array) => __spreadProps(__spreadValues({}, dataPoint), {
  parentId: dataPoint.parentId ? array.find((item) => item.id === dataPoint.parentId).code : null
});
