import React from 'react';
import Select from '../../../../_tools/formFields/select/Select';
import { Todo } from '../../../../../../../common/types/common';

export interface Device {
  deviceUUID: string;
  name: string;
  id?: string;
}

/**
 * Component for selecting multiple devices
 * @param props Component properties
 * @returns Select component for devices
 */
type DeviceOptions = { value: string; label: string; isFixed: boolean };

export const DevicesSelect: React.FC<{
  input: Todo;
  meta: Todo;
  devices?: Device[];
  isLoading?: boolean;
  disabled: boolean;
  [key: string]: Todo;
}> = ({ input, meta, devices = [], isLoading, disabled, ...restProps }) => {
  const options: DeviceOptions[] = devices.map((device: { deviceUUID: string; name: string }) => ({
    value: device.deviceUUID,
    label: device.name,
    isFixed: disabled, // Mark all options as fixed when disabled is true
  }));

  const handleChange = (selectedOptions) => {
    if (!selectedOptions) {
      input.onChange([]);
      return;
    }

    const selectedValues = Array.isArray(selectedOptions)
      ? selectedOptions.map((option) => option.value)
      : [selectedOptions.value];

    input.onChange(selectedValues);
  };
  const selectedValues: DeviceOptions[] = options.filter(
    (option) => input.value && input.value.includes(option.value),
  );

  const customStyles = {
    multiValueRemove: (base, { data }) => ({
      ...base,
      display: data.isFixed ? 'none' : base.display,
    }),
  };
  return (
    <Select
      value={selectedValues}
      input={input}
      meta={meta}
      options={options}
      isMulti={true}
      isDisabled={disabled}
      isLoading={isLoading}
      onChange={handleChange}
      styles={customStyles}
      {...restProps}
    />
  );
};
