import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ActivityEvent from './ActivityEvent';
import * as selectors from '../../../../../redux/selectors/maintenance.selector';
import { collapseExpand } from '../../../../../redux/modules/maintenance.expanded.module';
import { fetchAnomaly } from '../../../../../redux/modules/anomaly.fetch.module';
import * as eventCategories from '../../../../../../../common/enums/eventCategory';
import { openModal } from '../../../../../redux/modules/modals.module';
import { MAINTENANCE_ASSIGN } from '../../../../../redux/constants/modals.constants';
import PermissionedComponent from '../../../../common/permissions/PermissionedComponent.container';
import { EVENTS_PERMISSIONS } from '../../../../../../../common/constants/permissions';

export const mapStateToProps = (state, { activity, permissions }) => {
  const event = activity.events[0];

  return {
    event,
    canSeeAnomaly: event.category === eventCategories.anomaly,
    isFatal: selectors.isFatalEvent(event),
    isWarning: selectors.isWarningEvent(event),
    isInfo: selectors.isInfoEvent(event),
    isAnomaly: selectors.isAnomalyEvent(event),
    isError: selectors.isErrorEvent(event),
    isOpened: selectors.isDetailsShown(state, event.id),
    canUpdateActivities: permissions.canUpdateActivities(),
  };
};

export const mapDispatchToProps = (dispatch) => ({
  assign: (activityId, organizationId, assignee) => {
    dispatch(openModal(MAINTENANCE_ASSIGN, { activityId, organizationId, assignee }));
  },
  collapseExpand: bindActionCreators(collapseExpand, dispatch),
  fetchAnomaly: bindActionCreators(fetchAnomaly, dispatch),
});

export default PermissionedComponent(EVENTS_PERMISSIONS)(
  connect(mapStateToProps, mapDispatchToProps)(ActivityEvent),
);
