"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  COLUMN_IN_PROGRESS,
  COLUMN_PRIORITIZED,
  COLUMN_INBOX
} from "../../redux/constants/column.type.constants";
const availableEventMoves = {
  [COLUMN_INBOX]: {
    [COLUMN_PRIORITIZED]: true,
    [COLUMN_IN_PROGRESS]: true
  },
  [COLUMN_PRIORITIZED]: {
    [COLUMN_IN_PROGRESS]: true,
    [COLUMN_PRIORITIZED]: true
  },
  [COLUMN_IN_PROGRESS]: {
    [COLUMN_PRIORITIZED]: true,
    [COLUMN_IN_PROGRESS]: true
  }
};
export const canMoveEvent = (from, to) => !!availableEventMoves[from][to];
export function addActivityIndicatorBeforeUpdate(storeStateActivities, activityId, listPositions) {
  return storeStateActivities.map((activity) => {
    const found = listPositions.find(({ id }) => id === activity.id);
    if (found && activityId === found.id) {
      return __spreadProps(__spreadValues({}, activity), {
        state: found.state,
        position: found.position,
        indicate: true
      });
    } else if (found) {
      return __spreadProps(__spreadValues({}, activity), {
        state: found.state,
        position: found.position
      });
    } else {
      return activity;
    }
  });
}
export function removeActivityIndicatorAfterUpdateOrFail(storeStateActivities, activityId) {
  return storeStateActivities.map((activity) => {
    if (activityId === activity.id) {
      return __spreadProps(__spreadValues({}, activity), {
        indicate: false
      });
    } else {
      return activity;
    }
  });
}
export function createTempActivityBeforeCreate(storeStateEvents, eventId, columnDestination, position) {
  return {
    id: eventId,
    state: columnDestination,
    isArchived: false,
    isPlanned: false,
    resolvedTitle: null,
    resolvedComments: null,
    position,
    indicate: true,
    events: storeStateEvents.filter((event) => event.id === eventId),
    assignee: null
  };
}
