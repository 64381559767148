"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "redux-act";
import { put, call, takeEvery, select } from "redux-saga/effects";
import * as aiAppsApi from "../../core/api/aiApps";
import { initialState } from "../state/app.state";
import { getFilters, getPage, getSort } from "../selectors/aiApps.selector";
export const fetchAiApps = createAction("fetch aiApps");
export const fetchAiAppsSuccess = createAction("fetch aiApps - success", (aiApps) => aiApps);
export const fetchAiAppsFail = createAction("fetch aiApps - failed", (error) => error);
export const updateAiAppsFilters = createAction(
  "update aiApps filters",
  (queryString) => queryString
);
export const updateAiAppsSort = createAction(
  "update aiApps sort",
  (sort) => sort
);
export const updateAiAppsPage = createAction("update aiApps page", (page) => page);
export const reducer = {
  [fetchAiApps.getType()]: (state) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      loaded: false,
      loading: true,
      error: null
    })
  }),
  [fetchAiAppsSuccess.getType()]: (state, aiApps) => {
    const { list, page, totalPages, total, limit } = aiApps;
    return __spreadProps(__spreadValues({}, state), {
      aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
        list,
        pagination: {
          page,
          totalPages,
          total,
          limit
        },
        loaded: true,
        loading: false,
        error: null
      })
    });
  },
  [fetchAiAppsFail.getType()]: (state, error) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      loaded: false,
      loading: false,
      error
    })
  }),
  [updateAiAppsFilters.getType()]: (state, queryString) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      filters: {
        queryString
      },
      sort: initialState.aiApps.sort,
      pagination: __spreadProps(__spreadValues({}, state.aiApps.pagination), {
        page: initialState.aiApps.pagination.page
      })
    })
  }),
  [updateAiAppsSort.getType()]: (state, sort) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      sort
    })
  }),
  [updateAiAppsPage.getType()]: (state, page) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      pagination: __spreadProps(__spreadValues({}, state.aiApps.pagination), {
        page
      })
    })
  })
};
export function* fetchAiAppsSaga() {
  const state = yield select();
  const { queryString } = yield call(getFilters, state);
  const page = yield call(getPage, state);
  const { sortBy, sortOrder } = yield call(getSort, state);
  const payload = {
    page,
    queryString,
    sortBy,
    sortOrder
  };
  const { response, error } = yield call(aiAppsApi.fetch, payload);
  if (response) {
    yield put(fetchAiAppsSuccess(response));
  } else {
    yield put(fetchAiAppsFail(error));
  }
}
export function* watchFetchAIApps() {
  yield takeEvery(fetchAiApps, fetchAiAppsSaga);
}
