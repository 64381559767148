import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import TabsBar from '../../common/tabsBar/TabsBar';
import commonMessages from '../../../messages/common.messages';
import { openModal } from '../../../redux/modules/modals.module';
import { getAnalyzerSessionPrintLink } from '../../index/routes';

import {
  ANALYZER_DISPLAY_SESSION_OR_ANOMALY_DATA,
  ANALYZER_SESSION_COMMAND,
  ANALYZER_SESSION_EDIT,
  ANALYZER_SESSION_PANEL_ADD,
} from '../../../redux/constants/modals.constants';
import { TabCommands } from '../../../schema/type/session';
import { sendNotification } from '../../../redux/modules/notifications.module';
import notificationMessage from '../../../messages/notification.message';
import {
  NOTIFICATION_EVENT,
  NOTIFICATION_ERROR,
} from '../../../redux/constants/notification.constants';
import { encodeId } from '../../../redux/util';

interface SessionActionsProps extends WrappedComponentProps {
  session: {
    name: string;
    id: number;
    datatronUuid: string;
    devicesUuids: string[];
    createdAt: string;
    archived: boolean;
  };
}

export const SessionActions: React.FC<SessionActionsProps> = ({ intl, session }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const code = encodeId(session.id, new Date(session.createdAt));
  const mainButtons = [
    {
      title: session ? session.name : '',
      isActive: true,
      onClick: () =>
        dispatch(
          openModal(ANALYZER_SESSION_EDIT, {
            id: session.id,
            name: session.name,
            datatronUuid: session.datatronUuid,
            devices: session.devicesUuids,
          }),
        ),
      showEditButton: true,
    },
  ];
  const extraButtons = [
    {
      title: `${intl.formatMessage(commonMessages.add)} ${intl.formatMessage(commonMessages.panel)}`,
      isActive: true,
      onClick: () =>
        dispatch(
          openModal(ANALYZER_SESSION_PANEL_ADD, {
            id: session.id,
            name: session.name,
          }),
        ),
      color: 'light-grey-white',
      icon: 'fa fa-plus',
    },
    {
      title: intl.formatMessage(commonMessages.copyLink),
      isActive: true,
      icon: 'fa fa-share-alt',
      color: 'light-grey-white',
      onClick: () => {
        const currentUrl = window.location.href;
        navigator.clipboard
          .writeText(currentUrl)
          .then(() => {
            dispatch(
              sendNotification(
                commonMessages.copyLink,
                notificationMessage.copy_link_success,
                NOTIFICATION_EVENT,
              ),
            );
          })
          .catch(() => {
            dispatch(
              sendNotification(
                commonMessages.copyLink,
                notificationMessage.copy_link_failed,
                NOTIFICATION_ERROR,
              ),
            );
          });
      },
    },
    {
      title: intl.formatMessage(commonMessages.print),
      isActive: true,
      icon: 'fa fa-print',
      color: 'light-grey-white',
      onClick: () => history.push(getAnalyzerSessionPrintLink(code)),
    },
    {
      title: intl.formatMessage(commonMessages.details),
      icon: 'fa fa-info',
      isActive: true,
      color: 'light-grey-white',
      onClick: () => {
        dispatch(
          openModal(ANALYZER_DISPLAY_SESSION_OR_ANOMALY_DATA, {
            data: session,
          }),
        );
      },
    },
    !session.archived
      ? {
          title: intl.formatMessage(commonMessages.archive),
          icon: 'fa fa-archive',
          isActive: true,
          color: 'light-grey-white',
          onClick: () =>
            dispatch(
              openModal(ANALYZER_SESSION_COMMAND, {
                id: session.id,
                command: TabCommands.archiveSession,
              }),
            ),
        }
      : {
          title: intl.formatMessage(commonMessages.unarchive),
          icon: 'fa fa-undo',
          isActive: true,
          color: 'light-grey-white',
          onClick: () =>
            dispatch(
              openModal(ANALYZER_SESSION_COMMAND, {
                id: session.id,
                command: TabCommands.unarchiveSession,
              }),
            ),
        },
    {
      title: intl.formatMessage(commonMessages.delete),
      icon: 'fa fa-trash',
      isActive: true,
      color: 'light-grey-white',
      onClick: () =>
        dispatch(
          openModal(ANALYZER_SESSION_COMMAND, {
            id: session.id,
            command: TabCommands.deleteSession,
          }),
        ),
    },
  ];

  return <TabsBar list={mainButtons} extraButtons={extraButtons} disableScroll />;
};

export default injectIntl(SessionActions);
