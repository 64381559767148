import React, { useEffect } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import { renderRoutes } from 'react-router-config';
import { onAnalyzerEnter, onAnalyzerLeave } from '../index/routeHandlers';
import { AnalyzerHeader } from './header/AnalyzerHeader';
import { AnalyzerList } from './list/AnalyzerList';

interface AnalyzerProps extends RouteConfigComponentProps {}

export const AnalyzerOverviewPage: React.FC<AnalyzerProps> = ({ match, route }) => {
  useEffect(() => {
    // When component mounts or becomes exact match
    if (match.isExact) {
      onAnalyzerEnter();
    }

    return () => {
      onAnalyzerLeave();
    };
  }, [match.isExact]);

  if (!match.isExact) {
    return renderRoutes(route?.routes ?? []);
  }

  return (
    <>
      <div className='col--1'>
        <AnalyzerHeader />
      </div>
      <div className='col--1 location-page'>
        <AnalyzerList />
      </div>
    </>
  );
};
