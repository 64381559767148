"use strict";
export const testState = {
  appName: "Datatron Berlin",
  customLogo: {
    enabled: false,
    path: null
  },
  features: {
    preview: true,
    hideOee: false,
    altasigmaIntegration: true,
    experiments: true
  },
  locales: ["en", "de", "ru"],
  theme: "default"
};
export const initialState = {
  appName: "",
  customLogo: {
    enabled: false,
    path: null
  },
  features: {
    preview: false,
    hideOee: false,
    altasigmaIntegration: false,
    experiments: true
  },
  locales: ["en"],
  theme: "default"
};
