"use strict";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import permissionsList from "../../../../../common/permissions/permissionsList";
import { selectAppConfig } from "../../../redux/selectors/appConfig.selector";
import { getRoles } from "../../../redux/selectors/profile.selector";
export const usePermissionInstance = (permission) => {
  const roles = useSelector(getRoles);
  const config = useSelector(selectAppConfig);
  return useMemo(
    () => new permissionsList[permission](roles, config),
    [permission, roles, config]
  );
};
