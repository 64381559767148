"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { find } from "lodash";
import * as api from "../../core/api";
import {
  getDataPointByIdAndDeviceId,
  getDataPointFromListById,
  getDataPointsOfDevice,
  getDatatron,
  getDeviceById
} from "../selectors/datatron.selector";
import {
  compareById,
  mergeObjectInList,
  replaceObjectInList
} from "../../../../common/helpers/object";
import { highlightDataPoint } from "./datatron.devices.dataPoints.highlight.module";
import { initializeDataPoint } from "../../core/common/dataPoint";
import { handleApiError } from "../../core/common/errorHandling";
import { closeModal } from "./modals.module";
import { DP_EDIT_DATA_POINT } from "../constants/modals.constants";
import { sendNotification } from "./notifications.module";
import notificationMessages from "../../messages/notification.message";
import * as notification from "../../core/notifications";
import { createConfigKeyHashToFieldsMap } from "../../core/common/datatron.deviceType";
import { getModalPayload } from "../selectors/modals.selector";
import {
  getFormTypeFromDataPoint,
  transformNaNToUndefined,
  validateDataPoint
} from "../../core/validation/validateFieldsBaseOnSchema";
export const updateDataPoint = createAction(
  "update data point",
  (deviceId, dataPointId, newConfig) => ({ payload: { deviceId, dataPointId, newConfig } })
);
export const updateDataPointSuccess = createAction(
  "update data point - success",
  (deviceId, dataPointId, newDataPoint, configKeyHashToFieldsMap) => ({
    payload: {
      deviceId,
      dataPointId,
      newDataPoint,
      configKeyHashToFieldsMap
    }
  })
);
export const updateDataPointFail = createAction(
  "update data point - fail",
  (deviceId, dataPointId, error) => ({ payload: { deviceId, dataPointId, error } })
);
export const reducer = {
  [updateDataPoint.type]: (state, { deviceId, dataPointId }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const dataPoints = getDataPointsOfDevice(device);
    const dataPoint = getDataPointFromListById(dataPoints, dataPointId);
    if (!dataPoint)
      return state;
    const newDataPoint = __spreadProps(__spreadValues({}, dataPoint), {
      _update: {
        loading: true,
        loaded: false,
        error: null
      }
    });
    const newDevice = __spreadProps(__spreadValues({}, device), {
      dataPoints: __spreadProps(__spreadValues({}, device.dataPoints), {
        list: replaceObjectInList(getDataPointsOfDevice(device), newDataPoint, compareById)
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: mergeObjectInList(state.datatron.devices.list, newDevice, compareById)
        }),
        newDataPoint: {
          error: null
        }
      })
    });
  },
  [updateDataPointSuccess.type]: (state, { deviceId, dataPointId, newDataPoint, configKeyHashToFieldsMap }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const deviceDataPoints = getDataPointsOfDevice(device);
    const isDataPointReplaced = dataPointId !== newDataPoint.id;
    const prevDataPoint = find(deviceDataPoints, { id: dataPointId });
    const newDevice = __spreadProps(__spreadValues({}, device), {
      configKeyHashToFieldsMap: __spreadValues(__spreadValues({}, device.configKeyHashToFieldsMap), configKeyHashToFieldsMap),
      dataPoints: __spreadProps(__spreadValues({}, device.dataPoints), {
        list: replaceObjectInList(
          deviceDataPoints,
          newDataPoint,
          (item) => item.id === dataPointId
        )
      })
    });
    if (isDataPointReplaced) {
      newDevice.archivedDataPoints = __spreadProps(__spreadValues({}, device.archivedDataPoints), {
        list: [prevDataPoint, ...device.archivedDataPoints.list]
      });
    }
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        newDataPoint: {
          error: null
        },
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  },
  [updateDataPointFail.type]: (state, { deviceId, dataPointId, error }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const dataPoints = getDataPointsOfDevice(device);
    const dataPoint = getDataPointFromListById(dataPoints, dataPointId);
    if (!dataPoint)
      return state;
    const newDataPoint = __spreadProps(__spreadValues({}, dataPoint), {
      _update: {
        loading: false,
        loaded: false,
        error
      }
    });
    const newDevice = __spreadProps(__spreadValues({}, device), {
      dataPoints: __spreadProps(__spreadValues({}, device.dataPoints), {
        list: replaceObjectInList(getDataPointsOfDevice(device), newDataPoint, compareById)
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        newDataPoint: {
          error
        },
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: mergeObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  }
};
export function* updateDataPointSaga({ payload: { deviceId, dataPointId, newConfig } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const { deviceType } = yield select(getModalPayload, DP_EDIT_DATA_POINT);
  const currentDataPoint = yield call(getDataPointByIdAndDeviceId, state, deviceId, dataPointId);
  const formType = getFormTypeFromDataPoint(currentDataPoint);
  const transformedInputFields = transformNaNToUndefined(newConfig);
  const validationResult = validateDataPoint(
    deviceType.parsedDataPointSchema,
    formType,
    transformedInputFields
  );
  if (validationResult) {
    yield put(updateDataPointFail(deviceId, dataPointId, validationResult));
    return;
  }
  const { response, error } = yield call(api.datatrons.updateDataPoint, {
    datatronId: datatron.id,
    deviceId,
    dataPointId,
    payload: transformedInputFields
  });
  if (response) {
    const dataPoint = initializeDataPoint(response);
    const configKeyHashToFieldsMap = yield call(
      createConfigKeyHashToFieldsMap,
      [dataPoint],
      deviceType.dataPointFields,
      {}
    );
    yield put(updateDataPointSuccess(deviceId, dataPointId, dataPoint, configKeyHashToFieldsMap));
    yield put(closeModal(DP_EDIT_DATA_POINT));
    yield put(highlightDataPoint(response.id));
  } else {
    const formattedError = handleApiError(error);
    yield put(closeModal(DP_EDIT_DATA_POINT));
    yield put(
      sendNotification(
        notificationMessages.server_error,
        __spreadProps(__spreadValues({}, notificationMessages.something_happened), {
          values: { error: JSON.stringify(formattedError) }
        }),
        notification.NOTIFICATION_ERROR
      )
    );
  }
}
export function* watchUpdateDataPoint() {
  yield takeEvery(updateDataPoint, updateDataPointSaga);
}
