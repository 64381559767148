"use strict";
import { anySchema } from "../../../../common/validation/analyzer.validation";
import { apiRequestWithSchema, postApiRequest, deleteApiRequest, putApiRequest } from "./_tools";
export const getAnalyzer = () => apiRequestWithSchema("/api/analyzer", anySchema);
export const addNewSession = (data) => postApiRequest(`/api/sessions`, data);
export const updateSession = (sessionId, data) => putApiRequest(`/api/sessions/${sessionId}`, data);
export const archiveSession = (sessionId, comment) => postApiRequest(`/api/sessions/${sessionId}/archive`, { comment });
export const unarchiveSession = (sessionId, comment) => postApiRequest(`/api/sessions/${sessionId}/unarchive`, { comment });
export const archiveAnomaly = (anomalyId, comment, classification) => postApiRequest(`/api/anomalies/${anomalyId}/archive`, { comment, classification });
export const unarchiveAnomaly = (anomalyId, comment, classification) => postApiRequest(`/api/anomalies/${anomalyId}/unarchive`, { comment, classification });
export const deleteSession = (sessionId) => deleteApiRequest(`/api/sessions/${sessionId}`);
export const addNewPanel = (sessionId, data) => postApiRequest(`/api/sessions/${sessionId}/panels`, data);
export const updatePanel = (sessionId, panelId, data) => putApiRequest(`/api/sessions/${sessionId}/panels/${panelId}`, data);
export const deletePanel = (sessionId, panelId) => deleteApiRequest(`/api/sessions/${sessionId}/panels/${panelId}`);
export const getSessionById = (sessionId) => apiRequestWithSchema(`/api/sessions/${sessionId}`, anySchema);
export const getAnomalyById = (anomalyId) => apiRequestWithSchema(`/api/anomalies/${anomalyId}`, anySchema);
export const getPanelSignals = (sessionId, panelId) => apiRequestWithSchema(`/api/sessions/${sessionId}/panels/${panelId}/signals`, anySchema);
export const getSignals = (datatronUuid, devices) => {
  const searchParams = new URLSearchParams();
  devices.forEach((device) => searchParams.append("devices", device));
  return apiRequestWithSchema(
    `/api/datatrons/${datatronUuid}/signals?${searchParams.toString()}`,
    anySchema
  );
};
export const getSignalData = (signalUuid, startTime, endTime, abortSignal) => apiRequestWithSchema(
  `/api/signals/${signalUuid}?start_time=${startTime}&end_time=${endTime}`,
  anySchema,
  { signal: abortSignal }
);
