"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import moment from "moment";
import { pick } from "lodash";
import { DATE_FROM, DATE_TO } from "../../../../common/constants";
export const batch = (state) => state.batchDetails;
export const batchProcessings = (state) => state.batchDetails.processings.list;
export const loading = (state) => state.batchDetails.loading;
export const loaded = (state) => state.batchDetails.loaded;
export const dateFilters = (state) => pick(
  state.batchDetails.processings.filters,
  [DATE_FROM, DATE_TO]
);
export const areDateFiltersSelected = (state) => {
  const dates = dateFilters(state);
  return !!dates[DATE_FROM] && !!dates[DATE_TO];
};
export const getFilters = (state) => {
  const filters = __spreadValues({}, dateFilters(state));
  if (filters[DATE_FROM]) {
    filters[DATE_FROM] = moment(filters[DATE_FROM]).toISOString();
  }
  if (filters[DATE_TO]) {
    filters[DATE_TO] = moment(filters[DATE_TO]).toISOString();
  }
  return filters;
};
