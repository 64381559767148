import React from 'react';
import { useIntl } from 'react-intl';
import {
  getFieldsBasedOnConfig,
  formatDeviceConfigValue,
} from '../../../../../../core/common/datatron.deviceType';
import commonFieldsMessages from '../../../../../../messages/common.fields.messages';
import dynamicFieldMessages from '../../../../../../messages/dynamicField.messages';
import BooleanField from '../../../../../common/dataPoints/configItem/viewOptions/boolean/BooleanField';
import { Todo } from '../../../../../../../../common/types/common';

interface Props {
  device: Todo;
  deviceType: Todo;
}

const useConfigFields = (device: Todo, deviceType: Todo) => {
  if (!deviceType.hasDeviceSchema) return []; // there is not schema, so no fields to show
  if (!device) return []; // no device, so no fields to show
  if (!device.config) return []; // no config, so no fields to show
  return getFieldsBasedOnConfig(device.config ?? {}, deviceType.deviceFields);
};
const useHidePassword = (data: Todo[]) =>
  data.map((item) => {
    const { value, field } = item;
    if (field?._key === 'password') {
      const maskedValue = value ? '*'.repeat(value.length) : '';
      return {
        ...item,
        value: maskedValue,
      };
    }
    return item;
  });

export const DeviceDetails: React.FC<Props> = ({ device, deviceType }) => {
  const getConfigFields = useConfigFields(device, deviceType);
  const configFields = useHidePassword(getConfigFields);

  const { formatMessage } = useIntl();
  const deviceTypeLabel = formatMessage(commonFieldsMessages.deviceType);
  const serialNumberLabel = formatMessage(commonFieldsMessages.serialNumber);
  const deviceUUIDLabel = formatMessage(commonFieldsMessages.deviceUUID);
  const deviceActiveLabel = formatMessage(commonFieldsMessages.deviceActive);

  return (
    <div className='device-details'>
      <div className='device-detail'>
        <div className='device-detail-label'>{`${deviceTypeLabel}:`}</div>
        <div className='device-detail-text'>{`${device.deviceType.name} (${device.deviceType.deviceClass})`}</div>
      </div>
      <div className='device-detail'>
        <div className='device-detail-label'>{`${serialNumberLabel}:`}</div>
        <div className='device-detail-text'>{`${device.serialNumber}`}</div>
      </div>
      <div className='device-detail'>
        <div className='device-detail-label'>{`${deviceUUIDLabel}:`}</div>
        <div className='device-detail-text'>{`${device.deviceUUID}`}</div>
      </div>
      <div className='device-detail'>
        <div className='device-detail-label'>{`${deviceActiveLabel}:`}</div>
        <div className='device-detail-text'>
          <BooleanField value={device.active} />
        </div>
      </div>
      {configFields.map((item) => {
        const label =
          item.field._key in dynamicFieldMessages
            ? formatMessage(dynamicFieldMessages[item.field._key])
            : item.field._key;
        return (
          <div className='device-detail' key={item.field._key}>
            <div className='device-detail-label'>{`${label}:`}</div>
            <div className='device-detail-text'>{`${formatDeviceConfigValue(item.value)}`}</div>
          </div>
        );
      })}
    </div>
  );
};

export default DeviceDetails;
