import React from 'react';
import { SignalStateDisplay } from './SignalStateDisplay';

/**
 * Displays an error message.
 *
 * @returns {ReactElement} The JSX Element
 */
export const ErrorBox: React.FC<{ messageId?: string; fallbackMessageId?: string }> = ({
  messageId,
  fallbackMessageId = 'validation.file.error.default_message',
}) => (
  <SignalStateDisplay
    type='error'
    icon='fa fa-frown-o'
    messageId={messageId || fallbackMessageId}
    className='custom-class'
  />
);
