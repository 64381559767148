import React, { FC } from 'react';
import { LocationBreadcrumbsReadOnly } from '../../_tools/locationBreadcrumbs/LocationBreadcrumbReadOnly';

type Props = {
  location: string[];
};
export const AnalyzerSessionEditorHeader: FC<Props> = ({ location }) => (
  <div className='subheader'>
    <div className='subheader-in'>
      <div className='subheader--left'>
        <LocationBreadcrumbsReadOnly location={location} />
      </div>
    </div>
  </div>
);
