import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import analyzerMessages from '../../../messages/analyzer.messages';

import BasicModal from '../core/BasicModal';
import Form from './form/SessionPanelForm';
import { closeModal } from '../../../redux/modules/modals.module';
import { createSessionPanel } from '../../../redux/modules/analyzer.sessions.panel.create.module';
import { updateSessionPanel } from '../../../redux/modules/analyzer.sessions.panel.update.module';
import {
  ANALYZER_SESSION_PANEL_ADD,
  ANALYZER_SESSION_PANEL_EDIT,
} from '../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../redux/selectors/modals.selector';
import { AppState } from '../../../redux/state/app.state';

interface SessionFormProps {
  isEditForm: boolean;
}

const modalAddPayloadSelector = (state: AppState) =>
  getModalPayload(state, ANALYZER_SESSION_PANEL_ADD);

const modalEditPayloadSelector = (state: AppState) =>
  getModalPayload(state, ANALYZER_SESSION_PANEL_EDIT);

export const SessionPanelModal: React.FC<SessionFormProps> = ({ isEditForm }) => {
  const dispatch = useDispatch();
  const panel = useSelector(modalAddPayloadSelector);
  const panelEdit = useSelector(modalEditPayloadSelector);

  let initialValues = { panelId: 0, sessionId: panel.id, name: '' };

  if (isEditForm) {
    initialValues = {
      name: panelEdit.name,
      sessionId: panelEdit.id,
      panelId: panelEdit.panelId,
    };
  }
  const { formatMessage } = useIntl();
  const messageTitle = isEditForm
    ? analyzerMessages.editPanelTitle
    : analyzerMessages.addPanelTitle;

  const handleSubmit = ({ name }: { name: string }) => {
    if (!isEditForm) {
      dispatch(createSessionPanel(initialValues.sessionId, name));
    } else {
      dispatch(updateSessionPanel(initialValues.sessionId, initialValues.panelId, { name }));
    }
  };

  const close = () => {
    if (!isEditForm) {
      dispatch(closeModal(ANALYZER_SESSION_PANEL_ADD));
    } else {
      dispatch(closeModal(ANALYZER_SESSION_PANEL_EDIT));
    }
  };

  return (
    <BasicModal close={close} modalClass='modal--sm2'>
      <div className='modal--content'>
        <h3 className='first-title'>{formatMessage(messageTitle)}</h3>
        <Form
          onSubmit={handleSubmit}
          handleCancel={close}
          initialValues={initialValues}
          isEditForm={isEditForm}
        />
      </div>
    </BasicModal>
  );
};
