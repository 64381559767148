import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import commonMessages from '../../../messages/common.messages';
import aiAppConfigMessages from '../../../messages/aiApp.config.messages';
import aiAppMessages from '../../../messages/aiApp.config.messages';

interface Anomaly {
  type_value_str: string;
  max_cycle_time_seconds: number;
  createdAt: string;
  graph?: string;
}

interface AnomalyModelProps {
  anomaly: Anomaly;
}

const AnomalyModel: React.FC<AnomalyModelProps> = ({ anomaly }) => {
  const { formatDate, formatMessage } = useIntl();

  const {
    graph,
    createdAt,
    type_value_str: typeValue,
    max_cycle_time_seconds: maxCycleTime,
  } = anomaly;

  const renderDetailRow = (label: string, value: string | number | Date | undefined) => (
    <div className='detail-row'>
      <div className='detail-label'>{label}</div>
      <div className='detail-value'>{value}</div>
    </div>
  );

  const renderAnomalyDetails = () => (
    <div className='ai-app-details--text'>
      <div className='ai-app-details--position w50'>
        {renderDetailRow(formatMessage(aiAppMessages.typeValue), typeValue)}
        {renderDetailRow(formatMessage(aiAppMessages.maxCycleTime), maxCycleTime)}
        {renderDetailRow(
          formatMessage(commonMessages.date),
          formatDate(createdAt, {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }),
        )}
      </div>

      <div className='ai-app-details--position w50'>
        {graph && <div className='center-svg' dangerouslySetInnerHTML={{ __html: graph }} />}
      </div>
    </div>
  );

  return (
    <div className='anomaly--details'>
      <div className='table--title'>
        <FormattedMessage tagName='b' {...aiAppConfigMessages.anomalyModel} />
      </div>
      {renderAnomalyDetails()}
    </div>
  );
};

export default AnomalyModel;
