import React from 'react';

interface Props {
  onUnarchiveClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  canUpdate: boolean;
  dataPointId: string;
}

export const DataPointButtons: React.FC<Props> = ({ onUnarchiveClick, canUpdate, dataPointId }) => {
  if (!canUpdate) {
    return null;
  }

  return (
    <div className='data-points-actions unarchive'>
      <button
        type='button'
        className='button light-grey-white'
        onClick={onUnarchiveClick}
        id={dataPointId}
      >
        <i className='fa fa-undo' />
      </button>
    </div>
  );
};

export default DataPointButtons;
