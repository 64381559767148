"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { AppReducerMapBuilder } from "./util";
export const receivedCommandJournalMessage = createAction(
  "received command journal message",
  (message) => ({ payload: { message } })
);
export const setCallbackCommandId = createAction(
  "config repository callback command id set",
  (commandId) => ({ payload: { commandId } })
);
export const startedListening = createAction(
  "started listening for command journal messages"
);
export const stoppedListening = createAction(
  "stopped listening for command journal messages"
);
export const reducer = AppReducerMapBuilder.new().add(receivedCommandJournalMessage, (state, { message }) => {
  const { commandId, listening, messages } = state.callback;
  if (!listening)
    return state;
  if (commandId !== null && message.commandId !== commandId)
    return state;
  if (messages.some((m) => m.commandJournalId === message.commandJournalId)) {
    return state;
  }
  return __spreadProps(__spreadValues({}, state), {
    callback: __spreadProps(__spreadValues({}, state.callback), {
      messages: [...messages, message]
    })
  });
}).add(setCallbackCommandId, (state, { commandId }) => {
  const { listening, commandId: currentCommandId, messages } = state.callback;
  if (!listening)
    return state;
  if (currentCommandId === commandId)
    return state;
  return __spreadProps(__spreadValues({}, state), {
    callback: {
      listening,
      commandId,
      // filter out messages that don't match the new commandId
      messages: messages.filter((m) => m.commandId === commandId)
    }
  });
}).add(startedListening, (state) => __spreadProps(__spreadValues({}, state), {
  callback: {
    listening: true,
    commandId: null,
    messages: []
  }
})).add(stoppedListening, (state) => __spreadProps(__spreadValues({}, state), {
  callback: {
    listening: false,
    commandId: null,
    messages: []
  }
})).build();
