"use strict";
import querystring from "querystring";
import { apiRequest, postApiRequest } from "./_tools";
export const fetch = (payload) => {
  const query = querystring.stringify(payload);
  return apiRequest(`/api/ai-apps?${query}`);
};
export const fetchOne = (aiAppId) => apiRequest(`/api/ai-apps/${aiAppId}`);
export const createCycleDetection = (aiAppId, body) => postApiRequest(`/api/ai-apps/${aiAppId}/cycle-detections`, body);
export const fetchCycleDetection = (cycleDetectionId) => apiRequest(`/api/cycle-detections/${cycleDetectionId}`);
export const triggerCycleDetection = (cycleDetectionId) => postApiRequest(`/api/cycle-detections/${cycleDetectionId}/trigger`);
export const triggerAnomalyModel = (cycleDetectionId, anomalyDetectionId) => postApiRequest(
  `/api/cycle-detections/${cycleDetectionId}/anomalies/${anomalyDetectionId}/trigger`
);
export const create = (body) => postApiRequest("/api/ai-apps", body);
export const createAIAppAnomaly = (aiAppId, body) => postApiRequest(`/api/cycle-detections/${aiAppId}/anomalies`, body);
export const syncAnomalies = (aiAppId, body) => postApiRequest(`/api/ai-apps/${aiAppId}/anomalies/sync`, body);
export const getAIAppAnomalyModels = (aiAppId, anomalyDetectionId) => apiRequest(`/api/ai-apps/${aiAppId}/anomaly-detections/${anomalyDetectionId}/anomaly-models`);
