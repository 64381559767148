"use strict";
import { useSelector } from "react-redux";
export const createSelector = (selectFunction) => selectFunction;
export const useParamSelector = (selector, ...args) => useSelector((state) => selector(state, ...args));
export const decodeId = (value) => {
  const decoded = String(Buffer.from(String(value), "base64").toString("utf8"));
  const id = decoded.substring(0, decoded.length - 13);
  return Number(id);
};
export const encodeId = (id, createdAt) => {
  const code = Buffer.from(`${id}${createdAt.getTime()}`, "utf8");
  return code.toString("base64").replace(/=/g, "");
};
