import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import commonMessages from '../../../messages/common.messages';

interface CycleDetectionInfoProps {
  details: any;
  expandedRowId: string | null;
}

/**
 * CycleDetectionInfo component.
 *
 * @param {object} details - The cycle detection details to be displayed.
 * @param {string} onTrigger - The function to be called when the user
 *   triggers a cycle detection.
 *
 * @returns {React.ReactElement} The rendered component.
 */
export const CycleDetectionInfo: React.FC<CycleDetectionInfoProps> = ({
  details,
  expandedRowId,
}) => {
  const { formatDate } = useIntl();
  const {
    training_status: status,
    cycle_duration_sec_max: cycleDurationMax,
    cycle_duration_sec_min: cycleDurationMin,
    start_at: startDate,
    end_at: endDate,
  } = details;

  return (
    <div className='info-grid'>
      <div className='info-card'>
        <div className='ai-app-details-cycle-detection--item'>
          <div className='ai-app-details--text'>
            <div className='ai-app-details--position w33'>
              <label>
                <FormattedMessage tagName='b' {...commonMessages.status} />
              </label>
              <div className='ai-app-no-pagination-display-table'>
                <div className={`whitespace-nowrap status ${status.toString().toLowerCase()}`}>
                  {status}
                </div>
              </div>
            </div>
            <div className='ai-app-details--position w33'>
              <label>
                <FormattedMessage tagName='b' {...commonMessages.duration} />
              </label>
              <p>
                {cycleDurationMin} - {cycleDurationMax}
                <FormattedMessage tagName='b' {...commonMessages.minutes} />
              </p>
            </div>

            <div className='ai-app-details--position w33'>
              <label>
                <FormattedMessage tagName='b' {...commonMessages.startDate} />
                {'-'}
                <FormattedMessage tagName='b' {...commonMessages.endDate} />
              </label>
              <p>
                {formatDate(startDate)}-{formatDate(endDate)}
              </p>
            </div>
            <div className='ai-app-details--icon'>
              <i
                className={`fa  ${expandedRowId === details.id ? 'fa-angle-down' : 'fa-angle-left'}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
