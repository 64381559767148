"use strict";
export const COMMAND_STATUSES = ["PENDING", "SUCCESS", "FAIL"];
export const COMMAND_STATES = [
  "CREATED",
  "SUBMITTED",
  "ACCEPTED",
  "STARTED",
  "RECEIVED",
  "VALIDATED",
  "PERSISTED",
  "EXPIRED",
  "COMPLETED"
];
const CommandNames = ["collect", "stop", "list", "config", "status"];
