"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { z } from "zod";
import { clamp } from "./helpersTS";
import {
  INITIAL_RANGE_DELTA_MS,
  INITIAL_RANGE_FROM,
  MAX_RANGE_DELTA_MS,
  MIN_RANGE_DELTA_MS,
  MIN_SIGNAL_HEIGHT,
  MAX_SIGNAL_HEIGHT,
  INITIAL_SIGNAL_HEIGHT,
  COMMENT_FIELD_MAX_LENGTH
} from "../constants/analyzer";
export const anySchema = z.any();
const baseDateSchema = z.string().or(z.date());
export const dateSchema = baseDateSchema.transform((arg) => new Date(arg));
export const idSchema = z.number().min(1);
export const idUpdatedCreatedSchema = z.object({
  id: idSchema,
  createdAt: dateSchema,
  updatedAt: dateSchema
});
export const machineSchema = idUpdatedCreatedSchema.extend({
  name: z.string()
});
const SignalTypeEnum = z.enum(["integer", "bool", "string", "double"]);
const RowValueSchema = z.union([z.string(), z.number(), z.boolean()]);
const SignalDataRowSchema = z.object({
  systime: z.number(),
  isDelta: z.boolean(),
  value: RowValueSchema
});
export const SignalDataSchema = z.object({
  type: SignalTypeEnum,
  uuid: z.string(),
  data: z.array(SignalDataRowSchema)
});
export const signalDataValidationSchema = z.object({
  datapointUuid: z.string().uuid(),
  startTime: z.number(),
  endTime: z.number()
});
export const CHART_TYPES = ["line", "area"];
const chartTypeSchema = z.enum(CHART_TYPES);
export const INTERPOLATION_TYPES = [
  "basis",
  "linear",
  "monotone",
  "step",
  "stepBefore",
  "stepAfter"
];
const interpolationTypeSchema = z.enum(INTERPOLATION_TYPES);
const panelSignalBaseFields = {
  color: z.string(),
  height: z.number().nullable().transform((arg) => clamp(arg != null ? arg : INITIAL_SIGNAL_HEIGHT, MIN_SIGNAL_HEIGHT, MAX_SIGNAL_HEIGHT)),
  order: z.number().nullable().transform((arg) => arg != null ? arg : 0),
  datapointUuid: z.string().uuid(),
  chartType: chartTypeSchema.nullable(),
  interpolationType: interpolationTypeSchema.nullable()
};
export const panelSignalSchema = idUpdatedCreatedSchema.extend(__spreadValues({
  panelId: idSchema
}, panelSignalBaseFields));
const panelBaseFields = {
  rangeFrom: z.nullable(baseDateSchema).optional().transform((arg) => arg == null ? INITIAL_RANGE_FROM : new Date(arg)),
  rangeDeltaMs: z.number().min(MIN_RANGE_DELTA_MS).max(MAX_RANGE_DELTA_MS).nullable().optional().transform((arg) => arg == null ? INITIAL_RANGE_DELTA_MS : arg),
  panelSignals: z.array(z.object(panelSignalBaseFields)).optional()
};
export const panelCreateSchema = z.object(__spreadValues({
  name: z.string()
}, panelBaseFields));
export const panelUpdateSchema = z.object(__spreadProps(__spreadValues({}, panelBaseFields), {
  name: z.string().optional()
}));
export const panelSchema = idUpdatedCreatedSchema.extend(__spreadProps(__spreadValues({}, panelBaseFields), {
  sessionId: idSchema,
  name: z.string(),
  modifiedAt: dateSchema
}));
export const sessionSchema = idUpdatedCreatedSchema.extend({
  name: z.string(),
  modifiedAt: dateSchema,
  anomalyId: idSchema.nullable(),
  archived: z.boolean().default(false),
  datatronUuid: z.string().uuid(),
  devicesUuids: z.array(z.string().uuid()),
  comment: z.string().max(COMMENT_FIELD_MAX_LENGTH).nullable(),
  panels: z.array(panelSchema)
});
const signalTypeSchema = z.enum(["bool", "integer", "double", "string", "bytes"]);
export const signalSchema = idUpdatedCreatedSchema.extend({
  uuid: z.string().uuid(),
  name: z.string(),
  type: signalTypeSchema.nullable(),
  enabled: z.boolean()
});
export const ALERT_CLASSIFICATION_TYPES = ["ignore", "valid"];
const alertClassificationTypeSchema = z.enum(ALERT_CLASSIFICATION_TYPES);
export const isAlertClassification = (value) => ALERT_CLASSIFICATION_TYPES.includes(value);
export const anomalySchema = idUpdatedCreatedSchema.extend({
  anomalyFrom: dateSchema,
  referenceFrom: dateSchema,
  rangeDeltaMs: z.number().int().positive(),
  archived: z.boolean(),
  classification: alertClassificationTypeSchema.nullable(),
  datatronUuid: z.string().uuid(),
  devicesUuids: z.array(z.string().uuid()),
  comment: z.string().max(COMMENT_FIELD_MAX_LENGTH).nullable(),
  anomalySignals: z.array(
    idUpdatedCreatedSchema.extend({
      datapointUuid: z.string().uuid(),
      anomalyId: idSchema
    })
  )
});
export const anomalyCreateBodySchema = z.object({
  anomalyFrom: dateSchema,
  referenceFrom: dateSchema,
  rangeDeltaMs: z.number().int().positive(),
  datatronUuid: z.string().uuid(),
  devicesUuids: z.array(z.string().uuid()),
  anomalySignals: z.array(z.string().uuid())
});
export const anomalyArchiveBodySchema = z.object({
  classification: alertClassificationTypeSchema.optional().nullable(),
  comment: z.string().max(COMMENT_FIELD_MAX_LENGTH).optional()
});
export const sessionArchiveBodySchema = z.object({
  comment: z.string().max(COMMENT_FIELD_MAX_LENGTH).optional()
});
export const userSchema = idUpdatedCreatedSchema.extend({
  name: z.string()
});
export const commentSchema = idUpdatedCreatedSchema.extend({
  text: z.string(),
  user_id: idSchema,
  user: userSchema
});
export const sessionUpdateNameSchema = z.object({
  name: z.string().min(1, { message: "Required" }).trim()
});
const customPartialPanelSchema = z.object({
  name: z.string(),
  rangeDeltaMs: z.number(),
  rangeFrom: z.string(),
  signals: z.array(
    z.object({
      datapointUuid: z.string().uuid(),
      color: z.string(),
      chartType: z.string(),
      interpolationType: z.string(),
      order: z.number(),
      height: z.number()
    })
  ).optional()
});
export const sessionCreateBodySchema = sessionUpdateNameSchema.extend({
  datatronUuid: z.string().uuid(),
  devicesUuids: z.array(z.string().uuid()),
  anomalyId: z.number().optional(),
  panels: z.array(customPartialPanelSchema).optional()
});
