"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __knownSymbol = (name, symbol) => {
  return (symbol = Symbol[name]) ? symbol : Symbol.for("Symbol." + name);
};
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __await = function(promise, isYieldStar) {
  this[0] = promise;
  this[1] = isYieldStar;
};
var __yieldStar = (value) => {
  var obj = value[__knownSymbol("asyncIterator")];
  var isAwait = false;
  var method;
  var it = {};
  if (obj == null) {
    obj = value[__knownSymbol("iterator")]();
    method = (k) => it[k] = (x) => obj[k](x);
  } else {
    obj = obj.call(value);
    method = (k) => it[k] = (v) => {
      if (isAwait) {
        isAwait = false;
        if (k === "throw")
          throw v;
        return v;
      }
      isAwait = true;
      return {
        done: false,
        value: new __await(new Promise((resolve) => {
          var x = obj[k](v);
          if (!(x instanceof Object))
            throw TypeError("Object expected");
          resolve(x);
        }), 1)
      };
    };
  }
  return it[__knownSymbol("iterator")] = () => it, method("next"), "throw" in obj ? method("throw") : it.throw = (x) => {
    throw x;
  }, "return" in obj && method("return"), it;
};
import { createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import * as api from "../../core/api";
import { createTempActivityBeforeCreate } from "../../core/common/maintenance.stream";
import { enforceMinimalDuration } from "./helper";
export const createActivity = createAction(
  "create activity",
  (eventId, columnDestination, position) => ({
    payload: { eventId, columnDestination, position }
  })
);
export const createActivitySuccess = createAction("create activity success", (activity) => ({
  payload: { activity }
}));
export const createActivityFail = createAction(
  "create activity fail",
  (eventId, error) => ({
    payload: {
      eventId,
      error
    }
  })
);
export const reducer = {
  [createActivity.type]: (state, { eventId, columnDestination, position }) => {
    const tempActivity = createTempActivityBeforeCreate(
      state.maintenance.events,
      eventId,
      columnDestination,
      position
    );
    const newActivities = [...state.maintenance.activities, tempActivity];
    return __spreadProps(__spreadValues({}, state), {
      maintenance: __spreadProps(__spreadValues({}, state.maintenance), {
        activities: newActivities,
        error: "",
        movedEvents: __spreadProps(__spreadValues({}, state.maintenance.movedEvents), {
          [eventId]: true
        })
      })
    });
  },
  [createActivitySuccess.type]: (state, { activity }) => {
    const activitiesWithoutTemp = state.maintenance.activities.filter(
      (activityItem) => activityItem.id !== activity.events[0].id
    );
    const newEvents = state.maintenance.events.filter(
      (eventItem) => eventItem.id !== activity.events[0].id
    );
    const newActivities = [...activitiesWithoutTemp, activity];
    return __spreadProps(__spreadValues({}, state), {
      maintenance: __spreadProps(__spreadValues({}, state.maintenance), {
        activities: newActivities,
        events: newEvents,
        error: "",
        movedEvents: __spreadProps(__spreadValues({}, state.maintenance.movedEvents), {
          [activity.events[0].id]: false
        }),
        counts: __spreadProps(__spreadValues({}, state.maintenance.counts), {
          events: state.maintenance.counts.events - 1
        })
      })
    });
  },
  [createActivityFail.type]: (state, { eventId, error }) => {
    const activitiesWithoutTemp = state.maintenance.activities.filter(
      (activityItem) => activityItem.id !== eventId
    );
    return __spreadProps(__spreadValues({}, state), {
      maintenance: __spreadProps(__spreadValues({}, state.maintenance), {
        activities: activitiesWithoutTemp,
        error,
        movedEvents: __spreadProps(__spreadValues({}, state.maintenance.movedEvents), {
          [eventId]: false
        })
      })
    });
  }
};
export function* createActivitySaga({
  payload: { eventId, columnDestination, position }
}) {
  const startTime = Date.now();
  const { response, error } = yield call(
    api.activities.postActivity,
    eventId,
    columnDestination,
    position
  );
  yield* __yieldStar(enforceMinimalDuration(startTime));
  if (response) {
    yield put(createActivitySuccess(response));
  } else {
    yield put(createActivityFail(eventId, error));
  }
}
export function* watchCreateActivity() {
  yield takeEvery(createActivity, createActivitySaga);
}
