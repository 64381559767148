"use strict";
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { forEach, last, isEmpty } from "lodash";
const CONFIG_PREFIX = "config.";
export const convertErrors = (errors) => {
  const newErrors = {};
  forEach(errors, (value, key) => {
    if (key.substring(0, CONFIG_PREFIX.length) === CONFIG_PREFIX) {
      const subkeys = key.replace(CONFIG_PREFIX, "").split(".");
      const lastSubkey = last(subkeys);
      if (lastSubkey) {
        newErrors[lastSubkey] = value;
      }
    } else {
      newErrors[key] = value;
    }
  });
  return newErrors;
};
function isErrorWithDetails(error) {
  return typeof error === "object" && error !== null && "_details" in error;
}
export function handleApiError(error) {
  if (typeof error === "string") {
    return error;
  } else if (isErrorWithDetails(error)) {
    const _a = error, { _details: details, config, "config.(root)": configRoot } = _a, rest = __objRest(_a, ["_details", "config", "config.(root)"]);
    if (!isEmpty(rest)) {
      return convertErrors(rest);
    }
    return error._details;
  } else if (typeof error === "object" && error !== null) {
    return convertErrors(error);
  }
  return "something went wrong";
}
