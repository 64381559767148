"use strict";
import { selector } from "./util";
export const selectCommandStateIsSuccess = selector((state) => {
  var _a;
  return (_a = state.modals.commandState) == null ? void 0 : _a.success;
});
export const selectCommandStateDeviceId = selector((state) => {
  var _a;
  return (_a = state.modals.commandState) == null ? void 0 : _a.deviceId;
});
export const selectCommandStateSessionId = selector(
  (state) => {
    var _a;
    return (_a = state.modals.commandState) == null ? void 0 : _a.sessionId;
  }
);
export const selectCommandStateError = selector((state) => {
  var _a;
  return (_a = state.modals.commandState) == null ? void 0 : _a.error;
});
export const selectCommandStateIsSubmitting = selector(
  (state) => {
    var _a;
    return (_a = state.modals.commandState) == null ? void 0 : _a.submitting;
  }
);
