"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import * as accountsApi from "../../core/api/accounts.js";
export const fetchUser = createAction(
  "fetch user details"
);
export const fetchUserSuccess = createAction(
  "fetch user success",
  (response) => ({ payload: response })
);
export const fetchUserFail = createAction(
  "fetch user fail",
  (error) => ({ payload: error })
);
export const reducer = {
  [fetchUser.type]: (state) => __spreadProps(__spreadValues({}, state), {
    profile: __spreadProps(__spreadValues({}, state.profile), {
      loading: true
    })
  }),
  [fetchUserSuccess.type]: (state, user) => __spreadProps(__spreadValues({}, state), {
    profile: __spreadProps(__spreadValues({}, state.profile), {
      user,
      language: user.locale,
      loading: false,
      loaded: true
    })
  }),
  [fetchUserFail.type]: (state, error) => __spreadProps(__spreadValues({}, state), {
    profile: __spreadProps(__spreadValues({}, state.profile), {
      loading: false,
      loaded: false,
      error
    })
  })
};
export function* fetchUserSaga() {
  const { response, error } = yield call(accountsApi.getUser);
  if (response) {
    yield put(fetchUserSuccess(response.user));
  } else {
    yield put(fetchUserFail(error));
  }
}
export function* watchFetchUser() {
  yield takeEvery(fetchUser, fetchUserSaga);
}
