"use strict";
import { z } from "zod";
const baseDataEntry = z.object({
  systime: z.number(),
  isDelta: z.boolean()
});
const baseSignalData = z.object({
  uuid: z.string().uuid(),
  name: z.string()
});
const booleanSignalDataSchema = baseSignalData.extend({
  signalType: z.literal("bool"),
  data: z.array(
    baseDataEntry.extend({
      value: z.boolean()
    })
  )
});
export const isBooleanSignalData = (signalData) => signalData.signalType === "bool";
const integerSignalData = baseSignalData.extend({
  signalType: z.literal("integer"),
  data: z.array(
    baseDataEntry.extend({
      value: z.number().int()
    })
  )
});
const doubleSignalData = baseSignalData.extend({
  signalType: z.literal("double"),
  data: z.array(
    baseDataEntry.extend({
      value: z.number()
    })
  )
});
export const isNumberSignalData = (signalData) => signalData.signalType === "integer" || signalData.signalType === "double";
const stringSignalData = baseSignalData.extend({
  signalType: z.literal("string"),
  data: z.array(
    baseDataEntry.extend({
      value: z.string()
    })
  )
});
export const isStringSignalData = (signalData) => signalData.signalType === "string";
const bytesSignalData = baseSignalData.extend({
  signalType: z.literal("bytes"),
  data: z.array(
    baseDataEntry.extend({
      value: z.string()
    })
  )
});
export const signalDataSchema = z.discriminatedUnion("signalType", [
  booleanSignalDataSchema,
  integerSignalData,
  doubleSignalData,
  stringSignalData,
  bytesSignalData
]);
