import React from 'react';
import { useSelector } from 'react-redux';
import { getPanels } from '../../../redux/selectors/analyzer.selector';
import { PrintPanel } from './PrintPanel';

export const PrintPanelList: React.FC<{ sessionId: number }> = ({ sessionId }) => {
  const panels = useSelector(getPanels);
  return (
    <div>
      {panels.map((panelId) => (
        <PrintPanel sessionId={sessionId} panelId={panelId} key={`${panelId}-panel`} />
      ))}
    </div>
  );
};
