import React, { useRef } from 'react';
import Slider from 'react-slick';

import TabsBarItem from './TabsBarItem';
import TabsBarTools from './tabsBarTools/TabsBarTools';
import ExtraButtons from './extraButtons/ExtraButtons';

const SLIDER_SETTINGS = {
  variableWidth: true,
  speed: 300,
  arrows: false,
  dots: false,
  centerMode: false,
  swipe: true,
  swipeToSlide: true,
  touchMove: false,
};

interface TabsBarProps {
  list: Array<any>;
  extraButtons?: Array<any>;
  canAddTab?: boolean;
  onAddClick?: () => void;
  disableScroll?: boolean;
}

export const TabsBar: React.FC<TabsBarProps> = ({
  list,
  extraButtons = [],
  canAddTab = false,
  onAddClick = () => {},
  disableScroll = false,
}) => {
  const sliderRef = useRef<Slider>(null);

  const leftArrowClick = () => {
    sliderRef.current?.slickPrev();
  };

  const rightArrowClick = () => {
    sliderRef.current?.slickNext();
  };

  const tabs = list.map((tab, index) => <TabsBarItem {...tab} key={index + 1} />);

  return (
    <div className='big-tabs'>
      <div className='big-tabs-list'>
        <div className='big-tabs-scroll'>
          <div className='big-tabs-scroll-in'>
            {disableScroll ? (
              tabs
            ) : (
              <Slider ref={sliderRef} {...SLIDER_SETTINGS}>
                {tabs}
              </Slider>
            )}
          </div>
        </div>
        {!!extraButtons && extraButtons.length > 0 && <ExtraButtons list={extraButtons} />}
        {!disableScroll && (
          <TabsBarTools
            showPlusButton={canAddTab}
            isLeftButtonDisabled={false}
            isRightButtonDisabled={false}
            showLeftArrowButton
            showRightArrowButton
            onLeftButtonClick={leftArrowClick}
            onRightButtonClick={rightArrowClick}
            onPlusButtonClick={onAddClick}
          />
        )}
      </div>
    </div>
  );
};

export default TabsBar;
