"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import {
  CALENDAR_MANAGER,
  CALENDAR_EDITOR,
  CALENDAR_VIEWER
} from "../constants/permissions";
class CalendarPermissions {
  constructor(roles) {
    __publicField(this, "isCalendarManager");
    __publicField(this, "isCalendarEditor");
    __publicField(this, "isCalendarViewer");
    this.isCalendarManager = roles.indexOf(CALENDAR_MANAGER) !== -1;
    this.isCalendarEditor = roles.indexOf(CALENDAR_EDITOR) !== -1;
    this.isCalendarViewer = roles.indexOf(CALENDAR_VIEWER) !== -1;
  }
  canCloneCalendar() {
    return this.isCalendarManager;
  }
  canDeleteCalendar() {
    return this.isCalendarManager;
  }
  canAddCalendar() {
    return this.isCalendarManager;
  }
  canAssignCalendar() {
    return this.isCalendarManager;
  }
  canChangeEvents() {
    return this.isCalendarManager || this.isCalendarEditor;
  }
  canEditCalendar() {
    return this.isCalendarManager;
  }
  availableToAll() {
    return this.isCalendarManager || this.isCalendarEditor || this.isCalendarViewer;
  }
}
export default CalendarPermissions;
