"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  compareById,
  mergeObjectInList
} from "../../../../common/helpers/object";
import * as api from "../../core/api";
import { initializeDataPoint } from "../../core/common/dataPoint";
import { createConfigKeyHashToFieldsMap } from "../../core/common/datatron.deviceType";
import {
  getDatatron,
  getDeviceById,
  getDeviceTypeById
} from "../selectors/datatron.selector";
import { AppReducerMapBuilder } from "./util";
const getListName = (archived) => {
  if (!archived)
    return "dataPoints";
  return "archivedDataPoints";
};
export const fetchDataPoints = createAction(
  "fetch data points (of datatron device)",
  (deviceId, archived = false) => ({ payload: { deviceId, archived } })
);
export const fetchDataPointsSuccess = createAction(
  "fetch data points (of datatron device) - success",
  (deviceId, dataPoints, archived, configKeyHashToFieldsMap) => ({
    payload: {
      deviceId,
      dataPoints,
      archived,
      configKeyHashToFieldsMap
    }
  })
);
export const fetchDataPointsFail = createAction(
  "fetch data points (of datatron device) - fail",
  (deviceId, error, archived) => ({
    payload: { deviceId, error, archived }
  })
);
export const reducer = AppReducerMapBuilder.new().add(fetchDataPoints, (state, { deviceId, archived }) => {
  const listName = getListName(archived);
  return __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
        list: mergeObjectInList(
          state.datatron.devices.list,
          {
            id: deviceId,
            [listName]: {
              list: [],
              loading: true,
              loaded: false,
              error: null
            }
          },
          compareById
        )
      })
    })
  });
}).add(
  fetchDataPointsSuccess,
  (state, { deviceId, dataPoints, archived, configKeyHashToFieldsMap }) => {
    const listName = getListName(archived);
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: mergeObjectInList(
            state.datatron.devices.list,
            {
              id: deviceId,
              [listName]: {
                list: dataPoints,
                loading: false,
                loaded: true,
                error: null
              },
              configKeyHashToFieldsMap
            },
            compareById
          )
        })
      })
    });
  }
).add(fetchDataPointsFail, (state, { deviceId, error, archived }) => {
  const listName = getListName(archived);
  return __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
        list: mergeObjectInList(
          state.datatron.devices.list,
          {
            id: deviceId,
            [listName]: {
              list: [],
              loading: false,
              loaded: false,
              error
            }
          },
          compareById
        )
      })
    })
  });
}).build();
export function* fetchDataPointsSaga({ payload: { deviceId, archived } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const { response, error } = yield call(api.datatrons.getDataPoints, {
    datatronId: datatron.id,
    deviceId,
    archived
  });
  if (response) {
    const dataPoints = response.map(initializeDataPoint);
    const device = getDeviceById(state, deviceId);
    const deviceType = getDeviceTypeById(state, device.deviceType.id);
    const configKeyHashToFieldsMap = createConfigKeyHashToFieldsMap(
      dataPoints,
      deviceType.dataPointFields,
      {}
    );
    yield put(
      fetchDataPointsSuccess(
        deviceId,
        dataPoints,
        archived,
        configKeyHashToFieldsMap
      )
    );
  } else {
    yield put(fetchDataPointsFail(deviceId, error, archived));
  }
}
export function* watchFetchDataPoints() {
  yield takeEvery(fetchDataPoints, fetchDataPointsSaga);
}
