import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ANALYZER_ADD_SIGNAL } from '../../../redux/constants/modals.constants';
import { closeModal } from '../../../redux/modules/modals.module';
import { Loader } from '../../_tools';
import Modal from '../../_tools/modal/Modal';
import commonMessages from '../../../messages/common.messages';
import { VariableSizeList as WindowList } from 'react-window';
import analyzerMessages from '../../../messages/analyzer.messages';
import {
  getSignalsByPanelId,
  getAnalyzerSignalsState,
} from '../../../redux/selectors/analyzer.selector';
import { updateSessionPanelAddSignal } from '../../../redux/modules/analyzer.sessions.panel.update.module';
import { getModalPayload } from '../../../redux/selectors/modals.selector';
import { AppState } from '../../../redux/state/app.state';
import { Signal } from '../../../../../common/validation/analyzer.validation';

interface SignalItemProps {
  data: {
    signals: Signal[];
    selectedSignals: Signal[];
    setSelectedSignals: (signals: Signal[]) => void;
  };
  index: number;
  style: React.CSSProperties;
}
const SignalItem = ({ data, index, style }: SignalItemProps) => {
  const { signals, selectedSignals, setSelectedSignals } = data;
  const signal = signals[index];

  return (
    <div style={style}>
      <div
        className={`signal-item ${
          selectedSignals.some((item) => item.uuid === signal.uuid) ? 'selected' : ''
        }`}
        onClick={() => setSelectedSignals([signal])}
      >
        <div className='signal-info'>
          <div className='signal-details'>
            <span className={`signal-status ${signal.enabled ? 'enabled' : 'disabled'}`}>
              {signal.enabled ? 'Enabled' : 'Disabled'}
            </span>
            <span className='signal-id'>UUID: {signal.uuid}</span>
          </div>
          <div className='signal-name'>{signal.name}</div>
        </div>
        <div>
          {selectedSignals.some((item) => item.uuid === signal.uuid) && (
            <i className='fa fa-check' />
          )}
        </div>
      </div>
    </div>
  );
};

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}
const SearchInput: React.FC<SearchInputProps> = ({ value, onChange, placeholder }) => (
  <div className='search-input-container'>
    <i className='fa fa-search search-icon'></i>
    <input
      type='text'
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      className='search-input'
    />
  </div>
);
interface ModalPayload {
  panelId: number;
}
const modalPayloadSelector = (state: AppState) => getModalPayload(state, ANALYZER_ADD_SIGNAL);

export const AddSignalModal: React.FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { panelId }: ModalPayload = useSelector(modalPayloadSelector);
  const signalsSelector = (state: AppState) => getAnalyzerSignalsState(state);
  const { data: signals } = useSelector(signalsSelector);
  const [signalsLoading, setSignalsLoading] = useState(true);
  const [availableSignals, setAvailableSignals] = useState<Signal[]>([]);
  const panelSignals = useSelector((state) => getSignalsByPanelId(state, panelId));
  const [searchTerm, setSearchTerm] = useState('');
  const [showOnlyEnabled, setShowOnlyEnabled] = useState(true);
  const [selectedSignals, setSelectedSignals] = useState<Signal[]>([]);
  const getItemHeight = useCallback(() => 50, []);

  const close = useCallback(() => {
    dispatch(closeModal(ANALYZER_ADD_SIGNAL));
  }, [dispatch]);

  useEffect(() => {
    if (signals) {
      setSignalsLoading(false);
      const availableDatapoints = signals.filter((signal) => {
        const isAlreadyInPanel = panelSignals.some((ps) => ps.signal.uuid === signal.uuid);
        const matchesSearch =
          signal.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          signal.uuid.toLowerCase().includes(searchTerm.toLowerCase());
        const isDisabled = showOnlyEnabled ? !signal.enabled : false;
        return !isAlreadyInPanel && matchesSearch && !isDisabled;
      });
      setAvailableSignals(availableDatapoints);
    }
  }, [panelSignals, searchTerm, signals, showOnlyEnabled]);

  const handleAddSignal = useCallback(
    (selectedItem: Signal[]) => {
      dispatch(
        updateSessionPanelAddSignal(panelId, {
          signalId: (selectedItem[0] as Signal).id,
        }),
      );
      close();
    },
    [close, dispatch, panelId],
  );

  const itemData = useMemo(
    () => ({
      signals: availableSignals,
      selectedSignals,
      setSelectedSignals,
    }),
    [availableSignals, selectedSignals],
  );

  return (
    <Modal isOpen onClose={close} contentLabel=''>
      <div className='modal--header' onClick={close}>
        <span className='modal--close'>&times;</span>
      </div>
      <div className='modal--content'>
        <h3>{formatMessage(analyzerMessages.addSignal)}</h3>
        {signalsLoading ? (
          <Loader show fullPage={false} />
        ) : (
          <>
            <SearchInput
              value={searchTerm}
              onChange={setSearchTerm}
              placeholder='Search signals...'
            />
            <div className='signals-list'>
              <WindowList
                height={300}
                itemCount={availableSignals.length}
                itemSize={getItemHeight}
                width='100%'
                itemData={itemData}
              >
                {SignalItem}
              </WindowList>
            </div>
            <div className='filter-checkbox'>
              <input
                type='checkbox'
                id='filterEnabled'
                checked={showOnlyEnabled}
                onChange={() => setShowOnlyEnabled(!showOnlyEnabled)}
              />
              <label htmlFor='filterEnabled'>
                <i className={`fa ${showOnlyEnabled ? 'fa-toggle-on' : 'fa-toggle-off'}`}></i>
                <span>{!showOnlyEnabled ? 'Show All' : 'Show Only Enabled'}</span>
              </label>
            </div>
          </>
        )}

        <div className='line' />
        <div className='text-center'>
          <button type='button' className='button__cancel' onClick={close}>
            {formatMessage(commonMessages.cancel)}
          </button>
          {!signalsLoading && selectedSignals.length > 0 && (
            <button
              type='submit'
              className='button__save'
              onClick={() => handleAddSignal(selectedSignals)}
            >
              {formatMessage(commonMessages.add)}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
