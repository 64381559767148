import React, { Component } from 'react';
import moment, { Moment } from 'moment';
import { isEqual } from 'lodash';

import DatePickerMainButtons from './DatePickerMainButtons';
import Dropdown from './dropdown/Dropdown';
import { DATE_FROM, DATE_TO } from '../../../../../common/constants';
import { DateProps, RelativeDateProps } from '../../../core/propTypes/propTypes';
import { DEFAULT_RELATIVE } from '../../../redux/constants/relative.constants';
import commonMessages from '../../../messages/common.messages';

const DELAY_BEFORE_CLOSE = 700;

interface DatePickerProps {
  // @ts-ignore
  [DATE_FROM]?: DateProps;
  // @ts-ignore
  [DATE_TO]?: DateProps;
  fromRelative?: RelativeDateProps;
  toRelative?: RelativeDateProps;
  datesMode: string;
  onUpdate: (dates: any) => void;
  keepDropdownAlwaysOpen: boolean;
  useConfirmationFooter: boolean;
  showDatesModeBar: boolean;
  showAbsoluteDatesPresets: boolean;
  disabled?: boolean;
  shortPreview?: boolean;
  tabIndex?: number;
  fromLabel?: any;
  toLabel?: any;
  allowFuture: boolean;
}

interface DatePickerState {
  isOpen: boolean;
  preSelected: {
    // @ts-ignore
    [DATE_FROM]: Moment | null;
    // @ts-ignore
    [DATE_TO]: Moment | null;
    fromRelative: RelativeDateProps | null;
    toRelative: RelativeDateProps | null;
  };
  datesMode: string;
}

export default class DatePicker extends Component<DatePickerProps, DatePickerState> {
  static defaultProps = {
    allowFuture: false,
    disabled: false,
    shortPreview: false,
    keepDropdownAlwaysOpen: false,
    useConfirmationFooter: false,
    showDatesModeBar: true,
    showAbsoluteDatesPresets: false,
    fromRelative: DEFAULT_RELATIVE,
    toRelative: DEFAULT_RELATIVE,
    fromLabel: commonMessages.from,
    toLabel: commonMessages.to,
  };

  debounceId: NodeJS.Timeout | null = null;

  constructor(props: DatePickerProps) {
    super(props);

    this.state = {
      isOpen: false,
      preSelected: {
        [DATE_FROM]: props[DATE_FROM] ? moment(props[DATE_FROM]) : null,
        [DATE_TO]: props[DATE_TO] ? moment(props[DATE_TO]) : null,
        fromRelative: props.fromRelative || null,
        toRelative: props.toRelative || null,
      },
      datesMode: props.datesMode,
    };
  }

  componentWillReceiveProps = (nextProps: DatePickerProps) => {
    if (!isEqual(this.props, nextProps)) {
      this.setState({
        preSelected: {
          [DATE_FROM]: nextProps[DATE_FROM] ? moment(nextProps[DATE_FROM]) : null,
          [DATE_TO]: nextProps[DATE_TO] ? moment(nextProps[DATE_TO]) : null,
          fromRelative: nextProps.fromRelative || null,
          toRelative: nextProps.toRelative || null,
        },
        datesMode: nextProps.datesMode,
      });
    }
  };

  componentDidUpdate = () => {
    if (!this.state.isOpen || this.props.keepDropdownAlwaysOpen) {
      this.handleSave();
    }
  };

  setAbsoluteDates = (fromDate: Moment | null, toDate: Moment | null) => {
    this.setState({
      preSelected: {
        ...this.state.preSelected,
        [DATE_FROM]: fromDate ? moment(fromDate) : null,
        [DATE_TO]: toDate ? moment(toDate) : null,
      },
    });
  };

  setRelativeDates = (fromDate: RelativeDateProps | null, toDate: RelativeDateProps | null) => {
    this.setState({
      preSelected: {
        ...this.state.preSelected,
        fromRelative: fromDate || null,
        toRelative: toDate || null,
      },
    });
  };

  open = () => {
    this.setState({ isOpen: true });
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  handleSave = () => {
    const { onUpdate } = this.props;
    const { preSelected, datesMode } = this.state;

    if (this.isPristine()) return;
    onUpdate({ ...preSelected, datesMode });
  };

  handleCancel = () => {
    this.setState({
      isOpen: false,
      preSelected: {
        [DATE_FROM]: this.props[DATE_FROM] ? moment(this.props[DATE_FROM]) : null,
        [DATE_TO]: this.props[DATE_TO] ? moment(this.props[DATE_TO]) : null,
        fromRelative: this.props.fromRelative || null,
        toRelative: this.props.toRelative || null,
      },
      datesMode: this.props.datesMode,
    });
  };

  toggle = () => {
    const { isOpen } = this.state;
    const { keepDropdownAlwaysOpen } = this.props;

    if (!keepDropdownAlwaysOpen) return;
    this.setState({ isOpen: !isOpen });
  };

  runWithDebounce = (func: () => void, delay: number) => {
    this.debounceId = setTimeout(func, delay);
  };

  preventDebounceAction = () => {
    if (this.debounceId) clearTimeout(this.debounceId);
  };

  setDatesMode = (datesMode: string) => {
    this.setState({ datesMode });
  };

  setAbsoluteDate = (dateType: string, selectedDate: Moment | null, selectedDateOnly = false) => {
    let newDateFrom = this.state.preSelected[DATE_FROM];
    let newDateTo = this.state.preSelected[DATE_TO];

    if (dateType === DATE_FROM) {
      newDateFrom = selectedDate;
      if (newDateFrom && newDateTo && moment(newDateFrom).isAfter(moment(newDateTo))) {
        newDateTo = newDateFrom;
      }
    } else if (dateType === DATE_TO) {
      newDateTo = selectedDate;
      if (newDateFrom && newDateTo && moment(newDateTo).isBefore(moment(newDateFrom))) {
        newDateFrom = newDateTo;
      }
      if (selectedDateOnly && newDateTo) {
        newDateTo = moment(newDateTo).endOf('day');
      }
    }

    this.setAbsoluteDates(newDateFrom, newDateTo);
    this.runWithDebounce(this.toggle, DELAY_BEFORE_CLOSE);
  };

  setRelativeDate = (dateType: string, selectedDate: RelativeDateProps) => {
    let newDateFrom = this.state.preSelected.fromRelative;
    let newDateTo = this.state.preSelected.toRelative;

    if (dateType === DATE_FROM) {
      newDateFrom = selectedDate;
      if (
        newDateFrom &&
        newDateTo &&
        moment()
          // @ts-ignore
          .subtract(newDateFrom.count, newDateFrom.unit)
          // @ts-ignore
          .isAfter(moment().subtract(newDateTo.count, newDateTo.unit))
      ) {
        newDateTo = newDateFrom;
      }
    } else if (dateType === DATE_TO) {
      newDateTo = selectedDate;
      if (
        newDateFrom &&
        newDateTo &&
        moment()
          // @ts-ignore
          .subtract(newDateTo.count, newDateTo.unit)
          // @ts-ignore
          .isBefore(moment().subtract(newDateFrom.count, newDateFrom.unit))
      ) {
        newDateFrom = newDateTo;
      }
    }

    this.setRelativeDates(newDateFrom, newDateTo);
    this.runWithDebounce(this.toggle, DELAY_BEFORE_CLOSE);
  };

  isPristine = () => {
    const datesModePristine = this.props.datesMode === this.state.datesMode;

    const fromRelativePristine = isEqual(
      this.props.fromRelative,
      this.state.preSelected.fromRelative,
    );
    const toRelativePristine = isEqual(this.props.toRelative, this.state.preSelected.toRelative);

    const fromPristine = moment.isMoment(this.props[DATE_FROM])
      ? moment(this.props[DATE_FROM]).isSame(this.state.preSelected[DATE_FROM])
      : isEqual(this.props[DATE_FROM], this.state.preSelected[DATE_FROM]);
    const toPristine = moment.isMoment(this.props[DATE_TO])
      ? moment(this.props[DATE_TO]).isSame(this.state.preSelected[DATE_TO])
      : isEqual(this.props[DATE_TO], this.state.preSelected[DATE_TO]);

    return (
      datesModePristine && fromRelativePristine && toRelativePristine && fromPristine && toPristine
    );
  };

  render() {
    const {
      disabled,
      keepDropdownAlwaysOpen,
      useConfirmationFooter,
      shortPreview,
      showDatesModeBar,
      showAbsoluteDatesPresets,
      tabIndex,
      fromLabel,
      toLabel,
      allowFuture,
    } = this.props;
    const { isOpen, preSelected, datesMode } = this.state;

    return (
      <div className='datepicker-block large'>
        <DatePickerMainButtons
          isOpen={isOpen}
          onClick={this.open}
          disabled={disabled}
          shortPreview={shortPreview}
          datesMode={datesMode}
          tabIndex={tabIndex}
          {...preSelected}
        />
        {(isOpen || keepDropdownAlwaysOpen) && (
          <Dropdown
            closeDropdown={this.close}
            setDatesMode={this.setDatesMode}
            setAbsoluteDate={this.setAbsoluteDate}
            // @ts-ignore
            setAbsoluteDates={this.setAbsoluteDates}
            setRelativeDate={this.setRelativeDate}
            onCalendarHover={this.preventDebounceAction}
            cancel={this.handleCancel}
            keepDropdownAlwaysOpen={keepDropdownAlwaysOpen}
            useConfirmationFooter={useConfirmationFooter}
            showDatesModeBar={showDatesModeBar}
            showAbsoluteDatesPresets={showAbsoluteDatesPresets}
            datesMode={datesMode}
            pristine={this.isPristine()}
            fromLabel={fromLabel}
            toLabel={toLabel}
            allowFuture={allowFuture}
            {...preSelected}
          />
        )}
      </div>
    );
  }
}
