import React, { useCallback, useMemo } from 'react';
import { isNumber } from 'lodash';
import { injectIntl, IntlShape, MessageDescriptor } from 'react-intl';

import UnitSelectContainer from './UnitSelect.container';
import LiveTimestamp from './LiveTimestamp';
import { RelativeDateProps } from '../../../../../../core/propTypes/propTypes';

interface RelativeInputProps {
  label: MessageDescriptor;
  value: RelativeDateProps;
  change: (value: RelativeDateProps) => void;
  intl: IntlShape;
}

export const RelativeInput: React.FC<RelativeInputProps> = React.memo(
  ({ label, value, change, intl }) => {
    const handleNumberChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event) return;
        const numberValue = parseInt(event.target.value, 10);
        change({
          ...value,
          count: isNumber(numberValue) && !isNaN(numberValue) ? numberValue : 0,
        });
      },
      [value, change],
    );

    const handleUnitChange = useCallback(
      (newUnit: string) => {
        change({
          ...value,
          unit: newUnit,
        });
      },
      [value, change],
    );

    const labelTranslated = useMemo(() => `${intl.formatMessage(label)}:`, [intl, label]);

    return (
      <div className='datepicker-wrap'>
        <div className='datepicker-dropdowns--top'>{labelTranslated}</div>

        <LiveTimestamp relativeOffset={value} />

        <div className='relative-date-fields'>
          <input
            type='number'
            min={0}
            max={999}
            value={value.count}
            onChange={handleNumberChange}
          />
          <UnitSelectContainer change={handleUnitChange} value={value.unit} />
        </div>
      </div>
    );
  },
);

export default injectIntl(RelativeInput);
