import React from 'react';

import ChartContainer from '../../../../_tools/chartV2/ChartContainer';
import { selectSelectionByReportId } from '../../../../../redux/selectors/reports.selector';
import { ReportT } from '../../../../../../types/report';
import { useParamSelector } from '../../../../../redux/util';
import { BoundingRect } from '../../../../_tools/useBoundingClientRect';

interface Props extends BoundingRect {
  report: ReportT;
}

export const ChartV2Container: React.FC<Props> = ({ report, ...props }) => {
  const selection = useParamSelector(selectSelectionByReportId, report.id);
  return (
    <ChartContainer
      showAxisBottom={false}
      width={props.width}
      height={props.height}
      selection={selection}
      report={report}
    />
  );
};
