"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import {
  ROLES_MANAGER
} from "../constants/permissions";
class RolesPermissions {
  constructor(roles) {
    __publicField(this, "isRolesManager");
    this.isRolesManager = roles.indexOf(ROLES_MANAGER) !== -1;
  }
  canManageRoles() {
    return this.isRolesManager;
  }
}
export default RolesPermissions;
