import React from 'react';
import { SignalStateDisplay } from './SignalStateDisplay';

/**
 * A chart that displays an empty state.
 *
 * This component is a convenience wrapper around the [[SignalStateDisplay]]
 * component, and is used to display an empty state in a chart.
 *
 * @example
 * <EmptyChart />
 */
export const EmptyChart: React.FC = () => (
  <SignalStateDisplay
    type='empty'
    icon='fa-folder-open'
    messageId='placeholders.noDataToDisplay'
    className='custom-class'
  />
);
