"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import {
  ACCOUNTS_MANAGER,
  ACCOUNTS_VIEWER
} from "../constants/permissions";
class AccountsPermissions {
  constructor(roles) {
    __publicField(this, "isAccountsManager");
    __publicField(this, "isAccountsViewer");
    this.isAccountsManager = roles.indexOf(ACCOUNTS_MANAGER) !== -1;
    this.isAccountsViewer = roles.indexOf(ACCOUNTS_VIEWER) !== -1;
  }
  canCreateAccount() {
    return this.isAccountsManager;
  }
  canUpdateAccount() {
    return this.isAccountsManager;
  }
  availableToAll() {
    return this.isAccountsManager || this.isAccountsViewer;
  }
}
export default AccountsPermissions;
