"use strict";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DP_ADD_DEVICE } from "../../../../redux/constants/modals.constants";
import { addDevice } from "../../../../redux/modules/datatron.devices.add.module";
import { closeModal } from "../../../../redux/modules/modals.module";
import { getModalPayload } from "../../../../redux/selectors/modals.selector";
import DeviceFormModalContainer from "./DeviceFormModal.container";
export const mapStateToProps = (state) => {
  const { deviceClass } = getModalPayload(state, DP_ADD_DEVICE);
  return { loaded: true, isAddForm: true, deviceClass };
};
export const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(closeModal(DP_ADD_DEVICE)),
  addDevice: bindActionCreators(addDevice, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceFormModalContainer);
