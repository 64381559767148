"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
export const changeSelection = createAction(
  "change slider selection for report",
  (domain, reportId) => ({ payload: { domain, reportId } })
);
export const reset = createAction(
  "reset reports slider selection"
);
export const reducer = {
  [changeSelection.type]: (state, { domain, reportId }) => __spreadProps(__spreadValues({}, state), {
    reports: __spreadProps(__spreadValues({}, state.reports), {
      sliderSelection: __spreadProps(__spreadValues({}, state.reports.sliderSelection), {
        [reportId]: domain
      })
    })
  }),
  [reset.type]: (state) => __spreadProps(__spreadValues({}, state), {
    reports: __spreadProps(__spreadValues({}, state.reports), {
      sliderSelection: {}
    })
  })
};
