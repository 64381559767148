"use strict";
import * as reportTypes from "../../../../common/enums/reportTypes";
import { selectIsOeeHidden } from "./appConfig.selector";
import { createSelector } from "../util";
const EMPTY_SELECTION = [];
export const selectAllReports = createSelector((state) => {
  const list = state.reports.data;
  const oeeReportsHidden = selectIsOeeHidden(state);
  if (!oeeReportsHidden)
    return list;
  return list.filter((item) => item.type !== reportTypes.kpi);
});
export const selectReportsOfSelectedTab = createSelector((state) => {
  const selectedTabId = state.reports.tabs.selectedTabId;
  if (!selectedTabId)
    return [];
  return selectAllReports(state).filter((report) => report.reportsTab && report.reportsTab.id === selectedTabId);
});
export const selectLoading = createSelector((state) => state.reports.loading);
export const selectHasNoReports = createSelector((state) => !state.reports.data || !state.reports.data.length);
export const selectHasNoReportsInSelectedTab = createSelector((state) => selectHasNoReports(state) || !selectReportsOfSelectedTab(state).length);
export const selectAllSelections = createSelector((state) => state.reports.sliderSelection);
export const selectAllChartTypes = createSelector((state) => state.reports.chartTypes);
export const selectSelectionByReportId = createSelector((state, reportId) => {
  var _a;
  const selections = selectAllSelections(state);
  return (_a = selections[reportId]) != null ? _a : EMPTY_SELECTION;
});
export const selectReportIndexById = createSelector((state, reportId) => selectAllReports(state).findIndex(({ id }) => id === reportId));
export const selectReportByIndex = createSelector((state, index) => selectAllReports(state)[index]);
export const getReportById = (reportId, reports = []) => reports.find(({ id }) => id === reportId);
export const isReportOutputEmpty = (report) => {
  if (report.data.chartVersion === "v1") {
    return report.data.chart !== void 0 && report.data.chart.length === 0;
  }
  if (report.data.chartVersion === "v2") {
    return report.data.chartV2 !== void 0 && report.data.chartV2.length === 0;
  }
  return false;
};
export const isLeftAxisInPercent = (report) => report.type === reportTypes.kpi;
export const isTabsBarVisible = (report) => report.type !== reportTypes.dataPoints;
