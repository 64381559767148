"use strict";
import { atom, useAtom } from "jotai";
import { INITIAL_TOOL } from "../schema/type/panelTool";
export const panelInitAtomsMap = /* @__PURE__ */ new Map();
export const createPanelInitAtom = (panelId, toolInit, timeInit) => {
  const panelInitAtom = atom({
    id: panelId,
    tool: toolInit,
    time: timeInit
  });
  panelInitAtomsMap.set(panelId, panelInitAtom);
  return panelInitAtom;
};
export const getPanelInitAtom = (panelId) => panelInitAtomsMap.get(panelId);
export const resetPanelInitAtom = (panelId) => panelInitAtomsMap.delete(panelId);
export function usePanelAtom(panelId) {
  let existingAtom = getPanelInitAtom(panelId);
  if (!existingAtom) {
    const toolInit = INITIAL_TOOL;
    existingAtom = createPanelInitAtom(panelId, toolInit, {});
  }
  return useAtom(existingAtom);
}
