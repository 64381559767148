"use strict";
import styled from "styled-components";
export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin: 0 10px;
  font-weight: 500;
  font-size: 13px;
`;
export const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 4px;

  input[type='range'] {
    width: 100%;
  }
`;
export const TimeDisplay = styled.div`
  display: flex;
  gap: 5px;
  font-family: monospace;
  justify-content: center;
`;
export const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;
  align-self: center;
`;
export const Button = styled.button`
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
