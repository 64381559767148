"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { EMPTY_CALENDAR_QUERIES } from "../constants/calendar.constants";
export const machineCalendarsInitialState = {
  loading: false,
  loaded: false,
  error: null,
  list: [],
  filters: __spreadValues({}, EMPTY_CALENDAR_QUERIES),
  newCalendarEvent: {
    loading: false,
    loaded: false,
    error: null
  },
  available: true,
  cloneCalendar: {
    loading: false,
    loaded: false,
    error: null
  },
  enabledCreateEvent: true,
  access: true
};
export const machineCalendarsTestState = {
  loading: false,
  loaded: false,
  error: null,
  list: [],
  filters: __spreadValues({}, EMPTY_CALENDAR_QUERIES),
  newCalendarEvent: {
    loading: false,
    loaded: false,
    error: null
  },
  available: true,
  cloneCalendar: {
    loading: false,
    loaded: false,
    error: null
  },
  enabledCreateEvent: true,
  access: true
};
