"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { ANALYZER_MANAGER, ANALYZER_VIEWER } from "../constants/permissions";
export default class AnalyzerPermissions {
  constructor(roles) {
    __publicField(this, "isAnalyzerManager");
    __publicField(this, "isAnalyzerViewer");
    this.isAnalyzerManager = roles.indexOf(ANALYZER_MANAGER) !== -1;
    this.isAnalyzerViewer = roles.indexOf(ANALYZER_VIEWER) !== -1;
  }
  canAddAnalyzer() {
    return this.isAnalyzerManager;
  }
  canEditAnalyzer() {
    return this.isAnalyzerManager;
  }
  canViewAnalyzer() {
    return this.isAnalyzerManager || this.isAnalyzerViewer;
  }
}
