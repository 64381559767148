import classnames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { Todo } from '../../../../../../common/types/common';
import { InputError } from '../../index';

interface NewProps {
  input: Todo;
  meta: Todo;
  translateOptionsLabels?: boolean;
  disabled?: boolean;
  options: { label: { id: string; defaultMessage: string }; value: unknown }[];
}

export const RadioWithOptions: React.FC<NewProps> = ({
  input,
  meta,
  options,
  translateOptionsLabels = false,
  disabled = false,
}) => {
  const { formatMessage } = useIntl();

  const finalOptions = translateOptionsLabels
    ? options.map((item) => ({
        ...item,
        label: formatMessage(item.label),
      }))
    : options;

  const isError = meta.touched && meta.error;
  const className = classnames({
    error: isError,
  });

  return (
    <div className={className}>
      <div className='radio-items'>
        {finalOptions.map((item) => (
          <div className='radio-item' key={item.value}>
            <label className='radio radio-round'>
              <input
                type='radio'
                onChange={input.onChange}
                value={item.value}
                name={input.name}
                disabled={disabled}
                checked={item.value === input.value}
                required={input.required}
              />
              <span>{item.label}</span>
            </label>
          </div>
        ))}
      </div>
      <div className='error-line'>
        <InputError error={meta.errorMessage} />
      </div>
    </div>
  );
};
