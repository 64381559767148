"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import { flatten } from "lodash";
import { getInventory } from "../../core/api/inventory";
import { INVENTORY_DATATRONS, INVENTORY_MACHINES } from "../../../../common/constants/inventory";
export const fetchInventory = createAction("fetch inventory");
export const fetchInventorySuccess = createAction(
  "fetch inventory - success",
  (inventoryItems) => ({ payload: inventoryItems })
);
export const fetchInventoryError = createAction("fetch inventory - error", (error) => ({
  payload: error
}));
export const reducer = {
  [fetchInventory.type]: (state) => __spreadProps(__spreadValues({}, state), {
    inventory: __spreadProps(__spreadValues({}, state.inventory), {
      locations: [],
      machines: [],
      datatrons: [],
      loading: true,
      loaded: false
    })
  }),
  [fetchInventorySuccess.type]: (state, locations) => __spreadProps(__spreadValues({}, state), {
    inventory: __spreadProps(__spreadValues({}, state.inventory), {
      loading: false,
      loaded: true,
      locations,
      machines: flatten(locations.map((location) => location[INVENTORY_MACHINES])),
      datatrons: flatten(locations.map((location) => location[INVENTORY_DATATRONS]))
    })
  }),
  [fetchInventoryError.type]: (state, error) => __spreadProps(__spreadValues({}, state), {
    inventory: __spreadProps(__spreadValues({}, state.inventory), {
      loading: false,
      loaded: false,
      error
    })
  })
};
export function* fetchInventorySaga() {
  const response = yield call(getInventory);
  if (response.type === "generalError") {
    yield put(fetchInventoryError(response.error));
    return;
  }
  if (response.type === "parseError") {
    yield put(fetchInventoryError(response.error.message));
    return;
  }
  if ("status" in response.data) {
    yield put(fetchInventoryError(response.data.message));
    return;
  }
  yield put(fetchInventorySuccess(response.data));
}
export function* watchFetchInventory() {
  yield takeEvery(fetchInventory, fetchInventorySaga);
}
