"use strict";
import { connect } from "react-redux";
import Select from "./Select";
import * as selector from "../../../../redux/selectors/search.selector";
import { initializeDatatronAsOption } from "../../../../core/common/datatron";
import { searchDevices } from "../../../../redux/modules/search.devices.module";
import {
  AI_APP_FORM_NAME,
  DEVICES_FILTERS_FIELD
} from "../../../../redux/constants/aiApp.form.constants";
import { change } from "redux-form";
const mapStateToProps = (state) => ({
  options: selector.getDatatronSearchResults(state).map(initializeDatatronAsOption),
  isLoading: selector.loading(state)
});
const mapDispatchToProps = (dispatch, { input }) => ({
  onChange: (option) => {
    var _a;
    if (((_a = input.value) == null ? void 0 : _a.value) !== option.value) {
      dispatch(change(AI_APP_FORM_NAME, DEVICES_FILTERS_FIELD, []));
    }
    input.onChange(option);
    dispatch(searchDevices(option.value));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Select);
