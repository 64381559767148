"use strict";
export const TIME_RANGE_OFFSET_MS = 3 * 60 * 1e3;
export const MAX_DATA_POINT_DISPLAY = Math.floor(window.innerWidth * 50 / 100);
export const MIN_TIME_RANGE_OFFSET_MS = 1 * 10 * 1e3;
export const MAX_TIME_DIFF_IN_NS = 15 * 1e9;
export const isLoadable = (signalData, neededTimeWindow) => {
  if (signalData.status === "idle")
    return true;
  if (signalData.status === "failed")
    return true;
  if (signalData.request.fromUnixMs > neededTimeWindow.fromUnixMs)
    return true;
  if (signalData.request.toUnixMs < neededTimeWindow.toUnixMs)
    return true;
  return false;
};
