"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __knownSymbol = (name, symbol) => {
  return (symbol = Symbol[name]) ? symbol : Symbol.for("Symbol." + name);
};
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __await = function(promise, isYieldStar) {
  this[0] = promise;
  this[1] = isYieldStar;
};
var __yieldStar = (value) => {
  var obj = value[__knownSymbol("asyncIterator")];
  var isAwait = false;
  var method;
  var it = {};
  if (obj == null) {
    obj = value[__knownSymbol("iterator")]();
    method = (k) => it[k] = (x) => obj[k](x);
  } else {
    obj = obj.call(value);
    method = (k) => it[k] = (v) => {
      if (isAwait) {
        isAwait = false;
        if (k === "throw")
          throw v;
        return v;
      }
      isAwait = true;
      return {
        done: false,
        value: new __await(new Promise((resolve) => {
          var x = obj[k](v);
          if (!(x instanceof Object))
            throw TypeError("Object expected");
          resolve(x);
        }), 1)
      };
    };
  }
  return it[__knownSymbol("iterator")] = () => it, method("next"), "throw" in obj ? method("throw") : it.throw = (x) => {
    throw x;
  }, "return" in obj && method("return"), it;
};
import { createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import * as api from "../../core/api";
import {
  addActivityIndicatorBeforeUpdate,
  removeActivityIndicatorAfterUpdateOrFail
} from "../../core/common/maintenance.stream";
import { enforceMinimalDuration } from "./helper";
export const moveActivity = createAction(
  "move activity",
  (activityId, destinationColumn, listPositions) => ({
    payload: {
      activityId,
      destinationColumn,
      listPositions
    }
  })
);
export const moveActivityFail = createAction(
  "move activity fail",
  (activityId, error) => ({
    payload: {
      activityId,
      error
    }
  })
);
export const moveActivitySuccess = createAction("move activity success", (activityId) => ({
  payload: {
    activityId
  }
}));
export const reducer = {
  [moveActivity.type]: (storeState, { activityId, listPositions }) => {
    const activities = addActivityIndicatorBeforeUpdate(
      storeState.maintenance.activities,
      activityId,
      listPositions
    );
    return __spreadProps(__spreadValues({}, storeState), {
      maintenance: __spreadProps(__spreadValues({}, storeState.maintenance), {
        activities
      })
    });
  },
  [moveActivitySuccess.type]: (storeState, { activityId }) => {
    const activities = removeActivityIndicatorAfterUpdateOrFail(
      storeState.maintenance.activities,
      activityId
    );
    return __spreadProps(__spreadValues({}, storeState), {
      maintenance: __spreadProps(__spreadValues({}, storeState.maintenance), {
        activities
      })
    });
  },
  [moveActivityFail.type]: (state, { activityId, error }) => {
    const activities = removeActivityIndicatorAfterUpdateOrFail(
      state.maintenance.activities,
      activityId
    );
    return __spreadProps(__spreadValues({}, state), {
      maintenance: __spreadProps(__spreadValues({}, state.maintenance), {
        activities,
        error
      })
    });
  }
};
export function* moveActivitySaga({ payload: { activityId, listPositions } }) {
  const startTime = Date.now();
  const { response, error } = yield call(api.activities.updatePositions, {
    positions: listPositions
  });
  yield* __yieldStar(enforceMinimalDuration(startTime));
  if (response) {
    yield put(moveActivitySuccess(activityId));
  } else {
    yield put(moveActivityFail(activityId, error));
  }
}
export function* watchMoveActivity() {
  yield takeEvery(moveActivity, moveActivitySaga);
}
