"use strict";
export const pathButtonWidgetTestState = {
  tempLocationPath: {
    PROFILE_PAGE: [
      { id: "1st", name: "Germany" },
      { id: "2nd", name: "Berlin" }
    ],
    DATATRON_FORM_PAGE: [
      { id: "111", name: "USA" },
      { id: "222", name: "LA" }
    ],
    MACHINE_FORM_PAGE: [
      { id: "333", name: "Russia" },
      { id: "444", name: "Moscow" }
    ],
    ADMIN_LOCATION_FORM: [
      { id: "333", name: "Russia" },
      { id: "444", name: "Moscow" }
    ]
  }
};
export const pathButtonWidgetInitialState = {
  tempLocationPath: {}
};
