"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  forEach,
  groupBy,
  intersection,
  isArray,
  isEmpty,
  isEqual,
  mergeWith,
  uniq,
  uniqBy
} from "lodash";
const _mergeCustomizer = (objValue, srcValue) => {
  if (isArray(objValue))
    return uniq(objValue.concat(srcValue));
  return void 0;
};
export const findCommonEnum = (items = []) => {
  const enumsToItem = items.reduce(
    (result, item, index) => __spreadProps(__spreadValues({}, result), { [index]: [] }),
    {}
  );
  const enumValues = {};
  items.forEach((item, index) => {
    forEach(item.properties, (property, key) => {
      if (property.enum) {
        enumsToItem[index].push(key);
        enumValues[key] = [...enumValues[key] || [], ...property.enum];
      }
    });
  });
  const keys = intersection(...Object.values(enumsToItem));
  const fields = keys.reduce(
    (result, key) => [
      ...result,
      {
        _key: key,
        type: "enum",
        enum: enumValues[key],
        _isCommonEnum: true
      }
    ],
    []
  );
  return { keys, fields };
};
export const mergePropertiesWithSameKey = (properties = []) => {
  if (!properties || !properties.length)
    return null;
  return mergeWith({}, ...properties, _mergeCustomizer);
};
export const getProperties = (properties = {}, required = [], _parent = [], setValidFor = null) => {
  const fields = [];
  const validFor = {};
  forEach(properties, (value, key) => {
    const property = __spreadProps(__spreadValues({}, value), {
      _key: key,
      _required: required.indexOf(key) >= 0,
      _parent
    });
    if (!property.type && property.enum) {
      property.type = "enum";
      if (setValidFor && (setValidFor == null ? void 0 : setValidFor.length) && (setValidFor == null ? void 0 : setValidFor.indexOf(property._key)) >= 0) {
        validFor[property._key] = property.enum;
      }
    }
    fields.push(property);
  });
  if (!isEmpty(validFor)) {
    return fields.map((item) => __spreadProps(__spreadValues({}, item), { _validFor: validFor }));
  }
  return fields;
};
export const flatSchemaToFields = (parsedSchema, _parent = []) => {
  var _a;
  let fields = [];
  let common = null;
  let setValidFor = null;
  let objectToExplore = [parsedSchema];
  if (parsedSchema.allOf) {
    objectToExplore = parsedSchema.allOf;
  } else if (parsedSchema.oneOf) {
    objectToExplore = parsedSchema.oneOf;
    common = findCommonEnum(parsedSchema.oneOf);
  }
  if (common) {
    (_a = common.fields) == null ? void 0 : _a.forEach((item) => fields.push(__spreadProps(__spreadValues({}, item), { _parent })));
    setValidFor = common.keys;
  }
  forEach(objectToExplore, (block) => {
    if (block.properties) {
      const propsToAppend = getProperties(
        block.properties,
        block.required,
        _parent,
        setValidFor
      ).map((item) => {
        const newItem = __spreadValues({}, item);
        if (newItem.allOf || newItem.oneOf) {
          const childProps = flatSchemaToFields(newItem, [..._parent, newItem._key]);
          fields = [...fields, ...childProps];
          newItem._child = childProps;
        }
        if (newItem.type === "object" && !newItem.additionalProperties) {
          const childProps = flatSchemaToFields(newItem, [..._parent, newItem._key]);
          fields = [...fields, ...childProps];
          newItem._child = childProps;
          return null;
        }
        return newItem;
      });
      fields = [...fields, ...propsToAppend.filter((item) => item !== null)];
    }
    if (block.allOf || block.oneOf) {
      fields = [...fields, ...flatSchemaToFields(block, _parent)];
    }
  });
  const groupedFields = groupBy(fields, "_key");
  const mergedGroups = [];
  forEach(groupedFields, (group) => mergedGroups.push(mergePropertiesWithSameKey(group)));
  return mergedGroups;
};
export const getConfigValue = (key, config = {}, parent = []) => {
  let scope = config;
  let i = 0;
  while (i < parent.length) {
    scope = scope[parent[i++]];
  }
  return scope[key] === void 0 ? "" : scope[key];
};
function getNestedConfig(config, parentKeys) {
  if (parentKeys.length === 0) {
    return config;
  }
  const [currentKey, ...restKeys] = parentKeys;
  if (config.hasOwnProperty(currentKey)) {
    return getNestedConfig(config[currentKey], restKeys);
  }
  return void 0;
}
export const getFieldsBasedOnConfig = (config = {}, fields = [], _parent = []) => fields.flatMap((field) => {
  const key = field._key;
  const value = getConfigValue(key, config);
  const sameLevel = isEqual(_parent, field._parent || []);
  if (!sameLevel) {
    const parentKeys = field._parent;
    if (parentKeys && parentKeys.length > 0) {
      const nestedConfig = getNestedConfig(config, parentKeys);
      return {
        value: nestedConfig ? getConfigValue(key, nestedConfig) : "",
        field
      };
    }
    return [];
  }
  if ((value && typeof value === "object" && !isArray(value) || field.type === "object") && // Don't descend into custom config with additional properties as this will be editable directly.
  !(field._key === "custom" && field.additionalProperties) && key !== "internal") {
  } else {
    let allowed = true;
    if (field._validFor && !isEmpty(field._validFor)) {
      forEach(field._validFor, (allowedValues, validForKey) => {
        if (validForKey !== key && !(allowedValues.indexOf(config[validForKey]) >= 0)) {
          allowed = false;
        }
      });
    }
    if (allowed) {
      return {
        value,
        field
      };
    }
  }
  return [];
});
export const getFieldsBasedOnConfigKeys = (configKeys = [], fields = [], _parent = []) => {
  let result = [];
  forEach(fields, (field) => {
    const key = field._key;
    if (field.type === "object" && field._child) {
      result = [
        ...result,
        ...getFieldsBasedOnConfigKeys(
          configKeys,
          uniqBy(field._child, (i) => i._key),
          [..._parent, key]
        )
      ];
    } else {
      const expectedConfigKey = [..._parent, key].join(".");
      if (configKeys.indexOf(expectedConfigKey) >= 0) {
        result.push(field);
      }
    }
  });
  return result;
};
export const createConfigKeyHashToFieldsMap = (dataPoints = [], dataPointFields = [], inputMap = {}) => {
  const result = __spreadValues({}, inputMap);
  dataPoints.forEach((dataPoint) => {
    if (!result[dataPoint.configKeysHash]) {
      result[dataPoint.configKeysHash] = getFieldsBasedOnConfigKeys(
        dataPoint.configKeys,
        dataPointFields
      );
    }
  });
  return result;
};
export const formatDeviceConfigValue = (value) => {
  if (typeof value === "object" && value !== null) {
    return JSON.stringify(value);
  }
  return value;
};
