"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import * as locationsApi from "../../core/api/locations";
export const fetchLocationById = createAction(
  "fetch location by id",
  (locationId) => ({ payload: locationId })
);
export const fetchLocationByIdSuccess = createAction(
  "fetch location by id successfully",
  (location) => ({ payload: location })
);
export const fetchLocationByIdFail = createAction(
  "fetch location by id fail",
  (error) => ({ payload: error })
);
export const reducer = {
  [fetchLocationById.type]: (state) => __spreadProps(__spreadValues({}, state), {
    location: __spreadProps(__spreadValues({}, state.location), {
      _status: {
        loaded: false,
        loading: true,
        error: ""
      }
    })
  }),
  [fetchLocationByIdSuccess.type]: (state, location) => __spreadProps(__spreadValues({}, state), {
    location: __spreadProps(__spreadValues(__spreadValues({}, state.location), location), {
      _status: __spreadProps(__spreadValues({}, state.location.status), {
        loaded: true,
        loading: false
      })
    })
  }),
  [fetchLocationByIdFail.type]: (state, error) => __spreadProps(__spreadValues({}, state), {
    location: __spreadProps(__spreadValues({}, state.location), {
      _status: {
        loaded: false,
        loading: false,
        error
      }
    })
  })
};
export function* fetchLocationByIdSaga({ payload: locationId }) {
  const { response, error } = yield call(locationsApi.getById, locationId);
  if (response) {
    yield put(fetchLocationByIdSuccess(response));
  } else {
    yield put(fetchLocationByIdFail(error));
  }
}
export function* watchFetchLocationById() {
  yield takeEvery(fetchLocationById, fetchLocationByIdSaga);
}
