export const gridSize = 8;

/**
 * The space between each column in a board.
 */
export const columnGap = 2 * gridSize;

/**
 * The space between each card in a column.
 */
export const cardGap = 20;

export type Item = {
  name: string;
  role: string;
  avatarUrl: string;
  id: string;
  position: number;
  indicate?: boolean;
};

export type ColumnType = {
  title: string;
  columnId: string;
  translatedTitle: {
    id: string;
    defaultMessage: string;
  };
  items: Item[];
  isDropAllowed: boolean;
  cardsCount: number;
  moreAvailable?: boolean;
};

export type ColumnMap = { [columnId: string]: ColumnType };
