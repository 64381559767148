import React from 'react';
import { FormattedMessage } from 'react-intl';
import commonMessages from '../../../messages/common.messages';
import commonCoreMessages from '../../../messages/common.core.messages';
import { AiAppDetails, Device } from '../../../redux/state/aiApps.state';

// Smaller sub-components
const BasicInfoSection: React.FC<{ appName: string; description: string }> = ({
  appName,
  description,
}) => (
  <div className='ai-app-details--item'>
    <div className='ai-app-details--icon'>
      <span className='icon-info' />
    </div>
    <div className='ai-app-details--text'>
      <div className='ai-app-details--position w50'>
        <label>
          <FormattedMessage tagName='b' {...commonMessages.name} />
        </label>
        <p>{appName}</p>
      </div>
      <div className='ai-app-details--position w50'>
        <label>
          <FormattedMessage tagName='b' {...commonCoreMessages.description} />
        </label>
        <p className='text-clamp'>{description}</p>
      </div>
    </div>
  </div>
);

const StatusDatatronSection: React.FC<{ status: string; datatronName: string }> = ({
  status,
  datatronName,
}) => (
  <div className='ai-app-details--item'>
    <div className='ai-app-details--icon'></div>
    <div className='ai-app-details--text'>
      <div className='ai-app-details--position w50'>
        <label>
          <FormattedMessage tagName='b' {...commonMessages.status} />
        </label>
        <p>{status}</p>
      </div>
      <div className='ai-app-details--position w50'>
        <label>
          <FormattedMessage tagName='b' {...commonCoreMessages.datatron} />
        </label>
        <p>{datatronName}</p>
      </div>
    </div>
  </div>
);

const DevicesSection: React.FC<{ devices: Device[] }> = ({ devices }) => (
  <div className='ai-app-details--item'>
    <div className='ai-app-details--icon'></div>
    <div className='ai-app-details--text'>
      <div className='ai-app-details--position'>
        <label>
          <FormattedMessage tagName='b' {...commonCoreMessages.devices} />
        </label>
        <p>
          {devices.map((device) => (
            <span className='device-list' key={device.id}>
              {device.name}
            </span>
          ))}
        </p>
      </div>
    </div>
  </div>
);

interface AiAppInfoCardProps {
  appDetails: AiAppDetails;
}

export const AiAppInfoCard: React.FC<AiAppInfoCardProps> = ({ appDetails }) => {
  const { appName, description, status, datatron, devices } = appDetails;

  return (
    <div className='info-grid'>
      <div className='info-card'>
        <div className='ai-app-details--list'>
          {/* Basic Info Section */}
          <BasicInfoSection appName={appName} description={description} />

          {/* Status and Datatron Section */}
          <StatusDatatronSection status={status} datatronName={datatron.name} />

          {/* Devices Section */}
          <DevicesSection devices={devices} />
        </div>
      </div>
    </div>
  );
};
