import React from 'react';

import DeviceMenu from './deviceMenu/DeviceMenu';
import { Todo, TodoFunction } from '../../../../../../../../common/types/common';

interface DeviceHeaderProps {
  device: Todo;
  toggle: TodoFunction;
  isOpen: boolean;
  datatronId: string;
}

const DeviceHeader: React.FC<DeviceHeaderProps> = ({
  device,
  toggle,
  isOpen = false,
  datatronId = '',
}) => (
  <div className='datatron--top'>
    <div className='datatron--title'>
      <i className='icon icon-cogs' />
      <span>{device.name}</span>
    </div>
    {/* @ts-ignore */}
    <DeviceMenu device={device} isOpen={isOpen} toggle={toggle} datatronId={datatronId} />
  </div>
);

export default DeviceHeader;
