"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { put, call, takeEvery } from "redux-saga/effects";
import * as api from "../../core/api";
import { INVENTORY_MACHINES } from "../../../../common/constants/inventory";
import { fetchMachineOee } from "./machine.oee.fetch.module";
export const fetchPreviewItem = createAction(
  "fetch inventory preview",
  ({ itemType, itemId }) => ({ payload: { itemType, itemId } })
);
export const fetchPreviewItemSuccess = createAction(
  "fetch inventory preview - success",
  (item) => ({ payload: item })
);
export const fetchPreviewItemFail = createAction(
  "fetch inventory preview - fail",
  (error) => ({ payload: error })
);
export const reducer = {
  [fetchPreviewItem.type]: (state) => __spreadProps(__spreadValues({}, state), {
    inventory: __spreadProps(__spreadValues({}, state.inventory), {
      preview: __spreadProps(__spreadValues({}, state.inventory.preview), {
        loading: true,
        loaded: false,
        error: null
      })
    })
  }),
  [fetchPreviewItemSuccess.type]: (state, item) => __spreadProps(__spreadValues({}, state), {
    inventory: __spreadProps(__spreadValues({}, state.inventory), {
      preview: __spreadProps(__spreadValues({}, state.inventory.preview), {
        loading: false,
        loaded: true,
        error: null,
        item
      })
    })
  }),
  [fetchPreviewItemFail.type]: (state, error) => __spreadProps(__spreadValues({}, state), {
    inventory: __spreadProps(__spreadValues({}, state.inventory), {
      preview: __spreadProps(__spreadValues({}, state.inventory.preview), {
        loading: false,
        loaded: false,
        error,
        item: null
      })
    })
  })
};
export function* fetchPreviewItemSaga({ payload: { itemType, itemId } }) {
  let apiEndpoint = api.datatrons.getDatatron;
  if (itemType === INVENTORY_MACHINES) {
    apiEndpoint = api.machines.getMachine;
    yield put(fetchMachineOee(itemId));
  }
  const { response, error } = yield call(apiEndpoint, itemId);
  if (response) {
    yield put(fetchPreviewItemSuccess(response));
  } else {
    yield put(fetchPreviewItemFail(error));
  }
}
export function* watchFetchPreviewItem() {
  yield takeEvery(fetchPreviewItem, fetchPreviewItemSaga);
}
