"use strict";
import * as inventoryTypes from "../../../../common/constants/inventory";
import { getUsers } from "./usersSearch.selector";
import { getAssistants as getMachineAssistants } from "./machine.selector";
import { getAssistants as getDatatronAssistants } from "./datatron.selector";
export const filterExistingAssistants = (allUsers, addedAssistants) => {
  const assistantsIds = addedAssistants.map((assistance) => assistance.id);
  return allUsers.filter((user) => assistantsIds.indexOf(user.id) === -1);
};
export const getAssistants = (state, inventoryType) => {
  let list = [];
  if (inventoryType === inventoryTypes.INVENTORY_MACHINES) {
    list = getMachineAssistants(state);
  } else if (inventoryType === inventoryTypes.INVENTORY_DATATRONS) {
    list = getDatatronAssistants(state);
  }
  return filterExistingAssistants(getUsers(state), list);
};
