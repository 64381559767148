"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, delay, select, takeEvery } from "redux-saga/effects";
import * as api from "../../core/api";
import {
  getDataPointFromListById,
  getArchivedDataPointsOfDevice,
  getDatatron,
  getDeviceById
} from "../selectors/datatron.selector";
import {
  compareById,
  removeObjectFromList,
  replaceObjectInList
} from "../../../../common/helpers/object";
import notificationMessage from "../../messages/notification.message";
import { sendNotification } from "./notifications.module";
import { NOTIFICATION_EVENT, NOTIFICATION_ERROR } from "../../core/notifications";
import { highlightDataPoint } from "./datatron.devices.dataPoints.highlight.module";
const HIDE_DELAY_IN_MILLISECONDS = 1e3;
export const unarchiveDataPoint = createAction("unarchive data point", (deviceId, dataPointId) => ({
  payload: { deviceId, dataPointId }
}));
export const unarchiveDataPointSuccess = createAction(
  "unarchive data point - success",
  (deviceId, dataPointId) => ({ payload: { deviceId, dataPointId } })
);
export const unarchiveDataPointFail = createAction(
  "unarchive data point - fail",
  (deviceId, dataPointId, error) => ({ payload: { deviceId, dataPointId, error } })
);
export const reducer = {
  [unarchiveDataPoint.type]: (state, { deviceId, dataPointId }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const archivedDataPoints = getArchivedDataPointsOfDevice(device);
    const dataPoint = getDataPointFromListById(archivedDataPoints, dataPointId);
    if (!dataPoint)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      archiveDataPoints: __spreadProps(__spreadValues({}, device.archiveDataPoints), {
        loading: true,
        loaded: false,
        error: null
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  },
  [unarchiveDataPointSuccess.type]: (state, { deviceId, dataPointId }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const archivedDataPoints = getArchivedDataPointsOfDevice(device);
    const dataPointToRemove = getDataPointFromListById(archivedDataPoints, dataPointId);
    if (!dataPointToRemove)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      archivedDataPoints: __spreadProps(__spreadValues({}, device.dataPoints), {
        list: removeObjectFromList(archivedDataPoints, dataPointToRemove, compareById),
        loading: false,
        loaded: true,
        error: null
      }),
      dataPoints: __spreadProps(__spreadValues({}, device.dataPoints), {
        list: [...device.dataPoints.list, dataPointToRemove]
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  },
  [unarchiveDataPointFail.type]: (state, { deviceId, dataPointId, error }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const archivedDataPoints = getArchivedDataPointsOfDevice(device);
    const dataPoint = getDataPointFromListById(archivedDataPoints, dataPointId);
    if (!dataPoint)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      archiveDataPoints: __spreadProps(__spreadValues({}, device.archiveDataPoints), {
        loading: true,
        loaded: false,
        error
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  }
};
export function* unarchiveDataPointSaga({ payload: { deviceId, dataPointId } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const { response, error } = yield call(api.datatrons.unarchiveDataPoint, {
    datatronId: datatron.id,
    deviceId,
    dataPointId
  });
  if (response) {
    yield put(highlightDataPoint(dataPointId));
    yield put(
      sendNotification(
        notificationMessage.datapoint_unarchive_success_title,
        notificationMessage.successfully_unarchived_description,
        NOTIFICATION_EVENT
      )
    );
    yield delay(HIDE_DELAY_IN_MILLISECONDS);
    yield put(unarchiveDataPointSuccess(deviceId, dataPointId));
  } else {
    yield put(
      sendNotification(
        notificationMessage.datapoint_unarchive_failed_title,
        __spreadProps(__spreadValues({}, notificationMessage.failed_to_unarchive_description), {
          values: { errorMsg: error }
        }),
        NOTIFICATION_ERROR
      )
    );
    yield delay(HIDE_DELAY_IN_MILLISECONDS);
    yield put(unarchiveDataPointFail(deviceId, dataPointId, error));
  }
}
export function* watchUnarchiveDataPoint() {
  yield takeEvery(unarchiveDataPoint, unarchiveDataPointSaga);
}
