"use strict";
export const DATATRON_DEVICE_FORM_NAME = "DATATRON_DEVICE_FORM_NAME";
export const DEVICE_TYPE_FIELD = "deviceTypeId";
export const ORGANIZATION_FIELD = "organizationId";
export const LOCATION_FIELD = "locationId";
export const NAME_FIELD = "name";
export const SERIAL_NUMBER_FIELD = "serialNumber";
export const DEVICE_UUID_FIELD = "deviceUUID";
export const DATATRON_DEVICE_PROPS = [
  DEVICE_TYPE_FIELD,
  ORGANIZATION_FIELD,
  LOCATION_FIELD,
  NAME_FIELD,
  SERIAL_NUMBER_FIELD,
  DEVICE_UUID_FIELD
];
