"use strict";
import { EMPTY_RESULTS } from "../constants/search.constants";
export const searchInitialState = {
  loading: false,
  loaded: false,
  error: "",
  results: EMPTY_RESULTS
};
export const searchTestState = {
  loading: false,
  loaded: false,
  error: "",
  results: EMPTY_RESULTS
};
