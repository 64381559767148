import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import Form from './Form';
import { getDeviceFormFields } from '../../../../../redux/selectors/datatron.devices.selector';
import { getDeviceTypeById } from '../../../../../redux/selectors/datatron.selector';
import {
  DATATRON_DEVICE_FORM_NAME,
  DEVICE_TYPE_FIELD,
} from '../../../../../redux/constants/datatron.device.form.constants';

export const mapStateToProps = (state) => {
  const formValues = getDeviceFormFields(state);
  const selectedDeviceTypeId = formValues[DEVICE_TYPE_FIELD];
  const deviceType = getDeviceTypeById(state, selectedDeviceTypeId);
  const allFormValues = getFormValues(DATATRON_DEVICE_FORM_NAME)(state) || {};
  const additionalFields = deviceType && deviceType.hasDeviceSchema ? deviceType.deviceFields : [];

  return {
    allFormValues,
    additionalFields,
  };
};

export default connect(mapStateToProps)(Form);
