"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import {
  PRODUCTS_MANAGER,
  PRODUCTS_VIEWER
} from "../constants/permissions";
class ProductsPermissions {
  constructor(roles) {
    __publicField(this, "isProductsManager");
    __publicField(this, "isProductsViewer");
    this.isProductsManager = roles.indexOf(PRODUCTS_MANAGER) !== -1;
    this.isProductsViewer = roles.indexOf(PRODUCTS_VIEWER) !== -1;
  }
  canCreateBatches() {
    return this.isProductsManager;
  }
  canCreateProducts() {
    return this.isProductsManager;
  }
  availableToAll() {
    return this.isProductsManager || this.isProductsViewer;
  }
}
export default ProductsPermissions;
