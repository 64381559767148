import React from 'react';

interface CommentCellProps {
  trainingMsg: string;
}

export const CommentCell: React.FC<CommentCellProps> = React.memo(({ trainingMsg }) => {
  if (!trainingMsg) return null;

  return (
    <div className='comment-text'>
      <i className='fa fa-comment' /> {trainingMsg}
    </div>
  );
});
