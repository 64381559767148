import { connect } from 'react-redux';

import CardOee from './CardOee';
import { getShift } from '../../../../core/common/report';
import { kpi } from '../../../../../../common/enums/reportTypes';

import * as selector from '../../../../redux/selectors/machine.oee.selector';

export const mapStateToProps = (state) => {
  const loading = selector.selectLoading(state) || false;
  const loaded = selector.selectLoaded(state);

  if (!loaded) return { loaded, loading };

  const oee = selector.selectMachineOee(state);
  oee.chart = oee.charts;

  if (!oee.oeeAvailable) return { loaded: false, loading: false };

  const report = {
    type: kpi,
    data: oee,
    disabledProps: [],
  };

  const shift = getShift(report);
  const isShiftExist = !!shift;
  const startDateTime = isShiftExist ? shift.start : null;
  const endDateTime = isShiftExist ? shift.end : null;

  return {
    loaded,
    loading,
    report,
    startDateTime,
    endDateTime,
  };
};

export default connect(mapStateToProps)(CardOee);
