import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  CartesianGridProps,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { useXAxisProps, useYAxisProps, useLineProps, useAreaProps } from '../signalChart/Helper';
import { ChartType, InterpolationType } from '../../../../../common/validation/analyzer.validation';
import { BooleanSignalData, NumberSignalData } from '../../../schema/signalData';

interface Props {
  data: BooleanSignalData | NumberSignalData;
  fromUnixNano: number;
  toUnixNano: number;
  color: string;
  height: number;
  chartType: ChartType;
  interpolationType: InterpolationType;
}

export const cartesianGridProps: Omit<CartesianGridProps, 'ref'> = {
  strokeDasharray: '4',
  horizontal: false,
};

export const PrintChart: React.FC<Props> = ({
  data,
  fromUnixNano,
  toUnixNano,
  color,
  height,
  chartType,
  interpolationType,
}) => {
  const xAxisProps = useXAxisProps(fromUnixNano, toUnixNano);
  xAxisProps.hide = false;
  const yAxisProps = useYAxisProps(data.signalType);
  const areaProps = useAreaProps(data.signalType, color, interpolationType);
  const lineProps = useLineProps(data.signalType, color, interpolationType);
  const margin = { top: 0, right: 0, left: 0, bottom: 0 };

  return (
    <ResponsiveContainer width='100%' height={height} minHeight={height}>
      {chartType === 'area' ? (
        <AreaChart data={data.data} margin={margin} height={height}>
          <CartesianGrid {...cartesianGridProps} />
          <XAxis domain={[fromUnixNano, toUnixNano]} {...xAxisProps} />
          <YAxis {...yAxisProps} />
          <Area {...areaProps} isAnimationActive={false} />
        </AreaChart>
      ) : (
        <LineChart data={data.data} margin={margin} height={height}>
          <CartesianGrid {...cartesianGridProps} />
          <XAxis domain={[fromUnixNano, toUnixNano]} {...xAxisProps} />
          <YAxis {...yAxisProps} />
          <Line {...lineProps} isAnimationActive={false} />
        </LineChart>
      )}
    </ResponsiveContainer>
  );
};
