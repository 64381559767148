"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import {
  TOOLS_MANAGER,
  TOOLS_VIEWER
} from "../constants/permissions";
export default class ToolsPermissions {
  constructor(roles) {
    __publicField(this, "isToolsManager");
    __publicField(this, "isToolsViewer");
    this.isToolsManager = roles.indexOf(TOOLS_MANAGER) !== -1;
    this.isToolsViewer = roles.indexOf(TOOLS_VIEWER) !== -1;
  }
  canAddTool() {
    return this.isToolsManager;
  }
  canEditTool() {
    return this.isToolsManager;
  }
  canViewTools() {
    return this.isToolsManager || this.isToolsViewer;
  }
}
