import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PanelHeader } from './PanelHeader';
import { PanelFooter } from './PanelFooter';
import { RowSignalDragAndDropWrapper } from '../signal/RowSignalDragAndDropWrapper';
import { PanelContextMenu } from '../ContextMenu';
import { getPanelSettingsByPanelId } from '../../../redux/selectors/analyzer.selector';
import { PanelSettings } from '../../../schema/type/panel';
import { updateSessionPanelPartial } from '../../../redux/modules/analyzer.sessions.panel.update.module';

export const Panel: React.FC<{ panelId: number }> = ({ panelId }) => {
  const dispatch = useDispatch();
  const setPanelSettings = useCallback(
    (panelSettings: PanelSettings) => {
      dispatch(
        updateSessionPanelPartial(panelId, {
          settings: panelSettings,
        }),
      );
    },
    [dispatch, panelId],
  );
  const { showHeader } = useSelector((state) => getPanelSettingsByPanelId(state, panelId));
  const panelSettings = useSelector((state) => getPanelSettingsByPanelId(state, panelId));

  return (
    <PanelContextMenu panelSettings={panelSettings} setPanelSettings={setPanelSettings}>
      <div className='session-card'>
        {showHeader && (
          <div className='session-card-header'>
            <PanelHeader panelId={panelId} key={`${panelId}-panel-header`} />
          </div>
        )}
        <div className='session-card-body'>
          <RowSignalDragAndDropWrapper panelId={panelId} key={`${panelId}-row-signal`} />
        </div>
        <div className='session-card-footer'>
          <PanelFooter panelId={panelId} key={`${panelId}-panel-footer`} />
        </div>
      </div>
    </PanelContextMenu>
  );
};
