import { connect } from 'react-redux';

import { getLink, getDashboardLink, getBatchesLink } from '../../index/routes';
import Menu from './Menu';
import PermissionedComponent from '../../common/permissions/PermissionedComponent.container';
import {
  REPORTS_PERMISSIONS,
  TOOLS_PERMISSIONS,
  EXPERIMENTS_PERMISSIONS,
  ANALYZER_PERMISSIONS,
  AI_APPS_PERMISSIONS,
} from '../../../../../common/constants/permissions';

const dashboardLink = getDashboardLink();
const maintenanceLink = getLink('maintenance');
const inventoryLink = getLink('inventory');
const toolsLink = getLink('tools');
const reportsLink = getLink('reports');
const productsLink = getBatchesLink();
const experimentsLink = getLink('experiments');
const analyzerLink = getLink('analyzer');
const aiAppsLink = getLink('aiApps');

export const mapStateToProps = (state, { permissions }) => ({
  dashboardLink,
  maintenanceLink,
  inventoryLink,
  toolsLink,
  reportsLink,
  productsLink,
  experimentsLink,
  analyzerLink,
  aiAppsLink,
  isAiAppsItemAvailable: permissions[AI_APPS_PERMISSIONS].canViewAiApps(),
  isToolsItemAvailable: permissions[TOOLS_PERMISSIONS].canViewTools(),
  isReportsItemAvailable: permissions[REPORTS_PERMISSIONS].availableToAll(),
  isExperimentsItemAvailable:
    permissions[EXPERIMENTS_PERMISSIONS].canViewExperiments() ||
    permissions[EXPERIMENTS_PERMISSIONS].canViewMeasurements(),
  isAnalyzerItemAvailable: permissions[ANALYZER_PERMISSIONS].canViewAnalyzer(),
});

export default PermissionedComponent([
  REPORTS_PERMISSIONS,
  TOOLS_PERMISSIONS,
  EXPERIMENTS_PERMISSIONS,
  ANALYZER_PERMISSIONS,
  AI_APPS_PERMISSIONS,
])(connect(mapStateToProps)(Menu));
