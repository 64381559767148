import React from 'react';
import { DimensionsDisplay } from './DimensionsDisplay';

interface Props {
  dimensions: number[][];
  updateParent: (indices: number[], newValue: any) => void;
}

/**
 * Display multiple dimensions within an array. This element includes all the dimensions that are defined between
 * the brackets of an `ARRAY [] OF` statement.
 * @param dimensions
 * @param updateParent
 */
export const ArrayButton: React.FC<Props> = ({ dimensions, updateParent }) => {
  /**
   * Update state by sending it to the parent
   */
  const updateChild = (index: number) => (indices, newValue) => {
    updateParent([index, ...indices], newValue);
  };

  /**
   * Add a child dimension by adding an array with default values at an index that doesn't exist yet.
   */
  const addChild = (e) => {
    e.preventDefault();
    updateChild(dimensions.length)([], [0, 0]);
  };

  /**
   * Use map to display each dimension and give them an update function that is aware of its index.
   * Additionally add a single green plus button at the end to add a new element.
   */
  const dimensionsDisplay = (
    <div className='dimensionsVerticalCenterFlex'>
      {dimensions?.map((item, index) => (
        <React.Fragment key={`dimension-wrapper-${index}`}>
          <DimensionsDisplay
            key={`dimension-display-${index}`}
            dimension={item}
            update={updateChild(index)}
          />
          <span style={{ paddingLeft: '1px', paddingRight: '4px' }}>{', '}</span>
        </React.Fragment>
      ))}
      {
        <span
          className='dimensionButtons dimensionPlus dimensionsInternalPlus button green'
          onClick={addChild}
        ></span>
      }
    </div>
  );

  return (
    <div className='dimensionsArrayOf'>
      <span>ARRAY [</span>
      {dimensionsDisplay}
      <span>] OF</span>
    </div>
  );
};
