"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __restKey = (key) => typeof key === "symbol" ? key : key + "";
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
export const initial = () => ({
  allIds: [],
  byId: {}
});
export const contains = (normalized, id) => normalized.allIds.includes(id);
export const get = (normalized, id, defaultValue) => {
  const value = normalized.byId[id];
  if (value !== void 0)
    return value;
  return defaultValue;
};
export const getAll = (normalized) => normalized.allIds.map((id) => get(normalized, id)).filter((item) => item !== void 0);
export const upsert = (normalized, id, data) => ({
  allIds: contains(normalized, id) ? normalized.allIds : [...normalized.allIds, id],
  byId: __spreadProps(__spreadValues({}, normalized.byId), {
    [id]: data
  })
});
export const update = (normalized, id, data) => {
  const existing = get(normalized, id);
  if (!existing)
    return normalized;
  if (typeof data === "function")
    return update(normalized, id, data(existing));
  return __spreadProps(__spreadValues({}, normalized), {
    byId: __spreadProps(__spreadValues({}, normalized.byId), {
      [id]: __spreadValues(__spreadValues({}, existing), data)
    })
  });
};
export const map = (normalized, fn) => __spreadProps(__spreadValues({}, normalized), {
  byId: normalized.allIds.reduce(
    (acc, id) => {
      const data = get(normalized, id);
      if (!data)
        return acc;
      return __spreadProps(__spreadValues({}, acc), {
        [id]: fn(data, id)
      });
    },
    {}
  )
});
const removeOne = (normalized, id) => {
  if (!contains(normalized, id))
    return normalized;
  const _a = normalized.byId, { [id]: _ } = _a, byId = __objRest(_a, [__restKey(id)]);
  return {
    allIds: normalized.allIds.filter((i) => i !== id),
    byId
  };
};
export const remove = (normalized, ...ids) => ids.reduce(removeOne, normalized);
export const filter = (normalized, predicate) => {
  [1, 2, 3].filter((i) => i !== 2);
  return normalized.allIds.reduce((acc, id) => {
    const data = get(normalized, id);
    if (!data)
      return acc;
    if (predicate(data, id))
      return acc;
    return remove(acc, id);
  }, normalized);
};
