"use strict";
import { SORT_WAY_ASC, SORT_WAY_DESC } from "../constants/index";
export const isValidDate = (date) => {
  const myDate = typeof date === "string" ? new Date(date) : date;
  return !(myDate && myDate.toString() === "Invalid Date");
};
export const isValidSortOption = (value) => value === SORT_WAY_ASC || value === SORT_WAY_DESC;
export const isValueMatchEnum = (value, enumArray = []) => enumArray.includes(value);
export const isBoolean = (value) => typeof value === "boolean";
export const isNumber = (value) => typeof value === "number";
export const isPositiveNumber = (value) => isNumber(value) && !Number.isNaN(value) && value >= 0;
export const isInteger = (value) => Number.isInteger(value);
export const isString = (value) => typeof value === "string";
const UUID_REGEX = /([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}){1}/;
export const isUuid = (value) => isString(value) && UUID_REGEX.test(value);
export const isMissing = (value) => value === null || value === void 0;
export const isGifImage = (file) => file && file.type === "image/gif";
export const isPngImage = (file) => file && file.type === "image/png";
export const isJpegImage = (file) => file && (file.type === "image/jpeg" || file.type === "image/pjpeg");
const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9-_]+$/;
export const isAlphanumeric = (value) => isString(value) && ALPHANUMERIC_REGEX.test(value);
const DOMAIN_REGEX = /(([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}/;
const isDomain = (value) => isString(value) && DOMAIN_REGEX.test(value);
const IPV4_REGEX = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const isIpv4 = (input) => isString(input) && IPV4_REGEX.test(input);
const RESERVED_IP_REGEX = RegExp(
  "(^0.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|(^10.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|(^127.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|(^100.6[4-9]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|(^100.7[0-9]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|(^100.8[0-9]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|(^100.9[0-9]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|(^100.1[0-1]{1}[0-1]{1}[0-9]{1}.[0-9]{1,3}.[0-9]{1,3}$)|(^100.12[0-7]{1}.[0-9]{1,3}.[0-9]{1,3}$)|(^169.254.[0-9]{1,3}.[0-9]{1,3}$)|(^172.1[6-9]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|(^172.2[0-9]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|(^172.3[0-1]{1}[0-9]{0,1}.[0-9]{1,3}.[0-9]{1,3}$)|(^192.0.0.[0-9]{1,3}$)|(^192.0.2.[0-9]{1,3}$)|(^192.88.99.[0-9]{1,3}$)|(^198.1[8-9]{1}.[0-9]{1,3}.[0-9]{1,3}$)|(^198.51.[0-9]{1,3}.[0-9]{1,3}$)|(^203.0.113.[0-9]{1,3}$)|(^22[4-9]{1}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|(^23[0-9]{1}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|(^24[0-9]{1}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|(^25[0-5]{1}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}$)|(^192.168.[0-9]{1,3}.[0-9]{1,3}$)"
);
export const isReservedIp = (input) => {
  if (!isString(input))
    return false;
  if (input.toLowerCase() === "localhost")
    return true;
  return RESERVED_IP_REGEX.test(input);
};
export const changeSomeStep = (listSteps, newStateStep) => listSteps.map((beforeStep) => {
  if (beforeStep.id === newStateStep.id) {
    return newStateStep;
  }
  return beforeStep;
});
export const isServerValid = (server) => {
  if (isReservedIp(server))
    return false;
  return isDomain(server) || isIpv4(server);
};
export const clamp = (value, min, max) => Math.min(Math.max(value, min), max);
