import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { BooleanField } from '../../common/dataPoints/configItem/viewOptions/boolean/BooleanField';
import analyzerMessages from '../../../messages/analyzer.messages';

// Generic type to allow more flexible usage
type BaseItem = {
  id: string;
  name?: string;
  datatronActive: boolean;
  archived: boolean;
  datatronName: string;
  organizationName: string;
  type?: string;
};

interface SessionItem extends BaseItem {
  type?: 'Session';
}

interface AnomalyItem extends BaseItem {
  type?: 'Anomaly';
  anomalyFrom?: string;
}

type AnalyzerItem = SessionItem | AnomalyItem;

type AnalyzerItemProps<T extends AnalyzerItem> = {
  item: T;
  className?: string;
};

export function AnalyzerDatatronItem<T extends AnalyzerItem>({
  item,
  className,
}: AnalyzerItemProps<T>) {
  const { formatMessage } = useIntl();
  // Dynamic class names
  const itemClassName = classNames('analyzer--item', 'analyzer--item-datatron', {
    active: item.datatronActive,
    alert: 'anomalyFrom' in item,
    [className || '']: !!className,
  });

  // Dynamic title rendering
  const renderTitle = () => {
    const archiveIcon = item.archived ? <i className='fa fa-archive' /> : null;

    if ('anomalyFrom' in item) {
      return (
        <>
          {archiveIcon}
          {formatMessage(analyzerMessages.anomalyStart)}: {item.anomalyFrom}
        </>
      );
    }

    return (
      <>
        {archiveIcon}
        {item.name}
      </>
    );
  };

  return (
    <div className={itemClassName}>
      <div className='analyzer--item--content'>
        <div className='analyzer--item--header'>
          <div className='analyzer--item--title'>{renderTitle()}</div>
          <div className='analyzer--item--type'>
            {item.type ||
              ('anomalyFrom' in item
                ? formatMessage(analyzerMessages.anomaly)
                : formatMessage(analyzerMessages.session))}
          </div>
        </div>
        <div className='analyzer--item--status'>
          <BooleanField value={item.datatronActive} />
          <div className='analyzer--item--datatron-name'>{item.datatronName}</div>
        </div>
        <div className='analyzer--item--organization'>
          <div className='analyzer--item--organization-name'>{item.organizationName}</div>
        </div>
      </div>
    </div>
  );
}

// Specific component wrappers for type safety
export const AnalyzerSessionDatatron: React.FC<{ session: SessionItem }> = ({ session }) => (
  <AnalyzerDatatronItem item={session} />
);

export const AnalyzerAnomalyDatatron: React.FC<{ anomaly: AnomalyItem }> = ({ anomaly }) => (
  <AnalyzerDatatronItem item={anomaly} className='alert' />
);
