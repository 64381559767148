import React, { useEffect } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { PrintPanelList } from './PrintSessionPage/PrintPanelList';
import {
  getSession,
  sessionsError,
  sessionsLoading,
} from '../../redux/selectors/analyzer.selector';
import { onAnalyzerSessionEditorEnter } from '../index/routeHandlers';
import { ErrorPlaceholder } from '../inventory/list/ErrorPlaceholder';
import { Loader } from '../_tools';
import { getAnalyzerSessionEditLink } from '../index/routes';
import { decodeId } from '../../redux/util';
import commonMessages from '../../messages/common.messages';

interface AnalyzerProps extends RouteConfigComponentProps {}

const useSessionId = (match: any): number => {
  const { sessionId } = match.params as { sessionId: string };
  return decodeId(sessionId);
};

const useSessionCode = (match: any): string => {
  const { sessionId } = match.params as { sessionId: string };
  return sessionId;
};

export const AnalyzerSessionPrintPage: React.FC<AnalyzerProps> = ({ match }) => {
  const sessionId = useSessionId(match);
  const sessionCode = useSessionCode(match);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (sessionId && match.isExact) {
      onAnalyzerSessionEditorEnter(sessionId);
    }
  }, [sessionId, match.isExact]);

  const isLoading = useSelector((state) => sessionsLoading(state, sessionId));
  const error = useSelector((state) => sessionsError(state, sessionId));
  const session = useSelector((state) => getSession(state, sessionId));
  const backLink = getAnalyzerSessionEditLink(sessionCode);

  return (
    <>
      <Loader show={isLoading} />
      {error && <ErrorPlaceholder />}
      {!error && session && (
        <>
          <div className='print-header-container'>
            <div className='print-header-left'>
              <div className='back--wrap'>
                <ul className='menu tab-menu'>
                  <li className='back-link'>
                    {/* @ts-ignore */}
                    <Link to={backLink}>
                      <span className='icon-arrow-left' />
                      {formatMessage(commonMessages.back)}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='print-header-right'>
              <button className='print-button' onClick={() => window.print()}>
                <i className='fa fa-print'></i>
                {formatMessage(commonMessages.print)}
              </button>
            </div>
          </div>
          <div className='analyzer-container-wrap'>
            <PrintPanelList sessionId={sessionId} />
          </div>
        </>
      )}
    </>
  );
};
