"use strict";
export const timeFormat = "HH:mm";
export const DATE_TIME_RFC2445_FORMAT = "YYYYMMDDTHHmmss";
export const SORT_WAY_ASC = "ASC";
export const SORT_WAY_DESC = "DESC";
export const DATE_FROM = "DATE_FROM";
export const DATE_TO = "DATE_TO";
export const MAX_PER_PAGE = 50;
export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_SORT_BY = "createdAt";
export const DEFAULT_SORT_ORDER = SORT_WAY_DESC;
export const DEBOUNCE_TIMEOUT = 300;
export const DEFAULT_APP = "cycleAnalysis";
export const APP_VERSION = "v1.0.0";
