"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { AppReducerMapBuilder } from "./util";
import { createAction } from "@reduxjs/toolkit";
import { getAnalyzer } from "../../core/api/analyzer";
import { call, put, takeLatest } from "redux-saga/effects";
import { LOADING, succeeded, failed, IDLE } from "../../schema/fetched";
export const fetchAnalyzer = createAction("fetch analyzer");
export const fetchAnalyzerSuccess = createAction("fetch analyzer - success", (analyzer) => ({
  payload: analyzer
}));
export const fetchAnalyzerFailure = createAction("fetch analyzer - fail", (error) => ({
  payload: error
}));
export const resetAnalyzer = createAction("reset analyzer");
export const reducer = AppReducerMapBuilder.new().add(fetchAnalyzer, (state) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    main: LOADING
  })
})).add(fetchAnalyzerSuccess, (state, analyzer) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    main: succeeded(analyzer)
  })
})).add(fetchAnalyzerFailure, (state, error) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    main: failed(error)
  })
})).add(resetAnalyzer, (state) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    main: IDLE
  })
})).build();
function* fetchAnalyzerSaga() {
  try {
    const response = yield call(getAnalyzer);
    if (response.type === "generalError") {
      yield put(fetchAnalyzerFailure(response.error));
      return;
    }
    if (response.type === "parseError") {
      yield put(fetchAnalyzerFailure(response.error.message));
      return;
    }
    if (response.status == 200 && response.data) {
      yield put(fetchAnalyzerSuccess(response.data));
    }
  } catch (Err) {
    yield put(fetchAnalyzerFailure(Err));
  }
}
export function* watchFetchAnalyzer() {
  yield takeLatest(fetchAnalyzer, fetchAnalyzerSaga);
}
