"use strict";
import {
  filter,
  find,
  keys,
  isEmpty
} from "lodash";
export const calendarMode = (state) => state.calendar.mode;
export const getCalendarsByType = (state, type) => filter(state.machineCalendars.list, { type });
export const getCalendarById = (state, calendarId) => find(state.machineCalendars.list, { id: calendarId });
export const getFromDate = (calendar) => calendar.events.fromDate;
export const getToDate = (calendar) => calendar.events.toDate;
export const getCalendarId = (calendar) => calendar.id;
export const getCalendarName = (calendar) => calendar.name;
export const areCalendarsLoaded = (state) => state.machineCalendars.loaded;
export const isCalendarAvailable = (state) => state.machineCalendars.available;
export const isCalendarAccess = (state) => state.machineCalendars.access;
export const enabledCreateEvent = (state) => state.machineCalendars.enabledCreateEvent;
export const getCalendarsQueries = (state) => state.machineCalendars.filters;
export const getCalendarsQueryByType = (state, type) => state.machineCalendars.filters[type];
export const getFilteredCalendarsList = (state, type) => {
  const calendars = getCalendarsByType(state, type);
  const filters = getCalendarsQueries(state);
  if (keys(filters).every((calendarType) => isEmpty(filters[calendarType]))) {
    return calendars;
  }
  return calendars.filter((calendar) => {
    const calendarName = calendar.name.toLowerCase();
    return isEmpty(filters[calendar.type]) || calendarName.includes(filters[calendar.type].toLowerCase());
  });
};
export const isCalendarsEmpty = (state) => isEmpty(state.machineCalendars.list);
export const getCalendar = (state) => state.machineCalendars.selected.item;
export const isCalendarLoaded = (state) => state.machineCalendars.selected.loaded;
