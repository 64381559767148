"use strict";
import {
  DEFAULT_LIMIT,
  DEFAULT_PAGE,
  DEFAULT_SORT_BY,
  DEFAULT_SORT_ORDER
} from "../../../../common/constants";
export const aiAppsInitialState = {
  list: [],
  pagination: {
    total: 0,
    page: DEFAULT_PAGE,
    limit: DEFAULT_LIMIT,
    totalPages: 1
  },
  loading: false,
  loaded: false,
  error: null,
  filters: {
    queryString: ""
  },
  sort: {
    sortBy: DEFAULT_SORT_BY,
    sortOrder: DEFAULT_SORT_ORDER
  },
  details: null,
  cycleDetectionDetails: null
};
export const aiAppsTestState = {
  list: [
    {
      id: "123",
      appName: "name 123",
      description: "description 123",
      app: "",
      appVersion: "",
      status: "",
      datatron: {
        id: 0,
        code: "fsadfas",
        name: "data-tron-id"
      },
      devices: [],
      createdAt: "",
      updatedAt: ""
    }
  ],
  pagination: {
    total: 1,
    page: 1,
    limit: 10,
    totalPages: 1
  },
  loading: false,
  loaded: true,
  error: null,
  filters: {
    queryString: "hello"
  },
  sort: {
    sortBy: DEFAULT_SORT_BY,
    sortOrder: DEFAULT_SORT_ORDER
  },
  details: null,
  cycleDetectionDetails: null
};
