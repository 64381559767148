"use strict";
export const getAiApps = (state) => state.aiApps.list;
export const getAiAppsPagination = (state) => state.aiApps.pagination;
export const getPage = (state) => getAiAppsPagination(state).page;
export const isLoading = (state) => state.aiApps.loading;
export const isLoaded = (state) => state.aiApps.loaded;
export const getFilters = (state) => state.aiApps.filters;
export const getSort = (state) => state.aiApps.sort;
export const getQueryFilterValue = (state) => getFilters(state).queryString;
export const getAiAppsDetails = (state) => state.aiApps.details;
export const getCycleDetectionDetails = (state) => state.aiApps.cycleDetectionDetails;
export const getAiAppsDetailsId = (state) => {
  var _a;
  return (_a = state.aiApps.details) == null ? void 0 : _a.id;
};
export const getCycleTimeDetectionCandidates = (state) => {
  var _a;
  return (_a = state.aiApps.cycleDetectionDetails) == null ? void 0 : _a.candidates;
};
export const getSelectedDevices = (state) => {
  var _a;
  return (_a = state.aiApps.details) == null ? void 0 : _a.devices;
};
export const getSelectedDatatron = (state) => {
  var _a;
  return (_a = state.aiApps.details) == null ? void 0 : _a.datatron;
};
export const filtersApplied = (state) => {
  const filters = getFilters(state);
  return filters.queryString.length > 0;
};
