"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { authorized, token } from "../../auth/proxy";
import { exportedFileTypes } from "../../../../common/helpers/fileFormats.helper";
import { ZIP_MIME_TYPE } from "../../../../common/constants/fileFormats";
import { DATA_POINTS_EXCEL_MIME_TYPE } from "../../../../common/constants/datapoints.excel";
export const IS_API = "@@API";
export const HEADERS = {
  JSON: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
};
export function isApiCallResult(result) {
  return !!(result && result[IS_API]);
}
export const apiRequestWithSchema = (_0, _1, ..._2) => __async(void 0, [_0, _1, ..._2], function* (url, dataSchema, options = {}) {
  const validSession = yield authorized().then(() => true).catch(() => false);
  if (!validSession) {
    return {
      success: false,
      type: "generalError",
      status: 401,
      error: "session expired"
    };
  }
  const authToken = token();
  const passedHeaders = options ? options.headers : {};
  const optionsNoCache = __spreadProps(__spreadValues({}, options), {
    headers: __spreadValues(__spreadProps(__spreadValues({}, passedHeaders), {
      "Cache-Control": "no-cache",
      Pragma: "no-cache"
    }), authToken && { "x-auth-token": `Bearer ${authToken}` })
  });
  const response = yield fetch(url, optionsNoCache);
  return response.json().then((data) => {
    const safeParsed = dataSchema.safeParse(data);
    if (safeParsed.success) {
      return {
        success: true,
        type: "parsed",
        status: response.status,
        data: safeParsed.data
      };
    }
    console.error("API response did not match schema", safeParsed.error);
    return {
      success: false,
      type: "parseError",
      status: response.status,
      error: safeParsed.error
    };
  }).catch((error) => ({
    success: false,
    type: "generalError",
    status: response.status,
    error: `could not parse response body as json: ${error.message}`
  }));
});
export const apiRequest = (url, options = {}) => {
  const passedHeaders = options ? options.headers : {};
  const optionsNoCache = __spreadProps(__spreadValues({}, options), {
    headers: __spreadProps(__spreadValues({}, passedHeaders), {
      "Cache-Control": "no-cache",
      Pragma: "no-cache"
    })
  });
  return authorized().then(() => {
    const authToken = token();
    optionsNoCache.headers = __spreadValues(__spreadValues({}, optionsNoCache.headers), authToken && { "x-auth-token": `Bearer ${authToken}` });
    return fetch(url, Object.assign({}, optionsNoCache, { credentials: "include" }));
  }).then((response) => {
    const contentType = response.headers.get("content-type");
    if (contentType && (contentType.includes("image") || contentType.includes(DATA_POINTS_EXCEL_MIME_TYPE) || contentType.includes(ZIP_MIME_TYPE))) {
      return response;
    }
    if (contentType && exportedFileTypes.some((type) => contentType.includes(type))) {
      return response;
    }
    if (response.ok) {
      return response.json().then((data) => ({ response: data, status: response.status })).catch(() => ({ response: {}, status: response.status }));
    }
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return response.json().then((data) => ({
        [IS_API]: true,
        error: data,
        status: response.status
      })).catch(() => ({
        response: {},
        status: response.status
      }));
    }
    return response.text().then((text) => ({
      [IS_API]: true,
      error: text,
      status: response.status
    }));
  });
};
export function putApiRequest(url, body, options = { headers: {} }) {
  return apiRequest(url, __spreadProps(__spreadValues({}, options), {
    method: "PUT",
    headers: __spreadValues(__spreadValues({}, options.headers), HEADERS.JSON),
    body: JSON.stringify(body)
  }));
}
export function putFormDataRequest(url, data) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return apiRequest(url, {
    method: "PUT",
    body: formData
  });
}
export function patchApiRequest(url, body = {}, options = { headers: {} }) {
  return apiRequest(url, __spreadProps(__spreadValues({}, options), {
    method: "PATCH",
    headers: __spreadValues(__spreadValues({}, options.headers), HEADERS.JSON),
    body: JSON.stringify(body)
  }));
}
export function postApiRequest(url, body = {}, options = { headers: {} }) {
  return apiRequest(url, __spreadProps(__spreadValues({}, options), {
    method: "POST",
    headers: __spreadValues(__spreadValues({}, options.headers), HEADERS.JSON),
    body: JSON.stringify(body)
  }));
}
export function postFormDataRequest(url, data) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return apiRequest(url, {
    method: "POST",
    body: formData
  });
}
export function postUploadRequest(url, files) {
  const data = new FormData();
  data.append("files", files);
  return apiRequest(url, {
    method: "POST",
    body: data
  });
}
export function deleteApiRequest(url, options = { headers: {} }) {
  return apiRequest(url, __spreadProps(__spreadValues({}, options), {
    method: "DELETE",
    headers: options.headers
  }));
}
