"use strict";
export const REQUIRED = "errors.field.required";
export const INVALID = "errors.field.invalid";
export const DUPLICATE = "errors.field.duplicate";
export const INVALID_ID = "errors.invalid_id";
export const INVALID_IP = "errors.field.invalid_ip";
export const INVALID_UUID = "errors.field.must_be_uuid";
export const SUBSCRIPTION_NOT_FOUND = "errors.machine.data_point_subscription.not_found";
export const ERROR_FROM_DATE_MUST_BE_BEFORE_TO_DATE = "reports.error.dates.FROM_must_be_earlier_than_TO";
export const ERROR_FROM_OR_TO_DATE_MUST_BE_BEFORE_NOW = "reports.error.dates.FROM_or_TO_dates_must_be_earlier_than_NOW";
export const ERROR_DATE_INVALID = "reports.error.date.invalid";
