"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { createAction } from "redux-act";
import { put, call, takeEvery, select } from "redux-saga/effects";
import { getAIAppAnomalyFormFields } from "../selectors/aiApp.form.selector";
import { startSubmit, stopSubmit, touch } from "redux-form";
import * as api from "../../core/api";
import * as form from "../constants/aiApp.anomalyModule.form.constants";
import { closeModal } from "./modals.module";
import { ANOMALY_MODEL_ADD } from "../constants/modals.constants";
import { fetchCycleDetectionDetails } from "./aiApps.cycleDetection.details.fetch.module";
import { sendNotification } from "./notifications.module";
import notificationMessage from "../../messages/notification.message";
import { NOTIFICATION_EVENT } from "../../core/notifications";
export const createAIAppAnomaly = createAction("create AIAppAnomaly");
export const createAIAppAnomalySuccess = createAction(
  "create AIAppAnomaly - success",
  (item) => item
);
export const createAIAppAnomalyFail = createAction("create AIAppAnomaly - fail", (error) => error);
export function* touchAllFieldsSaga() {
  yield put(touch(form.AI_APP_ANOMALY_FORM_NAME, ...form.AI_APP_ANOMALY_PROPS));
}
export function* handleErrors(errors) {
  yield put(createAIAppAnomalyFail(errors));
  yield put(stopSubmit(form.AI_APP_ANOMALY_FORM_NAME, errors));
}
export function* createAiAppAnomalySaga() {
  const state = yield select();
  yield call(touchAllFieldsSaga);
  const fields = yield call(getAIAppAnomalyFormFields, state);
  yield put(startSubmit(form.AI_APP_ANOMALY_FORM_NAME));
  const _a = fields, { cycle_detection_id: cycleDetectionId } = _a, otherFields = __objRest(_a, ["cycle_detection_id"]);
  const { response, error } = yield call(
    api.aiApps.createAIAppAnomaly,
    cycleDetectionId,
    otherFields
  );
  if (response) {
    yield put(createAIAppAnomalySuccess(response));
    yield put(fetchCycleDetectionDetails(cycleDetectionId));
    yield put(stopSubmit(form.AI_APP_ANOMALY_FORM_NAME));
    yield put(closeModal(ANOMALY_MODEL_ADD));
    yield put(
      sendNotification(
        notificationMessage.item_created_title,
        __spreadProps(__spreadValues({}, notificationMessage.item_created_description), {
          values: {
            itemName: "Anomaly Model"
          }
        }),
        NOTIFICATION_EVENT
      )
    );
  } else {
    yield handleErrors(error);
  }
}
export function* watchCreateAIAppAnomaly() {
  yield takeEvery(createAIAppAnomaly.getType(), createAiAppAnomalySaga);
}
