import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorModalContent } from '../../../error/ErrorModalContent';
import Modal from '../../../_tools/modal/Modal';
import { MAINTENANCE_ERROR } from '../../../../redux/constants/modals.constants';
import { closeModal } from '../../../../redux/modules/modals.module';
import { isModalOpen } from '../../../../redux/selectors/modals.selector';
import { selector } from '../../../../redux/selectors/util';
import commonMessages from '../../../../messages/common.messages';
import { onMaintenanceEnter } from '../../../index/routeHandlers';

const modalOpenSelector = selector((state) => isModalOpen(state, MAINTENANCE_ERROR));

interface Props {
  errorMessage: string;
}

export const ErrorModal: React.FC<Props> = ({ errorMessage }) => {
  const isOpen = useSelector(modalOpenSelector);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(closeModal(MAINTENANCE_ERROR));
    onMaintenanceEnter();
  };
  const { formatMessage } = useIntl();

  return (
    <Modal isOpen={isOpen} onClose={onClose} contentLabel=''>
      <div className='modal--header' onClick={onClose}>
        <span className='modal--close'>&times;</span>
      </div>
      <div className='modal--content'>
        <ErrorModalContent errorMessage={errorMessage} />
        <div className='line' />
        <div className='text-center'>
          <button type='button' className='button__cancel' onClick={onClose}>
            {formatMessage(commonMessages.close)}
          </button>
        </div>
      </div>
    </Modal>
  );
};
