import React from 'react';
import { CycleDetectionInfo } from '../components/CycleDetectionInfo';
import { CommentCell } from './CommentCell';
import Table from '../../common/table/table';
import { FormattedMessage } from 'react-intl';
import { CREATED_STATUS } from '../../../redux/constants/aiApp.cycleDetection.form.constants';
import { TRAINING_STATUS } from '../../../redux/constants/aiApp.anomalyModule.form.constants';
import Counter from '../../common/counter/Counter';

interface AccordionItemProps {
  row: any;
  expandedRowId: string | null;
  cycleDetectionDetails: any;
  onTrigger: (id: string) => void;
  onAccordionClick: (row: any) => void;
}

export const AccordionItem: React.FC<AccordionItemProps> = React.memo(
  ({ row, expandedRowId, cycleDetectionDetails, onTrigger, onAccordionClick }) => {
    const isExpanded = expandedRowId === row.id;

    return (
      <div className='accordion-item'>
        <div className='accordion-header' onClick={() => onAccordionClick(row)}>
          <CycleDetectionInfo details={row} expandedRowId={expandedRowId} />
        </div>
        {isExpanded && (
          <div className={`accordion-content ${isExpanded ? 'active' : ''}`}>
            {cycleDetectionDetails && (
              <div className='accordion-details'>
                {row[TRAINING_STATUS] === CREATED_STATUS && (
                  <button
                    title='Run'
                    className='button button__xs light-grey-white'
                    onClick={() => onTrigger(row.id)}
                  >
                    <i className='fa fa-terminal' />
                    <FormattedMessage tagName='b' id='common.run' />
                  </button>
                )}
                <CommentCell trainingMsg={row.training_message} />

                <div className='table--title'>
                  <FormattedMessage tagName='b' id='aiApp.config.candidates' />
                  <Counter value={cycleDetectionDetails.candidates.length} />
                </div>
                <div className='table-responsive'>
                  <Table
                    headers={
                      cycleDetectionDetails.candidates.length > 0
                        ? Object.keys(cycleDetectionDetails.candidates[0])
                        : []
                    }
                    rows={cycleDetectionDetails.candidates}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
);
