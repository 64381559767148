import React from 'react';
import PropTypes from 'prop-types';
import { getBatchDetailedLink } from '../../index/routes';

const Batch = ({ batch, pushPath }) => {
  const onBatchRowClick = () => {
    pushPath(getBatchDetailedLink(batch.id));
  };

  return (
    <tr onClick={onBatchRowClick}>
      <td>
        <strong>{batch.name}</strong>
      </td>
      <td>{batch.averageDurationNoOutliers}s</td>
      <td>{batch.pieces}</td>
    </tr>
  );
};

Batch.propTypes = {
  batch: PropTypes.object.isRequired,
  pushPath: PropTypes.func.isRequired,
};

export default Batch;
