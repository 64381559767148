"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { getLocationsSchema } from "../../schema/locations";
import { apiRequest, apiRequestWithSchema } from "./_tools";
export function search(query) {
  return apiRequest(`/api/locations?q=${query}`);
}
export const findByName = (name) => search(name);
export function getById(id) {
  return apiRequest(`/api/locations/${id}`);
}
export function getByType(type) {
  return apiRequest(`/api/locations/type/${type}`);
}
export const getChildLocations = (parentId, params) => {
  const query = new URLSearchParams(__spreadProps(__spreadValues({}, params), {
    parent: parentId || "",
    force_parent: "true"
  })).toString();
  return apiRequestWithSchema(`/api/locations?${query}`, getLocationsSchema);
};
export function getLocationParentTree(id) {
  return apiRequest(`/api/locations/${id}/path`);
}
