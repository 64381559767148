"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
export class AppReducerMapBuilder {
  constructor(appReducerMap) {
    __publicField(this, "appReducerMap");
    this.appReducerMap = appReducerMap;
  }
  static new() {
    return new AppReducerMapBuilder({});
  }
  add(actionCreator, reducer) {
    return new AppReducerMapBuilder(__spreadProps(__spreadValues({}, this.appReducerMap), {
      [actionCreator.toString()]: (state, action) => reducer(state, action)
    }));
  }
  build() {
    return this.appReducerMap;
  }
}
