"use strict";
import { connect } from "react-redux";
import { selectAppConfig } from "../../../redux/selectors/appConfig.selector";
import { getRoles } from "../../../redux/selectors/profile.selector";
import PermissionedComponent from "./PermissionedComponent";
export const mapStateToProps = (state) => ({
  roles: getRoles(state),
  config: selectAppConfig(state)
});
const PermissionedComponentContainer = (types) => (WrappedComponent) => connect(mapStateToProps)(PermissionedComponent(types)(WrappedComponent));
export default PermissionedComponentContainer;
