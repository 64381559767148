"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useSelector } from "react-redux";
import * as ExcelJS from "exceljs";
import { getSignalsByPanelId } from "../../../redux/selectors/analyzer.selector";
import { dateToUnixNano, unixNanoToDate, formatLocalDate } from "../../helper/time";
import { getTimeRange } from "../signalChart/Helper";
import { getSignalData } from "../../../core/api/analyzer";
import { MIN_TIME_RANGE_OFFSET_MS } from "../../helper/signalData";
import { EXCEL_MIME_TYPE } from "../../../../../common/helpers/fileFormats.helper";
const panelSignalToRows = (panelSignal, fromUnixNano, toUnixNano, signalName, uuid) => {
  if (panelSignal === null)
    return [];
  const rows = panelSignal.data.data.map(
    (d) => ({
      systime: d.systime,
      isDelta: d.isDelta,
      name: signalName,
      uuid,
      value: d.value
    })
  );
  const leftOutOfBounds = rows.reduce(
    (left, row) => {
      if (row.systime <= fromUnixNano) {
        if (left === null)
          return row;
        if (left.systime < row.systime)
          return row;
      }
      return left;
    },
    null
  );
  const rowsInRange = rows.filter(
    (row) => row.systime >= fromUnixNano && row.systime <= toUnixNano
  );
  rowsInRange.sort((a, b) => a.systime - b.systime);
  if (rowsInRange.length === 0)
    return rowsInRange;
  if (rowsInRange[0].systime !== fromUnixNano && leftOutOfBounds !== null) {
    rowsInRange.unshift({
      systime: fromUnixNano,
      isDelta: false,
      name: signalName,
      uuid,
      value: leftOutOfBounds.value
    });
  }
  if (rowsInRange[rowsInRange.length - 1].systime !== toUnixNano) {
    rowsInRange.push({
      systime: toUnixNano,
      isDelta: false,
      name: signalName,
      uuid,
      value: rowsInRange[rowsInRange.length - 1].value
    });
  }
  return rowsInRange;
};
const formatRow = (row) => {
  const zdt = unixNanoToDate(row.systime);
  const isoTime = zdt.toISOString();
  const localTime = formatLocalDate(zdt);
  return [isoTime, localTime, row.isDelta, row.uuid, row.name, row.value];
};
export const usePanelExcelExport = (panel) => {
  const panelId = panel.id;
  const panelSignals = useSelector((state) => getSignalsByPanelId(state, panelId));
  const exportToExcel = () => __async(void 0, null, function* () {
    const timeRange = getTimeRange(panel.rangeFrom, panel.rangeDeltaMs);
    if (!panel || !panelSignals || !timeRange)
      return;
    const timeRangeFrom = timeRange.fromDate.getTime();
    const timeRangeTo = timeRange.toDate.getTime();
    const fromUnixNano = dateToUnixNano(timeRange.fromDate);
    const toUnixNano = dateToUnixNano(timeRange.toDate);
    const allRows = yield Promise.all(
      panelSignals.flatMap((panelSignal) => __async(void 0, null, function* () {
        const signalData = yield getSignalData(
          panelSignal.signal.uuid,
          timeRangeFrom - MIN_TIME_RANGE_OFFSET_MS,
          timeRangeTo + MIN_TIME_RANGE_OFFSET_MS
        );
        return panelSignalToRows(
          signalData,
          fromUnixNano,
          toUnixNano,
          panelSignal.signal.name,
          panelSignal.signal.uuid
        );
      }))
    );
    const flattenedRows = allRows.flat();
    flattenedRows.sort((a, b) => a.systime - b.systime);
    const formattedRows = flattenedRows.map(formatRow);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Signal Data");
    worksheet.columns = [
      { header: "ISO Time", key: "systime", width: 28 },
      { header: "Local Time", key: "localtime", width: 18 },
      { header: "Is Delta", key: "isDelta", width: 8 },
      { header: "UUID", key: "uuid", width: 36 },
      { header: "Signal Name", key: "name", width: 100 },
      { header: "Value", key: "value", width: 40 }
    ];
    worksheet.addRows(formattedRows);
    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFE0E0E0" }
    };
    const fileName = `${panel.name.toLowerCase().replace(/ /g, "_")}.xlsx`;
    try {
      const buffer = yield workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: EXCEL_MIME_TYPE });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    } catch (error) {
      console.error("Error exporting Excel file:", error);
    }
  });
  return exportToExcel;
};
export const downSampleWithPrecision = (signalData, maxPoints = 1e3, valueKey = "value") => {
  const _a = signalData, { data } = _a, otherProps = __objRest(_a, ["data"]);
  if (data.length <= maxPoints)
    return signalData;
  const downSampledData = [];
  const bucketSize = (data.length - 2) / (maxPoints - 2);
  let a = 0;
  downSampledData.push(data[a]);
  for (let i = 0; i < maxPoints - 2; i++) {
    const rangeStart = Math.floor((i + 1) * bucketSize) + 1;
    const rangeEnd = Math.min(Math.floor((i + 2) * bucketSize) + 1, data.length);
    const range = data.slice(rangeStart, rangeEnd);
    const avgX = range.reduce((sum, point) => sum + point.systime, 0) / range.length;
    const avgY = range.reduce((sum, point) => sum + point[valueKey], 0) / range.length;
    let maxArea = -1;
    let nextPointIndex = -1;
    for (let j = rangeStart; j < rangeEnd; j++) {
      const area = Math.abs(
        (data[a].systime - avgX) * (data[j][valueKey] - data[a][valueKey]) - (data[a].systime - data[j].systime) * (avgY - data[a][valueKey])
      ) / 2;
      if (area > maxArea) {
        maxArea = area;
        nextPointIndex = j;
      }
    }
    downSampledData.push(data[nextPointIndex]);
    a = nextPointIndex;
  }
  downSampledData.push(data[data.length - 1]);
  return __spreadProps(__spreadValues({}, otherProps), {
    data: downSampledData
  });
};
