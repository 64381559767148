"use strict";
import { z } from "zod";
import { errorSchema } from "./authorized";
import { idSchema, jsonSchema } from "./general";
const performancePeriod = z.enum(["hour", "day", "week", "month", "yea"]);
const oeeSource = z.enum(["CYCLE_TIME", "PERFORMANCE"]);
const nullableString = z.string().nullable();
const machineSchema = z.object({
  id: idSchema,
  name: z.string().min(1),
  image: nullableString,
  make: nullableString,
  model: nullableString,
  year: z.number().min(1).int().nullable(),
  serialNumber: nullableString,
  isIgnored: z.boolean(),
  performancePeriod: performancePeriod.nullable(),
  performanceAmountWanted: z.number().int().nullable(),
  performanceAmountMax: z.number().int().nullable(),
  isAvailable: z.boolean(),
  isMaintenancePlanned: z.boolean(),
  type: nullableString,
  notes: nullableString,
  cycleSecondsPerPart: z.number().nullable(),
  oeeSource: oeeSource.nullable(),
  oeeAvailabilityDataPointId: z.number().int().nullable(),
  oeePerformanceDataPointId: z.number().int().nullable(),
  oeeQualityDataPointId: z.number().int().nullable(),
  oeeThresholdGreen: z.number(),
  oeeThresholdYellow: z.number(),
  oeeThresholdRed: z.number(),
  rfidDataPointId: z.number().int().nullable(),
  analyticsCycleStartDataPointId: z.number().int().nullable(),
  analyticsCycleEndDataPointId: z.number().int().nullable(),
  statusError: z.boolean(),
  statusRunning: z.boolean(),
  oeePerformanceDataPointUsesDmc: z.boolean(),
  dmcConfig: jsonSchema
  // TODO: is this more refinable?
});
export const getMachinesSchema = z.union([errorSchema, z.array(machineSchema)]);
