"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "redux-act";
import { put, call, takeLatest, delay, select, take } from "redux-saga/effects";
import * as aiAppsApi from "../../core/api/aiApps";
import { getCycleDetectionDetails } from "../selectors/aiApps.selector";
import { ANOMALY_RUNNING_PULLING_FREQUENCY_IN_MS } from "../../../../common/constants/aiApp";
import { RUNNING_STATUS } from "../constants/aiApp.cycleDetection.form.constants";
export const fetchCycleDetectionDetails = createAction(
  "fetch cycleDetection details",
  (cycleDetection) => cycleDetection
);
export const fetchCycleDetectionDetailsSuccess = createAction(
  "fetch cycleDetection details - success",
  (cycleDetection) => cycleDetection
);
export const fetchCycleDetectionDetailsFail = createAction(
  "fetch cycleDetection details - failed",
  (error) => error
);
export const resetCycleDetectionDetails = createAction("reset cycleDetection details");
export const reducer = {
  [fetchCycleDetectionDetails.getType()]: (state) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      loaded: false,
      loading: true,
      error: null
    })
  }),
  [fetchCycleDetectionDetailsSuccess.getType()]: (state, cycleDetection) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      cycleDetectionDetails: cycleDetection,
      loaded: true,
      loading: false,
      error: null
    })
  }),
  [fetchCycleDetectionDetailsFail.getType()]: (state, error) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      loaded: false,
      loading: false,
      error
    })
  }),
  [resetCycleDetectionDetails.getType()]: (state) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      cycleDetectionDetails: null
    })
  })
};
export function* fetchCycleDetectionDetailsSaga({ payload: cycleDetection }) {
  const { response, error } = yield call(aiAppsApi.fetchCycleDetection, cycleDetection);
  if (response) {
    yield put(fetchCycleDetectionDetailsSuccess(response));
  } else {
    yield put(fetchCycleDetectionDetailsFail(error));
  }
}
export function* pollCycleDetectionDetailsSaga(cycleDetectionId) {
  while (true) {
    yield delay(ANOMALY_RUNNING_PULLING_FREQUENCY_IN_MS);
    const { response, error } = yield call(aiAppsApi.fetchCycleDetection, cycleDetectionId);
    if (response) {
      yield put(fetchCycleDetectionDetailsSuccess(response));
      const cycleDetectionDetails = yield select(getCycleDetectionDetails);
      const hasRunning = cycleDetectionDetails == null ? void 0 : cycleDetectionDetails.anomaly_detections.some(
        (item) => item.training_status === RUNNING_STATUS
      );
      if (!hasRunning) {
        break;
      }
    } else {
      yield put(fetchCycleDetectionDetailsFail(error));
      break;
    }
  }
}
export function* watchFetchCycleDetectionDetailsSuccess() {
  while (true) {
    const { payload: cycleDetection } = yield take(fetchCycleDetectionDetailsSuccess.getType());
    const hasRunning = cycleDetection.anomaly_detections.some(
      (item) => item.training_status === RUNNING_STATUS
    );
    if (hasRunning) {
      yield call(pollCycleDetectionDetailsSaga, cycleDetection.id);
    }
  }
}
export function* watchFetchCycleDetectionDetails() {
  yield takeLatest(fetchCycleDetectionDetails, fetchCycleDetectionDetailsSaga);
}
