"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import {
  put,
  call,
  select,
  takeEvery
} from "redux-saga/effects";
import { last } from "lodash";
import * as api from "../../core/api";
import { getDatatron } from "../selectors/datatron.selector";
import { getDataPointMetric } from "../selectors/dataPoint.metric.selector";
export const fetchDataPointMetric = createAction(
  "fetch data point metric",
  (deviceId, dataPointId) => ({ payload: { deviceId, dataPointId } })
);
export const fetchDataPointMetricSuccess = createAction(
  "fetch data point metric - success",
  (dataPointId, list = []) => ({ payload: { dataPointId, list } })
);
export const fetchDataPointMetricFail = createAction(
  "fetch data point metric - fail",
  (dataPointId, error) => ({ payload: { dataPointId, error } })
);
export const reducer = {
  [fetchDataPointMetric.type]: (state, { dataPointId }) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      dataPointMetric: __spreadProps(__spreadValues({}, state.datatron.dataPointMetric), {
        loading: true,
        loaded: false,
        error: null,
        dataPointId
      })
    })
  }),
  [fetchDataPointMetricSuccess.type]: (state, { dataPointId, list }) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      dataPointMetric: __spreadProps(__spreadValues({}, state.datatron.dataPointMetric), {
        list: [
          ...state.datatron.dataPointMetric.list,
          ...list
        ],
        loading: false,
        loaded: true,
        error: null,
        moreAvailable: list.length > 0,
        dataPointId
      })
    })
  }),
  [fetchDataPointMetricFail.type]: (state, { dataPointId, error }) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      dataPointMetric: __spreadProps(__spreadValues({}, state.datatron.dataPointMetric), {
        list: [],
        loading: false,
        loaded: false,
        error,
        moreAvailable: false,
        dataPointId
      })
    })
  })
};
export function* fetchDataPointMetricSaga({ payload: { deviceId, dataPointId } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const list = yield call(getDataPointMetric, state, dataPointId);
  const lastEntry = last(list);
  const startTimestamp = lastEntry ? lastEntry[0] + 1 : 1;
  const { response, error } = yield call(api.datatrons.getDataPointMetric, {
    datatronId: datatron.id,
    deviceId,
    dataPointId,
    startTimestamp
  });
  if (response) {
    yield put(fetchDataPointMetricSuccess(dataPointId, response.metric));
  } else {
    yield put(fetchDataPointMetricFail(dataPointId, error));
  }
}
export function* watchFetchDataPointMetric() {
  yield takeEvery(fetchDataPointMetric, fetchDataPointMetricSaga);
}
