"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as datatronApi from "../../core/api";
import { getDataPointsOfDevice, getDatatron, getDeviceById } from "../selectors/datatron.selector";
import { compareById, replaceObjectInList } from "../../../../common/helpers/object";
export const updateBulkDataPoints = createAction(
  "update bulk data points",
  (deviceId, action, actionValue, dataPointCodes) => ({
    payload: { deviceId, action, actionValue, dataPointCodes }
  })
);
export const updateBulkDataPointsSuccess = createAction(
  "update bulk data points - success",
  (deviceId, updatedDataPoints) => ({ payload: { deviceId, updatedDataPoints } })
);
export const updateBulkDataPointsFail = createAction(
  "update bulk data points - fail",
  (deviceId, error) => ({ payload: { deviceId, error } })
);
export const resetUpdateBulkResponse = createAction(
  "reset update bulk response",
  (deviceId) => ({ payload: { deviceId } })
);
export const reducer = {
  [updateBulkDataPoints.type]: (state, action) => {
    const { deviceId } = action;
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      _update: __spreadProps(__spreadValues({}, device._update), {
        loading: true,
        error: null
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  },
  [resetUpdateBulkResponse.type]: (state, action) => {
    const { deviceId } = action;
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      _update: {
        isRenaming: false,
        loaded: false,
        loading: false,
        error: null
      }
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  },
  [updateBulkDataPointsSuccess.type]: (state, { deviceId, updatedDataPoints }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    if (device._update.loading === false)
      return state;
    const deviceDataPoints = getDataPointsOfDevice(device);
    const dataPointToArchive = [];
    const newDataPoints = deviceDataPoints.map((dataPoint) => {
      const updatedDataPoint = updatedDataPoints.completed.find((dp) => dp.id === dataPoint.id);
      if (!updatedDataPoint) {
        return dataPoint;
      }
      const { enabled, isMaxRate, archived } = updatedDataPoint;
      if (archived !== void 0) {
        dataPointToArchive.push(dataPoint);
        return null;
      }
      if (updatedDataPoint.id !== updatedDataPoint.newId) {
        dataPointToArchive.push(dataPoint);
      }
      const newDataPoint = __spreadProps(__spreadValues({}, dataPoint), {
        id: updatedDataPoint.newId,
        uuid: updatedDataPoint.newUuid,
        enabled: enabled !== void 0 ? enabled : dataPoint.enabled,
        config: __spreadProps(__spreadValues({}, dataPoint.config), {
          isMaxRate: isMaxRate !== void 0 ? isMaxRate : dataPoint.config.isMaxRate
        })
      });
      return newDataPoint;
    }).filter(Boolean);
    const newDevice = __spreadProps(__spreadValues({}, device), {
      _update: __spreadProps(__spreadValues({}, device._update), {
        loading: false,
        updateBulkResponse: updatedDataPoints,
        error: null
      }),
      dataPoints: __spreadProps(__spreadValues({}, device.dataPoints), {
        list: newDataPoints
      }),
      archivedDataPoints: __spreadProps(__spreadValues({}, device.archivedDataPoints), {
        list: [...device.archivedDataPoints.list, ...dataPointToArchive]
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          loading: false,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  },
  [updateBulkDataPointsFail.type]: (state, { deviceId, error }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    if (device._update.loading === false)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      _update: __spreadProps(__spreadValues({}, device._update), {
        loading: false,
        error
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  }
};
export function* updateBulkDataPointsSaga({
  payload: { deviceId, action, actionValue, dataPointCodes }
}) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const { response, error } = yield call(datatronApi.datatrons.updateBulkDataPoints, {
    datatronId: datatron.id,
    deviceId,
    payload: { action, actionValue, dataPointCodes }
  });
  if (response) {
    yield put(updateBulkDataPointsSuccess(deviceId, response));
  } else {
    yield put(updateBulkDataPointsFail(deviceId, error));
  }
}
export function* watchUpdateBulkDataPoints() {
  yield takeEvery(updateBulkDataPoints, updateBulkDataPointsSaga);
}
