"use strict";
export const pageInitialState = {
  loading: false,
  isSpinnerShown: false,
  error: ""
};
export const pageTestState = {
  loading: false,
  isSpinnerShown: false,
  error: ""
};
