"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { apiRequest } from "../core/api/_tools";
import Keycloak from "keycloak-js";
let keycloak;
function setKeycloakToken(accessToken, refreshToken) {
  if (accessToken) {
    window.localStorage.setItem("accessToken", accessToken);
  }
  if (refreshToken) {
    window.localStorage.setItem("refreshToken", refreshToken);
  }
}
export function unsetKeycloakToken() {
  window.localStorage.removeItem("accessToken");
  window.localStorage.removeItem("refreshToken");
}
export function initKeycloak(token, refreshToken) {
  return __async(this, null, function* () {
    var _a, _b, _c, _d;
    keycloak = Keycloak("/api/keycloak.json");
    try {
      const authenticated = yield keycloak.init({
        checkLoginIframe: false,
        onLoad: "login-required",
        token,
        refreshToken
        // scope: ["roles"] // No way to pass options to login in init TODO? do keycloak.login() after keycloak.init()?
      });
      console.log(authenticated ? "authenticated" : "not authenticated");
    } catch (e) {
      console.error("failed to initialize due to error: ", e);
      return false;
    }
    setKeycloakToken((_a = keycloak.token) != null ? _a : "", (_b = keycloak.refreshToken) != null ? _b : "");
    try {
      yield keycloak.updateToken(5);
      setKeycloakToken((_c = keycloak.token) != null ? _c : "", (_d = keycloak.refreshToken) != null ? _d : "");
    } catch (e) {
      console.error("Failed to refresh the token, or the session has expired, error: ", e);
    }
    return true;
  });
}
export function logout(logoutOptions) {
  return __async(this, null, function* () {
    try {
      yield apiRequest("/api/logoutCookies");
    } catch (error) {
      console.error("Failed to clear cookies", error);
    }
    try {
      yield keycloak.logout(logoutOptions);
    } catch (error) {
      console.error("Keycloak logout failed", error);
    }
  });
}
export function getInstance() {
  return keycloak;
}
