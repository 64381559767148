"use strict";
import { formValueSelector } from "redux-form";
import * as form from "../constants/experiment.form.constants";
import { DATE_FROM, DATE_TO } from "../../../../common/constants";
export const createFormSelector = () => formValueSelector(form.EXPERIMENT_FORM_NAME);
export const getFormFields = (state) => {
  const fields = createFormSelector()(state, ...form.EXPERIMENT_PROPS);
  if (fields[form.DATES_FIELD]) {
    const dates = fields[form.DATES_FIELD];
    fields[form.START_FIELD] = dates[DATE_FROM];
    fields[form.END_FIELD] = dates[DATE_TO];
  }
  return fields;
};
