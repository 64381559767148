"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DATATRONS_MANAGER } from "../constants/permissions";
class DatatronsPermissions {
  constructor(roles) {
    __publicField(this, "isDatatronsManager");
    this.isDatatronsManager = roles.indexOf(DATATRONS_MANAGER) !== -1;
  }
  canAddDatatronAssistants() {
    return this.isDatatronsManager;
  }
  canDeleteDatatronAssistants() {
    return this.isDatatronsManager;
  }
  canCreateDatatrons() {
    return this.isDatatronsManager;
  }
  canUpdateDatatrons() {
    return this.isDatatronsManager;
  }
  canCreateDatatronsDeviceTypes() {
    return this.isDatatronsManager;
  }
  canCreateDatatronDevices() {
    return this.isDatatronsManager;
  }
  canUpdateDatatronDevices() {
    return this.isDatatronsManager;
  }
  canDeleteDatatronDevices() {
    return this.isDatatronsManager;
  }
  canManageDatatronDevices() {
    return this.isDatatronsManager;
  }
  canCreateDatatronDeviceDataPoints() {
    return this.isDatatronsManager;
  }
  canUpdateDatatronDeviceDataPoints() {
    return this.isDatatronsManager;
  }
  canDeleteDatatronDeviceDataPoints() {
    return this.isDatatronsManager;
  }
}
export default DatatronsPermissions;
