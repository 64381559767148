"use strict";
import * as formConstants from "../../redux/constants/datatron.form.constants";
import { NO_FILE } from "../../redux/constants/fileUpload.component.constants";
export const getValuesFromDatatron = (datatron) => {
  var _a, _b, _c;
  return {
    [formConstants.PHOTO_FIELD]: !datatron.imagePath ? NO_FILE : null,
    [formConstants.NAME_FIELD]: datatron.name || "",
    [formConstants.VENDOR_FIELD]: datatron.make || "",
    [formConstants.MODEL_FIELD]: datatron.model || "",
    [formConstants.YEAR_FIELD]: datatron.year || "",
    [formConstants.SERIAL_NUMBER_FIELD]: datatron.serialNumber || "",
    [formConstants.DEVICE_UUID_FIELD]: datatron.deviceUUID || "",
    [formConstants.MAC_ADDRESS_FIELD]: datatron.macAddress || "",
    [formConstants.NOTES_FIELD]: datatron.notes || "",
    [formConstants.LOCATION_FIELD]: ((_a = datatron.location) == null ? void 0 : _a.id) || "",
    [formConstants.ORGANIZATION_FIELD]: {
      label: (_b = datatron.organization) == null ? void 0 : _b.name,
      value: (_c = datatron.organization) == null ? void 0 : _c.id
    },
    [formConstants.IP_ADDRESS_FIELD]: datatron.ipAddress ? datatron.ipAddress : "",
    [formConstants.NETWORK_SPEED_FIELD]: datatron.networkSpeed || formConstants.NETWORK_SPEED_FIELD_DEFAULT,
    [formConstants.NODE_NAME_FIELD]: datatron.nodeName || "",
    [formConstants.NETMASK]: datatron.netmask || "",
    [formConstants.GATEWAY]: datatron.gateway || "",
    [formConstants.BOOT_ARGUMENTS]: datatron.bootArguments || "",
    [formConstants.OS_MODULES]: datatron.osModules || "",
    [formConstants.OS_CONFIG]: datatron.osConfig || formConstants.OS_CONFIG_DEFAULT
  };
};
