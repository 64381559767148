"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import { initializeDeviceTypes } from "../../core/common/datatron";
import { getDeviceTypes } from "../../core/api/datatrons";
export const fetchDeviceTypes = createAction("datatrons/deviceTypes/fetch");
export const fetchDeviceTypesSuccess = createAction(
  "datatrons/deviceTypes/fetchSuccess",
  (deviceTypes) => ({ payload: deviceTypes })
);
export const fetchDeviceTypesFail = createAction(
  "datatrons/deviceTypes/fetchFail",
  (error) => ({ payload: error })
);
export const reducer = {
  [fetchDeviceTypes.type]: (state) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      deviceTypes: __spreadProps(__spreadValues({}, state.datatron.deviceTypes), {
        list: [],
        loading: true,
        loaded: false,
        error: null
      })
    })
  }),
  [fetchDeviceTypesSuccess.type]: (state, deviceTypes) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      deviceTypes: __spreadProps(__spreadValues({}, state.datatron.deviceTypes), {
        list: deviceTypes,
        loading: false,
        loaded: true,
        error: null
      })
    })
  }),
  [fetchDeviceTypesFail.type]: (state, error) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      deviceTypes: __spreadProps(__spreadValues({}, state.datatron.deviceTypes), {
        list: [],
        loading: false,
        loaded: false,
        error
      })
    })
  })
};
export function* fetchDeviceTypesSaga() {
  const { response, error } = yield call(getDeviceTypes);
  if (response) {
    const deviceTypes = yield call(initializeDeviceTypes, response);
    yield put(fetchDeviceTypesSuccess(deviceTypes));
  } else {
    yield put(fetchDeviceTypesFail(error));
  }
}
export function* watchFetchDeviceTypes() {
  yield takeEvery(fetchDeviceTypes, fetchDeviceTypesSaga);
}
