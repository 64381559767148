// used to keep the same reference of the routes object during HMR
import refEqualRoutes from './referentially-equal-routes';

import * as routes from './routes';

import Root from './Root';
import IndexRoute from './IndexRoute';
import ErrorContainer from '../error/Error.container';
import { Dashboard } from '../dashboard/Dashboard';
import MaintenanceContainer from '../maintenance/Maintenance.container';
import Inventory from '../inventory/Inventory';
import ReportsContainer from '../reports/Reports.container';
import MachineContainer from '../machine/Machine.container';
import NewMachineContainer from '../machineFormPage/NewMachineFormPage.container';
import UpdateMachineContainer from '../machineFormPage/UpdateMachineFormPage.container';
import NewDatatronContainer from '../datatronFormPage/NewDatatronFormPage';
import UpdateDatatron from '../datatronFormPage/UpdateDatatronFormPage';
import ProfileContainer from '../profile/Profile/Profile.container';
import OUContainer from '../ou/OU.container';
import TagsCloud from '../tagsCloud/TagsCloud';
import Tools from '../tools/Tools.container';
import AIAppsOverviewPage from '../aiApps/AIAppsOverviewPage';
import AiAppsEditorPage from '../aiApps/AiAppsEditorPage';
import ExperimentsPageContainer from '../experimentsPage/ExperimentsPage.container';
import { AnalyzerOverviewPage } from '../analyzer/AnalyzerOverviewPage';
import { AnalyzerSessionEditorPage } from '../analyzer/AnalyzerSessionEditorPage';
import { AnalyzerSessionPrintPage } from '../analyzer/AnalyzerSessionPrintPage';
import { AnalyzerAnomalyEditorPage } from '../analyzer/AnalyzerAnomalyEditorPage';
import MachineCalendars from '../machineCalendars/MachineCalendars';
import AnomalyContainer from '../anomaly/Anomaly.container';
import Archive from '../archive/Archive';
import LocationContainer from '../locations/LocationPage.container';
import BatchesContainer from '../products/batches/Batches.container';
import BatchDetailsPageContainer from '../products/batchDetails/BatchDetailsPage.container';
import { Datatron } from '../datatron/Datatron';
import AdminPageContainer from '../adminPage/AdminPage.container';
import AdminAccountsModule from '../adminPage/modules/accounts/AccountsModule.container';
import AdminAddAccountModule from '../adminPage/modules/forms/accountForm/AddAccountModule.container';
import AdminEditAccountModule from '../adminPage/modules/forms/accountForm/EditAccountModule.container';
import AdminOrganizationsModule from '../adminPage/modules/organizations/OrganizationsModule.container';
import AdminAddOrganizationModule from '../adminPage/modules/forms/organizationForm/AddOrganizationModule.container';
import AdminEditOrganizationModule from '../adminPage/modules/forms/organizationForm/EditOrganizationModule.container';
import AdminLocationsModule from '../adminPage/modules/locations/LocationsModule.container';
import AdminAddLocationModule from '../adminPage/modules/forms/locationForm/AddLocationModule.container';
import AdminViewLocationModule from '../adminPage/modules/forms/locationForm/ViewLocationModule.container';
import AdminEditLocationModule from '../adminPage/modules/forms/locationForm/EditLocationModule.container';
import AdminUsersModule from '../adminPage/modules/users/UsersModule.container';
import AdminAddUserModule from '../adminPage/modules/forms/userForms/addUserForm/AddUserFormModule.container';
import AdminViewUserModule from '../adminPage/modules/forms/userForms/regularForm/ViewUserModule.container';
import AdminEditUserModule from '../adminPage/modules/forms/userForms/regularForm/EditUserModule.container';
import AdminRolesModule from '../adminPage/modules/roles/RolesModule.container';
import AdminViewRoleModule from '../adminPage/modules/forms/roleForm/ViewRoleModule.container';
import AdminEditRoleModule from '../adminPage/modules/forms/roleForm/EditRoleModule.container';
import UnauthorizedPage from '../pages/unauthorized/Unauthorized';

export function hashLinkScroll() {
  const { hash } = window.location;
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  }
}

export default Object.assign(refEqualRoutes, {
  path: '/',
  routes: [
    {
      path: '/',
      exact: true,
      component: IndexRoute,
    },
    {
      path: routes.app.prefix,
      component: Root,
      routes: [
        {
          path: routes.app.prefix + routes.app.error,
          component: ErrorContainer,
        },
        {
          path: routes.app.prefix + routes.app.profile,
          component: ProfileContainer,
        },
        {
          path: routes.app.prefix + routes.app.dashboard,
          component: Dashboard,
        },
        {
          path: routes.app.prefix + routes.app.maintenance,
          component: MaintenanceContainer,
        },
        {
          path: routes.app.prefix + routes.app.inventory,
          component: Inventory,
          routes: [
            {
              path: `${routes.app.prefix + routes.app.inventory}/${routes.app.machinesPrefix}${routes.app.newMachine}`,
              component: NewMachineContainer,
            },
            {
              path: `${routes.app.prefix + routes.app.inventory}/${routes.app.machinesPrefix}${routes.app.machine}`,
              component: MachineContainer,
              routes: [
                {
                  path: `${routes.app.prefix + routes.app.inventory}/${routes.app.machinesPrefix}${routes.app.machine}/${routes.app.edit}`,
                  component: UpdateMachineContainer,
                },
              ],
            },
            {
              path: `${routes.app.prefix + routes.app.inventory}/${routes.app.datatronPrefix}${routes.app.newDatatron}`,
              component: NewDatatronContainer,
            },
            {
              path: `${routes.app.prefix + routes.app.inventory}/${routes.app.datatronPrefix}${routes.app.datatron}`,
              component: Datatron,
              routes: [
                {
                  path: `${routes.app.prefix + routes.app.inventory}/${routes.app.datatronPrefix}${routes.app.datatron}/${routes.app.edit}`,
                  component: UpdateDatatron,
                },
              ],
            },
          ],
        },
        {
          path: routes.app.prefix + routes.app.machineCalendars,
          component: MachineCalendars,
        },
        {
          path: routes.app.prefix + routes.app.reports,
          component: ReportsContainer,
        },
        {
          path: routes.app.prefix + routes.app.adminPage,
          component: AdminPageContainer,
          routes: [
            {
              path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageAccounts}`,
              component: AdminAccountsModule,
              routes: [
                {
                  path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageAccounts}/${routes.app.entryCreate}`,
                  component: AdminAddAccountModule,
                },
                {
                  path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageAccounts}/${routes.app.adminPageAccountId}/${routes.app.entryEdit}`,
                  component: AdminEditAccountModule,
                },
              ],
            },
            {
              path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageOrganizations}`,
              component: AdminOrganizationsModule,
              routes: [
                {
                  path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageOrganizations}/${routes.app.entryCreate}`,
                  component: AdminAddOrganizationModule,
                },
                {
                  path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageOrganizations}/${routes.app.adminPageOrganizationId}/${routes.app.entryEdit}`,
                  component: AdminEditOrganizationModule,
                },
              ],
            },
            {
              path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageLocations}`,
              component: AdminLocationsModule,
              routes: [
                {
                  path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageLocations}/${routes.app.entryCreate}`,
                  component: AdminAddLocationModule,
                },
                {
                  path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageLocations}/${routes.app.adminPageLocationId}/${routes.app.entryView}`,
                  component: AdminViewLocationModule,
                },
                {
                  path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageLocations}/${routes.app.adminPageLocationId}/${routes.app.entryEdit}`,
                  component: AdminEditLocationModule,
                },
              ],
            },
            {
              path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageUsers}`,
              component: AdminUsersModule,
              routes: [
                {
                  path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageUsers}/${routes.app.entryCreate}`,
                  component: AdminAddUserModule,
                },
                {
                  path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageUsers}/${routes.app.adminPageUserId}/${routes.app.entryView}`,
                  component: AdminViewUserModule,
                },
                {
                  path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageUsers}/${routes.app.adminPageUserId}/${routes.app.entryEdit}`,
                  component: AdminEditUserModule,
                },
              ],
            },
            {
              path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageRoles}`,
              component: AdminRolesModule,
              routes: [
                {
                  path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageRoles}/${routes.app.adminPageRoleId}/${routes.app.entryView}`,
                  component: AdminViewRoleModule,
                },
                {
                  path: `${routes.app.prefix + routes.app.adminPage}/${routes.app.adminPageRoles}/${routes.app.adminPageRoleId}/${routes.app.entryEdit}`,
                  component: AdminEditRoleModule,
                },
              ],
            },
          ],
        },
        {
          path: routes.app.prefix + routes.app.batches,
          component: BatchesContainer,
          routes: [
            {
              path: `${routes.app.prefix + routes.app.batches}/${routes.app.batch}`,
              component: BatchDetailsPageContainer,
            },
          ],
        },
        {
          path: routes.app.prefix + routes.app.analyzer,
          component: AnalyzerOverviewPage,
          routes: [
            {
              path: `${routes.app.prefix + routes.app.analyzer}/${routes.app.sessions}/${routes.app.sessionId}`,
              component: AnalyzerSessionEditorPage,
            },
            {
              path: `${routes.app.prefix + routes.app.analyzer}/${routes.app.print}/${routes.app.sessionId}`,
              component: AnalyzerSessionPrintPage,
            },
            {
              path: `${routes.app.prefix + routes.app.analyzer}/${routes.app.anomalies}/${routes.app.anomalyId}`,
              component: AnalyzerAnomalyEditorPage,
            },
          ],
        },
        {
          path: routes.app.prefix + routes.app.aiApps,
          component: AIAppsOverviewPage,
          routes: [
            {
              path: `${routes.app.prefix + routes.app.aiApps}/${routes.app.aiApp}`,
              component: AiAppsEditorPage,
            },
          ],
        },
        {
          path: routes.app.prefix + routes.app.tools,
          component: Tools,
        },
        {
          path: routes.app.prefix + routes.app.experiments,
          component: ExperimentsPageContainer,
        },
        {
          path: routes.app.prefix + routes.app.locationPrefix + routes.app.location,
          component: LocationContainer,
        },
        {
          path: routes.app.prefix + routes.app.tagsCloud,
          component: TagsCloud,
        },
        {
          path: routes.app.prefix + routes.app.maintenancePrefix + routes.app.archive,
          component: Archive,
        },
        {
          path: routes.app.prefix + routes.app.ouPrefix + routes.app.ou,
          component: OUContainer,
        },
        {
          path: routes.app.prefix + routes.app.anomalyPrefix + routes.app.anomalyEventId,
          component: AnomalyContainer,
        },
        {
          path: routes.app.prefix + routes.app.unauthorizedPage,
          component: UnauthorizedPage,
        },
      ],
    },
  ],
});
