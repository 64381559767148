"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { collectCommand, publishDevice, stopCommand } from "../../core/api/datatrons";
import notificationMessage from "../../messages/notification.message";
import { NOTIFICATION_ERROR } from "../constants/notification.constants";
import { getDatatron } from "../selectors/datatron.selector";
import { setCallbackCommandId } from "./configRepo.callback.receive.module";
import { sendNotification } from "./notifications.module";
import { AppReducerMapBuilder } from "./util";
import { transformErrorToMessageString } from "../../../../common/utils";
export const submitQuickCommand = createAction(
  "quick command submitted",
  (deviceId, command) => ({
    payload: { deviceId, command }
  })
);
export const quickCommandSuccess = createAction("quick command success", (deviceId) => ({
  payload: { deviceId }
}));
export const quickCommandFailure = createAction(
  "quick command failure",
  (deviceId, error) => ({ payload: { deviceId, error } })
);
export const reducer = AppReducerMapBuilder.new().add(submitQuickCommand, (state, { deviceId }) => __spreadProps(__spreadValues({}, state), {
  modals: __spreadProps(__spreadValues({}, state.modals), {
    commandState: __spreadProps(__spreadValues({}, state.modals.commandState), {
      deviceId,
      submitting: true
    })
  })
})).add(quickCommandSuccess, (state, { deviceId }) => __spreadProps(__spreadValues({}, state), {
  modals: __spreadProps(__spreadValues({}, state.modals), {
    commandState: {
      submitting: false,
      success: true,
      deviceId
    }
  })
})).add(quickCommandFailure, (state, { deviceId, error }) => __spreadProps(__spreadValues({}, state), {
  modals: __spreadProps(__spreadValues({}, state.modals), {
    commandState: {
      submitting: false,
      success: false,
      deviceId,
      error
    }
  })
})).build();
const quickCommandEndpoint = (command) => {
  switch (command) {
    case "collect":
      return collectCommand;
    case "stop":
      return stopCommand;
    case "publish":
      return publishDevice;
    default:
      return () => ({
        response: null
      });
  }
};
export function* quickCommandSaga({ payload: { deviceId, command } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const endpoint = quickCommandEndpoint(command);
  const { response, error } = yield call(endpoint, datatron.id, deviceId);
  if (response) {
    yield put(setCallbackCommandId(response.commandId));
    yield put(quickCommandSuccess(deviceId));
    yield put(sendNotification(notificationMessage.device_generic_success));
  } else {
    yield put(setCallbackCommandId(-1));
    const errorString = transformErrorToMessageString(error);
    yield put(quickCommandFailure(deviceId, errorString));
    yield put(
      sendNotification(notificationMessage.device_generic_failed, errorString, NOTIFICATION_ERROR)
    );
  }
}
export function* watchQuickCommand() {
  yield takeEvery(submitQuickCommand, quickCommandSaga);
}
