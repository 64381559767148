"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { scroller } from "react-scroll";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { startSubmit, stopSubmit, touch } from "redux-form";
import { isEmpty } from "lodash";
import * as dfc from "../constants/datatron.form.constants";
import * as api from "../../core/api";
import notificationMessages from "../../messages/notification.message";
import { getFormFields } from "../selectors/datatron.page.form.selector";
import { getDatatron } from "../selectors/datatron.selector";
import { findFirstErrorField } from "../../core/common/forms";
import { getDatatronLink } from "../../components/index/routes";
import { sendNotification } from "./notifications.module";
import { NO_FILE } from "../constants/fileUpload.component.constants";
import { INVENTORY_DATATRONS } from "../../../../common/constants/inventory";
import { fetchInventory } from "./inventory.fetch.module";
import { validatePhoto } from "../../core/validation/datatrons.form.validation";
export const updateDatatron = createAction(
  "update datatron"
);
export const updateDatatronSuccess = createAction(
  "update datatron - success",
  (payload) => ({ payload })
);
export const updateDatatronFail = createAction(
  "update datatron - fail",
  (error) => ({ payload: error })
);
export const reducer = {
  [updateDatatron.type]: (state) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      _update: __spreadProps(__spreadValues({}, state.datatron._update), {
        loading: true,
        loaded: false,
        error: null
      })
    })
  }),
  [updateDatatronSuccess.type]: (state, payload) => {
    const newState = __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        details: __spreadValues(__spreadValues({}, state.datatron.details), payload),
        _update: __spreadProps(__spreadValues({}, state.datatron._update), {
          loading: false,
          loaded: true,
          error: null
        })
      })
    });
    const inventoryPreview = state.inventory.preview;
    if (inventoryPreview && inventoryPreview.itemType === INVENTORY_DATATRONS && inventoryPreview.item && inventoryPreview.item.id === payload.id) {
      newState.inventory = __spreadProps(__spreadValues({}, state.inventory), {
        preview: __spreadProps(__spreadValues({}, state.inventory.preview), {
          item: __spreadValues(__spreadValues({}, state.inventory.preview.item), payload)
        })
      });
    }
    return newState;
  },
  [updateDatatronFail.type]: (state, error) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      _update: __spreadProps(__spreadValues({}, state.datatron._update), {
        loading: false,
        loaded: false,
        error
      })
    })
  })
};
export function* touchAllFieldsSaga() {
  yield put(touch(dfc.DATATRON_FORM_NAME, ...dfc.DATATRON_PROPS));
}
export function* getFields(state) {
  const fields = yield call(getFormFields, state);
  if (fields[dfc.ORGANIZATION_FIELD]) {
    fields[dfc.ORGANIZATION_FIELD] = fields[dfc.ORGANIZATION_FIELD].value;
  }
  if (!fields[dfc.PHOTO_FIELD]) {
    delete fields[dfc.PHOTO_FIELD];
  }
  if (fields[dfc.IP_ADDRESS_FIELD] && fields[dfc.IP_ADDRESS_FIELD].length > 0) {
    fields[dfc.IP_ADDRESS_FIELD] = fields[dfc.IP_ADDRESS_FIELD].split("\n");
  }
  return fields;
}
export function* handleErrors(errors) {
  yield put(updateDatatronFail(errors));
  yield put(stopSubmit(dfc.DATATRON_FORM_NAME, errors));
  const firstErrorField = yield call(findFirstErrorField, dfc.DATATRON_PROPS, errors);
  if (firstErrorField) {
    yield scroller.scrollTo(
      firstErrorField,
      { offset: dfc.DATATRON_FORM_ERROR_FIELD_OFFSET }
    );
  }
}
export function* updateDatatronSaga() {
  const state = yield select();
  yield call(touchAllFieldsSaga);
  const datatron = yield call(getDatatron, state);
  const fields = yield call(getFields, state);
  yield put(startSubmit(dfc.DATATRON_FORM_NAME));
  if (fields[dfc.PHOTO_FIELD] === NO_FILE) {
    const deletePhotoCall = yield call(api.datatrons.deleteDatatronPhoto, datatron.id);
    if (deletePhotoCall.error) {
      yield put(updateDatatronFail(deletePhotoCall.error));
    }
    delete fields[dfc.PHOTO_FIELD];
  }
  const preRequestValidationErrors = yield call(validatePhoto, fields);
  if (!isEmpty(preRequestValidationErrors)) {
    yield handleErrors(preRequestValidationErrors);
    return;
  }
  const { response, error } = yield call(api.datatrons.updateDatatron, datatron.id, fields);
  if (response) {
    yield put(updateDatatronSuccess(response));
    yield put(stopSubmit(dfc.DATATRON_FORM_NAME));
    yield put(fetchInventory());
    yield put(push(getDatatronLink(datatron.id)));
    yield put(sendNotification(notificationMessages.datatron_updated_title));
  } else {
    yield handleErrors(error);
  }
}
export function* watchUpdateDatatron() {
  yield takeLatest(updateDatatron, updateDatatronSaga);
}
