"use strict";
export const IDLE = {
  status: "idle",
  data: null,
  isLoading: false,
  error: null
};
export const LOADING = {
  status: "loading",
  data: null,
  isLoading: true,
  error: null
};
export const failed = (error) => ({
  status: "failed",
  data: null,
  isLoading: false,
  error
});
export const succeeded = (data) => ({
  status: "succeeded",
  data,
  isLoading: false,
  error: null
});
export const isIdle = (state) => state.status === "idle";
export const isLoading = (state) => state.status === "loading";
export const isFailed = (state) => state.status === "failed";
export const isSucceeded = (state) => state.status === "succeeded";
export const isLoaded = (state) => isSucceeded(state) || isFailed(state);
export function map(state, mapFunc) {
  switch (state.status) {
    case "failed":
    case "idle":
    case "loading":
      return state;
    case "succeeded":
      return succeeded(mapFunc(state.data));
  }
}
export const unwrap = (state) => state.data;
