"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as reportsSelector from "../selectors/reports.selector";
import * as api from "../../core/api";
import { initializeReport } from "../../core/common/report";
import { updateReportSuccess } from "./report.update.module";
import { DATA_POINTS } from "../../../../common/constants/entities";
export const removeLegendDataPoint = createAction(
  "remove legend data point from report",
  (reportId, dataPointId) => ({ payload: { reportId, dataPointId } })
);
export function* removeLegendDataPointSaga({ payload: { reportId, dataPointId } }) {
  const state = yield select();
  const reports = yield call(reportsSelector.selectAllReports, state);
  const report = yield call(reportsSelector.getReportById, reportId, reports);
  if (!report)
    return;
  const apiCallBody = {
    name: report.name,
    from: report.from,
    to: report.to,
    type: report.type,
    interval: report.interval,
    filters: __spreadProps(__spreadValues({}, report.filters), {
      [DATA_POINTS]: report.filters[DATA_POINTS].reduce((result, filter) => {
        if (dataPointId === filter.dataPoint.id)
          return result;
        return [
          ...result,
          __spreadProps(__spreadValues({}, filter), { id: filter.dataPoint.id, dataPoint: void 0 })
        ];
      }, [])
    })
  };
  const { response } = yield call(api.reports.update, reportId, apiCallBody);
  if (response) {
    yield put(updateReportSuccess(__spreadProps(__spreadValues({}, initializeReport(response)), {
      disabledProps: report.disabledProps || []
    })));
  }
}
export function* watchRemoveLegendDataPoint() {
  yield takeEvery(removeLegendDataPoint.toString(), removeLegendDataPointSaga);
}
