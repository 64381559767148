"use strict";
const INITIAL_LABEL_WIDTH = 300;
export const TOOL_TYPES = ["zoom", "cursor", "bitRuler"];
export const isToolType = (value) => TOOL_TYPES.includes(value);
export const INITIAL_TOOL = {
  type: "zoom",
  firstUnixNano: null,
  secondUnixNano: null
};
export const INITIAL_SETTINGS = {
  labelWidth: INITIAL_LABEL_WIDTH,
  showHeader: true,
  showTooltips: true
};
