"use strict";
import * as entities from "../../../../common/constants/entities";
import { createSelector } from "../util";
export const loading = createSelector((state) => state.search.loading);
export const selectIsLoaded = createSelector((state) => state.search.loaded);
export const getSearchResults = createSelector((state) => state.search.results);
export const getLocationsSearchResults = createSelector(
  (state) => state.search.results[entities.LOCATIONS]
);
export const getOrganizationSearchResults = createSelector(
  (state) => state.search.results[entities.ORGANIZATIONS]
);
export const getExperimentSearchResults = createSelector(
  (state) => state.search.results[entities.EXPERIMENTS]
);
export const getDatatronSearchResults = createSelector(
  (state) => state.search.results[entities.DATATRONS]
);
export const difference = (arr1, arr2) => arr1.filter(({ id }) => arr2.findIndex((item) => item.id === id) === -1);
export const makeGetFilteredSearchResults = (getSelectedFilters) => createSelector((state, itemType) => {
  const searchResults = getSearchResults(state);
  const selectedFilters = getSelectedFilters(state, itemType);
  return {
    [entities.MACHINES]: difference(
      searchResults[entities.MACHINES],
      selectedFilters[entities.MACHINES]
    ),
    [entities.LOCATIONS]: difference(
      searchResults[entities.LOCATIONS],
      selectedFilters[entities.LOCATIONS]
    ),
    [entities.ORGANIZATIONS]: difference(
      searchResults[entities.ORGANIZATIONS],
      selectedFilters[entities.ORGANIZATIONS]
    ),
    [entities.TAGS]: difference(searchResults[entities.TAGS], selectedFilters[entities.TAGS]),
    [entities.DATATRONS]: difference(
      searchResults[entities.DATATRONS],
      selectedFilters[entities.DATATRONS]
    )
  };
});
