"use strict";
export const AI_APP_CYCLE_DETECTION_FORM = "AI_APP_CYCLE_DETECTION_FORM";
export const DATES_FIELD = "dates";
export const MIN_CYCLE_TIME_FIELD = "minCycleTime";
export const MAX_CYCLE_TIME_FIELD = "maxCycleTime";
export const TYPE_UUID_FIELD = "typeUuid";
export const MIN_CYCLE_TIME_DEFAULT = "30";
export const MAX_CYCLE_TIME_DEFAULT = "90";
export const AI_APP_CYCLE_DETECTION_PROPS = [
  DATES_FIELD,
  MIN_CYCLE_TIME_FIELD,
  MAX_CYCLE_TIME_FIELD,
  TYPE_UUID_FIELD
];
export const CREATED_STATUS = "CREATED";
export const RUNNING_STATUS = "RUNNING";
export const COMPLETED_STATUS = "COMPLETED";
export const FAILED_STATUS = "FAILED";
export const CYCLE_DETECTION_DISPLAY_HEADER = [
  "training_status",
  "start_at",
  "end_at",
  "cycle_duration_sec_min",
  "cycle_duration_sec_max"
];
