import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { debounce } from 'lodash';
import { PanelActions } from './PanelActions';
import { ToolToggleGroup } from './ToolToggleGroup';
import { TimeRangeInput } from '../../_tools/input/InputRange';
import {
  getPanelById,
  getPanelStatus,
  panelIsLoading,
} from '../../../redux/selectors/analyzer.selector';
import { updateSessionPanelPartial } from '../../../redux/modules/analyzer.sessions.panel.update.module';
import { calculateFromDate, withNewDelta, withNewFromDate } from '../../helper/panelTime';
import { formatDateWithMicrosecondsAndOffset } from '../../helper/time';
import { MIN_RANGE_DELTA_MS } from '../../../../../common/constants/analyzer';
import { DateTimeCursorInput } from '../cursorDateTimeInput/CursorDateTimeInput';
import { usePanelAtom } from '../../../atom/panelHelper';
import analyzerMessages from '../../../messages/analyzer.messages';

export const PanelHeader: React.FC<{ panelId: number }> = React.memo(({ panelId }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const panel = useSelector((state) => getPanelById(state, panelId));
  const isPanelIsLoading = useSelector((state) => panelIsLoading(state, panelId));
  const panelStatus = useSelector((state) => getPanelStatus(state, panelId));
  const [, setPanelAtom] = usePanelAtom(panelId);

  const handleDateChange = debounce((newDate: Date) => {
    const rangeFrom = formatDateWithMicrosecondsAndOffset(newDate);
    const time = withNewFromDate(panel.time, newDate);
    setPanelAtom((prevState) => ({
      ...prevState,
      time,
    }));
    dispatch(
      updateSessionPanelPartial(panelId, {
        rangeFrom: rangeFrom,
        time,
      }),
    );
  }, 300);

  const getSelectedDate = () => {
    if (panel && panel.rangeFrom) {
      return new Date(panel.rangeFrom);
    }
    return new Date();
  };

  const handleRangeChange = (newRange: number) => {
    const time = withNewDelta(panel.time, newRange);
    const calculatedFromDate = calculateFromDate(time);

    dispatch(
      updateSessionPanelPartial(panelId, {
        rangeFrom: formatDateWithMicrosecondsAndOffset(calculatedFromDate),
        rangeDeltaMs: newRange,
        time,
      }),
    );
  };

  const handelToggleTool = (panelTool) => {
    setPanelAtom((prevState) => ({
      ...prevState,
      tool: panelTool,
    }));
    dispatch(
      updateSessionPanelPartial(panelId, {
        tool: panelTool,
      }),
    );
  };
  const valueDate = getSelectedDate();

  const translatePanelName = (panelName) => {
    const baseMatch = panelName.match(/^(Anomaly|Reference)/i);

    if (baseMatch) {
      const baseWord = baseMatch[1];
      const baseWordKey = baseWord.toLowerCase();

      if (analyzerMessages[baseWordKey]) {
        return panelName.replace(
          new RegExp(`^${baseWord}`, 'i'),
          formatMessage(analyzerMessages[baseWordKey]),
        );
      }
    }

    return panelName;
  };

  return (
    <>
      <div>{panel.isPanelAnomaly ? translatePanelName(panel.name) : panel.name}</div>
      <div className='action-panel-session'>
        <ToolToggleGroup panel={panel} onChange={handelToggleTool} />
        <PanelActions
          panel={panel}
          status={panelStatus}
          panelIsLoading={isPanelIsLoading}
        ></PanelActions>
        <div className='cursor-time-container'>
          <DateTimeCursorInput value={valueDate} onChange={handleDateChange} />
        </div>
        <TimeRangeInput
          initialValue={panel.rangeDeltaMs || MIN_RANGE_DELTA_MS}
          onChange={handleRangeChange}
        />
      </div>
    </>
  );
});
