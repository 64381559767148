"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { cloneDeep } from "lodash";
import { createAction } from "@reduxjs/toolkit";
import { put, call, takeEvery } from "redux-saga/effects";
import * as api from "../../core/api";
import { initializeDatatron } from "../../core/common/datatron";
import { basic } from "../state/datatron.state";
import { searchUsersByOrganization } from "./users.organization.search.module";
import { fetchDevices } from "./datatron.devices.fetch.module";
export const fetchDatatron = createAction("fetch datatron", (id) => ({ payload: id }));
export const fetchDatatronSuccess = createAction("fetch datatron - success", (datatron) => ({
  payload: datatron
}));
export const fetchDatatronFail = createAction("fetch datatron - fail", (error) => ({
  payload: error
}));
export const resetDatatron = createAction("reset datatron");
export const reducer = {
  [fetchDatatron.type]: (state) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      loaded: false,
      loading: true,
      error: null
    })
  }),
  [fetchDatatronSuccess.type]: (state, datatron) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      /*
       * TODO: fix me, implement a different solution
       * In Redux, reducers should be pure functions that take the current state
       * and an action as input and return a new state based on the action type and payload.
       * Reducers should not have any side effects, such as making API calls,
       * like this case  on initializeDatatron ends up calling await getDatatronStats api
       */
      details: initializeDatatron(datatron),
      loaded: true,
      loading: false
    })
  }),
  [fetchDatatronFail.type]: (state, error) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      loaded: false,
      loading: false,
      error
    })
  }),
  [resetDatatron.type]: (state) => __spreadProps(__spreadValues({}, state), {
    datatron: cloneDeep(basic)
  })
};
export function* fetchDatatronSaga({ payload: id }) {
  var _a;
  const { response, error } = yield call(api.datatrons.getDatatron, id);
  if (response) {
    yield put(fetchDatatronSuccess(response));
    yield put(searchUsersByOrganization((_a = response.organization) == null ? void 0 : _a.id, ""));
    yield put(fetchDevices());
  } else {
    yield put(fetchDatatronFail(error));
  }
}
export function* watchFetchDatatron() {
  yield takeEvery(fetchDatatron, fetchDatatronSaga);
}
