import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import invariant from 'tiny-invariant';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';
import { RowSignal } from './RowSignal';
import { PanelSignalContextMenu } from '../panel/PanelSignalContextMenu';
import {
  updateSessionPanelSignal,
  updateSessionPanelSignalOrder,
} from '../../../redux/modules/analyzer.sessions.panel.update.module';
import { getSignalsByPanelId } from '../../../redux/selectors/analyzer.selector';
import { ResizableWrapper } from './ResizableWrapper';

export const RowSignalDragAndDropWrapper: React.FC<{ panelId: number }> = ({ panelId }) => {
  const panelSignals = useSelector((state) => getSignalsByPanelId(state, panelId));
  const ref = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();

  const sortedPanelSignals = useMemo(
    () => [...panelSignals].sort((a, b) => a.order - b.order),
    [panelSignals],
  );
  useEffect(() => {
    invariant(ref.current);

    return monitorForElements({
      onDrop: ({ source, location }) => {
        const dropTarget = location.current.dropTargets[0];
        if (!dropTarget) return;
        if (source.data.panelId !== panelId) return;
        const sourceId = source.data.id;
        if (typeof sourceId !== 'number') return;
        const targetId = dropTarget.data.id;
        if (typeof targetId !== 'number') return;

        const startIndex = sortedPanelSignals.findIndex((item) => item.id === sourceId);
        const endIndex = sortedPanelSignals.findIndex((item) => item.id === targetId);
        const updated = reorderWithEdge({
          list: sortedPanelSignals,
          startIndex,
          indexOfTarget: endIndex,
          closestEdgeOfTarget: null,
          axis: 'vertical',
        });
        const updatedWithOrders = updated.map((item, index) => ({
          id: item.id,
          order: index,
        }));
        dispatch(updateSessionPanelSignalOrder(panelId, updatedWithOrders));
      },
    });
  }, [dispatch, panelId, sortedPanelSignals]);

  return (
    <div ref={ref}>
      {sortedPanelSignals.map((panelSignal, i) => (
        <PanelSignalContextMenu panelSignal={panelSignal} panelId={panelId} key={panelSignal.id}>
          <ResizableWrapper
            defaultHeight={panelSignal.height}
            onResizeStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onResizeStop={(_, __, ___, d) => {
              dispatch(
                updateSessionPanelSignal(panelId, panelSignal.id, {
                  height: panelSignal.height + d.height,
                }),
              );
            }}
          >
            <RowSignal
              panelId={panelId}
              panelSignal={panelSignal}
              key={panelSignal.id}
              id={panelSignal.id}
              index={i}
            />
          </ResizableWrapper>
        </PanelSignalContextMenu>
      ))}
    </div>
  );
};
