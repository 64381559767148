"use strict";
export const notificationsInitialState = [];
export const notificationsTestState = [
  {
    id: "123",
    title: "New Augur",
    description: "New Augur was added",
    type: "event"
  }
];
