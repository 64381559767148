import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

interface LoadingTablePlaceholderProps {
  customMessage?: string | MessageDescriptor;
}

export const LoadingTablePlaceholder: React.FC<LoadingTablePlaceholderProps> = ({
  customMessage,
}) => (
  <div className='loading-table-placeholder'>
    <div className='loading-table-indicator'>
      <i className='fa fa-spinner fa-spin'></i>
    </div>
    <p>
      <strong>
        {customMessage ? (
          typeof customMessage === 'string' ? (
            customMessage
          ) : (
            <FormattedMessage {...customMessage} />
          )
        ) : (
          <FormattedMessage id='datatron.modals.loading' defaultMessage='Loading' />
        )}
      </strong>
    </p>
  </div>
);
