import React from 'react';
import { LoadingTablePlaceholder } from './LoadingTablePlaceholder';

interface LoadingTableProps {
  className?: string;
}

export const LoadingTable: React.FC<LoadingTableProps> = ({ className = 'flex-el' }) => (
  <div className={className}>
    <LoadingTablePlaceholder />
  </div>
);
