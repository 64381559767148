"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { filter } from "lodash";
import { selectReportByIndex, selectReportIndexById } from "../selectors/reports.selector";
export const toggleIndicator = createAction(
  "toggle report indicator",
  (reportId, indicatorType) => ({ payload: { reportId, indicatorType } })
);
export const reducer = {
  [toggleIndicator.toString()]: (state, { reportId, indicatorType }) => {
    const reportIndex = selectReportIndexById(state, reportId);
    if (reportIndex === -1) {
      return state;
    }
    const report = selectReportByIndex(state, reportIndex);
    if (report.disabledProps.indexOf(indicatorType) >= 0) {
      report.disabledProps = filter(report.disabledProps, (item) => item !== indicatorType);
    } else {
      report.disabledProps.push(indicatorType);
    }
    return __spreadProps(__spreadValues({}, state), {
      reports: __spreadProps(__spreadValues({}, state.reports), {
        data: [
          ...state.reports.data.slice(0, reportIndex),
          report,
          ...state.reports.data.slice(reportIndex + 1)
        ]
      })
    });
  }
};
