"use strict";
import { DASHBOARD_EVENTS } from "../constants/export.type.constants";
export const exportWidgetInitialState = {
  downloadVerification: {
    type: null,
    ready: false,
    entriesCount: 0,
    loaded: false,
    loading: false,
    error: null
  }
};
export const exportWidgetTestState = {
  downloadVerification: {
    type: DASHBOARD_EVENTS,
    ready: true,
    entriesCount: 500,
    loaded: true,
    loading: false,
    error: null
  }
};
