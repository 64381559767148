import { connect } from 'react-redux';
import Columns from './Columns';
import * as selectors from '../../../redux/selectors/maintenance.selector';
import {
  COLUMN_PRIORITIZED,
  COLUMN_IN_PROGRESS,
  COLUMN_INBOX,
} from '../../../redux/constants/column.type.constants';
import { createActivity } from '../../../redux/modules/maintenance.columns.activity.create.module';
import { moveActivity } from '../../../redux/modules/maintenance.columns.activity.move.module';
import messages from '../../../messages/MaintenanceColumnHeader.messages';

function mapStateToProps(state) {
  const prioritizedEvents = selectors.getFilteredPrioritizedEvents(state);
  const inProgressEvents = selectors.getFilteredInProgressEvents(state);
  const inboxEvents = selectors.getFilteredInboxEvents(state);

  return {
    data: {
      orderedColumnIds: [COLUMN_IN_PROGRESS, COLUMN_PRIORITIZED, COLUMN_INBOX],
      columnMap: {
        [COLUMN_IN_PROGRESS]: {
          title: COLUMN_IN_PROGRESS,
          translatedTitle: messages.inProgress,
          columnId: COLUMN_IN_PROGRESS,
          items: inProgressEvents.sort((a, b) => a.position - b.position),
          isDropAllowed: true,
          cardsCount: inProgressEvents.length,
        },
        [COLUMN_PRIORITIZED]: {
          title: COLUMN_PRIORITIZED,
          translatedTitle: messages.planned,
          columnId: COLUMN_PRIORITIZED,
          items: prioritizedEvents.sort((a, b) => a.position - b.position),
          isDropAllowed: true,
          cardsCount: prioritizedEvents.length,
        },
        [COLUMN_INBOX]: {
          title: COLUMN_INBOX,
          translatedTitle: messages.issues,
          columnId: COLUMN_INBOX,
          items: inboxEvents,
          isDropAllowed: false,
          cardsCount: selectors.inboxEventsAmount(state),
          moreAvailable: selectors.moreEventsAvailable(state),
        },
      },
    },
  };
}

const mapDispatchToProps = {
  createActivity,
  moveActivity,
};

const ColumnContainer = connect(mapStateToProps, mapDispatchToProps)(Columns);
export default ColumnContainer;
