import classnames from 'classnames';
import React, { ChangeEvent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Todo, TodoFunction } from '../../../../../../common/types/common';
import commonMessages from '../../../../messages/common.messages';
import { InputError } from '../../index';

const options = [
  { label: commonMessages.yes, value: true },
  { label: commonMessages.no, value: false },
];

interface NewProps {
  translateOptionsLabels?: boolean;
  isError?: boolean;
  disabled?: boolean;
  errorMessage?: string;

  value: boolean;
  inputName: string;
  onChange: TodoFunction;
  required: boolean;
}

/**
 * Redefine the radio element. The previous one works as a wrapper to this one.
 */
export const NewRadio: React.FC<NewProps> = ({
  translateOptionsLabels = false,
  isError = false,
  value,
  disabled = false,
  inputName,
  onChange,
  errorMessage = '',
  required = false,
}) => {
  const { formatMessage } = useIntl();

  const handleChange = (e) => {
    onChange(e?.target.value === 'true');
  };

  const finalOptions = translateOptionsLabels
    ? options.map((item) => ({
        ...item,
        label: formatMessage(item.label),
      }))
    : options;

  const className = classnames({
    error: isError,
  });

  return (
    <div className={className}>
      <div className='radio-items'>
        {finalOptions.map((item) => (
          <div className='radio-item' key={item.value}>
            <label className='radio radio-round'>
              <input
                onChange={handleChange}
                type='radio'
                value={item.value}
                name={inputName}
                disabled={disabled}
                checked={item.value === value}
                required={required}
              />
              <span>{item.label}</span>
            </label>
          </div>
        ))}
      </div>
      <div className='error-line'>
        <InputError error={errorMessage} />
      </div>
    </div>
  );
};

interface Props {
  input: Todo;
  meta: Todo;

  translateOptionsLabels?: boolean;
  disabled?: boolean;
}

const normalize = (value: boolean | string) => {
  if (typeof value === 'boolean') return value;
  if (value.toLowerCase() === 'true') return true;
  return false;
};

export const Radio: React.FC<Props> = ({
  input,
  meta,
  translateOptionsLabels = false,
  disabled = false,
}) => {
  const onChange = useCallback(
    (event: string | boolean | ChangeEvent<HTMLInputElement>) => {
      if (typeof event === 'string' || typeof event === 'boolean') {
        input.onChange(normalize(event));
      } else {
        const newValue = normalize(event.target.value);
        input.onChange(newValue);
      }
    },
    [input],
  );

  const isError = meta.touched && meta.error;

  return (
    <NewRadio
      translateOptionsLabels={translateOptionsLabels}
      isError={isError}
      value={input.value}
      disabled={disabled}
      onChange={onChange}
      inputName={input.name}
      errorMessage={meta.error}
      required={input.required}
    />
  );
};

export default Radio;
