"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import {
  call,
  put,
  takeLatest,
  delay
} from "redux-saga/effects";
import * as api from "../../core/api";
export const searchUsersByOrganization = createAction(
  "search users by organization",
  (organizationId, query) => ({ payload: { organizationId, query } })
);
export const searchUsersByOrganizationSuccess = createAction(
  "search users by organization - success",
  (users) => ({ payload: users })
);
export const searchUsersByOrganizationFail = createAction(
  "search users by organization - fail",
  (error) => ({ payload: error })
);
export const reducer = {
  [searchUsersByOrganization.type]: (state, { organizationId, query }) => __spreadProps(__spreadValues({}, state), {
    usersSearch: __spreadProps(__spreadValues({}, state.usersSearch), {
      loading: true,
      loaded: false,
      error: "",
      query,
      organizationId
    })
  }),
  [searchUsersByOrganizationSuccess.type]: (state, users) => __spreadProps(__spreadValues({}, state), {
    usersSearch: __spreadProps(__spreadValues({}, state.usersSearch), {
      loading: false,
      loaded: true,
      users
    })
  }),
  [searchUsersByOrganizationFail.type]: (state, error) => __spreadProps(__spreadValues({}, state), {
    usersSearch: __spreadProps(__spreadValues({}, state.usersSearch), {
      loading: false,
      loaded: false,
      error
    })
  })
};
export function* searchUsersByOrganizationSaga({ payload: { query, organizationId } }) {
  yield delay(500);
  const { response, error } = yield call(api.users.searchByOrganization, organizationId, query);
  if (response) {
    yield put(searchUsersByOrganizationSuccess(response));
  } else {
    yield put(searchUsersByOrganizationFail(error));
  }
}
export function* watchForSearchUsersByOrganization() {
  yield takeLatest(searchUsersByOrganization, searchUsersByOrganizationSaga);
}
