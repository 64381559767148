"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
export const openModal = createAction(
  "open modal",
  (id, payload = {}, path = []) => ({ payload: { id, payload, path } })
);
export const closeModal = createAction("close modal");
export const setModalPath = createAction("set modal path", (id, path = []) => ({
  payload: { id, path }
}));
export const goBackModalPath = createAction("go back in modal path");
export const reducer = {
  [openModal.type]: (state, { id, payload, path }) => __spreadProps(__spreadValues({}, state), {
    modals: __spreadProps(__spreadValues({}, state.modals), {
      visible: __spreadProps(__spreadValues({}, state.modals.visible), {
        [id]: true
      }),
      payloads: __spreadProps(__spreadValues({}, state.modals.payloads), {
        [id]: payload
      }),
      paths: __spreadProps(__spreadValues({}, state.modals.paths), {
        [id]: path
      })
    })
  }),
  [closeModal.type]: (state, id) => __spreadProps(__spreadValues({}, state), {
    modals: __spreadProps(__spreadValues({}, state.modals), {
      visible: __spreadProps(__spreadValues({}, state.modals.visible), {
        [id]: false
      }),
      payloads: __spreadProps(__spreadValues({}, state.modals.payloads), {
        [id]: null
      }),
      paths: __spreadProps(__spreadValues({}, state.modals.paths), {
        [id]: null
      }),
      commandState: void 0
    })
  }),
  [setModalPath.type]: (state, { id, path }) => __spreadProps(__spreadValues({}, state), {
    modals: __spreadProps(__spreadValues({}, state.modals), {
      paths: __spreadProps(__spreadValues({}, state.modals.paths), {
        [id]: path
      })
    })
  }),
  [goBackModalPath.type]: (state, id) => {
    const newPath = [...state.modals.paths[id]];
    newPath.pop();
    return __spreadProps(__spreadValues({}, state), {
      modals: __spreadProps(__spreadValues({}, state.modals), {
        paths: __spreadProps(__spreadValues({}, state.modals.paths), {
          [id]: newPath
        })
      })
    });
  }
};
