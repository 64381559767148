"use strict";
import { DATE_FROM, DATE_TO } from "../../../../common/constants";
export const batchDetailsInitialState = {
  loaded: false,
  loading: false,
  error: null,
  processings: {
    list: [],
    filters: {
      [DATE_FROM]: null,
      [DATE_TO]: null
    }
  }
};
export const batchDetailsTestState = {
  loaded: true,
  loading: false,
  error: null,
  id: 12345,
  processings: {
    list: [
      {
        id: 1,
        name: "Processing #1"
      }
    ],
    filters: {
      [DATE_FROM]: "yesterday",
      [DATE_TO]: "today"
    }
  }
};
