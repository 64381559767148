"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { AppReducerMapBuilder } from "./util";
import { createAction } from "@reduxjs/toolkit";
import { getSessionById, getSignals } from "../../core/api/analyzer";
import { call, put, takeLatest } from "redux-saga/effects";
import { LOADING, succeeded, failed, IDLE } from "../../schema/fetched";
import * as norm from "../../schema/normalized";
import { INITIAL_SETTINGS, INITIAL_TOOL } from "../../schema/type/panelTool";
import { INITIAL_RANGE_FROM, MIN_RANGE_DELTA_MS } from "../../../../common/constants/analyzer";
import { calculatePanelTime } from "../../components/helper/panelTime";
import { createPanelInitAtom } from "../../atom/panelHelper";
export const fetchAnalyzerSession = createAction("fetch session", (sessionId) => ({
  payload: sessionId
}));
export const fetchAnalyzerSessionSuccess = createAction(
  "fetch session - success",
  (id, sessionData) => ({
    payload: { id, sessionData }
  })
);
export const fetchAnalyzerSessionFailure = createAction("fetch session - fail", (error) => ({
  payload: error
}));
export const fetchAnalyzerSessionSignalsSuccess = createAction(
  "fetch session signals success",
  (data) => ({
    payload: { signals: data }
  })
);
export const resetAnalyzerSession = createAction("reset analyzer session");
export const reducer = AppReducerMapBuilder.new().add(fetchAnalyzerSession, (state, sessionId) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    sessions: norm.upsert(state.analyzer.sessions, sessionId, LOADING)
  })
})).add(fetchAnalyzerSessionSuccess, (state, { id, sessionData }) => {
  const { session, signals, datatron } = sessionData;
  const _a = session, { panels: panelSignals } = _a, sessionAttributes = __objRest(_a, ["panels"]);
  const otherSessionAttributes = __spreadValues(__spreadValues({}, sessionAttributes), datatron);
  let normalizedPanels = norm.initial();
  let normalizedPanelSignals = norm.initial();
  const settingsInit = INITIAL_SETTINGS;
  const toolInit = INITIAL_TOOL;
  panelSignals.map((panel) => {
    const _a2 = panel, { panelSignals: panelSignalsAttr } = _a2, otherPanelAttributes = __objRest(_a2, ["panelSignals"]);
    const rangeFrom = panel.rangeFrom ? new Date(panel.rangeFrom) : INITIAL_RANGE_FROM;
    const rangeDeltaMs = panel.rangeDeltaMs ? panel.rangeDeltaMs : MIN_RANGE_DELTA_MS;
    const timeInit = calculatePanelTime(rangeFrom, rangeDeltaMs);
    normalizedPanels = norm.upsert(
      normalizedPanels,
      panel.id,
      succeeded(__spreadProps(__spreadValues({}, otherPanelAttributes), {
        settings: settingsInit,
        tool: toolInit,
        time: timeInit
      }))
    );
    createPanelInitAtom(panel.id, toolInit, timeInit);
    panelSignalsAttr.map((signal) => {
      normalizedPanelSignals = norm.upsert(
        normalizedPanelSignals,
        signal.id,
        succeeded(__spreadProps(__spreadValues({}, signal), {
          signal: signals[signal.datapointUuid],
          addons: []
        }))
      );
    });
  });
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      sessions: norm.upsert(
        state.analyzer.sessions,
        id,
        succeeded(__spreadValues({}, otherSessionAttributes))
      ),
      panels: normalizedPanels,
      panelSignals: normalizedPanelSignals
    })
  });
}).add(fetchAnalyzerSessionFailure, (state, { id, error }) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    sessions: norm.upsert(state.analyzer.sessions, id, failed(error))
  })
})).add(fetchAnalyzerSessionSignalsSuccess, (state, { signals }) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    signals: succeeded(signals.map((signal) => __spreadProps(__spreadValues({}, signal), { label: signal.name })))
  })
})).add(resetAnalyzerSession, (state) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    panels: norm.initial(),
    panelSignals: norm.initial(),
    signals: IDLE
  })
})).build();
function* fetchAnalyzerSessionSaga({ payload: sessionId }) {
  try {
    const response = yield call(getSessionById, sessionId);
    if (response.type === "generalError") {
      yield put(fetchAnalyzerSessionFailure({ id: sessionId, error: response.error }));
      return;
    }
    if (response.type === "parseError") {
      yield put(fetchAnalyzerSessionFailure({ id: sessionId, error: response.error.message }));
      return;
    }
    if (response.status == 200 && response.data) {
      yield put(fetchAnalyzerSessionSuccess(sessionId, response.data));
    }
    const signalResponse = yield call(
      getSignals,
      response.data.session.datatronUuid,
      response.data.session.devicesUuids
    );
    if (signalResponse.status == 200 && signalResponse.data) {
      yield put(fetchAnalyzerSessionSignalsSuccess(signalResponse.data));
    }
  } catch (Err) {
    yield put(fetchAnalyzerSessionFailure({ id: sessionId, error: Err }));
  }
}
export function* watchFetchAnalyzerSession() {
  yield takeLatest(fetchAnalyzerSession, fetchAnalyzerSessionSaga);
}
