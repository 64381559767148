import React from 'react';

import { useSelector } from 'react-redux';
import { DATATRONS_PERMISSIONS } from '../../../../../../common/constants/permissions';
import {
  areDevicesLoaded,
  getDevices,
  areDevicesLoading,
} from '../../../../redux/selectors/datatron.selector';
import { Loader } from '../../../../components/_tools';
import PermissionedComponent from '../../../common/permissions/PermissionedComponent.container';
import DataAddButtonTopBar from './addDeviceButton/DataAddButtonTopBar';
import Device from './device/Device';
import NoDevices from './NoDevices';

export const Data = ({ permissions }) => {
  const devices = useSelector(getDevices);
  const devicesLoaded = useSelector(areDevicesLoaded);
  const devicesLoading = useSelector(areDevicesLoading);
  // const newDataPointFormDeviceId = useSelector(getNewDataPointFormDeviceId);

  const canCreateDatatronDevices: boolean = permissions.canCreateDatatronDevices();

  return (
    <div>
      {canCreateDatatronDevices && <DataAddButtonTopBar />}
      {devicesLoaded && !!devices.length && (
        <div className='inventory-tab--in related'>
          {devices.map((item) => (
            <Device device={item} key={item.id} />
          ))}
        </div>
      )}
      {devicesLoading && <Loader show fullPage={true} />}
      {devicesLoaded && !devices.length && (
        <div className='inventory-tab--in'>
          <NoDevices canCreateDatatronDevices={canCreateDatatronDevices} />
        </div>
      )}
    </div>
  );
};

export default PermissionedComponent(DATATRONS_PERMISSIONS)(Data);
