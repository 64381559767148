import React, { memo, useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { Item } from '../util/constants';
import { cardGap } from '../util/constants';
import InboxEventContainer from './inbox/InboxEvent.container';
import ActivitiesContainer from './activity/Activity.container';
import {
  attachClosestEdge,
  Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

type DraggableState = 'idle' | 'generate-preview' | 'dragging';

export const Card = memo(function Card({
  item,
  isDropAllowed,
  columnTitle,
}: {
  item: Item;
  isDropAllowed: boolean;
  columnTitle: string;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { id } = item;
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [state, setState] = useState<DraggableState>('idle');

  useEffect(() => {
    invariant(ref.current);
    return combine(
      draggable({
        element: ref.current,
        getInitialData: () => ({ type: 'card', id }),
        onGenerateDragPreview: () => setState('generate-preview'),
        onDragStart: () => setState('dragging'),
        onDrop: () => setState('idle'),
      }),
      dropTargetForElements({
        element: ref.current,
        canDrop: (args) => args.source.data.type === 'card',
        getData: ({ input, element }) => {
          const data = { type: 'card', id: id };

          return attachClosestEdge(data, {
            input,
            element,
            allowedEdges: ['top', 'bottom'],
          });
        },
        onDragEnter: (args) => {
          if (args.source.data.id !== id) {
            setClosestEdge(extractClosestEdge(args.self.data));
          }
        },
        onDrag: (args) => {
          if (args.source.data.id !== id) {
            setClosestEdge(extractClosestEdge(args.self.data));
          }
        },
        onDragLeave: () => setClosestEdge(null),
        onDrop: () => setClosestEdge(null),
      }),
    );
  }, [id]);

  return (
    <div ref={ref}>
      <div className={`cardListStyles ${state === 'dragging' ? 'dragging' : ''} `}>
        {columnTitle == 'inbox' && <InboxEventContainer event={item} />}
        {columnTitle !== 'inbox' && (
          <div className={` ${item.indicate ? 'blinking' : ''} `}>
            <ActivitiesContainer activity={item} />
          </div>
        )}
        {isDropAllowed && closestEdge && (
          <DropIndicator edge={closestEdge} gap={`${cardGap}px`}></DropIndicator>
        )}
      </div>
    </div>
  );
});
