"use strict";
import { defineMessages } from "react-intl";
export default defineMessages({
  details: {
    id: "aiApp.config.details",
    defaultMessage: "Ai Apps details"
  },
  maxCycleTime: {
    id: "aiApp.config.maxCycleTime",
    defaultMessage: "Max Cycle Time"
  },
  minCycleTime: {
    id: "aiApp.config.minCycleTime",
    defaultMessage: "Min Cycle Time"
  },
  typeUUID: {
    id: "aiApp.config.typeUUID",
    defaultMessage: "Type variant UUID"
  },
  cycleDetection: {
    id: "aiApp.config.cycleDetection",
    defaultMessage: "Cycle Detection"
  },
  addCycleDetection: {
    id: "aiApps.config.add_cycle_detection",
    defaultMessage: "Cycle Detection"
  },
  addAiApp: {
    id: "aiApps.config.add_ai_app",
    defaultMessage: "Add AI App"
  },
  syncAnomaly: {
    id: "aiApps.config.sync_anomaly",
    defaultMessage: "Sync Anomaly"
  },
  anomalyModel: {
    id: "aiApp.config.model",
    defaultMessage: "Model"
  },
  addAnomalyModel: {
    id: "aiApp.config.add_model",
    defaultMessage: "Add Model"
  },
  anomalyDetection: {
    id: "aiApp.config.anomalyDetection",
    defaultMessage: "Anomaly Detection"
  },
  addAnomalyDetection: {
    id: "aiApps.config.add_anomaly_detection",
    defaultMessage: "Add Anomaly Detection"
  },
  cycleDetectionId: {
    id: "aiApp.config.cycleDetectionId",
    defaultMessage: "Cycle Detection ID"
  },
  candidate: {
    id: "aiApp.config.candidate",
    defaultMessage: "Candidate"
  },
  candidates: {
    id: "aiApp.config.candidates",
    defaultMessage: "Candidates"
  },
  numEpochs: {
    id: "aiApp.config.numEpochs",
    defaultMessage: "Number of Epochs"
  },
  batchSize: {
    id: "aiApp.config.batchSize",
    defaultMessage: "Batch Size"
  },
  typeValue: {
    id: "aiApp.config.typeValue",
    defaultMessage: "Type Value"
  }
});
