"use strict";
export const newMachineTestState = {
  creating: false,
  error: "",
  tab: "equipment",
  sensors: [],
  expandedSensors: {},
  isLocationModalOpen: true
};
export const newMachineInitialState = {
  creating: false,
  error: "",
  tab: "equipment",
  sensors: [],
  expandedSensors: {},
  isLocationModalOpen: false
};
