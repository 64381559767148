"use strict";
import { isNumber } from "../../../../common/validation/helpers";
export const getUsers = (state) => state.admin.users.list;
export const getPagination = (state) => state.admin.users.pagination;
export const getTotalCount = (state) => {
  const pagination = getPagination(state);
  if (pagination && isNumber(pagination.total))
    return pagination.total;
  return null;
};
export const areUsersLoaded = (state) => state.admin.users.loaded;
export const getUser = (state) => state.admin.users.selected.item;
export const isUserLoaded = (state) => state.admin.users.selected.loaded;
export const isUserLoading = (state) => state.admin.users.selected.loading;
export const getFilters = (state) => state.admin.users.filters;
export const getSort = (state) => getFilters(state).sort;
export const getNameFilterValue = (state) => getFilters(state).name;
export const getEmailFilterValue = (state) => getFilters(state).email;
export const getFiltersQuery = (state) => {
  const query = {
    name: getNameFilterValue(state),
    email: getEmailFilterValue(state)
  };
  const sort = getSort(state);
  if (sort.option && sort.way) {
    query[sort.option] = sort.way;
  }
  return query;
};
export const getTab = (state) => state.admin.users.selected.tab;
