import React from 'react';
import { Tooltip } from '../../../../components/_tools/tooltip/TooltipV2';

interface ExtraButtonsItemProps {
  title: string;
  onClick: () => void;
  color?: 'green' | 'primary' | 'warning' | 'danger';
  icon?: string;
  isActive?: boolean;
  showTooltip?: boolean;
  description?: string;
}

export const ExtraButtonsItem: React.FC<ExtraButtonsItemProps> = ({
  title,
  onClick,
  color = 'green',
  icon = 'fa fa-plus',
  isActive = true,
  showTooltip = false,
  description,
}) => (
  <>
    {!showTooltip && (
      <button
        className={`button extra-button ${color} mr-1`}
        onClick={onClick}
        disabled={!isActive}
      >
        <span className={icon} />
        {title}
      </button>
    )}
    {showTooltip && (
      <Tooltip position='top' data={{ [title]: description as string }}>
        <button
          className={`button extra-button ${color} mr-1`}
          onClick={onClick}
          disabled={!isActive}
        >
          <span className={icon} />
        </button>
      </Tooltip>
    )}
  </>
);

export default ExtraButtonsItem;
