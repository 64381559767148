"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import {
  REPORTS_MANAGER
} from "../constants/permissions";
class ReportsPermissions {
  constructor(roles) {
    __publicField(this, "isReportsManager");
    this.isReportsManager = roles.indexOf(REPORTS_MANAGER) !== -1;
  }
  canCloneReports() {
    return this.isReportsManager;
  }
  canCreateReports() {
    return this.isReportsManager;
  }
  canUpdateReports() {
    return this.isReportsManager;
  }
  canDeleteReports() {
    return this.isReportsManager;
  }
  canManageReportsTabs() {
    return this.isReportsManager;
  }
  availableToAll() {
    return this.isReportsManager;
  }
}
export default ReportsPermissions;
