import { createAction } from 'redux-act';
import { put, takeEvery } from 'redux-saga/effects';

import { COLUMN_INBOX } from '../constants/column.type.constants';
import {
  resetMaintenanceEventsPagination,
  fetchMaintenanceEvents,
} from './maintenance.fetch.events.module';
import { getEventIndexById } from '../selectors/maintenance.selector';

export const updateEvent = createAction('update event', (eventId, updateData) => ({
  eventId,
  updateData,
}));

export const addFilter = createAction('add filter to the column', (stream, filter) => ({
  stream,
  filter,
}));

export const removeFilter = createAction('remove filter to the column', (stream, filter) => ({
  stream,
  filter,
}));

export const reducer = {
  [updateEvent]: (state, { eventId, updateData }) => {
    const index = getEventIndexById(state, eventId);

    if (index === -1) return state;

    const event = state.maintenance.events[index];

    return {
      ...state,
      maintenance: {
        ...state.maintenance,
        events: [
          ...state.maintenance.events.slice(0, index),
          {
            ...event,
            ...updateData,
          },
          ...state.maintenance.events.slice(index + 1),
        ],
      },
    };
  },
  [addFilter]: (state, { stream, filter }) => {
    const filters = state.maintenance.columns[stream];
    const exist = filters.indexOf(filter) !== -1;

    if (exist) {
      return state;
    }

    return {
      ...state,
      maintenance: {
        ...state.maintenance,
        columns: {
          ...state.maintenance.columns,
          [stream]: [...filters, filter],
        },
      },
    };
  },
  [removeFilter]: (state, { stream, filter }) => {
    const filters = state.maintenance.columns[stream];
    const index = filters.indexOf(filter);

    if (index === -1) {
      return state;
    }

    return {
      ...state,
      maintenance: {
        ...state.maintenance,
        columns: {
          ...state.maintenance.columns,
          [stream]: [...filters.slice(0, index), ...filters.slice(index + 1)],
        },
      },
    };
  },
};

export function* changeMaintenanceFilterSaga({ payload: { stream } }) {
  if (stream === COLUMN_INBOX) {
    yield put(resetMaintenanceEventsPagination());
    yield put(fetchMaintenanceEvents());
  }
}

export function* watchChangeMaintenanceFilter() {
  yield takeEvery(addFilter.getType(), changeMaintenanceFilterSaga);
  yield takeEvery(removeFilter.getType(), changeMaintenanceFilterSaga);
}
