"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import { findByName, getChildLocations } from "../../core/api/locations";
export const setLocationFilter = createAction(
  "set location filter",
  (location) => ({ payload: { location } })
);
export const setLocationFilterChildren = createAction(
  "set location filter children",
  (children) => ({ payload: { children } })
);
export const reducer = {
  [setLocationFilter.type]: (state, { location }) => __spreadProps(__spreadValues({}, state), {
    location: __spreadProps(__spreadValues({}, state.location), {
      filter: location
    })
  }),
  [setLocationFilterChildren.type]: (state, { children }) => __spreadProps(__spreadValues({}, state), {
    location: __spreadProps(__spreadValues({}, state.location), {
      filterChildren: children
    })
  })
};
export function* setLocationFilterSaga({ payload: { location } }) {
  var _a;
  if (location === "") {
    const response = yield call(getChildLocations, "", { per_page: 1e4 });
    if (response.success && "list" in response.data) {
      yield put(setLocationFilterChildren(response.data.list));
    }
    return;
  }
  const { response: nameResponse } = yield call(findByName, location);
  const parentLocationId = (_a = nameResponse == null ? void 0 : nameResponse.list[0]) == null ? void 0 : _a.id;
  if (parentLocationId) {
    const response = yield call(getChildLocations, parentLocationId, { per_page: 1e4 });
    if (response.success && "list" in response.data) {
      yield put(setLocationFilterChildren(response.data.list));
    }
  }
}
export function* watchSetLocationFilter() {
  yield takeEvery(setLocationFilter, setLocationFilterSaga);
}
