import React from 'react';
import { TodoFunction } from '../../../../../../../../../../../../common/types/common';

interface Props {
  onEditClick: TodoFunction;
  onArchiveClick: TodoFunction;
  onViewClick: TodoFunction;
  onIndexClick: TodoFunction;
  canUpdate: boolean;
  canDelete: boolean;
  isArrayType: boolean;
  dataPointId: string;
}

export const DataPointButtons: React.FC<Props> = ({
  onEditClick,
  onArchiveClick,
  onViewClick,
  onIndexClick,
  canUpdate,
  canDelete,
  dataPointId,
  isArrayType,
}) => (
  <div className='data-points-actions'>
    {canUpdate && isArrayType && (
      <button
        type='button'
        className='button light-grey-white'
        onClick={onIndexClick}
        id={dataPointId}
      >
        <span>[i]</span>
      </button>
    )}
    <button
      type='button'
      className='button light-grey-white'
      onClick={onViewClick}
      id={dataPointId}
    >
      <i className='icon-eye' />
    </button>
    {canUpdate && (
      <button
        type='button'
        className='button light-grey-white'
        onClick={onEditClick}
        id={dataPointId}
      >
        <i className='icon-edit' />
      </button>
    )}
    {canDelete && (
      <button
        type='button'
        className='button light-grey-white'
        onClick={onArchiveClick}
        id={dataPointId}
      >
        <i className='icon-archive' />
      </button>
    )}
  </div>
);

export default DataPointButtons;
