import React from 'react';
import PropTypes from 'prop-types';

import ActivityEventContainer from './ActivityEvent.container';
import { COLUMN_IN_PROGRESS } from '../../../../../redux/constants/column.type.constants';
import ResolveModalContainer from '../../resolveModal/ResolveModal.container';

export function Activities({
  activity,
  isResolveModalOpen,
  onResolveModalClose,
  onResolveModalOpen,
}) {
  return (
    <div>
      <ActivityEventContainer
        activity={activity}
        isInProgress={activity.state === COLUMN_IN_PROGRESS}
        resolve={onResolveModalOpen}
      />
      <ResolveModalContainer
        activityId={activity.id}
        isOpen={isResolveModalOpen}
        onClose={onResolveModalClose}
      />
    </div>
  );
}

Activities.propTypes = {
  activity: PropTypes.object.isRequired,
  isResolveModalOpen: PropTypes.bool.isRequired,
  onResolveModalClose: PropTypes.func.isRequired,
  onResolveModalOpen: PropTypes.func.isRequired,
};

export default Activities;
