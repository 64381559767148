import React from 'react';
import ReactSelect from 'react-select';

type StylesProps = NonNullable<React.ComponentProps<typeof ReactSelect>['styles']>;

const control: StylesProps['control'] = (provided, state) => {
  const styles = {
    ...provided,
    borderColor: '#d4d7dc',
    borderRadius: '4px',
  };
  // TODO
  // &:hover {
  //   box-shadow: none;
  // }
  // TODO: this seems unnecessary
  if (state.isFocused) {
    styles.borderColor = '#00acb6';
  }
  return styles;
};

const option: StylesProps['option'] = (provided, state) => {
  const styles = { ...provided };
  if (state.isFocused) {
    styles.background = '#e8ebef';
  }
  if (state.isSelected) {
    styles.background = '#00acb6';
    styles.color = '#fff';
  }
  return styles;
};

export const customStyles: React.ComponentProps<typeof ReactSelect>['styles'] = {
  control,
  option,
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    maxHeight: 200,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 200,
    overflowY: 'auto',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: '0',
  }),
  placeholder: (provided) => ({ ...provided, paddingLeft: '5px' }),
  singleValue: (provided) => ({ ...provided, paddingLeft: '5px' }),
  input: (provided) => ({
    ...provided,
    gridTemplateColumns: '0 auto',
    height: '37px',
    padding: 0,
    margin: 0,
  }),
};
