import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDropzone } from 'react-dropzone';

import FileUploadPlaceholder from '../../fileUpload/FileUploadPlaceholder';
import Button from '../../button/Button';
import { NO_FILE } from '../../../../redux/constants/fileUpload.component.constants';
import InputError from '../../inputError/InputError';
import { Todo } from '../../../../../../common/types/common';

interface Props {
  input: Todo;
  meta: {
    error: string | Todo;
    touched?: boolean;
  };
  placeholder: string;
  mimeTypes: string;
  photoUrl?: string;
}

const PhotoFieldNew: React.FC<Props> = ({
  placeholder,
  mimeTypes = 'image/*',
  photoUrl = '',
  input: { value, onChange },
  meta,
}) => {
  const [showUploadPlaceholder, showUploadPlaceholderSet] = useState(
    !(value || photoUrl) || value === NO_FILE,
  );

  const handleDrop = useCallback(
    (files) => {
      if (files.length < 1) return;
      onChange(files[0]);
      showUploadPlaceholderSet(false);
    },
    [onChange],
  );

  const handleCancelClick = useCallback(
    (e) => {
      onChange(NO_FILE);
      showUploadPlaceholderSet(true);
      e.preventDefault();
    },
    [onChange],
  );

  const hasError = meta.touched && meta.error;

  const style = {
    height: '100%',
    width: '100%',
  };

  const { getRootProps, getInputProps, rootRef } = useDropzone({
    onDrop: handleDrop,
    accept: mimeTypes,
    multiple: false,
  });

  const handleUploadClick = useCallback(
    (e) => {
      e.preventDefault();
      rootRef.current?.click();
    },
    [rootRef],
  );

  const imageToShow = value && value instanceof Blob ? URL.createObjectURL(value) : photoUrl;

  return (
    <>
      <div className='card--position-sm'>
        <div className='image--wrap'>
          <div className='card--image' {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {showUploadPlaceholder && <FileUploadPlaceholder placeholder={placeholder} />}
            {!showUploadPlaceholder && (
              <img src={imageToShow} alt='Datatron Image' style={{ display: 'inline' }} />
            )}
          </div>
          {!showUploadPlaceholder && (
            <div className='image--actions'>
              <Button onClick={handleUploadClick}>
                <span className='icon icon-photo' />
                <FormattedMessage id='common.upload' defaultMessage='Upload' />
              </Button>
              <Button onClick={handleCancelClick}>
                <span className='icon icon-remove' />
                <FormattedMessage id='common.remove' defaultMessage='Remove' />
              </Button>
            </div>
          )}
        </div>
      </div>
      {hasError && (
        <div className='error-line'>
          <InputError error={meta.error} />
        </div>
      )}
    </>
  );
};

export default PhotoFieldNew;
