import React, { useEffect, useState } from 'react';
import { getDatatrons } from '../../../../../core/api/datatrons';
import { Todo } from '../../../../../../../common/types/common';
import Select from '../../../../_tools/formFields/select/Select';

/**
 * Component for selecting a datatron
 * @param props Component properties
 * @returns Select component for datatron
 */
export const DatatronSelect: React.FC<{
  input: Todo;
  meta: Todo;
  disabled?: boolean;
  onDatatronChange: (id: string) => void;
  [key: string]: any;
}> = ({ input, meta, disabled, onDatatronChange, ...props }) => {
  const [datatrons, setDatatrons] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDatatrons = async () => {
      try {
        const { response } = await getDatatrons();
        if (Array.isArray(response)) {
          setDatatrons(response);
        } else {
          console.error('Unexpected data format from getDatatrons API:', response);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching datatrons:', error);
        setIsLoading(false);
      }
    };

    fetchDatatrons();
  }, []);

  useEffect(() => {
    if (datatrons.length > 0 && input.value) {
      const selectedDatatron = datatrons.find((datatron) => datatron.deviceUUID === input.value);
      onDatatronChange(selectedDatatron.id);
    }
  }, [datatrons, props, input.value, onDatatronChange]);
  const handleChange = (selectedOption) => {
    input.onChange(selectedOption.value);
    onDatatronChange(selectedOption.id);
  };

  const transformDatatrons = (data) => {
    if (Array.isArray(data)) {
      return data.map((datatron) => ({
        value: datatron.deviceUUID,
        label: datatron.name,
        id: datatron.id,
      }));
    }
    return [];
  };

  return (
    <Select
      input={input}
      meta={meta}
      isDisabled={disabled}
      options={transformDatatrons(datatrons)}
      onChange={handleChange}
      isLoading={isLoading}
      {...props}
    />
  );
};
