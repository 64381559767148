import React, { PropsWithChildren } from 'react';
import * as RadixContextMenu from '@radix-ui/react-context-menu';
import { useIntl } from 'react-intl';
import { MenuItem } from './MenuItem';
import { MenuLabel } from './MenuLabel';
import * as helper from './helper';
import { PanelSettings } from '../../../schema/type/panel';
import analyzerMessages from '../../../messages/analyzer.messages';

export interface PanelContextMenuItemsProps {
  panelSettings: PanelSettings;
  setPanelSettings: (panelSettings: PanelSettings) => void;
}

export const PanelContextMenuItems: React.FC<PanelContextMenuItemsProps> = ({
  panelSettings,
  setPanelSettings,
}) => {
  const { formatMessage } = useIntl();
  const headerVisibilityLabel = panelSettings.showHeader
    ? formatMessage(analyzerMessages.hideHeader)
    : formatMessage(analyzerMessages.showHeader);
  const toggleHeaderVisibility = () =>
    setPanelSettings({
      ...panelSettings,
      showHeader: !panelSettings.showHeader,
    });

  const tooltipVisibilityLabel = panelSettings.showTooltips
    ? formatMessage(analyzerMessages.hideTooltips)
    : formatMessage(analyzerMessages.showTooltips);
  const toggleTooltipVisibility = () =>
    setPanelSettings({
      ...panelSettings,
      showTooltips: !panelSettings.showTooltips,
    });

  return (
    <>
      <MenuLabel>{formatMessage(analyzerMessages.panelOptions)}</MenuLabel>
      <MenuItem onSelect={toggleHeaderVisibility}>{headerVisibilityLabel}</MenuItem>
      <MenuItem onSelect={toggleTooltipVisibility}>{tooltipVisibilityLabel}</MenuItem>
    </>
  );
};

export const PanelContextMenu: React.FC<PropsWithChildren<PanelContextMenuItemsProps>> = ({
  panelSettings,
  setPanelSettings,
  children,
}) => (
  <RadixContextMenu.Root>
    <RadixContextMenu.Trigger>{children}</RadixContextMenu.Trigger>
    <RadixContextMenu.Portal>
      <RadixContextMenu.Content className={helper.contentClass}>
        <PanelContextMenuItems panelSettings={panelSettings} setPanelSettings={setPanelSettings} />
      </RadixContextMenu.Content>
    </RadixContextMenu.Portal>
  </RadixContextMenu.Root>
);
