"use strict";
import { defineMessages } from "react-intl";
export default defineMessages({
  black: {
    id: "colors.black",
    defaultMessage: "Black"
  },
  red: {
    id: "colors.red",
    defaultMessage: "Red"
  },
  green: {
    id: "colors.green",
    defaultMessage: "Green"
  },
  blue: {
    id: "colors.blue",
    defaultMessage: "Blue"
  },
  purple: {
    id: "colors.purple",
    defaultMessage: "Purple"
  },
  cyan: {
    id: "colors.cyan",
    defaultMessage: "Cyan"
  },
  pink: {
    id: "colors.pink",
    defaultMessage: "Pink"
  },
  gray: {
    id: "colors.gray",
    defaultMessage: "Gray"
  },
  orange: {
    id: "colors.orange",
    defaultMessage: "Orange"
  },
  savePanelDescription: {
    id: "analyzer.panel.save.description",
    defaultMessage: "Recent session panel updates."
  },
  editPanelDescription: {
    id: "analyzer.panel.edit",
    defaultMessage: "Session panel settings"
  },
  clonePanelDescription: {
    id: "analyzer.panel.clone.description",
    defaultMessage: "Session panel with all details."
  },
  deletePanelDescription: {
    id: "analyzer.panel.delete.description",
    defaultMessage: "Session panel with all details."
  },
  cursorTime: {
    id: "analyzer.cursor_time",
    defaultMessage: "Cursor Time"
  },
  cursorTimeDescription: {
    id: "analyzer.cursor_time.description",
    defaultMessage: "The time of the green vertical cursor within the panel. Use the left and right arrow keys to move between different time units. Use the up and down arrow keys to increment or decrement different time units."
  },
  startDate: {
    id: "analyzer.start_date",
    defaultMessage: "Start Date"
  },
  startDateDescription: {
    id: "analyzer.start_date.description",
    defaultMessage: "The start date of the session panel."
  },
  timeRange: {
    id: "analyzer.time_range",
    defaultMessage: "Time Range"
  },
  timeRangeDescription: {
    id: "analyzer.time_range.description",
    defaultMessage: "Adjust the new time range"
  },
  zoomIn: {
    id: "analyzer.zoom_in",
    defaultMessage: "Zoom in"
  },
  zoomOut: {
    id: "analyzer.zoom_out",
    defaultMessage: "Zoom out"
  },
  zoomInPanelRangeDescription: {
    id: "analyzer.panel.zoom_in.description",
    defaultMessage: "Reduce the range values."
  },
  zoomOutPanelRangeDescription: {
    id: "analyzer.panel.zoom_out.description",
    defaultMessage: "Enlarge the range values."
  },
  addSignal: {
    id: "analyzer.add_signal",
    defaultMessage: "Add Signal"
  },
  signalName: {
    id: "analyzer.signal_name",
    defaultMessage: "Signal Name"
  },
  panelOptions: {
    id: "analyzer.panel.panel_options",
    defaultMessage: "Panel Options"
  },
  hideHeader: {
    id: "analyzer.panel.hide_header",
    defaultMessage: "Hide Header"
  },
  showHeader: {
    id: "analyzer.panel.show_header",
    defaultMessage: "Show Header"
  },
  hideTooltips: {
    id: "analyzer.panel.hide_tooltips",
    defaultMessage: "Hide Tooltips"
  },
  showTooltips: {
    id: "analyzer.panel.show_tooltips",
    defaultMessage: "Show Tooltips"
  },
  signalOptions: {
    id: "analyzer.signal_options",
    defaultMessage: "Signal Options"
  },
  chartType: {
    id: "analyzer.chartType",
    defaultMessage: "Chart Type"
  },
  area: {
    id: "analyzer.chartType.area",
    defaultMessage: "Area"
  },
  line: {
    id: "analyzer.chartType.line",
    defaultMessage: "Line"
  },
  interpolationType: {
    id: "analyzer.interpolationType",
    defaultMessage: "Interpolation Type"
  },
  changeColor: {
    id: "analyzer.change_color",
    defaultMessage: "Change Color"
  },
  changeInterpolation: {
    id: "analyzer.change_interpolation",
    defaultMessage: "Change Interpolation"
  },
  anomaly: {
    id: "analyzer.anomaly",
    defaultMessage: "Anomaly"
  },
  session: {
    id: "analyzer.session",
    defaultMessage: "Session"
  },
  reference: {
    id: "analyzer.reference",
    defaultMessage: "Reference"
  },
  anomalyDetection: {
    id: "analyzer.anomaly_detection",
    defaultMessage: "Anomaly Detection"
  },
  anomalyStart: {
    id: "analyzer.anomaly_start",
    defaultMessage: "Anomaly Start"
  },
  addSession: {
    id: "analyzer.modals.add_session",
    defaultMessage: "Add Session"
  },
  editSession: {
    id: "analyzer.modals.edit_session",
    defaultMessage: "Edit Session"
  },
  addPanelTitle: {
    id: "analyzer.modals.add_new_panel.title",
    defaultMessage: "Add new panel"
  },
  editPanelTitle: {
    id: "analyzer.modals.edit_panel.title",
    defaultMessage: "Edit panel"
  },
  archiveSessionTitle: {
    id: "analyzer.modals.archive_session.title",
    defaultMessage: "Are you sure you want to archive this session?"
  },
  unarchiveSessionTitle: {
    id: "analyzer.modals.unarchive_session.title",
    defaultMessage: "Are you sure you want to unarchive this session?"
  },
  archiveAnomalyTitle: {
    id: "analyzer.modals.archive_anomaly.title",
    defaultMessage: "Are you sure you want to archive this anomaly?"
  },
  unarchiveAnomalyTitle: {
    id: "analyzer.modals.unarchive_anomaly.title",
    defaultMessage: "Are you sure you want to unarchive this anomaly?"
  },
  addNewPanelAnomalyTitle: {
    id: "analyzer.modals.add_new_panel_anomaly.title",
    defaultMessage: "Add new panel anomaly"
  },
  deleteSessionTitle: {
    id: "analyzer.modals.delete_session.title",
    defaultMessage: "Are you sure you want to delete this session?"
  },
  deleteSessionPanelTitle: {
    id: "analyzer.modals.delete_session_panel.title",
    defaultMessage: "Delete session panel"
  },
  deleteSessionPanelDescription: {
    id: "analyzer.modals.delete_session_panel.description",
    defaultMessage: "This will delete all data from the panel. Are you sure?"
  },
  deleteAnomalyPanelTitle: {
    id: "analyzer.modals.delete_anomaly_panel.title",
    defaultMessage: "Delete anomaly panel"
  },
  deleteAnomalyPanelDescription: {
    id: "analyzer.modals.delete_anomaly_panel.description",
    defaultMessage: "This will delete all data from the panel. Are you sure?"
  },
  name: {
    id: "analyzer.modals.name",
    defaultMessage: "Name"
  },
  datatronUuid: {
    id: "analyzer.modals.datatron_uuid",
    defaultMessage: "Datatron UUID"
  },
  datatronName: {
    id: "analyzer.modals.datatron_name",
    defaultMessage: "Datatron Name"
  },
  anomalyFrom: {
    id: "analyzer.modals.anomaly_from",
    defaultMessage: "Anomaly From"
  },
  referenceFrom: {
    id: "analyzer.modals.reference_from",
    defaultMessage: "Reference From"
  },
  rangeDeltaMs: {
    id: "analyzer.modals.range_delta_ms",
    defaultMessage: "Range Delta (ms)"
  },
  archived: {
    id: "analyzer.modals.archived",
    defaultMessage: "Archived"
  },
  comment: {
    id: "analyzer.modals.comment",
    defaultMessage: "Comment"
  },
  zoom: {
    id: "analyzer.zoom",
    defaultMessage: "Zoom"
  },
  cursor: {
    id: "analyzer.cursor",
    defaultMessage: "Cursor"
  },
  bitRuler: {
    id: "analyzer.bit_ruler",
    defaultMessage: "Bit Ruler"
  },
  bitRulerDescription: {
    id: "analyzer.bit_ruler.description",
    defaultMessage: "Shows the time difference between the two edges of a digital signal"
  },
  cursorDescription: {
    id: "analyzer.cursor.description",
    defaultMessage: "Sets the relative position of the vertical cursor within the current time range"
  },
  zoomDescription: {
    id: "analyzer.zoom.description",
    defaultMessage: "Zoom into the current time range by dragging the mouse"
  },
  info: {
    id: "analyzer.info",
    defaultMessage: "Info"
  },
  noValueAvailable: {
    id: "analyzer.no_value_available",
    defaultMessage: "No value available for this time."
  },
  valueNotLoaded: {
    id: "analyzer.value_not_loaded",
    defaultMessage: "The value is not loaded yet."
  },
  exportPanelDescription: {
    id: "analyzer.export_panel_description",
    defaultMessage: "Export panel to excel file"
  },
  classification: {
    id: "analyzer.classification",
    defaultMessage: "Classification"
  },
  noClassification: {
    id: "analyzer.no_classification",
    defaultMessage: "No classification"
  },
  ignoreClassification: {
    id: "analyzer.ignore_classification",
    defaultMessage: "Ignore classification"
  },
  validClassification: {
    id: "analyzer.valid_classification",
    defaultMessage: "Valid classification"
  }
});
