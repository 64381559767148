"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "redux-act";
import { put, call, takeEvery, select } from "redux-saga/effects";
import { getFormFields } from "../selectors/aiApp.form.selector";
import { startSubmit, stopSubmit, touch } from "redux-form";
import * as api from "../../core/api";
import * as form from "../constants/aiApp.form.constants";
import { closeModal } from "./modals.module";
import { AI_APP_ADD } from "../constants/modals.constants";
import { fetchAiApps } from "./aiApps.fetch.module";
import { sendNotification } from "./notifications.module";
import notificationMessage from "../../messages/notification.message";
import { NOTIFICATION_EVENT } from "../../core/notifications";
export const createAIApp = createAction("create AIApp");
export const createAIAppSuccess = createAction("create AIApp - success", (item) => item);
export const createAIAppFail = createAction("create AIApp - fail", (error) => error);
export function* touchAllFieldsSaga() {
  yield put(touch(form.AI_APP_FORM_NAME, ...form.AI_APP_PROPS));
}
export function* handleErrors(errors) {
  yield put(createAIAppFail(errors));
  yield put(stopSubmit(form.AI_APP_FORM_NAME, errors));
}
export function* createAiAppsSaga() {
  const state = yield select();
  yield call(touchAllFieldsSaga);
  const fields = yield call(getFormFields, state);
  yield put(startSubmit(form.AI_APP_FORM_NAME));
  const { response, error } = yield call(api.aiApps.create, fields);
  if (response) {
    yield put(createAIAppSuccess(response));
    yield put(fetchAiApps());
    yield put(stopSubmit(form.AI_APP_FORM_NAME));
    yield put(closeModal(AI_APP_ADD));
    yield put(
      sendNotification(
        notificationMessage.item_created_title,
        __spreadProps(__spreadValues({}, notificationMessage.item_created_description), {
          values: {
            itemName: fields.appName
          }
        }),
        NOTIFICATION_EVENT
      )
    );
  } else {
    yield handleErrors(error);
  }
}
export function* watchCreateAIApp() {
  yield takeEvery(createAIApp.getType(), createAiAppsSaga);
}
