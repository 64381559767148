"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import * as dataPointFormConstants from "../../redux/constants/dataPoint.form.constants";
export const ENABLED_FIELD = {
  _key: dataPointFormConstants.ENABLED_FIELD,
  type: "boolean"
};
export const UUID_FIELD = {
  _key: dataPointFormConstants.UUID_FIELD,
  type: "string",
  hidden: true
};
export const LABEL_FIELD = {
  _key: dataPointFormConstants.LABEL_FIELD,
  type: "string"
};
export const UNIT_FIELD = {
  _key: dataPointFormConstants.UNIT_FIELD,
  type: "string"
};
export const SCALING_FACTOR_FIELD = {
  _key: dataPointFormConstants.SCALING_FACTOR_FIELD,
  default: 1,
  type: "number"
};
export const SCALING_OFFSET_FIELD = {
  _key: dataPointFormConstants.SCALING_OFFSET_FIELD,
  type: "number"
};
export const DATA_POINT_COMMON_FIELDS = [
  ENABLED_FIELD,
  UUID_FIELD,
  LABEL_FIELD,
  UNIT_FIELD,
  SCALING_FACTOR_FIELD,
  SCALING_OFFSET_FIELD
].map((item) => __spreadProps(__spreadValues({}, item), {
  _isCommon: true
}));
export const DATA_POINT_ARRAY_COMMON_FIELDS = [LABEL_FIELD];
export const DATA_POINT_INDEX_COMMON_FIELDS = [
  ENABLED_FIELD,
  __spreadProps(__spreadValues({}, LABEL_FIELD), { readOnly: true }),
  UNIT_FIELD,
  SCALING_FACTOR_FIELD,
  SCALING_OFFSET_FIELD
];
export const dataPointCommonSchema = {
  type: "object",
  properties: {
    [ENABLED_FIELD._key]: { type: "boolean" },
    [UUID_FIELD._key]: { type: "string" },
    [LABEL_FIELD._key]: { type: "string" },
    [UNIT_FIELD._key]: { type: "string" },
    [SCALING_FACTOR_FIELD._key]: { type: "number" },
    [SCALING_OFFSET_FIELD._key]: { type: "number" }
  },
  required: [
    ENABLED_FIELD._key,
    LABEL_FIELD._key,
    SCALING_FACTOR_FIELD._key,
    SCALING_OFFSET_FIELD._key
  ]
};
export const dataPointArrayCommonSchema = {
  type: "object",
  properties: {
    [LABEL_FIELD._key]: { type: "string" }
  },
  required: [LABEL_FIELD._key]
};
export const dataPointIndexCommonSchema = {
  type: "object",
  properties: {
    [ENABLED_FIELD._key]: { type: "boolean" },
    [LABEL_FIELD._key]: { type: "string" },
    [UNIT_FIELD._key]: { type: "string" },
    [SCALING_FACTOR_FIELD._key]: { type: "number" },
    [SCALING_OFFSET_FIELD._key]: { type: "number" }
  },
  required: [
    ENABLED_FIELD._key,
    LABEL_FIELD._key,
    SCALING_FACTOR_FIELD._key,
    SCALING_OFFSET_FIELD._key
  ]
};
