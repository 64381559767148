"use strict";
export const CURSOR_DATE_TIME_UNITS = ["h", "min", "s", "dd", "mm", "yyyy"];
export const useFormattedValue = (value, unit) => {
  switch (unit) {
    case "yyyy":
      return value.toString().padStart(4, "0");
    case "mm":
    case "dd":
    case "h":
    case "min":
    case "s":
      return value.toString().padStart(2, "0");
    default:
      return value.toString();
  }
};
