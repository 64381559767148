"use strict";
import { connect } from "react-redux";
import Maintenance from "./Maintenance";
import * as selector from "../../redux/selectors/maintenance.selector";
import { isModalOpen } from "../../redux/selectors/modals.selector";
import { MAINTENANCE_ASSIGN, MAINTENANCE_ERROR } from "../../redux/constants/modals.constants";
import { openModal } from "../../redux/modules/modals.module";
export const mapStateToProps = (state) => ({
  isAssignModalOpen: isModalOpen(state, MAINTENANCE_ASSIGN),
  isErrorModalOpen: isModalOpen(state, MAINTENANCE_ERROR),
  isLoading: selector.isLoading(state),
  errorMessage: selector.hasErrorHappened(state)
});
export const mapDispatchToProps = (dispatch) => ({
  showError: (errorMessage) => {
    dispatch(openModal(MAINTENANCE_ERROR, { errorMessage }));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
