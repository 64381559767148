import React from 'react';

import { useSignalData } from '../signal/UseSignalDataHook';
import { PrintChart } from './PrintChart';
import { LoadingChart } from '../signalChart/LoadingChart';
import { ErrorBox } from '../signalChart/ErrorBox';
import { isBooleanSignalData, isNumberSignalData } from '../../../schema/signalData';
import { PanelSignal } from '../../../schema/type/panelSignal';
import notificationMessage from '../../../messages/notification.message';

interface Props {
  panelSignal: PanelSignal;
  panelId: number;
}

export const PrintSignalChart: React.FC<Props> = ({ panelSignal, panelId }) => {
  const { signal, ...panelSignalProps } = panelSignal;

  const { signalData, isLoading, error, fromUnixNano, toUnixNano } = useSignalData(
    panelId,
    signal.uuid,
  );

  return (
    <div>
      {isLoading ? (
        <LoadingChart />
      ) : error || signalData === null ? (
        <ErrorBox />
      ) : !isBooleanSignalData(signalData) && !isNumberSignalData(signalData) ? (
        <ErrorBox messageId={notificationMessage.unsupported_signal.id} />
      ) : (
        <PrintChart
          data={signalData}
          fromUnixNano={fromUnixNano}
          toUnixNano={toUnixNano}
          color={panelSignalProps.color}
          height={panelSignalProps.height}
          chartType={panelSignalProps.chartType}
          interpolationType={panelSignalProps.interpolationType}
        />
      )}
    </div>
  );
};
