"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { forEach } from "lodash";
import moment from "moment";
import { calculateOee, convertKPIs } from "./indicators";
import * as reportTypes from "../../../../common/enums/reportTypes";
import * as periodTypes from "../../../../common/enums/periodTypes";
import * as datesModes from "../../../../common/enums/dates.modes";
import * as relativeUnits from "../../../../common/constants/relative.units";
import * as chartTypes from "../../redux/constants/chart.type.constants";
import * as oeeHelper from "./oee";
export const isIndicatorDisabled = (type, disabledProps) => disabledProps.indexOf(type) >= 0;
export const getShift = (report) => report.data && report.data.shifts ? report.data.shifts[0] : null;
export const getIndicators = (report) => {
  switch (report.type) {
    case reportTypes.events: {
      const counts = report.data.total;
      const child = [];
      let totalAmount = 0;
      forEach(counts, (value) => {
        totalAmount += value;
      });
      forEach(counts, (value, category) => {
        child.push({
          type: category,
          isDisabled: isIndicatorDisabled(category, report.disabledProps),
          label: `events.${category}`,
          value: value > 0 ? value / totalAmount : 0,
          displayValue: value,
          isAmount: true
        });
      });
      const main = {
        label: "indicators.all",
        value: totalAmount > 0 ? 1 : 0,
        displayValue: totalAmount,
        isAmount: true
      };
      return {
        main,
        child
      };
    }
    case reportTypes.kpi: {
      const indicators = convertKPIs(report.data.total);
      const main = calculateOee(
        report.data.total[periodTypes.availability],
        report.data.total[periodTypes.performance],
        report.data.total[periodTypes.quality]
      );
      return {
        main,
        child: indicators
      };
    }
    case reportTypes.maintenance: {
      let total = 0;
      const amounts = periodTypes.maintenanceTypes.reduce((final, type) => {
        let thisTypeValue;
        if (report.data.total && report.data.total[type]) {
          thisTypeValue = report.data.total[type];
        } else {
          thisTypeValue = 0;
        }
        total += thisTypeValue;
        return __spreadProps(__spreadValues({}, final), {
          [type]: thisTypeValue
        });
      }, {});
      amounts.total = total;
      return {
        main: {
          label: "indicators.all",
          value: amounts.total,
          isAmount: true,
          noAnimation: true
        },
        child: [
          {
            type: periodTypes.maintenancePlanned,
            isDisabled: isIndicatorDisabled(periodTypes.maintenancePlanned, report.disabledProps),
            label: `maintenance.${periodTypes.maintenancePlanned}`,
            value: amounts.planned > 0 ? amounts.planned / amounts.total : 0,
            displayValue: amounts.planned > 0 ? amounts.planned : 0,
            isAmount: true,
            noAnimation: true
          },
          {
            type: periodTypes.maintenanceUnplanned,
            isDisabled: isIndicatorDisabled(periodTypes.maintenanceUnplanned, report.disabledProps),
            label: `maintenance.${periodTypes.maintenanceUnplanned}`,
            value: amounts.unplanned > 0 ? amounts.unplanned / amounts.total : 0,
            displayValue: amounts.unplanned > 0 ? amounts.unplanned : 0,
            isAmount: true,
            noAnimation: true
          }
        ]
      };
    }
    default: {
      return {
        main: null,
        child: []
      };
    }
  }
};
const periodTypeToLabel = {
  [periodTypes.maintenancePlanned]: {
    label: `maintenance.${periodTypes.maintenancePlanned}`
  },
  [periodTypes.maintenanceUnplanned]: {
    label: `maintenance.${periodTypes.maintenanceUnplanned}`
  },
  [periodTypes.eventAnomaly]: {
    label: `events.category.${periodTypes.eventAnomaly}`
  },
  [periodTypes.eventInfo]: {
    label: `events.category.${periodTypes.eventInfo}`
  },
  [periodTypes.eventFatal]: {
    label: `events.category.${periodTypes.eventFatal}`
  },
  [periodTypes.eventWarning]: {
    label: `events.category.${periodTypes.eventWarning}`
  },
  [periodTypes.eventError]: {
    label: `events.category.${periodTypes.eventError}`
  },
  [periodTypes.quality]: {
    label: "indicators.quality"
  },
  [periodTypes.performance]: {
    label: "indicators.performance"
  },
  [periodTypes.availability]: {
    label: "indicators.availability"
  }
};
const getGaugeProps = (gaugeName) => periodTypeToLabel[gaugeName];
export const calculateGaugeValues = (data, disabledProps, type) => {
  let mainValue;
  const child = [];
  if (type === reportTypes.kpi) {
    mainValue = 1;
    if (disabledProps.length === Object.keys(data).length) {
      mainValue = 0;
    }
    forEach(
      data,
      (value, key) => {
        if (!isIndicatorDisabled(key, disabledProps)) {
          mainValue *= value;
        }
      }
    );
    forEach(
      data,
      (value, key) => child.push(__spreadProps(__spreadValues({}, getGaugeProps(key)), {
        type: key,
        isDisabled: isIndicatorDisabled(key, disabledProps),
        value: value || 0
      }))
    );
    return {
      main: {
        label: "indicators.oee",
        value: mainValue || 0
      },
      child
    };
  }
  mainValue = 0;
  forEach(
    data,
    (value, key) => {
      if (!isIndicatorDisabled(key, disabledProps)) {
        mainValue += value;
      }
    }
  );
  forEach(
    data,
    (value, key) => child.push(__spreadProps(__spreadValues({}, getGaugeProps(key)), {
      type: key,
      isDisabled: isIndicatorDisabled(key, disabledProps),
      value: value > 0 ? value / mainValue : 0,
      displayValue: value,
      isAmount: true
    }))
  );
  return {
    main: {
      label: "indicators.all",
      value: mainValue,
      isAmount: true,
      noAnimation: true
    },
    child
  };
};
const parseRelativeDate = (value = "") => {
  const DEFAULT = { count: 0, unit: relativeUnits.seconds };
  if (value === relativeUnits.now)
    return DEFAULT;
  const split = value.split(".");
  if (split.length !== 2)
    return DEFAULT;
  return { count: parseInt(split[0]), unit: split[1] };
};
export const initializeReport = (report) => {
  const chartType = report.type === reportTypes.dataPoints ? chartTypes.STACKED_CHART : chartTypes.STACKED_BAR_CHART;
  const initializedReport = __spreadProps(__spreadValues({}, report), {
    from: report.from ? moment(report.from) : null,
    to: report.to ? moment(report.to) : null,
    loaded: false,
    loading: false,
    error: null,
    disabledProps: [],
    chartType
  });
  if (report.fromRelative) {
    initializedReport.fromRelative = parseRelativeDate(report.fromRelative);
  }
  if (report.toRelative) {
    initializedReport.toRelative = parseRelativeDate(report.toRelative);
  }
  if (report.type === reportTypes.kpi && report.data) {
    initializedReport.data = __spreadValues(__spreadValues({}, report.data), oeeHelper.calculateOee(report.data.oee));
  }
  return initializedReport;
};
export const isAutoRefreshAvailable = (report) => report.datesMode === datesModes.RELATIVE;
export const hasArchivedDataPoints = (dataPointFilters = []) => dataPointFilters.some((item) => item && item.dataPoint && item.dataPoint.archived);
