import React from 'react';
import { forwardRef, useMemo } from 'react';
import { CursorUnit, UNIT_TO_NANO } from './helper';

interface Props {
  ref?: React.RefObject<HTMLDivElement>;
  unit: CursorUnit;
  value: number;
  disabled?: boolean;
  incCursorUnixNano: (deltaMs: number) => void;
  onKeyLeft?: (unit: CursorUnit) => void;
  onKeyRight?: (unit: CursorUnit) => void;
}

const useFormattedValue = (value: number, unit: CursorUnit) =>
  useMemo(() => {
    switch (unit) {
      case 'h':
      case 'min':
      case 's':
        return value.toString().padStart(2, '0');
      default:
        return value.toString();
    }
  }, [value, unit]);

const keysToListenTo = new Set(['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']);

export const CursorInputSegment = forwardRef<HTMLDivElement, Props>(
  ({ unit, incCursorUnixNano, value, onKeyLeft, onKeyRight, disabled = false }, ref) => {
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (!keysToListenTo.has(event.key)) return;
      event.preventDefault();
      if (event.key === 'ArrowUp') {
        incCursorUnixNano(UNIT_TO_NANO[unit]);
      } else if (event.key === 'ArrowDown') {
        incCursorUnixNano(-UNIT_TO_NANO[unit]);
      } else if (onKeyLeft && event.key === 'ArrowLeft') {
        onKeyLeft(unit);
      } else if (onKeyRight && event.key === 'ArrowRight') {
        onKeyRight(unit);
      }
    };

    const tabIndex = disabled ? undefined : 0;
    const textClass = disabled ? 'disabled' : '';
    const formattedValue = useFormattedValue(value, unit);

    return (
      <div
        ref={ref}
        className={`input-cursor-display ${textClass}`}
        tabIndex={tabIndex}
        onKeyDown={onKeyDown}
      >
        {formattedValue}
      </div>
    );
  },
);
