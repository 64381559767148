"use strict";
import * as idProtector from "../../server/services/idProtector";
import { z } from "zod";
const isValidId = (value) => idProtector.isValid(value);
export const CreateDatatronDeviceSchema = z.object({
  name: z.string(),
  deviceUUID: z.string().uuid(),
  serialNumber: z.string(),
  locationId: z.string().refine(isValidId, {
    message: "errors.field.invalid"
  }),
  organizationId: z.string().refine(isValidId, {
    message: "errors.field.invalid"
  }),
  deviceTypeId: z.string().refine(isValidId, {
    message: "errors.field.invalid"
  }),
  networkName: z.string().optional()
});
export const CreateDeviceParamSchema = z.object({
  datatronId: z.number(),
  datatronTs: z.date(),
  accountId: z.number()
});
export const UpdateDeviceParamSchema = z.object({
  deviceId: z.number(),
  deviceTs: z.date(),
  datatronId: z.number(),
  datatronTs: z.date(),
  accountId: z.number()
});
export const UpdateDatatronDeviceSchema = z.object({
  name: z.string().min(1, "errors.field.invalid").optional(),
  serialNumber: z.string().min(1, "errors.field.invalid").optional(),
  networkName: z.string().optional()
});
export const mainDeviceSchema = z.object({
  datatronId: z.number(),
  datatronTs: z.date(),
  deviceId: z.number(),
  deviceTs: z.date(),
  accountId: z.number()
});
export const deviceIdentifierSchema = z.object({
  deviceId: z.number()
});
export const quickCommandDeviceSchema = mainDeviceSchema.extend({
  command: z.enum(["collect", "stop"])
});
