"use strict";
import { defineMessages } from "react-intl";
export default defineMessages({
  accounts: {
    id: "common.core.accounts",
    defaultMessage: "Accounts"
  },
  account: {
    id: "common.core.account",
    defaultMessage: "Account"
  },
  organizations: {
    id: "common.core.organizations",
    defaultMessage: "Organizations"
  },
  organization: {
    id: "common.core.organization",
    defaultMessage: "Organization"
  },
  locations: {
    id: "common.core.locations",
    defaultMessage: "Locations"
  },
  location: {
    id: "common.core.location",
    defaultMessage: "Location"
  },
  users: {
    id: "common.core.users",
    defaultMessage: "Users"
  },
  user: {
    id: "common.core.user",
    defaultMessage: "User"
  },
  calendars: {
    id: "common.core.calendars",
    defaultMessage: "Calendars"
  },
  calendar: {
    id: "common.core.calendar",
    defaultMessage: "Calendar"
  },
  shift: {
    id: "common.core.shift",
    defaultMessage: "Shift"
  },
  schedule: {
    id: "common.core.schedule",
    defaultMessage: "Schedule"
  },
  machines: {
    id: "common.core.machines",
    defaultMessage: "Machines"
  },
  machine: {
    id: "common.core.machine",
    defaultMessage: "Machine"
  },
  roles: {
    id: "common.core.roles",
    defaultMessage: "Roles"
  },
  role: {
    id: "common.core.role",
    defaultMessage: "Role"
  },
  tags: {
    id: "common.core.tags",
    defaultMessage: "Tags"
  },
  tag: {
    id: "common.core.tag",
    defaultMessage: "Tag"
  },
  datatrons: {
    id: "common.core.datatrons",
    defaultMessage: "DATATRONs"
  },
  datatron: {
    id: "common.core.datatron",
    defaultMessage: "DATATRON"
  },
  devices: {
    id: "common.core.devices",
    defaultMessage: "Devices"
  },
  device: {
    id: "common.core.device",
    defaultMessage: "Device"
  },
  email: {
    id: "common.core.email",
    defaultMessage: "Email"
  },
  name: {
    id: "common.core.name",
    defaultMessage: "Name"
  },
  actions: {
    id: "common.core.actions",
    defaultMessage: "Actions"
  },
  position: {
    id: "common.core.position",
    defaultMessage: "Position"
  },
  firstName: {
    id: "common.core.firstName",
    defaultMessage: "First Name"
  },
  lastName: {
    id: "common.core.lastName",
    defaultMessage: "Last Name"
  },
  middleName: {
    id: "common.core.middleName",
    defaultMessage: "Middle Name"
  },
  password: {
    id: "common.core.password",
    defaultMessage: "Password"
  },
  passwordConfirmation: {
    id: "common.core.passwordConfirmation",
    defaultMessage: "Password Confirmation"
  },
  newPassword: {
    id: "common.core.newPassword",
    defaultMessage: "New Password"
  },
  newPasswordConfirmation: {
    id: "common.core.newPasswordConfirmation",
    defaultMessage: "New Password Confirmation"
  },
  phoneNumber: {
    id: "common.core.phoneNumber",
    defaultMessage: "Phone Number"
  },
  locale: {
    id: "common.core.locale",
    defaultMessage: "Locale"
  },
  basic: {
    id: "common.core.basic",
    defaultMessage: "Basic"
  },
  advanced: {
    id: "common.core.advanced",
    defaultMessage: "Advanced"
  },
  working: {
    id: "common.core.working",
    defaultMessage: "Working"
  },
  profileStatus: {
    id: "common.core.profileStatus",
    defaultMessage: "Profile Status"
  },
  description: {
    id: "common.core.description",
    defaultMessage: "Description"
  },
  reports: {
    id: "common.core.reports",
    defaultMessage: "Reports"
  },
  report: {
    id: "common.core.report",
    defaultMessage: "Report"
  },
  datePeriod: {
    id: "common.core.datePeriod",
    defaultMessage: "Date Period"
  },
  filters: {
    id: "common.core.filters",
    defaultMessage: "Filters"
  },
  dataPoints: {
    id: "common.core.dataPoints",
    defaultMessage: "Data Points"
  },
  dataPoint: {
    id: "common.core.dataPoint",
    defaultMessage: "Data Point"
  },
  dataPointArray: {
    id: "common.core.dataPointArray",
    defaultMessage: "Data Point Array Definition"
  },
  dataPointIndex: {
    id: "common.core.dataPointIndex",
    defaultMessage: "Define Data Point Index"
  },
  color: {
    id: "common.core.color",
    defaultMessage: "Color"
  },
  axis: {
    id: "common.core.axis",
    defaultMessage: "Axis"
  },
  lineWidth: {
    id: "common.core.lineWidth",
    defaultMessage: "Line Width"
  },
  fillOpacity: {
    id: "common.core.fillOpacity",
    defaultMessage: "Fill Opacity"
  },
  left: {
    id: "common.core.left",
    defaultMessage: "Left"
  },
  right: {
    id: "common.core.right",
    defaultMessage: "Right"
  },
  label: {
    id: "common.core.label",
    defaultMessage: "Label"
  },
  type: {
    id: "common.core.type",
    defaultMessage: "Type"
  },
  model: {
    id: "common.core.model",
    defaultMessage: "Model"
  },
  comment: {
    id: "common.core.comment",
    defaultMessage: "Comment"
  },
  activity: {
    id: "common.core.activity",
    defaultMessage: "Activity"
  },
  searchPlaceholder: {
    id: "common.search_placeholder",
    defaultMessage: "Search here..."
  },
  selectPlaceholder: {
    id: "common.select_placeholder",
    defaultMessage: "Select..."
  }
});
