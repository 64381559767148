"use strict";
import styled from "styled-components";
export const WrapperContainer = styled.div`
  position: relative;

  .custom-resize-handle {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4px;
    cursor: row-resize;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 2px;
      width: 30px;
      height: 2px;
      background: #ddd;
      border-radius: 2px;
      opacity: 0;
      transition: opacity 0.2s;
    }

    &:hover::after {
      opacity: 1;
    }
  }
`;
export const ResizeIconStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15px;
  height: 15px;
  cursor: row-resize;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-left: 2px solid #686868;
    border-bottom: 2px solid #686868;
    bottom: 4px;
    left: 4px;
  }

  &:hover::before {
    border-color: #333;
  }
`;
