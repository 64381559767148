import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { CursorInputSegment } from './CursorInputSegment';
import { CURSOR_UNITS, CursorUnit } from './helper';

interface Props {
  cursorUnixNano: number;
  setCursorUnixNano: (date: number) => void;
}

const useTime = (unixNano: number): Record<CursorUnit, number> => {
  const getTime = useCallback(() => {
    // get local time
    const date = new Date(Math.floor(unixNano / 1e6));
    return {
      h: date.getHours(),
      min: date.getMinutes(),
      s: date.getSeconds(),
      ms100: Math.floor(date.getMilliseconds() / 100),
      ms10: Math.floor(date.getMilliseconds() / 10) % 10,
      ms: date.getMilliseconds() % 10,
    };
  }, [unixNano]);

  return getTime();
};

const useDebouncedCursorUnixNano = (
  cursorUnixNano: number,
  setCursorUnixNano: (newCUN: number) => void,
) => {
  // the cursor unix nano value that is used for the input field and changes immediately
  const [valueCUN, setValueCUN] = useState(cursorUnixNano);

  // the increment function that is used for the input field and changes immediately
  const incValueCUN = useCallback(
    (deltaMs: number) => setValueCUN((prev) => Math.max(prev + deltaMs * 1e6, 0)),
    [],
  );

  // a debounced function that sets the cursor unix nano in the store to the value of the input field
  const debouncedSetStoreCUN = useMemo(() => {
    const func = (newCUN: number) => setCursorUnixNano(newCUN);
    return debounce(func, 300);
  }, [setCursorUnixNano]);

  // set the cursor unix nano in the store to the value of the input field
  useEffect(() => {
    debouncedSetStoreCUN(valueCUN);
  }, [debouncedSetStoreCUN, valueCUN]);

  // set the value of the input field to the cursor unix nano from the store
  useEffect(() => {
    setValueCUN(cursorUnixNano);
  }, [cursorUnixNano]);

  return {
    cursorUnixNano: valueCUN,
    incCursorUnixNano: incValueCUN,
  };
};

const isUnitDisabled = (unit: CursorUnit) => unit === 'h';

const prevCursorUnit = (unit: CursorUnit) => {
  const index = CURSOR_UNITS.indexOf(unit);
  return index === 0 ? undefined : CURSOR_UNITS[index - 1];
};

const nextCursorUnit = (unit: CursorUnit) => {
  const index = CURSOR_UNITS.indexOf(unit);
  return index === CURSOR_UNITS.length - 1 ? undefined : CURSOR_UNITS[index + 1];
};

export const CursorInput: React.FC<Props> = ({ cursorUnixNano, setCursorUnixNano }) => {
  const { cursorUnixNano: debouncedCUN, incCursorUnixNano } = useDebouncedCursorUnixNano(
    cursorUnixNano,
    setCursorUnixNano,
  );
  const time = useTime(debouncedCUN);

  const unitRef = useRef(
    new Map<CursorUnit, React.RefObject<HTMLDivElement>>(
      CURSOR_UNITS.map((unit) => [unit, createRef()]),
    ),
  );

  const onKeyLeft = useCallback((unit: CursorUnit) => {
    const prevUnit = prevCursorUnit(unit);
    if (!prevUnit) return;
    if (isUnitDisabled(prevUnit)) return;
    unitRef.current.get(prevUnit)?.current?.focus();
  }, []);

  const onKeyRight = useCallback((unit: CursorUnit) => {
    const nextUnit = nextCursorUnit(unit);
    if (!nextUnit) return;
    if (isUnitDisabled(nextUnit)) return;
    unitRef.current.get(nextUnit)?.current?.focus();
  }, []);

  const unitPros = useMemo(
    () =>
      new Map(
        CURSOR_UNITS.map((unit) => {
          const props = {
            unit,
            ref: unitRef.current.get(unit),
            incCursorUnixNano,
            onKeyLeft: () => onKeyLeft(unit),
            onKeyRight: () => onKeyRight(unit),
            disabled: isUnitDisabled(unit),
          };
          return [unit, props];
        }),
      ),
    [onKeyLeft, onKeyRight, incCursorUnixNano],
  );

  return (
    <div className='input-cursor input-cursor-container'>
      <div className='input-cursor-wrapper'>
        <div className='input-2-digits'>
          <CursorInputSegment value={time.h} {...unitPros.get('h')!} />
        </div>
        {':'}
        <div className='input-2-digits'>
          <CursorInputSegment value={time.min} {...unitPros.get('min')!} />
        </div>
        {':'}
        <div className='input-2-digits'>
          <CursorInputSegment value={time.s} {...unitPros.get('s')!} />
        </div>
        {'.'}
        <CursorInputSegment value={time.ms100} {...unitPros.get('ms100')!} />
        <CursorInputSegment value={time.ms10} {...unitPros.get('ms10')!} />
        <CursorInputSegment value={time.ms} {...unitPros.get('ms')!} />
      </div>
    </div>
  );
};
