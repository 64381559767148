"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  createDataPointSchemaFromJSO,
  createZodSchemaFromJSON
} from "../../../../common/validation/datapoints/jsonToZod";
import { transformZodError } from "../../../../common/utils";
import {
  dataPointArrayCommonSchema,
  dataPointCommonSchema,
  dataPointIndexCommonSchema
} from "../common/dataPoint.constants";
import {
  ARRAY_DATAPOINT_FORM_TYPE,
  ARRAY_INDEX_DATAPOINT_FORM_TYPE,
  SIMPLE_DATAPOINT_FORM_TYPE
} from "../../redux/constants/dataPoint.form.constants";
export const validateDevice = (mainZodSchema, deviceSchema, body) => {
  try {
    const mainValidationResult = mainZodSchema.safeParse(body);
    const dynamicZodSchema = createZodSchemaFromJSON(deviceSchema);
    const dynamicValidationResult = dynamicZodSchema.safeParse(body);
    if (!dynamicValidationResult.success || !mainValidationResult.success) {
      const validationError = __spreadValues(__spreadValues({}, dynamicValidationResult.success ? {} : transformZodError(dynamicValidationResult.error)), mainValidationResult.success ? {} : transformZodError(mainValidationResult.error));
      return validationError;
    }
  } catch (error) {
    console.error("Failed to validate data point", error);
  }
  return null;
};
const getCommonFieldsSchema = (formType) => {
  switch (formType) {
    case "simple":
      return dataPointCommonSchema;
    case "array":
      return dataPointArrayCommonSchema;
    case "index":
      return dataPointIndexCommonSchema;
    default:
      return {};
  }
};
const getExtraFieldsSchema = (dataPointSchema, formType) => {
  switch (formType) {
    case "simple":
      return dataPointSchema.definitions[SIMPLE_DATAPOINT_FORM_TYPE];
    case "array":
      return dataPointSchema.definitions[ARRAY_DATAPOINT_FORM_TYPE];
    case "index":
      return dataPointSchema.definitions[ARRAY_INDEX_DATAPOINT_FORM_TYPE];
    default:
      return void 0;
  }
};
export const getFormTypeFromDataPoint = (dataPoint) => {
  var _a, _b, _c, _d;
  if (((_a = dataPoint == null ? void 0 : dataPoint.config) == null ? void 0 : _a.arrayIndex) && ((_c = (_b = dataPoint == null ? void 0 : dataPoint.config) == null ? void 0 : _b.arrayIndex) == null ? void 0 : _c.length) !== 0) {
    return "index";
  }
  if ((_d = dataPoint == null ? void 0 : dataPoint.config) == null ? void 0 : _d.arrayDimensions) {
    return "array";
  }
  return "simple";
};
export function transformNaNToUndefined(value) {
  if (typeof value === "number") {
    return Number.isNaN(value) ? void 0 : value;
  }
  if (value === null || value === void 0) {
    return void 0;
  }
  if (Array.isArray(value)) {
    return value.map(transformNaNToUndefined);
  }
  if (typeof value === "object") {
    if (value instanceof Date)
      return value;
    const result = {};
    for (const key in value) {
      if (Object.prototype.hasOwnProperty.call(value, key)) {
        const originalValue = value[key];
        const transformedValue = transformNaNToUndefined(originalValue);
        result[key] = transformedValue;
      }
    }
    return result;
  }
  return value;
}
export const validateDataPoint = (dataPointExtraSchema, formType, body) => {
  const commonFields = getCommonFieldsSchema(formType);
  const configFields = getExtraFieldsSchema(dataPointExtraSchema, formType);
  commonFields.properties.config = configFields;
  const fullSchema = __spreadProps(__spreadValues({}, commonFields), {
    properties: __spreadProps(__spreadValues({}, commonFields.properties), {
      config: configFields != null ? configFields : {}
    })
  });
  try {
    const zodSchema = createDataPointSchemaFromJSO(fullSchema);
    const validationResult = zodSchema.safeParse(body);
    if (!validationResult.success) {
      return transformZodError(validationResult.error);
    }
  } catch (error) {
    console.error("Failed to validate data point", error);
  }
  return null;
};
export const validateData = (zodSchema, data) => {
  const validationResult = zodSchema.safeParse(data);
  if (!validationResult.success) {
    return transformZodError(validationResult.error);
  }
  return null;
};
