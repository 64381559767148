"use strict";
export const usersSearchTestState = {
  query: "",
  users: [{
    name: "John"
  }],
  loading: false,
  loaded: false,
  error: ""
};
export const usersSearchInitialState = {
  query: "",
  users: [],
  loading: false,
  loaded: false,
  error: ""
};
