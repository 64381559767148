"use strict";
export const DATA_POINTS_EXCEL_MIME_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const DATA_POINTS_XLSX = "data-points.xlsx";
export const UPLOAD_BULK_DATA_POINT_ACCEPT = ".xlsx";
export const MAX_FILE_SIZE = 15728640;
export const NR_FILES_ALLOWED = 1;
export const DATA_POINTS_XLSX_SHEET_NAME = "Data Points";
export const UPLOAD_DEFAULT_ACCEPT = ".pdf,.docx,.pptx,.txt,.xlsx";
export const EXCEL_BULK_SUMMARY_PAGE_SIZE = 10;
export const EXCEL_BULK_SUMMARY_DEFAULT_PAGE = 1;
export const EXCEL_SHEET_INDEX = 0;
export const UPDATE_DATA_POINT_BULK_BATCH_SIZE = 50;
export const SHOW_TIMEOUT_WARNING_MESSAGE = 3e3;
