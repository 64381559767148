"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as selector from "../../../redux/selectors/aiApps.selector";
import { getAIAppAnomalyModels } from "../../../core/api/aiApps";
import { openModal } from "../../../redux/modules/modals.module";
import { triggerAnomalyModel } from "../../../redux/modules/aiApps.anomalyModel.trigger.module";
import { triggerCycleDetection } from "../../../redux/modules/aiApps.cycleDetection.trigger.module";
export const useAnomalyModelSection = () => {
  const dispatch = useDispatch();
  const cycleDetectionDetails = useSelector(selector.getCycleDetectionDetails);
  const aiAppId = useSelector(selector.getAiAppsDetailsId);
  const [selectedAnomalyDetection, setAnomalyDetection] = useState(null);
  const [anomalyModels, setAnomalyModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    setAnomalyDetection(null);
  }, [cycleDetectionDetails]);
  const handleViewAnomalyModelDetails = (row) => __async(void 0, null, function* () {
    try {
      setIsLoading(true);
      const { response } = yield getAIAppAnomalyModels(aiAppId, row.id);
      setAnomalyDetection(row.id);
      setAnomalyModels(response);
    } catch (err) {
      console.error("Failed to fetch anomaly model details", err);
      setError("Failed to fetch anomaly model details");
    } finally {
      setIsLoading(false);
    }
  });
  const handleOpenAnomalyModelModal = () => {
    dispatch(openModal("ANOMALY_MODEL_ADD"));
  };
  const triggerModelAction = (cycleDetectionId, rowId) => rowId ? dispatch(triggerAnomalyModel(cycleDetectionId, rowId)) : dispatch(triggerCycleDetection(cycleDetectionId));
  return {
    cycleDetectionDetails,
    selectedAnomalyDetection,
    anomalyModels,
    isLoading,
    error,
    handleViewAnomalyModelDetails,
    handleOpenAnomalyModelModal,
    triggerModelAction
  };
};
