"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { find } from "lodash";
import { createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import { getDataPointsOfDevice, getDeviceById } from "../selectors/datatron.selector";
import * as api from "../../core/api";
import { initializeDataPoint } from "../../core/common/dataPoint";
import { compareById, replaceObjectInList } from "../../../../common/helpers/object";
export const updateBulkExcelDataPoints = createAction(
  "update by excel bulk data points",
  (datatronId, deviceId, file) => ({
    payload: { datatronId, deviceId, file }
  })
);
export const updateBulkExcelDataPointsSuccess = createAction(
  "update by excel bulk data points - success",
  (deviceId, bulkUploadResponse) => ({
    payload: { deviceId, bulkUploadResponse }
  })
);
export const updateBulkExcelDataPointsFail = createAction(
  "update by excel bulk data points - fail",
  (deviceId, error) => ({ payload: { deviceId, error } })
);
export const resetUpdateBulkExcelResponse = createAction(
  "reset update bulk excel response",
  (deviceId) => ({ payload: { deviceId } })
);
export const reducer = {
  [updateBulkExcelDataPoints.type]: (state, action) => {
    const { deviceId } = action;
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      _update: __spreadProps(__spreadValues({}, device._update), {
        loading: true,
        error: null
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  },
  [resetUpdateBulkExcelResponse.type]: (state, action) => {
    const { deviceId } = action;
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      _update: {
        isRenaming: false,
        loaded: false,
        loading: false,
        error: null
      }
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  },
  [updateBulkExcelDataPointsSuccess.type]: (state, { deviceId, bulkUploadResponse }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    if (device._update.loading === false)
      return state;
    const deviceDataPoints = getDataPointsOfDevice(device);
    const { datapoints, bulkReport } = bulkUploadResponse;
    const archivedDataPoints = bulkReport.completed.filter((data) => data.action === "archived").map((data) => {
      const dataPointToRemove = find(deviceDataPoints, { uuid: data.uuid });
      return dataPointToRemove;
    });
    const newDevice = __spreadProps(__spreadValues({}, device), {
      dataPoints: {
        query: "",
        sort: { option: null, way: null },
        list: datapoints,
        loading: false,
        loaded: true,
        error: null
      },
      archivedDataPoints: __spreadProps(__spreadValues({}, device.archivedDataPoints), {
        list: [...archivedDataPoints, ...device.archivedDataPoints.list]
      }),
      _update: __spreadProps(__spreadValues({}, device._update), {
        loading: false,
        updateBulkResponse: bulkReport,
        error: null
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          loading: false,
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  },
  [updateBulkExcelDataPointsFail.type]: (state, { deviceId, error }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    if (device._update.loading === false)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      _update: __spreadProps(__spreadValues({}, device._update), {
        loading: false,
        error
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        })
      })
    });
  }
};
export function* updateBulkExcelDataPointsSaga({
  payload: { datatronId, deviceId, file }
}) {
  try {
    const { response, error } = yield call(api.datatrons.uploadDatapoints, {
      datatronId,
      deviceId,
      file
    });
    if (response) {
      const { bulkReport } = response;
      const datapoints = response.datapoints.map(initializeDataPoint);
      yield put(updateBulkExcelDataPointsSuccess(deviceId, { datapoints, bulkReport }));
    } else {
      yield put(updateBulkExcelDataPointsFail(deviceId, error));
    }
  } catch (error) {
    yield put(updateBulkExcelDataPointsFail(deviceId, JSON.stringify(error)));
  }
}
export function* watchUpdateBulkExcelDataPoints() {
  yield takeEvery(updateBulkExcelDataPoints, updateBulkExcelDataPointsSaga);
}
