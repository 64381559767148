"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { AI_APPS_MANAGER, AI_APPS_VIEWER } from "../constants/permissions";
class AiAppsPermissions {
  constructor(roles) {
    __publicField(this, "isAiAppsManager");
    __publicField(this, "isAiAppsViewer");
    this.isAiAppsManager = roles.indexOf(AI_APPS_MANAGER) !== -1;
    this.isAiAppsViewer = roles.indexOf(AI_APPS_VIEWER) !== -1;
  }
  canManageAiApps() {
    return this.isAiAppsManager;
  }
  canAddAiApp() {
    return this.isAiAppsManager;
  }
  canEditAiApp() {
    return this.isAiAppsManager;
  }
  canViewAiApps() {
    return this.isAiAppsManager || this.isAiAppsViewer;
  }
}
export default AiAppsPermissions;
