import React, { FC } from 'react';

import AddButton from './AddButton';
import { LocationBreadcrumbs } from '../../_tools/locationBreadcrumbs/LocationBreadcrumbs';

export const AnalyzerHeader: FC = () => (
  <div className='subheader'>
    <div className='subheader-in'>
      <div className='subheader--left'>
        <LocationBreadcrumbs />
      </div>
      <div className='subheader--right'>
        <AddButton />
      </div>
    </div>
  </div>
);
