"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import moment from "moment";
import { pick } from "lodash";
import { DATE_FROM, DATE_TO } from "../../../../common/constants";
export const getAllBatches = (state) => state.batches.list;
export const areBatchesLoading = (state) => state.batches.loading;
export const getNameFilterInputValue = (state) => state.batches.filters.q;
export const getBatchSort = (state) => state.batches.filters.sort;
export const dateFilters = (state) => pick(state.batches.filters, [DATE_FROM, DATE_TO]);
export const areDateFiltersSelected = (state) => {
  const dates = dateFilters(state);
  return !!dates[DATE_FROM] && !!dates[DATE_TO];
};
export const getFilters = (state) => {
  const filters = __spreadValues(__spreadValues({}, pick(state.batches.filters, ["q"])), dateFilters(state));
  if (filters[DATE_FROM]) {
    filters[DATE_FROM] = moment(filters[DATE_FROM]).toISOString();
  }
  if (filters[DATE_TO]) {
    filters[DATE_TO] = moment(filters[DATE_TO]).toISOString();
  }
  delete filters.sort;
  const sort = getBatchSort(state);
  if (sort.option && sort.way) {
    filters[sort.option] = sort.way;
  }
  return filters;
};
export const hasFilters = (state) => {
  const filters = getFilters(state);
  return !(!filters.q && !filters[DATE_FROM] && !filters[DATE_TO]);
};
