"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import {
  compareById,
  replaceObjectInList
} from "../../../../common/helpers/object";
import { getDeviceById } from "../selectors/datatron.selector";
export const updateActiveStatusOfDevice = createAction(
  "update active status of datatron device",
  (deviceId, newActive) => ({
    payload: { deviceId, newActive }
  })
);
export const reducer = {
  [updateActiveStatusOfDevice.type]: (state, { deviceId, newActive }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), { active: newActive });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById
          )
        })
      })
    });
  }
};
