"use strict";
import * as toolStates from "../../../../common/enums/toolStates";
export const toolsInitialState = {
  all: {
    list: [],
    pagination: null,
    loading: false,
    loaded: false,
    error: null,
    filters: {
      query: "",
      state: [toolStates.NEW, toolStates.MOUNTED, toolStates.USED]
    }
  }
};
export const toolsTestState = {
  all: {
    list: [
      {
        id: 123,
        label: "label 123",
        type: "type 123",
        model: "model 123",
        state: "mounted",
        machine: {
          id: 456,
          name: "machine 456"
        }
      }
    ],
    pagination: {
      total: 1,
      current_page: 1,
      per_page: 10,
      total_pages: 1
    },
    loading: false,
    loaded: true,
    error: null,
    filters: {
      query: "hello",
      state: [toolStates.NEW, toolStates.MOUNTED, toolStates.USED]
    }
  }
};
