"use strict";
export const ANALYZER_SESSION_FORM = "ANALYZER_SESSION_FORM";
export const NAME_FIELD = "name";
export const DATATRON_SELECT_FIELD = "datatron";
export const DEVICES_SELECT_FIELD = "devices";
export const ANALYZER_SESSION_PROPS = [NAME_FIELD, DATATRON_SELECT_FIELD, DEVICES_SELECT_FIELD];
export const ANALYZER_SESSION_PANEL_FORM = "ANALYZER_SESSION_PANEL_FORM";
export const ANALYZER_DETAILS_FIELDS = [
  "name",
  "datatronUuid",
  "datatronName",
  "anomalyFrom",
  "referenceFrom",
  "rangeDeltaMs",
  "archived",
  "classification",
  "comment"
];
