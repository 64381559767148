"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { cloneDeep, find, findIndex, forEach, last } from "lodash";
export const modifyLocationsTree = (tree, newLocations, parentId) => {
  const thisLevel = [];
  forEach(
    tree,
    (location) => {
      if (location.id === parentId) {
        thisLevel.push(__spreadProps(__spreadValues({}, location), {
          child: newLocations
        }));
      } else if (location.child && location.child.length > 0) {
        thisLevel.push(__spreadProps(__spreadValues({}, location), {
          child: modifyLocationsTree(location.child, newLocations, parentId)
        }));
      } else {
        thisLevel.push(location);
      }
    }
  );
  return thisLevel;
};
export const addNewLocationsIntoExistingTree = (oldTree, newLocations, parentLocationId = null) => {
  if (!parentLocationId)
    return cloneDeep(newLocations);
  return modifyLocationsTree(cloneDeep(oldTree), newLocations, parentLocationId);
};
export const flattenLocationTreeIntoPaths = (tree, prefix = []) => {
  const paths = [];
  forEach(
    tree,
    (location) => {
      const currentPath = [...prefix, location];
      paths.push(currentPath);
      if (location.child && location.child.length > 0) {
        forEach(
          flattenLocationTreeIntoPaths(location.child, currentPath),
          (childLocation) => paths.push(childLocation)
        );
      }
    }
  );
  return paths;
};
export const findSelectedPath = (paths) => find(
  paths,
  (path) => last(path).selected === true
);
export const splitTreeIntoPickerSides = (tree) => {
  const paths = flattenLocationTreeIntoPaths(tree);
  const selectedPath = findSelectedPath(paths);
  if (!selectedPath) {
    return {
      leftSide: [],
      rightSide: tree
    };
  }
  return {
    leftSide: selectedPath,
    rightSide: last(selectedPath).child || []
  };
};
export const updateLocationInTree = (tree, locationId, newData = {}, otherLocationsNewData = {}) => cloneDeep(tree).map((location) => {
  if (location.id === locationId) {
    return __spreadValues(__spreadValues({}, location), newData);
  }
  if (location.child && location.child.length > 0) {
    return __spreadProps(__spreadValues(__spreadValues({}, location), otherLocationsNewData), {
      child: updateLocationInTree(location.child, locationId, newData, otherLocationsNewData)
    });
  }
  return __spreadValues(__spreadValues({}, location), otherLocationsNewData);
});
export const getParentOfLocationFromPath = (pathArray, locationId) => {
  const locationIndex = findIndex(pathArray, { id: locationId });
  const parentLocationIndex = locationIndex - 1;
  if (parentLocationIndex >= 0)
    return pathArray[parentLocationIndex];
  return null;
};
