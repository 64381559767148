"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { addNewLocationsIntoExistingTree } from "../../core/common/locationPicker";
import { getTree } from "../selectors/locationPicker.selector";
import { getChildLocations } from "../../core/api/locations";
export const fetchLocationPicker = createAction(
  "fetch locations for location picker",
  (parentLocationId) => ({ payload: parentLocationId })
);
export const fetchLocationPickerSuccess = createAction(
  "fetch locations for location picker successfully",
  (newTree, latestLoadedParentId) => ({ payload: { newTree, latestLoadedParentId } })
);
export const fetchLocationPickerFail = createAction(
  "fetch locations for location picker fail",
  (error) => ({ payload: error })
);
export const reducer = {
  [fetchLocationPicker.type]: (state) => __spreadProps(__spreadValues({}, state), {
    locationPicker: __spreadProps(__spreadValues({}, state.locationPicker), {
      loading: true,
      loaded: false,
      error: null
    })
  }),
  [fetchLocationPickerSuccess.type]: (state, { newTree, latestLoadedParentId }) => __spreadProps(__spreadValues({}, state), {
    locationPicker: __spreadProps(__spreadValues({}, state.locationPicker), {
      tree: newTree,
      latestLoadedParentId,
      loading: false,
      loaded: true,
      error: null
    })
  }),
  [fetchLocationPickerFail.type]: (state, error) => __spreadProps(__spreadValues({}, state), {
    locationPicker: __spreadProps(__spreadValues({}, state.locationPicker), {
      loading: false,
      loaded: false,
      error
    })
  })
};
export function* fetchLocationByIdSaga({ payload: parentLocationId }) {
  const response = yield call(getChildLocations, parentLocationId, { per_page: 1e4 });
  if (response.success) {
    if ("message" in response.data) {
      yield put(fetchLocationPickerFail(response.data.message));
    } else {
      const state = yield select();
      const oldTree = yield call(getTree, state);
      const newTree = yield call(
        addNewLocationsIntoExistingTree,
        oldTree,
        response.data.list,
        parentLocationId
      );
      yield put(fetchLocationPickerSuccess(newTree, parentLocationId));
    }
  } else {
    const { error, type } = response;
    if (type === "generalError") {
      yield put(fetchLocationPickerFail(error));
    } else {
      yield put(fetchLocationPickerFail(error.message));
    }
  }
}
export function* watchFetchLocationPickerById() {
  yield takeEvery(fetchLocationPicker, fetchLocationByIdSaga);
}
