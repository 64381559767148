"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "redux-act";
import { put, call, takeEvery, select } from "redux-saga/effects";
import { getAIAppCycleDetectionFormFields } from "../selectors/aiApp.form.selector";
import { startSubmit, stopSubmit, touch } from "redux-form";
import * as api from "../../core/api";
import * as form from "../constants/aiApp.cycleDetection.form.constants";
import { closeModal } from "./modals.module";
import { CYCLE_DETECTION_ADD } from "../constants/modals.constants";
import { fetchAiAppDetails } from "./aiApps.details.fetch.module";
import { getAiAppsDetailsId } from "../selectors/aiApps.selector";
import { sendNotification } from "./notifications.module";
import notificationMessage from "../../messages/notification.message";
import { NOTIFICATION_EVENT } from "../../core/notifications";
export const createAIAppCycleDetection = createAction("AiApp: create cycle detection");
export const createAIAppCycleDetectionSuccess = createAction(
  "AiApp: create cycle detection - success",
  (item) => item
);
export const createAIAppCycleDetectionFail = createAction(
  "AiApp: create cycle detection - fail",
  (error) => error
);
export function* touchAllFieldsSaga() {
  yield put(touch(form.AI_APP_CYCLE_DETECTION_FORM, ...form.AI_APP_CYCLE_DETECTION_PROPS));
}
export function* handleErrors(errors) {
  yield put(createAIAppCycleDetectionFail(errors));
  yield put(stopSubmit(form.AI_APP_CYCLE_DETECTION_FORM, errors));
}
export function* createAiAppsSaga() {
  const state = yield select();
  yield call(touchAllFieldsSaga);
  const aiAppId = yield call(getAiAppsDetailsId, state);
  const fields = yield call(getAIAppCycleDetectionFormFields, state);
  yield put(startSubmit(form.AI_APP_CYCLE_DETECTION_FORM));
  const { response, error } = yield call(api.aiApps.createCycleDetection, aiAppId, fields);
  if (response) {
    yield put(createAIAppCycleDetectionSuccess(response));
    yield put(fetchAiAppDetails(aiAppId));
    yield put(stopSubmit(form.AI_APP_CYCLE_DETECTION_FORM));
    yield put(closeModal(CYCLE_DETECTION_ADD));
    yield put(
      sendNotification(
        notificationMessage.item_created_title,
        __spreadProps(__spreadValues({}, notificationMessage.item_created_description), {
          values: {
            itemName: "Cycle Detection"
          }
        }),
        NOTIFICATION_EVENT
      )
    );
  } else {
    yield handleErrors(error);
  }
}
export function* watchCreateAIAppCycleDetection() {
  yield takeEvery(createAIAppCycleDetection.getType(), createAiAppsSaga);
}
