import React, { useState } from 'react';
import { useCycleDetections } from '../hooks/useCycleDetections';
import AiAppsNoSelection from '../components/AiAppsEmptyPlaceholder';
import { AiAppInfoCard } from '../components/AiAppInfoCard';
import { useAnomalyModelSection } from '../hooks/useAnomalyModelSection';
import { AccordionItem } from '../components/AccordionItem';
import { CycleDetectionHeader } from '../components/CycleDetectionHeader';

const CycleDetections: React.FC = () => {
  const {
    aiAppsDetails,
    cycleDetectionDetails,
    handleOpenCycleDetectionModal,
    handleViewCycleDetectionDetails,
  } = useCycleDetections();
  const { triggerModelAction } = useAnomalyModelSection();
  const [expandedRowId, setExpandedRowId] = useState<string | null>(null);

  if (!aiAppsDetails) return <AiAppsNoSelection />;

  const { cycleDetections } = aiAppsDetails;

  const handleAccordionClick = (row: any) => {
    if (expandedRowId === row.id) {
      setExpandedRowId(null);
    } else {
      setExpandedRowId(row.id);
      handleViewCycleDetectionDetails(row);
    }
  };

  return (
    <div className='container mt-5'>
      <AiAppInfoCard appDetails={aiAppsDetails} />

      <CycleDetectionHeader
        cycleDetectionsCount={cycleDetections.length}
        onAddCycleDetection={handleOpenCycleDetectionModal}
      />

      <div className='accordion'>
        {cycleDetections.map((row: any) => (
          <AccordionItem
            key={row.id}
            row={row}
            expandedRowId={expandedRowId}
            cycleDetectionDetails={cycleDetectionDetails}
            onTrigger={triggerModelAction}
            onAccordionClick={handleAccordionClick}
          />
        ))}
      </div>
    </div>
  );
};

export default CycleDetections;
