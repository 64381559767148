"use strict";
import { defineMessages } from "react-intl";
export default defineMessages({
  bootArguments: {
    id: "datatron.bootArguments",
    defaultMessage: "Boot Arguments"
  },
  bootArgumentsPlaceholder: {
    id: "datatron.bootArguments.placeholder",
    defaultMessage: "printk.devkmsg"
  },
  datatronName: {
    id: "newDatatron.datatron_name",
    defaultMessage: "DATATRON Name"
  },
  datatronNamePlaceholder: {
    id: "newDatatron.datatron_name.placeholder",
    defaultMessage: "Type DATATRON name here..."
  },
  deviceUUID: {
    id: "newDatatron.device_uuid",
    defaultMessage: "Device UUID"
  },
  deviceUUIDPlaceholder: {
    id: "newDatatron.device_uuid.placeholder",
    defaultMessage: "Type device UUID here..."
  },
  gateway: {
    id: "datatron.gateway",
    defaultMessage: "Gateway"
  },
  gatewayPlaceholder: {
    id: "datatron.gateway.placeholder",
    defaultMessage: "192.168.4.3"
  },
  ipAddress: {
    id: "datatron.ipAddress",
    defaultMessage: "IP Address"
  },
  ipAddressPlaceholder: {
    id: "datatron.ipAddress.placeholder",
    defaultMessage: "Type an IP (v4 or v6) address, e.g.: 192.168.0.1 or 2001:db8::ff00:42:8329"
  },
  macAddress: {
    id: "datatron.mac_address",
    defaultMessage: "MAC Address"
  },
  macAddressPlaceholder: {
    id: "datatron.mac_address.placeholder",
    defaultMessage: "Type MAC address here..."
  },
  model: {
    id: "newDatatron.model",
    defaultMessage: "Model"
  },
  modelPlaceholder: {
    id: "newDatatron.model.placeholder",
    defaultMessage: "Type Model here..."
  },
  netmask: {
    id: "datatron.netmask",
    defaultMessage: "Netmask"
  },
  netmaskPlaceholder: {
    id: "datatron.netmask.placeholder",
    defaultMessage: "0.0.0.0"
  },
  networkSpeed: {
    id: "datatron.networkSpeed",
    defaultMessage: "Connection Speed"
  },
  networkSpeedPlaceholder: {
    id: "datatron.networkSpeed.placeholder",
    defaultMessage: "Gb/s"
  },
  nodeName: {
    id: "datatron.nodeName",
    defaultMessage: "Node Name"
  },
  nodeNamePlaceholder: {
    id: "datatron.nodeName.placeholder",
    defaultMessage: "Type a node name here..."
  },
  notes: {
    id: "datatron.notes",
    defaultMessage: "Notes"
  },
  notesPlaceholder: {
    id: "datatron.notes.placeholder",
    defaultMessage: "Type notes here..."
  },
  osConfig: {
    id: "datatron.osConfig",
    defaultMessage: "OS Configuration"
  },
  osConfigPlaceholder: {
    id: "datatron.osConfig.placeholder",
    defaultMessage: "Type OS configuration here..."
  },
  osModules: {
    id: "datatron.osModules",
    defaultMessage: "OS Modules"
  },
  osModulesPlaceholder: {
    id: "datatron.osModules.placeholder",
    defaultMessage: 'Comma-separated list of OS modules (e.g. "module1, module2")'
  },
  serialNumber: {
    id: "newDatatron.serial_number",
    defaultMessage: "Serial Number"
  },
  serialNumberPlaceholder: {
    id: "newDatatron.serial_number.placeholder",
    defaultMessage: "Type serial number here..."
  },
  vendor: {
    id: "newDatatron.vendor",
    defaultMessage: "Vendor"
  },
  vendorPlaceholder: {
    id: "newDatatron.vendor.placeholder",
    defaultMessage: "Type vendor here..."
  },
  year: {
    id: "newDatatron.year",
    defaultMessage: "Year"
  }
});
