import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { activateDevice, deactivateDevice } from '../../../core/api/datatrons';
import commonMessages from '../../../messages/common.messages';
import { updateActiveStatusOfDevice } from '../../../redux/modules/datatron.devices.active.module';
import { Loader } from '../../_tools';
import Modal from '../../_tools/modal/Modal';

type CommandState = 'default' | 'submitting' | 'success' | 'error';

interface Props {
  datatronId: string;
  deviceId: string;
  isActivated: boolean;
  isOpen: boolean;
  close: () => void;
}

export const ActivateDeviceModal: React.FC<Props> = ({
  datatronId,
  deviceId,
  isActivated,
  isOpen,
  close,
}) => {
  const [commandState, commandStateSet] = useState<CommandState>('default');

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOpen) {
      // reset state when modal is closed
      commandStateSet('default');
    }
  }, [isOpen]);
  const handleSubmit = () => {
    commandStateSet('submitting');
    const action = isActivated
      ? deactivateDevice(datatronId, deviceId)
      : activateDevice(datatronId, deviceId);
    action.then((res) => {
      if (res.status === 200) {
        commandStateSet('success');
        dispatch(updateActiveStatusOfDevice(deviceId, !isActivated));
      } else {
        commandStateSet('error');
      }
    });
  };

  const command = isActivated ? 'deactivate' : 'activate';

  return (
    <Modal isOpen={isOpen} onClose={close} contentLabel=''>
      <div className='modal--header' onClick={close}>
        <span className='modal--close'>&times;</span>
      </div>
      <div className='modal--content'>
        <div>
          {commandState === 'default' && (
            <FormattedMessage
              defaultMessage={`Do you really want to ${command} this Device?`}
              id={`datatron.modals.${command}_device.text`}
              tagName='h3'
            />
          )}
          {commandState === 'submitting' && (
            <FormattedMessage
              defaultMessage='Submitting...'
              id='datatron.modals.quick_command.submitting'
              tagName='h3'
            />
          )}
          {commandState === 'success' && (
            <FormattedMessage
              defaultMessage='Success!'
              id='datatron.modals.quick_command.success'
              tagName='h3'
            />
          )}
          {commandState === 'error' && (
            <FormattedMessage
              defaultMessage='Error!'
              id='datatron.modals.quick_command.error'
              tagName='h3'
            />
          )}
        </div>
        {commandState === 'submitting' && (
          <div style={{ height: '100%', width: '100%' }}>
            <Loader show fullPage={false} />
          </div>
        )}
        {commandState === 'error' && <span>Something went wrong!</span>}

        <div className='line' />

        <div className='text-center'>
          {(commandState === 'default' || commandState === 'submitting') && (
            <button type='button' className='button__cancel' onClick={close}>
              {formatMessage(commonMessages.cancel)}
            </button>
          )}
          {commandState === 'default' && (
            <button type='submit' className='button__save' onClick={handleSubmit}>
              {isActivated
                ? formatMessage(commonMessages.deactivate)
                : formatMessage(commonMessages.activate)}
            </button>
          )}
          {commandState === 'success' && (
            <button type='submit' className='button__save' onClick={close}>
              {formatMessage(commonMessages.close)}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
