"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "redux-act";
import { put, call, takeEvery } from "redux-saga/effects";
import * as api from "../../core/api";
export const fetchAllMachines = createAction(
  "fetch all machines"
);
export const fetchAllMachinesSuccess = createAction(
  "fetch all machines - success",
  (list) => list
);
export const fetchAllMachinesFail = createAction(
  "fetch all machines - fail",
  (error) => error
);
export const reducer = {
  [fetchAllMachines.getType()]: (state) => __spreadProps(__spreadValues({}, state), {
    machine: __spreadProps(__spreadValues({}, state.machine), {
      allMachines: __spreadProps(__spreadValues({}, state.machine.allMachines), {
        loaded: false,
        loading: true,
        error: null
      })
    })
  }),
  [fetchAllMachinesSuccess.getType()]: (state, list) => __spreadProps(__spreadValues({}, state), {
    machine: __spreadProps(__spreadValues({}, state.machine), {
      allMachines: __spreadProps(__spreadValues({}, state.machine.allMachines), {
        list,
        loaded: true,
        loading: false,
        error: null
      })
    })
  }),
  [fetchAllMachinesFail.getType()]: (state, error) => __spreadProps(__spreadValues({}, state), {
    machine: __spreadProps(__spreadValues({}, state.machine), {
      allMachines: __spreadProps(__spreadValues({}, state.machine.allMachines), {
        list: [],
        loaded: false,
        loading: false,
        error
      })
    })
  })
};
export function* fetchAllMachinesSaga() {
  const response = yield call(api.machines.getAll);
  if (response.type === "generalError") {
    yield put(fetchAllMachinesFail(response.error));
    return;
  }
  if (response.type === "parseError") {
    yield put(fetchAllMachinesFail(response.error.message));
    return;
  }
  const { data } = response;
  if ("status" in data) {
    yield put(fetchAllMachinesFail(data.message));
    return;
  }
  yield put(fetchAllMachinesSuccess(data));
}
export function* watchFetchAllMachines() {
  yield takeEvery(fetchAllMachines.getType(), fetchAllMachinesSaga);
}
