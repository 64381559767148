import React from 'react';
import { InjectedFormProps } from 'redux-form';
import * as form from '../../../../redux/constants/analyzer.form.constants';
import formsPlaceholdersMessages from '../../../../messages/forms.placeholders.messages';
import commonCoreMessages from '../../../../messages/common.core.messages';
import ModalLine from '../../core/ModalLine';
import AddModalFooter from '../../core/footers/AddModalFooter';
import EditModalFooter from '../../core/footers/EditModalFooter';
import { FormField } from '../../../_tools';
import { reduxForm } from 'redux-form';
import { ANALYZER_SESSION_PANEL_FORM } from '../../../../redux/constants/analyzer.form.constants';

interface FormPanelSession {
  [form.NAME_FIELD]: string;
}

interface Props {
  handleCancel: () => void;
  isEditForm: boolean;
}

type SessionFormProps = InjectedFormProps<FormPanelSession, Props> & Props;

const SessionForm: React.FC<SessionFormProps> = ({
  handleSubmit,
  handleCancel,
  submitting,
  pristine,
  isEditForm,
}) => (
  <form className='form' onSubmit={handleSubmit}>
    <div className='modal-form-in'>
      <FormField
        label={commonCoreMessages.name.id}
        name={form.NAME_FIELD}
        placeholder={formsPlaceholdersMessages.name.id}
        tabIndex={1}
        maxLength='255'
        autoFocus
      />
    </div>
    <ModalLine />
    {isEditForm ? (
      <EditModalFooter
        onCancelClick={handleCancel}
        onSaveClick={handleSubmit}
        isAddButtonDisabled={pristine || submitting}
        isAddButtonLoading={submitting}
        cancelButtonTabIndex={7}
        addButtonTabIndex={8}
      />
    ) : (
      <AddModalFooter
        onCancelClick={handleCancel}
        onAddClick={handleSubmit}
        isAddButtonDisabled={pristine || submitting}
        isAddButtonLoading={submitting}
        cancelButtonTabIndex={7}
        addButtonTabIndex={8}
      />
    )}
  </form>
);

const currentForm = {
  form: ANALYZER_SESSION_PANEL_FORM,
  destroyOnUnmount: true,
};
export default reduxForm<FormPanelSession, Props>(currentForm)(SessionForm);
