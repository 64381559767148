"use strict";
import { createSelector } from "reselect";
export const getUser = (state) => state.profile.user;
export const position = (state) => state.account.user.position;
export const company = (state) => state.account.user.accountName;
export const firstName = (state) => state.account.user.firstName;
export const middleName = (state) => state.account.user.middleName;
export const lastName = (state) => state.account.user.lastName;
export const id = (state) => state.account.user.id;
export const avatarId = (state) => state.account.user.avatarId;
export const accountName = createSelector(
  firstName,
  middleName,
  lastName,
  (first, middle, last) => `${first}${middle ? ` ${middle} ` : " "}${last}`
);
export const code = (state) => state.account.user.code;
