import React from 'react';
import * as RadixContextMenu from '@radix-ui/react-context-menu';

import * as helper from './helper';

export const MenuItem: React.FC<RadixContextMenu.ContextMenuItemProps> = ({
  onSelect,
  children,
  className,
  ...props
}) => (
  <RadixContextMenu.Item
    onSelect={onSelect}
    className={`
      ${className ?? helper.itemClass}
      ${props.disabled ? helper.itemDisabledClass : helper.itemEnabledClass}`}
    {...props}
  >
    {children}
  </RadixContextMenu.Item>
);
