import React from 'react';
import { FormattedMessage } from 'react-intl';
import placeholdersMessages from '../../../messages/placeholders.messages';

type ContentStateType = 'empty' | 'loading' | 'error';

interface ContentStateProps {
  type?: ContentStateType;
  icon?: string;
  className?: string;
  messageId: string;
  backgroundColor?: string;
}

const defaultIcons: Record<ContentStateType, string> = {
  empty: 'fa-database',
  loading: 'fa-spinner fa-spin',
  error: 'fa-exclamation-circle',
};

export const SignalStateDisplay: React.FC<ContentStateProps> = ({
  type = 'empty',
  icon,
  className,
  messageId,
  backgroundColor,
}) => {
  const defaultMessageId = {
    empty: placeholdersMessages.noDataToDisplay,
    loading: placeholdersMessages.loading,
    error: placeholdersMessages.error,
  }[type];

  const iconClass = icon || defaultIcons[type];

  return (
    <div
      className={`content-state ${type} ${className || ''}`}
      style={
        backgroundColor
          ? ({ '--base-bg-color': backgroundColor } as React.CSSProperties)
          : undefined
      }
    >
      <i className={`fa ${iconClass}`} />
      <span className='content-state__message'>
        <FormattedMessage {...(messageId ? { id: messageId } : defaultMessageId)} tagName='div' />
      </span>
    </div>
  );
};
