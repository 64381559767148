"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import {
  put,
  call,
  select,
  takeEvery
} from "redux-saga/effects";
import { getDatatron, getDeviceById } from "../selectors/datatron.selector";
import * as api from "../../core/api";
import { compareById, replaceObjectInList, removeObjectFromList } from "../../../../common/helpers/object";
import { closeModal } from "./modals.module";
import { DP_ARCHIVE_DEVICE } from "../constants/modals.constants";
import notificationMessage from "../../messages/notification.message";
import { sendNotification } from "./notifications.module";
export const archiveDevice = createAction(
  "archive datatron device",
  (deviceId) => ({ payload: deviceId })
);
export const archiveDeviceSuccess = createAction(
  "archive datatron device - success",
  (deviceId) => ({ payload: deviceId })
);
export const archiveDeviceFail = createAction(
  "archive datatron device - fail",
  (deviceId, error) => ({ payload: { deviceId, error } })
);
export const reducer = {
  [archiveDevice.type]: (state, deviceId) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      _archive: __spreadProps(__spreadValues({}, device._archive), {
        loading: true,
        loaded: false,
        error: null
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById
          )
        })
      })
    });
  },
  [archiveDeviceSuccess.type]: (state, deviceId) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: removeObjectFromList(
            state.datatron.devices.list,
            device,
            compareById
          )
        })
      })
    });
  },
  [archiveDeviceFail.type]: (state, { deviceId, error }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      _archive: __spreadProps(__spreadValues({}, device._archive), {
        loading: false,
        loaded: false,
        error
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById
          )
        })
      })
    });
  }
};
export function* archiveDeviceSaga({ payload: deviceId }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const {
    response,
    error
  } = yield call(api.datatrons.archiveDevice, datatron.id, deviceId);
  if (response) {
    yield put(archiveDeviceSuccess(deviceId));
    yield put(closeModal(DP_ARCHIVE_DEVICE));
    yield put(sendNotification(notificationMessage.device_archived_title));
  } else {
    yield put(archiveDeviceFail(deviceId, error));
  }
}
export function* watchArchiveDevice() {
  yield takeEvery(archiveDevice, archiveDeviceSaga);
}
