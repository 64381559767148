"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { startSubmit, stopSubmit } from "redux-form";
import { push } from "connected-react-router";
import { closeModal } from "./modals.module";
import { sendNotification } from "./notifications.module";
import { ANALYZER_SESSION_FORM } from "../constants/analyzer.form.constants";
import {
  ANALYZER_ANOMALY_CREATE_SESSION,
  ANALYZER_SESSION_ADD
} from "../constants/modals.constants";
import notificationMessages from "../../messages/notification.message";
import { getAnalyzerSessionEditLink } from "../../components/index/routes";
import * as notification from "../../core/notifications";
import { addNewSession } from "../../core/api/analyzer";
import { validateData } from "../../core/validation/validateFieldsBaseOnSchema";
import {
  sessionCreateBodySchema
} from "../../../../common/validation/analyzer.validation";
import { isModalOpen } from "../selectors/modals.selector";
export const createSession = createAction(
  "create session",
  (datatronUuid, name, devicesUuids) => ({
    payload: { datatronUuid, name, devicesUuids }
  })
);
export function* createSessionSaga({
  payload: { datatronUuid, name, devicesUuids }
}) {
  let payload = { datatronUuid, name, devicesUuids };
  const state = yield select();
  if (!isModalOpen(state, ANALYZER_SESSION_ADD)) {
    const panels = state.analyzer.panels.allIds.map((panelId) => {
      const panel = state.analyzer.panels.byId[panelId].data;
      return {
        name: panel.name,
        rangeDeltaMs: panel.rangeDeltaMs,
        rangeFrom: panel.rangeFrom,
        panelSignals: state.analyzer.panelSignals.allIds.filter(
          (panelSignalId) => state.analyzer.panelSignals.byId[panelSignalId].data.panelId === panel.id
        ).map((panelSignalId) => {
          const panelSignal = state.analyzer.panelSignals.byId[panelSignalId].data;
          return {
            chartType: panelSignal.chartType,
            color: panelSignal.color,
            height: panelSignal.height,
            interpolationType: panelSignal.interpolationType,
            order: panelSignal.order,
            datapointUuid: panelSignal.signal.uuid
          };
        })
      };
    });
    payload = {
      anomalyId: state.analyzer.anomalies.allIds[0],
      datatronUuid,
      devicesUuids,
      name,
      panels
    };
  } else {
    yield put(startSubmit(ANALYZER_SESSION_FORM));
    const validationResult = validateData(sessionCreateBodySchema, {
      datatronUuid,
      name,
      devicesUuids
    });
    if (validationResult) {
      yield put(stopSubmit(ANALYZER_SESSION_FORM, validationResult));
      return;
    }
  }
  const { response, error } = yield call(addNewSession, payload);
  if (isModalOpen(state, ANALYZER_SESSION_ADD)) {
    yield put(stopSubmit(ANALYZER_SESSION_FORM));
    yield put(closeModal(ANALYZER_SESSION_ADD));
  } else {
    yield put(closeModal(ANALYZER_ANOMALY_CREATE_SESSION));
  }
  if (response) {
    const { id } = response;
    yield put(push(getAnalyzerSessionEditLink(id)));
  } else {
    yield put(
      sendNotification(
        __spreadProps(__spreadValues({}, notificationMessages.something_happened), {
          values: { error }
        }),
        null,
        notification.NOTIFICATION_ERROR
      )
    );
  }
}
export function* watchCreateSession() {
  yield takeLatest(createSession, createSessionSaga);
}
