import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import Button from '../../_tools/button/Button';
import { openModal } from '../../../redux/modules/modals.module';
import { ANALYZER_SESSION_ADD } from '../../../redux/constants/modals.constants';

const AddButton: React.FC = () => {
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(openModal(ANALYZER_SESSION_ADD));
  };

  return (
    <Button className='button green' onClick={handleOpenModal}>
      <span className='fa fa-plus' />
      <FormattedMessage defaultMessage='Add Session' id='analyzer.modals.add_session' />
    </Button>
  );
};

export default AddButton;
