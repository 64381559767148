"use strict";
import { createSelector } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
const getAnalyzerMainState = (state) => state.analyzer.main;
const getAnalyzerSessionsState = (state) => state.analyzer.sessions;
const getAnalyzerPanelsState = (state) => state.analyzer.panels;
export const getAnalyzerPanelSignalsState = (state) => state.analyzer.panelSignals;
export const getAnalyzerSignalsState = (state) => state.analyzer.signals;
export const getSessionsLocation = createSelector([getAnalyzerMainState], (main) => main.data);
export const selectAnalyzerError = createSelector([getAnalyzerMainState], (main) => main.error);
export const analyzerLoading = createSelector([getAnalyzerMainState], (main) => main.isLoading);
export const isAnalyzerEmpty = createSelector([getSessionsLocation], (data) => isEmpty(data));
export const getLocationById = createSelector(
  [getSessionsLocation, (_, locationId) => locationId],
  (locations, locationId) => locations.findIndex(({ id }) => id === locationId)
);
export const getSession = createSelector(
  [getAnalyzerSessionsState, (_, sessionId) => sessionId],
  (sessions, sessionId) => sessionId && sessions.byId[sessionId] ? sessions.byId[sessionId].data : null
);
export const getSessionLocation = createSelector(
  [getSession],
  (session) => (session == null ? void 0 : session.locationPath) || []
);
export const sessionsError = createSelector(
  [getAnalyzerSessionsState, (_, sessionId) => sessionId],
  (sessions, sessionId) => sessionId && sessions.byId[sessionId] ? sessions.byId[sessionId].error : null
);
export const sessionsLoading = createSelector(
  [getAnalyzerSessionsState, (_, sessionId) => sessionId],
  (sessions, sessionId) => sessionId && sessions.byId[sessionId] ? sessions.byId[sessionId].isLoading : true
);
export const getPanels = createSelector([getAnalyzerPanelsState], (panels) => panels.allIds);
export const getPanelById = createSelector(
  [getAnalyzerPanelsState, (_, id) => id],
  (panels, id) => panels.byId[id].data
);
export const panelIsLoading = createSelector(
  [getAnalyzerPanelsState, (_, id) => id],
  (panels, id) => panels.byId[id].isLoading
);
export const getToolByPanelId = createSelector([getPanelById], (panel) => panel.tool);
export const getTimeByPanelId = createSelector([getPanelById], (panel) => panel.time);
export const getPanelSettingsByPanelId = createSelector([getPanelById], (panel) => panel.settings);
export const getPanelStatus = createSelector(
  [getAnalyzerPanelsState, (_, id) => id],
  (panels, id) => panels.byId[id].status
);
export const getSignalsByPanelId = createSelector(
  [getAnalyzerPanelSignalsState, (_, panelId) => panelId],
  (panelSignals, panelId) => panelSignals.allIds.filter((id) => panelSignals.byId[id].data.panelId === panelId).map((id) => panelSignals.byId[id].data)
);
export const getSignalByPanelIdAndSignalId = createSelector(
  [getSignalsByPanelId, (_, __, signalId) => signalId],
  (signals, signalId) => signals.find((signal) => signal.id === signalId)
);
export const getSignalsByPanelSignalId = createSelector(
  [getAnalyzerPanelSignalsState, (_, panelSignalId) => panelSignalId],
  (panelSignals, panelSignalId) => {
    const signals = panelSignals.allIds.filter((id) => panelSignals.byId[id].data.id === panelSignalId).map((id) => panelSignals.byId[id].data);
    return signals[0];
  }
);
export const getSignals = createSelector([getAnalyzerSignalsState], (signals) => signals.data);
const getAnomalyState = (state) => state.analyzer.anomalies;
export const getAnomaly = createSelector(
  [getAnomalyState, (_, id) => id],
  (anomalies, id) => anomalies.byId[id] ? anomalies.byId[id].data : null
);
export const getAnomalyLocation = createSelector(
  [getAnomaly],
  (anomaly) => anomaly ? anomaly.locationPath || [] : []
);
export const anomalyError = createSelector(
  [getAnomalyState, (_, id) => id],
  (anomalies, id) => anomalies.byId[id] ? anomalies.byId[id].error : null
);
export const anomalyLoading = createSelector(
  [getAnomalyState, (_, id) => id],
  (anomalies, id) => anomalies.byId[id] ? anomalies.byId[id].isLoading : true
);
