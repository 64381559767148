import React, { useCallback, useMemo } from 'react';
import Select from 'react-select';
import { injectIntl, IntlShape, MessageDescriptor } from 'react-intl';

interface UnitSelectProps {
  options: Array<{
    label: MessageDescriptor;
    value: string;
  }>;
  intl: IntlShape;
  change: (value: string) => void;
  value: string;
}

interface LocalizedOption {
  label: string;
  value: string;
}

export function UnitSelect({ options, intl, change, value }: UnitSelectProps) {
  const optionsLocalized = useMemo<LocalizedOption[]>(
    () =>
      options.map((option) => ({
        label: intl.formatMessage(option.label, { count: -1 }),
        value: option.value,
      })),
    [options, intl],
  );

  const selectedOption = useMemo<LocalizedOption | null>(
    () => optionsLocalized.find((option) => option.value === value) || null,
    [optionsLocalized, value],
  );

  const onSelectChange = useCallback(
    (option: LocalizedOption | null) => {
      if (option) {
        change(option.value);
      }
    },
    [change],
  );

  return (
    <Select<LocalizedOption>
      value={selectedOption}
      isClearable={false}
      onChange={onSelectChange}
      options={optionsLocalized}
    />
  );
}

export default injectIntl(UnitSelect);
