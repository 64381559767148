"use strict";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NOT_LOADED } from "../signalChart/CursorLabel";
import { useChartData } from "../hooks/useChartData";
import { calculateCursorValue, getTimeRange } from "../signalChart/Helper";
import { dateToUnixNano } from "../../helper/time";
import { getPanelById, getTimeByPanelId } from "../../../redux/selectors/analyzer.selector";
export const useSignalData = (panelId, signalUuid) => {
  const { rangeDeltaMs, rangeFrom } = useSelector((state) => getPanelById(state, panelId));
  const { cursorUnixNano } = useSelector((state) => getTimeByPanelId(state, panelId));
  const timeRange = useMemo(() => getTimeRange(rangeFrom, rangeDeltaMs), [rangeFrom, rangeDeltaMs]);
  const [isLoading, setIsLoading] = useState(false);
  const { signalData, error } = useChartData(signalUuid, timeRange, setIsLoading);
  return {
    signalData,
    isLoading,
    error,
    timeRange,
    cursorUnixNano,
    fromUnixNano: dateToUnixNano(timeRange.fromDate),
    toUnixNano: dateToUnixNano(timeRange.toDate),
    cursorValue: isLoading ? NOT_LOADED : calculateCursorValue(signalData == null ? void 0 : signalData.data, cursorUnixNano)
  };
};
