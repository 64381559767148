"use strict";
export const INITIAL_RANGE_FROM = /* @__PURE__ */ new Date();
export const INITIAL_RANGE_DELTA_MS = 60 * 1e3;
export const MIN_RANGE_DELTA_MS = 100;
export const MIN_RANGE_STEP_MS = 100;
export const MAX_RANGE_DELTA_MS = 60 * 60 * 1e3;
export const INITIAL_COLOR = "#0f172a";
export const MIN_SIGNAL_HEIGHT = 40;
export const MAX_SIGNAL_HEIGHT = 500;
export const INITIAL_SIGNAL_HEIGHT = 80;
export const INITIAL_SIGNAL_HEIGHT_BOOL = 50;
export const INITIAL_SIGNAL_HEIGHT_STRING = 50;
export const INITIAL_LABEL_WIDTH = 300;
export const MIN_LABEL_WIDTH = 200;
export const COMMENT_FIELD_MAX_LENGTH = 500;
