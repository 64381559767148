import Modal from '../../../_tools/modal/Modal';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { closeModal } from '../../../../redux/modules/modals.module';
import { DP_COMMAND_HISTORY } from '../../../../redux/constants/modals.constants';
import { useDispatch, useSelector } from 'react-redux';
import { Todo } from '../../../../../../common/types/common';
import { getModalPayload, selectCommandHistory } from '../../../../redux/selectors/modals.selector';
import {
  fetchCommandHistory,
  setCommandHistory,
} from '../../../../redux/modules/datatron.devices.command.module';
import { CommandHistoryDisplay } from './commandHistoryDisplay';
import { AbridgedCommand } from '../../../../../../common/types/api/datatron.devices';
import { AppState } from '../../../../redux/state/app.state';
import {
  selectCommandStateDeviceId,
  selectCommandStateError,
  selectCommandStateIsSubmitting,
  selectCommandStateIsSuccess,
} from '../../../../redux/selectors/modal.commandState.selector';
import { Loader } from '../../../_tools';
import modalMessages from '../../../../messages/command.modal.messages';

type Props = {
  deviceId: string;
} & Todo;

export type CommandHistoryState = 'default' | 'loading' | 'done' | 'error';

const modalPayloadSelector = (state: AppState) => getModalPayload(state, DP_COMMAND_HISTORY);

export const CommandHistoryModal: React.FC<Props> = () => {
  const [commandHistoryState, setCommandHistoryState] = useState<CommandHistoryState>('default');
  /**
   * pageCount is always one lower than the actual, human-readable page count displayed in the pagination element
   */
  const [pageCount, pageCountSet] = useState(0);

  const { deviceId } = useSelector(modalPayloadSelector);
  const commandHistory: AbridgedCommand[] = useSelector(selectCommandHistory);
  const commandStateDeviceId = useSelector(selectCommandStateDeviceId);
  const commandSuccess = useSelector(selectCommandStateIsSuccess);
  const commandError = useSelector(selectCommandStateError);
  const submitting = useSelector(selectCommandStateIsSubmitting);

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const close = () => {
    dispatch(closeModal(DP_COMMAND_HISTORY));
    setCommandHistory({
      commands: [],
      total: 0,
    });
  };

  /**
   * Set the page number to navigate to and fetch the data for that page.
   *
   * @param page {number} The human-readable page number.
   */
  const choosePage = (page: number) => {
    pageCountSet(page - 1);

    const offset = (page - 1) * 10;
    dispatch(fetchCommandHistory(deviceId, { offset, length: 10 }));
  };

  useEffect(() => {
    if (commandStateDeviceId === deviceId) {
      if (submitting) {
        setCommandHistoryState('loading');
      } else if (commandSuccess) {
        setCommandHistoryState('done');
      } else if (commandError) {
        setCommandHistoryState('error');
      }
    }
  }, [commandError, commandStateDeviceId, commandSuccess, deviceId, submitting]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Select pageCount + 1 , because pageCount is 1 less than the actual human readable page
    choosePage(pageCount + 1);
  };

  return (
    <Modal
      isOpen
      onClose={close}
      contentLabel=''
      modalClass={commandHistoryState == 'done' ? 'modal-content-wide' : ''}
    >
      <div className='modal--header' onClick={close}>
        <span className='modal--close'>&times;</span>
      </div>
      <div className='modal--content'>
        <FormattedMessage
          defaultMessage='Latest Commands for this Device'
          id='datatron.modals.command_history.loaded'
          tagName='h3'
        />

        {commandHistoryState == 'loading' && <Loader show fullPage={false} />}
        {commandHistoryState == 'done' && (
          <CommandHistoryDisplay
            commandHistory={commandHistory}
            pageCount={pageCount + 1}
            choosePage={choosePage}
          />
        )}
        {commandHistoryState === 'error' && (
          <h4 className='flexed error-text-red'>
            {formatMessage(modalMessages.error)}: {commandError}
          </h4>
        )}

        <div className='line' />
        <div className='text-center'>
          <button
            type='button'
            className={commandHistoryState !== 'done' ? 'button__cancel' : 'button__save'}
            onClick={close}
          >
            {commandHistoryState !== 'done' && (
              <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
            )}
            {commandHistoryState === 'done' && (
              <FormattedMessage id='common.close' defaultMessage='Close' />
            )}
          </button>
          {commandHistoryState === 'done' && (
            <button type='submit' className='button__cancel' onClick={handleSubmit}>
              <FormattedMessage
                defaultMessage='Reload'
                id='datatron.modals.command_history.reload'
              />
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
