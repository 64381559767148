"use strict";
export const modalsInitialState = {
  visible: {},
  payloads: {},
  paths: {}
};
export const modalsTestState = {
  visible: {
    DP_ADD_DEVICE: true,
    DP_ADD_DATA_POINT: true,
    DP_EDIT_DATA_POINT: true,
    DP_PUBLISH_DEVICE: true,
    AP_LOCATION_REMOVE: true,
    AP_ORGANIZATION_REMOVE: true
  },
  payloads: {
    DP_ADD_DEVICE: {
      deviceClass: "PLC"
    },
    DP_EDIT_DEVICE: {
      device: {
        id: 111,
        name: "device name",
        deviceUUID: "device uuid",
        serialNumber: "serial number",
        config: {
          payloadSize: 123,
          profinetName: "network name"
        },
        deviceType: {
          id: "NTE1MjY4MzcyNzM5NjU=",
          deviceClass: "PLC"
        }
      }
    },
    DP_PUBLISH_DEVICE: {
      deviceId: 12345
    },
    CP_CALENDAR_EDIT: {
      calendar: {
        id: 1,
        name: "name",
        type: "type"
      }
    },
    REPORT_LEGEND_DATA_POINT: {
      filter: {
        color: "aaaaaa",
        axis: "Y_LEFT_AXIS",
        lineWidth: 3,
        fillOpacity: 0.5,
        dataPoint: {
          id: "200",
          label: "super data point"
        }
      },
      reportId: "100"
    },
    REPORT_EDIT: {
      report: {
        id: "456",
        name: "test",
        type: "kpi",
        filters: {
          machines: [{ id: "555", name: "Machine 555" }]
        },
        reportsTab: {
          id: "666",
          name: "tab 666"
        },
        from: "yesterday",
        to: "today",
        fromRelative: { count: 5, unit: "days" },
        toRelative: { count: 3, unit: "days" },
        datesMode: "RELATIVE"
      }
    },
    DP_ADD_DATA_POINT: {
      deviceId: "MTE1MjY4MzcyNzM5NjU=",
      deviceType: {
        dataPointFields: [
          {
            _key: "label",
            _required: true,
            _parent: [],
            type: "string"
          },
          {
            _key: "length",
            _required: true,
            _parent: [],
            type: "integer",
            minimum: 1,
            maximum: 1e3
          }
        ]
      }
    },
    DP_EDIT_DATA_POINT: {
      deviceId: "MTE1MjY4MzcyNzM5NjU=",
      deviceType: {
        dataPointFields: [
          {
            _key: "extraId",
            _required: true,
            _parent: [],
            type: "string"
          },
          {
            _key: "length",
            _required: true,
            _parent: [],
            type: "integer",
            minimum: 1,
            maximum: 1e3
          }
        ]
      },
      dataPointId: "OTE1MjY4MzcyNzM5NjU="
    },
    DP_VIEW_DATA_POINT: {
      deviceId: "MTE1MjY4MzcyNzM5NjU=",
      dataPointId: "OTE1MjY4MzcyNzM5NjU="
    },
    TOOL_EDIT: {
      tool: {
        id: 123,
        label: "label 123",
        type: "type 123",
        model: "model 123",
        state: "mounted",
        machine: {
          id: 456,
          name: "machine 456"
        }
      }
    },
    TOOL_DETAILS: {
      tool: {
        id: 123,
        label: "label 123",
        type: "type 123",
        model: "model 123",
        state: "mounted",
        machine: {
          id: 456,
          name: "machine 456"
        }
      }
    },
    MEASUREMENT_MERGE: {
      measurement: {
        id: 12345
      }
    },
    MEASUREMENT_ARCHIVE: {
      measurement: {
        id: 12345
      }
    },
    EXPERIMENT_ARCHIVE: {
      experiment: {
        id: 12345
      }
    }
  },
  paths: {
    TEST_MODAL_ID: ["ROOT", "CHILD_ONE"]
  }
};
