"use strict";
import { find, filter, isEmpty } from "lodash";
export const getSubscriptions = (state) => state.machine.dataPoints.list || [];
export const getSubscriptionsCount = (state) => getSubscriptions(state).length;
export const getPagination = (state) => state.machine.dataPoints.pagination;
export const isLoading = (state) => state.machine.dataPoints.loading;
export const getSubscriptionById = (state, id) => find(getSubscriptions(state), { id });
export const getFilters = (state) => state.machine.dataPoints.filters;
export const isSubscribing = (state) => state.machine.dataPoints.subscribe.loading;
export const getFilteredSubscriptions = (state) => {
  const pagination = getPagination(state);
  const all = getSubscriptions(state).slice(0, pagination.current);
  let result = [...all];
  const filters = getFilters(state);
  if (filters && !isEmpty(filters.category)) {
    result = filter(
      all,
      (item) => filters.category.indexOf(item.category) !== -1
    );
  }
  return result;
};
