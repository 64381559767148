"use strict";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CycleDetectionCreate from "./CycleDetectionCreate";
import { createAIAppCycleDetection } from "../../../../redux/modules/aiApp.cycleDetection.create.module";
import { closeModal } from "../../../../redux/modules/modals.module";
import { CYCLE_DETECTION_ADD } from "../../../../redux/constants/modals.constants";
const mapStateToProps = () => ({
  isAddForm: true
});
const mapDispatchToProps = (dispatch) => ({
  createCycleDetection: bindActionCreators(createAIAppCycleDetection, dispatch),
  close: () => dispatch(closeModal(CYCLE_DETECTION_ADD))
});
export default connect(mapStateToProps, mapDispatchToProps)(CycleDetectionCreate);
