"use strict";
export const getAnomalyData = (state, id) => state.anomaly.list[id] || null;
export const isLoading = (state, id) => {
  const anomalyData = getAnomalyData(state, id);
  return anomalyData ? anomalyData.loading : null;
};
export const isLoaded = (state, id) => {
  const anomalyData = getAnomalyData(state, id);
  return anomalyData ? anomalyData.loaded : null;
};
export const getEvent = (state, id) => {
  const anomalyData = getAnomalyData(state, id);
  return anomalyData ? anomalyData.event : null;
};
export const getAnomaly = (state, id) => {
  const eventData = getEvent(state, id);
  return eventData ? eventData.payload : null;
};
export const isLazyLoadRequired = (state, id) => !(getAnomalyData(state, id).noExtraLazyLoad === true);
