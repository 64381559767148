"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import {
  EXPERIMENTS_MANAGER,
  EXPERIMENTS_VIEWER
} from "../constants/permissions";
export default class ExperimentsPermissions {
  constructor(roles, config) {
    __publicField(this, "isExperimentsManager");
    __publicField(this, "isExperimentsViewer");
    if (!(config == null ? void 0 : config.features.experiments)) {
      this.isExperimentsManager = false;
      this.isExperimentsViewer = false;
    } else {
      this.isExperimentsManager = roles.indexOf(EXPERIMENTS_MANAGER) !== -1;
      this.isExperimentsViewer = roles.indexOf(EXPERIMENTS_VIEWER) !== -1;
    }
  }
  canAddExperiment() {
    return this.isExperimentsManager;
  }
  canEditExperiment() {
    return this.isExperimentsManager;
  }
  canArchiveExperiment() {
    return this.isExperimentsManager;
  }
  canAddMeasurement() {
    return this.isExperimentsManager;
  }
  canEditMeasurement() {
    return this.isExperimentsManager;
  }
  canArchiveMeasurement() {
    return this.isExperimentsManager;
  }
  canMergeMeasurement() {
    return this.isExperimentsManager;
  }
  canViewExperiments() {
    return this.isExperimentsManager || this.isExperimentsViewer;
  }
  canViewMeasurements() {
    return this.isExperimentsManager || this.isExperimentsViewer;
  }
}
