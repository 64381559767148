"use strict";
import { createSelector } from "reselect";
import { isEmpty } from "lodash";
export const getMachines = (state) => state.inventory.machines;
export const getDatatrons = (state) => state.inventory.datatrons;
export const selectInventoryLocations = (state) => state.inventory.locations;
export const selectInventoryError = (state) => state.inventory.error;
export const previewItem = (state) => state.inventory.preview.item;
export const previewItemType = (state) => state.inventory.preview.itemType;
export const inventoryLoading = (state) => state.inventory.loading;
export const isInventoryEmpty = (state) => isEmpty(getMachines(state)) && isEmpty(getDatatrons(state));
export const getMachine = createSelector(
  getMachines,
  (state, machineId) => machineId,
  (machines, machineId) => machines.find((machine) => machine.id === machineId)
);
export const selectIsPreviewSelected = (state) => previewItem(state) !== null;
export const getLocationById = (state, locationId) => selectInventoryLocations(state).findIndex(({ id }) => id === locationId);
