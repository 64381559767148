import React from 'react';
import { Link } from 'react-router-dom';
import {
  getAnalyzerSessionEditLink,
  getLocationLink,
  getAnalyzerAnomalyEditLink,
} from '../../index/routes';
import {
  ANOMALIES_WITH_DATATRON,
  SESSIONS_WITH_DATATRON,
} from '../../../../../common/constants/inventory';
import { InventoryItem } from '../../../core/propTypes';

import { AnalyzerAnomalyDatatron, AnalyzerSessionDatatron } from './AnalyzerDatatron';
const getPath = (path: string[]): string => path.join(' - ');

type Props = {
  location: InventoryItem;
  showArchived: boolean;
};

export const SessionLocation: React.FC<Props> = ({ location, showArchived }) => (
  <div className='analyzer-block'>
    <div className='title'>
      {/* @ts-ignore */}
      <Link to={getLocationLink(location.id)} className='links links-big'>
        <span className='icon icon-location' />
        <span className='links-text'>{location.name}</span>
      </Link>
      <p>{getPath(location.path)}</p>
    </div>

    <div className='analyzer-wrap'>
      <div className='analyzer'>
        {location[SESSIONS_WITH_DATATRON].filter(
          (anomaly) => showArchived === anomaly.archived,
        ).map((session) => (
          <div className='analyzer--col' key={session.id}>
            {/* @ts-ignore */}
            <Link to={getAnalyzerSessionEditLink(session.id)}>
              <AnalyzerSessionDatatron session={session} />
            </Link>
          </div>
        ))}
        {location[ANOMALIES_WITH_DATATRON].filter(
          (anomaly) => showArchived === anomaly.archived,
        ).map((anomaly) => (
          <div className='analyzer--col' key={anomaly.id}>
            {/* @ts-ignore */}
            <Link to={getAnalyzerAnomalyEditLink(anomaly.id)}>
              <AnalyzerAnomalyDatatron anomaly={anomaly} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  </div>
);
