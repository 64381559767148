import React, { useCallback } from 'react';
import { pick } from 'lodash';

import { DATE_FROM, DATE_TO } from '../../../../../../common/constants';
import * as datesModes from '../../../../../../common/enums/dates.modes';
import DropdownTabs from './tabs/DropdownTabs';
import DropdownAbsolute, { DropdownAbsoluteProps } from './absolute/DropdownAbsolute';
import DropdownRelative from './relative/DropdownRelative';
import { Todo, TodoFunction } from '../../../../../../common/types/common';
import { DateProps } from '../../../../core/propTypes/propTypes';
import { useClickOutside } from '../../../../core/common/hooks/useClickOutside';

const ABSOLUTE_DROPDOWN_PROPS = [
  DATE_FROM,
  DATE_TO,
  'setAbsoluteDate',
  'setAbsoluteDates',
  'useConfirmationFooter',
  'onCalendarHover',
  'cancel',
  'pristine',
  'showAbsoluteDatesPresets',
  'fromLabel',
  'toLabel',
  'allowFuture',
];

interface Props {
  // @ts-ignore
  [DATE_FROM]: DateProps;
  // @ts-ignore
  [DATE_TO]: DateProps;
  fromRelative: Todo;
  toRelative: Todo;
  setAbsoluteDate: TodoFunction;
  setRelativeDate: TodoFunction;
  setDatesMode: TodoFunction;
  datesMode: string;
  onCalendarHover?: TodoFunction;
  closeDropdown: TodoFunction;
  keepDropdownAlwaysOpen: boolean;
  useConfirmationFooter: boolean;
  showDatesModeBar: boolean;
  showAbsoluteDatesPresets: boolean;
  cancel: TodoFunction;
  fromLabel: Todo;
  toLabel: Todo;
  allowFuture: boolean;
  pristine: boolean;
}

export const Dropdown = (props: Props) => {
  if (!props.onCalendarHover) props.onCalendarHover = () => {};

  const {
    setDatesMode,
    datesMode,
    closeDropdown,
    keepDropdownAlwaysOpen,
    showDatesModeBar,
    cancel,
    pristine,
    useConfirmationFooter,
    setRelativeDate,
    fromRelative,
    toRelative,
    fromLabel,
    toLabel,
  } = props;

  const confirm = useCallback(() => {
    if (keepDropdownAlwaysOpen) return;
    closeDropdown();
  }, [closeDropdown, keepDropdownAlwaysOpen]);

  const handleClickOutside = useCallback(() => {
    if (keepDropdownAlwaysOpen) return;
    cancel();
  }, [cancel, keepDropdownAlwaysOpen]);

  const absoluteProps = {
    confirm,
    ...pick(props, ABSOLUTE_DROPDOWN_PROPS),
  } as DropdownAbsoluteProps;

  const relativeProps = {
    confirm,
    pristine,
    cancel,
    useConfirmationFooter,
    setRelativeDate,
    fromRelative,
    toRelative,
    fromLabel,
    toLabel,
  };

  const clickRef = useClickOutside(handleClickOutside);

  return (
    <div className='datepicker-dropdowns' onClick={(e) => e.preventDefault()} ref={clickRef}>
      {showDatesModeBar && <DropdownTabs select={setDatesMode} selected={datesMode} />}
      {datesMode === datesModes.ABSOLUTE && <DropdownAbsolute {...absoluteProps} />}
      {datesMode === datesModes.RELATIVE && <DropdownRelative {...relativeProps} />}
    </div>
  );
};

export default Dropdown;
