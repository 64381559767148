"use strict";
import { formValueSelector } from "redux-form";
import { find, sortBy } from "lodash";
import * as MachineClusterTabConstants from "../constants/machine.cluster.tabs.constants";
import * as MachineSummaryConstants from "../constants/machine.summary.constants";
import {
  NEW_ASSISTANT_FORM_NAME,
  SELECTED_ASSISTANT_NAME,
  ASSISTANT_COMMENT_NAME
} from "../constants/machine.newAssistance.form.constants";
import {
  MACHINE_SET_CALENDARS_FORM_NAME,
  SHIFT_CALENDAR_ID,
  SCHEDULE_CALENDAR_ID
} from "../constants/machine.setCalendars.form.constants";
import { DATE_FROM, DATE_TO, SORT_WAY_DESC } from "../../../../common/constants";
export const events = (state) => state.machine.events;
export const hasEvents = (state) => state.machine.events && !!state.machine.events.length;
export const getChartType = (state) => state.machine.chartType;
export const getSelectedTab = (state) => state.machine.tab;
export const machine = (state) => state.machine.details;
export const getMachine = machine;
export const loading = (state) => !!state.machine.loading;
export const loaded = (state) => !!state.machine.loaded;
export const kpis = (state) => state.machine.kpi;
export const isLoading = (state) => loading(state);
export const getEventIndexById = (state, eventId) => state.machine.events.findIndex((event) => event.id === eventId);
export const sensors = (state) => state.machine.sensors;
export const isSensorExpanded = (state, sensorId) => !!state.machine.expandedSensors[sensorId];
export const hasSensors = (state) => state.machine.sensors && !!state.machine.sensors.length;
export function getDocuments(state) {
  return machine(state).documents;
}
export const isSummaryLoading = (state) => state.machine.summary.loading;
export const areEventsOfSummaryGroupLoading = (summaryGroup) => summaryGroup.events.loading;
export const areEventsOfSummaryGroupLoaded = (summaryGroup) => summaryGroup.events.loaded;
export const moreEventsAvailable = (summaryGroup) => summaryGroup.events.moreAvailable;
export const getEventsOfSummaryGroup = (summaryGroup) => summaryGroup.events.data;
export const getExpandedRowIndex = (state) => state.machine.summary.expandedRowIndex;
export const getMode = (summaryGroup) => summaryGroup.mode;
export const isClustersSummaryLoading = (state) => state.machine.clustersSummary.loading;
export const getClustersExpandedRowIndex = (state) => state.machine.clustersSummary.expandedRowIndex;
export const findSummaryGroup = (list, summaryGroup) => find(
  list,
  (group) => group.category === summaryGroup.category && group.title === summaryGroup.title
);
export const replaceSummaryGroup = (list, summaryGroup) => list.reduce((final, current) => {
  if (current.category === summaryGroup.category && current.title === summaryGroup.title) {
    return [
      ...final,
      summaryGroup
    ];
  }
  return [
    ...final,
    current
  ];
}, []);
export const areClustersOfClusterGroupLoaded = (clusterGroup) => clusterGroup.clusters.loaded;
export const areClustersOfClusterGroupLoading = (clusterGroup) => clusterGroup.clusters.loading;
export const getClustersOfClusterGroup = (clusterGroup) => clusterGroup.clusters.data;
export const areTailEventsOpen = (cluster) => cluster.displayMode === MachineClusterTabConstants.TAIL_EVENTS_LIST;
export const areCommentsOfHeadEventOpen = (cluster) => cluster.displayMode === MachineClusterTabConstants.HEAD_EVENT_COMMENTS;
export const areDetailsLoaded = (cluster) => cluster.details.loaded;
export const getGroupedEventsSort = (state) => state.machine.summary.sort;
export const getGroupedEventsFilters = (state) => state.machine.summary.filters;
export const areGroupedEventsFilterDatesSelected = (state) => {
  const dates = getGroupedEventsFilters(state);
  return !!dates[DATE_FROM] && !!dates[DATE_TO];
};
export const getGroupedEventsNameFilter = (state) => state.machine.summary.filters.query;
export const getClusteredEventsSort = (state) => state.machine.clustersSummary.sort;
export const getClusteredEventsFilters = (state) => state.machine.clustersSummary.filters;
export const areClusteredEventsFilterDatesSelected = (state) => {
  const dates = getClusteredEventsFilters(state);
  return !!dates[DATE_FROM] && !!dates[DATE_TO];
};
export const getClusteredEventsNameFilter = (state) => state.machine.clustersSummary.filters.query;
export const sortSummary = (list, sort) => {
  switch (sort.option) {
    case MachineSummaryConstants.SORT_BY_TITLE: {
      const sorted = sortBy(
        list,
        (item) => item.title
      );
      if (sort.way === SORT_WAY_DESC)
        return sorted.reverse();
      return sorted;
    }
    case MachineSummaryConstants.SORT_BY_COUNT: {
      const sorted = sortBy(
        list,
        (item) => item.amount
      );
      if (sort.way === SORT_WAY_DESC)
        return sorted.reverse();
      return sorted;
    }
    case MachineSummaryConstants.SORT_BY_LAST_SEEN: {
      const sorted = sortBy(
        list,
        (item) => item.latestAt
      );
      if (sort.way === SORT_WAY_DESC)
        return sorted.reverse();
      return sorted;
    }
    default:
      return list;
  }
};
export const getSummary = (state) => {
  const list = state.machine.summary.data;
  const sort = getGroupedEventsSort(state);
  return sortSummary(list, sort);
};
export const getClustersSummary = (state) => {
  const list = state.machine.clustersSummary.data;
  const sort = getClusteredEventsSort(state);
  return sortSummary(list, sort);
};
export const createFormSelector = (formName) => formValueSelector(formName);
export const getNewAssistantFormFields = (state) => createFormSelector(NEW_ASSISTANT_FORM_NAME)(
  state,
  SELECTED_ASSISTANT_NAME,
  ASSISTANT_COMMENT_NAME
);
export const getAssistants = (state) => state.machine.details.assistants;
export function getCalendarsFormFields(state) {
  return createFormSelector(MACHINE_SET_CALENDARS_FORM_NAME)(
    state,
    SCHEDULE_CALENDAR_ID,
    SHIFT_CALENDAR_ID
  );
}
export const getMachineCalendars = (state) => {
  const stateMachine = getMachine(state);
  return stateMachine && stateMachine.calendars ? stateMachine.calendars : [];
};
export const getMachineCalendarByType = (state, type) => find(getMachineCalendars(state), { type });
export const getCalendarViewDates = (state) => state.machine.calendars.view;
export const getDataPoints = (state) => state.machine.dataPoints.list;
export const selectedTab = (state) => state.machine.tab;
export const resetSelectedTab = (state) => state.machine.resetSelectedTab;
export const getAllMachines = (state) => state.machine.allMachines.list || [];
