import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  getSignalsByPanelId,
  getSession,
  getPanelById,
} from '../../../redux/selectors/analyzer.selector';
import { formatLocalDate } from '../../helper/time';
import { getTimeRange } from '../signalChart/Helper';
import { PrintPanelSignal } from './PrintPanelSignal';
import commonMessages from '../../../messages/common.messages';
import analyzerMessages from '../../../messages/analyzer.messages';

interface Props {
  sessionId: number;
  panelId: number;
}

export const PrintPanel: React.FC<Props> = ({ sessionId, panelId }) => {
  const { formatMessage } = useIntl();
  const panel = useSelector((state) => getPanelById(state, panelId));
  const panelSignals = useSelector((state) => getSignalsByPanelId(state, panelId));
  const timeRange = getTimeRange(panel.rangeFrom, panel.rangeDeltaMs);
  const session = useSelector((state) => getSession(state, sessionId));

  return (
    <div className='panel-print-container'>
      <div className='print-panel-header'>
        <div className='print-panel-info'>
          <div>
            <span className='font-bold'>{formatMessage(analyzerMessages.session)}:</span>{' '}
            {session.name}
          </div>
          <div>
            <span className='font-bold'>{formatMessage(commonMessages.panel)}:</span> {panel.name}
          </div>
          <div>
            <span className='font-bold'>{formatMessage(commonMessages.from)}:</span>{' '}
            {formatLocalDate(timeRange.fromDate)}
          </div>
          <div>
            <span className='font-bold'>{formatMessage(commonMessages.to)}:</span>{' '}
            {formatLocalDate(timeRange.toDate)}
          </div>
        </div>

        <PrintPanelSignal panelSignals={panelSignals} panelId={panelId} />
      </div>
    </div>
  );
};
