import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ANALYZER_SESSION_COMMAND } from '../../../redux/constants/modals.constants';
import { submitSessionQuickCommand } from '../../../redux/modules/analyzer.sessions.quickCommands.module';
import { closeModal } from '../../../redux/modules/modals.module';
import {
  selectCommandStateError,
  selectCommandStateIsSubmitting,
  selectCommandStateIsSuccess,
  selectCommandStateSessionId,
} from '../../../redux/selectors/modal.commandState.selector';
import { getModalPayload, selectCallbackCommandId } from '../../../redux/selectors/modals.selector';
import { AppState } from '../../../redux/state/app.state';
import { Loader } from '../../_tools';
import Modal from '../../_tools/modal/Modal';
import commonMessages from '../../../messages/common.messages';
import analyzerMessages from '../../../messages/analyzer.messages';
import modalMessages from '../../../messages/command.modal.messages';
import { IntlMessage } from '../../../core/common/intl';
import { TabCommandValue } from '../../../schema/type/session';
import notificationMessage from '../../../messages/notification.message';
import { COMMENT_FIELD_MAX_LENGTH } from '../../../../../common/constants/analyzer';

type CommandState = 'default' | 'submitting' | 'success' | 'error';
type ClassificationType = 'no_classification' | 'ignore' | 'valid';
const modalPayloadSelector = (state: AppState) => getModalPayload(state, ANALYZER_SESSION_COMMAND);

interface ModalPayload {
  id: number;
  command: TabCommandValue;
}

const TITLE: Record<TabCommandValue, IntlMessage> = {
  ARCHIVE_SESSION: analyzerMessages.archiveSessionTitle,
  DELETE_SESSION: analyzerMessages.deleteSessionTitle,
  ARCHIVE_ANOMALY: analyzerMessages.archiveAnomalyTitle,
  UNARCHIVE_ANOMALY: analyzerMessages.unarchiveAnomalyTitle,
  UNARCHIVE_SESSION: analyzerMessages.unarchiveSessionTitle,
};

export const SessionCommandModal: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [commandState, commandStateSet] = useState<CommandState>('default');
  const { id, command }: ModalPayload = useSelector(modalPayloadSelector);
  const [classification, setClassification] = useState<ClassificationType>('no_classification');
  const [comment, setComment] = useState('');

  const commandStateSessionId = useSelector(selectCommandStateSessionId);
  const commandSuccess = useSelector(selectCommandStateIsSuccess);
  const commandError = useSelector(selectCommandStateError);
  const submitting = useSelector(selectCommandStateIsSubmitting);
  const commandId = useSelector(selectCallbackCommandId);

  const close = useCallback(() => {
    dispatch(closeModal(ANALYZER_SESSION_COMMAND));
  }, [dispatch]);

  const handleSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      commandStateSet('submitting');
      event.preventDefault();
      dispatch(
        submitSessionQuickCommand(
          id,
          command,
          comment,
          classification === 'no_classification' ? undefined : classification,
        ),
      );
    },
    [command, id, dispatch, classification, comment],
  );

  useEffect(() => {
    if (commandId !== null && commandId < 0) {
      commandStateSet('error');
    }

    if (commandState === 'submitting' && !submitting && commandStateSessionId === id) {
      if (commandSuccess) {
        commandStateSet('success');
      } else if (commandError) {
        commandStateSet('error');
      }
    }
  }, [
    commandError,
    commandId,
    commandState,
    commandStateSessionId,
    commandSuccess,
    id,
    submitting,
  ]);

  const CLASSIFICATION_OPTIONS = [
    {
      value: 'no_classification',
      messageKey: analyzerMessages.noClassification,
    },
    {
      value: 'ignore',
      messageKey: analyzerMessages.ignoreClassification,
    },
    {
      value: 'valid',
      messageKey: analyzerMessages.validClassification,
    },
  ] as const;

  const renderClassificationOptions = () => {
    if (command !== 'ARCHIVE_ANOMALY' || commandState !== 'default') {
      return null;
    }

    return (
      <div className='classification-container'>
        <h4>{formatMessage(analyzerMessages.classification)}</h4>
        <div className='radio-group'>
          {CLASSIFICATION_OPTIONS.map(({ value, messageKey }) => (
            <label key={value} className='radio-label'>
              <input
                type='radio'
                name='classification'
                value={value}
                checked={classification === value}
                onChange={() => setClassification(value as ClassificationType)}
              />
              {formatMessage(messageKey)}
            </label>
          ))}
        </div>
      </div>
    );
  };
  const renderComment = () => {
    if (command === 'DELETE_SESSION' || commandState !== 'default') {
      return null;
    }

    return (
      <div className='form'>
        <label htmlFor='panelName'>
          <span>{formatMessage({ id: 'common.core.comment' })}:</span>
          <textarea
            id='comment-textarea'
            className='comment-textarea'
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={formatMessage({ id: 'common.core.comment' })}
            maxLength={COMMENT_FIELD_MAX_LENGTH}
            rows={4}
          />
          <div className='character-count'>
            {comment.length} / {COMMENT_FIELD_MAX_LENGTH}
          </div>
        </label>
      </div>
    );
  };

  return (
    <Modal isOpen onClose={close} contentLabel=''>
      <div className='modal--header' onClick={close}>
        <span className='modal--close'>&times;</span>
      </div>
      <div className='modal--content'>
        <h3>{formatMessage(TITLE[command])}</h3>
        {renderClassificationOptions()}
        {renderComment()}
        {commandState === 'submitting' && (
          <div style={{ height: '100%', width: '100%' }}>
            <Loader show fullPage={false} />
          </div>
        )}
        {commandState === 'success' && (
          <div className='session-alert success'>
            {formatMessage(notificationMessage.success_title)}
          </div>
        )}

        {commandState === 'error' && (
          <h4 className='flexed error-text-red'>
            {formatMessage(modalMessages.error)}: {commandError}
          </h4>
        )}
        <div className='line' />
        <div className='text-center'>
          {(commandState === 'default' || commandState === 'submitting') && (
            <button type='button' className='button__cancel' onClick={close}>
              {formatMessage(commonMessages.cancel)}
            </button>
          )}
          {commandState === 'default' && (
            <button type='submit' className='button__save' onClick={handleSubmit}>
              {formatMessage(commonMessages.save)}
            </button>
          )}
          {commandState === 'success' && (
            <button type='submit' className='button__save' onClick={close}>
              {formatMessage(commonMessages.close)}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};
