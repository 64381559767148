"use strict";
export const capitalizeFirstChar = (string = "") => string[0].toUpperCase() + string.slice(1);
export function stringifyObject(error) {
  let errorMessage;
  if (typeof error === "string") {
    errorMessage = error;
  } else if (typeof error === "object") {
    errorMessage = JSON.stringify(error);
  } else {
    errorMessage = String(error);
  }
  return errorMessage;
}
