import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../_tools/modal/Modal';
import { ANALYZER_ANOMALY_CREATE_SESSION } from '../../../redux/constants/modals.constants';
import { closeModal } from '../../../redux/modules/modals.module';
import commonMessages from '../../../messages/common.messages';
import analyzerMessages from '../../../messages/analyzer.messages';
import { createSession } from '../../../redux/modules/analyzer.sessions.create.module';
import { getModalPayload } from '../../../redux/selectors/modals.selector';
import { AppState } from '../../../redux/state/app.state';
import { Loader } from '../../_tools';

const modalPayloadSelector = (state: AppState) =>
  getModalPayload(state, ANALYZER_ANOMALY_CREATE_SESSION);

interface ModalPayload {
  id: number;
  datatronUuid: string;
  devicesUuids: string[];
  name: string;
}

export const CreateSessionFromAnomalyModal: React.FC = () => {
  const {
    name: tabName,
    datatronUuid,
    devicesUuids,
  }: ModalPayload = useSelector(modalPayloadSelector);

  const [name, setName] = useState(tabName);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const close = useCallback(() => {
    dispatch(closeModal(ANALYZER_ANOMALY_CREATE_SESSION));
  }, [dispatch]);

  const handleSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      setIsSubmitting(true);
      dispatch(createSession(datatronUuid, name, devicesUuids));
    },
    [name, dispatch, datatronUuid, devicesUuids],
  );

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const isSubmitDisabled = !name.trim() || isSubmitting;
  return (
    <Modal isOpen onClose={close} contentLabel=''>
      <div className='modal--header' onClick={close}>
        <span className='modal--close'>&times;</span>
      </div>
      <div className='modal--content'>
        <h3>{formatMessage(analyzerMessages.addSession)}</h3>

        {isSubmitting && <Loader show fullPage={false} />}
        {!isSubmitting && (
          <div className='form'>
            <label htmlFor='panelName'>
              <span>{formatMessage(commonMessages.name)}:</span>
              <input
                type='text'
                id='panelName'
                name='panelName'
                className='input-group white-group'
                value={name}
                onChange={handleInputChange}
                placeholder={formatMessage(commonMessages.name)}
                required
                maxLength={100}
              />
            </label>
          </div>
        )}
        <div className='line' />
        <div className='text-center'>
          <button type='button' className='button__cancel' onClick={close}>
            {formatMessage(commonMessages.cancel)}
          </button>
          <button
            type='submit'
            className='button__save'
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            {formatMessage(commonMessages.save)}
          </button>
        </div>
      </div>
    </Modal>
  );
};
