"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { pick } from "lodash";
import { startSubmit, stopSubmit, touch } from "redux-form";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { compareById, replaceObjectInList } from "../../../../common/helpers/object";
import * as api from "../../core/api";
import { handleApiError } from "../../core/common/errorHandling";
import { applyFormValuesOntoConfig } from "../../core/common/dynamicField";
import notificationMessages from "../../messages/notification.message";
import * as formConstants from "../constants/datatron.device.form.constants";
import { DP_EDIT_DEVICE } from "../constants/modals.constants";
import { getDeviceFormFields } from "../selectors/datatron.devices.selector";
import { getDatatron, getDeviceById, getDeviceTypeById } from "../selectors/datatron.selector";
import { NOTIFICATION_ERROR } from "../../core/notifications";
import { getModalPayload } from "../selectors/modals.selector";
import { closeModal } from "./modals.module";
import { sendNotification } from "./notifications.module";
import { AppReducerMapBuilder } from "./util";
import { validateDevice } from "../../core/validation/validateFieldsBaseOnSchema";
import { UpdateDatatronDeviceSchema } from "../../../../common/validation/datatrons.devices.validation";
export const updateDevice = createAction("updateDevice/submit", (deviceId) => ({
  payload: { deviceId }
}));
export const updateDeviceSuccess = createAction("updateDevice/success", (updatedDevice) => ({
  payload: updatedDevice
}));
export const updateDeviceFail = createAction("updateDevice/fail", (deviceId, error) => ({
  payload: { deviceId, error }
}));
export const reducer = AppReducerMapBuilder.new().add(updateDevice, (state, { deviceId }) => {
  const device = getDeviceById(state, deviceId);
  if (!device)
    return state;
  const newDevice = __spreadProps(__spreadValues({}, device), {
    _update: __spreadProps(__spreadValues({}, device._update), {
      loading: true,
      loaded: false,
      error: null
    })
  });
  return __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
        list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
      })
    })
  });
}).add(updateDeviceSuccess, (state, updatedDevice) => {
  const device = getDeviceById(state, updatedDevice.id);
  if (!device)
    return state;
  const newDevice = __spreadProps(__spreadValues(__spreadValues({}, device), updatedDevice), {
    _update: __spreadProps(__spreadValues({}, device._update), {
      isRenaming: false,
      loading: false,
      loaded: true,
      error: null
    })
  });
  return __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
        list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
      })
    })
  });
}).add(updateDeviceFail, (state, { deviceId, error }) => {
  const device = getDeviceById(state, deviceId);
  if (!device)
    return state;
  const newDevice = __spreadProps(__spreadValues({}, device), {
    _update: __spreadProps(__spreadValues({}, device._update), {
      loading: false,
      loaded: false,
      error
    })
  });
  return __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
        list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
      })
    })
  });
}).build();
export function* touchAllFieldsSaga(additionalKeys) {
  yield put(
    touch(
      formConstants.DATATRON_DEVICE_FORM_NAME,
      ...formConstants.DATATRON_DEVICE_PROPS,
      ...additionalKeys
    )
  );
}
export function* updateDeviceSaga() {
  const state = yield select();
  const datatron = getDatatron(state);
  const { device } = getModalPayload(state, DP_EDIT_DEVICE);
  const deviceType = getDeviceTypeById(state, device.deviceType.id);
  const additionalFields = deviceType && deviceType.hasDeviceSchema ? deviceType.deviceFields : [];
  const additionalKeys = additionalFields.map((item) => item._key);
  yield call(touchAllFieldsSaga, additionalKeys);
  const body = getDeviceFormFields(state, additionalKeys);
  if (additionalFields.length > 0) {
    body.config = applyFormValuesOntoConfig(
      device.config || {},
      pick(body, additionalKeys),
      additionalFields
    );
  }
  yield put(startSubmit(formConstants.DATATRON_DEVICE_FORM_NAME));
  const validationResult = validateDevice(
    UpdateDatatronDeviceSchema,
    deviceType.deviceSchema,
    body
  );
  if (validationResult) {
    yield put(stopSubmit(formConstants.DATATRON_DEVICE_FORM_NAME, validationResult));
    yield put(updateDeviceFail(device.id, validationResult));
    return;
  }
  const { response, error } = yield call(api.datatrons.updateDevice, datatron.id, device.id, body);
  if (response) {
    yield put(updateDeviceSuccess(response));
    yield put(stopSubmit(formConstants.DATATRON_DEVICE_FORM_NAME));
    yield put(closeModal(DP_EDIT_DEVICE));
    yield put(sendNotification(notificationMessages.device_updated_title));
  } else {
    const formattedError = handleApiError(error);
    yield put(stopSubmit(formConstants.DATATRON_DEVICE_FORM_NAME));
    yield put(closeModal(DP_EDIT_DEVICE));
    yield put(
      sendNotification(
        notificationMessages.server_error,
        __spreadProps(__spreadValues({}, notificationMessages.something_happened), {
          values: { error: JSON.stringify(formattedError) }
        }),
        NOTIFICATION_ERROR
      )
    );
  }
}
export function* watchUpdateDevice() {
  yield takeEvery(updateDevice.type, updateDeviceSaga);
}
