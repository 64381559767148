import React from 'react';
import { TooltipProps } from 'recharts';
import { formatLocalDate, unixNanoToDate } from '../../helper/time';

export const ChartTooltip: React.FC<TooltipProps<number, number>> = ({ active, payload }) => {
  if (!active || !payload?.length) return null;

  const data = payload[0].payload;
  const unixToDate = unixNanoToDate(data.systime);
  const localTime = formatLocalDate(unixToDate, true);
  const isoTime = unixToDate.toISOString();

  return (
    <>
      <div className='chart-tooltip'>
        <div className='tooltip-grid'>
          <div className='tooltip-label'>System Time (ISO):</div>
          <div>{isoTime}</div>

          <div className='tooltip-label'>System Time (Local):</div>
          <div>{localTime}</div>

          <div className='tooltip-label'>Is Delta:</div>
          <div>{data.isDelta ? 'yes' : 'no'}</div>

          <div className='tooltip-label'>Value (boolean):</div>
          <div>{payload[0].value}</div>
        </div>
      </div>
    </>
  );
};
