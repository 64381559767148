import React, { useMemo } from 'react';
import { Tooltip } from '../../_tools/tooltip/TooltipV2';
import { Todo } from '../../../../../common/types/common';
import { useIntl } from 'react-intl';
import analyzerMessages from '../../../messages/analyzer.messages';

const TOOL_CONFIG = [
  {
    id: 'zoom',
    icon: 'fa-arrows-alt',
    tooltip: 'zoomDescription',
    panelTool: {
      type: 'zoom',
      firstUnixNano: null,
      secondUnixNano: null,
    },
  },
  {
    id: 'cursor',
    icon: 'fa-arrows-v',
    tooltip: 'cursorDescription',
    panelTool: {
      type: 'cursor',
      highlightUnixNano: null,
    },
  },
  {
    id: 'bitRuler',
    icon: 'fa-arrows-h',
    tooltip: 'bitRulerDescription',
    panelTool: {
      type: 'bitRuler',
    },
  },
];

const ToolButton = ({ id, icon, tooltip, isActive, onClick }) => {
  const { formatMessage } = useIntl();
  const title = formatMessage(analyzerMessages[id]);
  const description = formatMessage(analyzerMessages[tooltip]);

  return (
    <Tooltip position='top' data={{ [title]: description }}>
      <div className={`tool-button ${isActive ? 'active' : ''}`} onClick={() => onClick(id)}>
        <i className={`fa ${icon}`} />
      </div>
    </Tooltip>
  );
};

export const ToolToggleGroup: React.FC<{ panel: Todo; onChange: (tool: Todo) => void }> = ({
  panel,
  onChange,
}) => {
  const { tool } = panel;
  const renderTools = useMemo(
    () =>
      TOOL_CONFIG.map(({ id, icon, tooltip, panelTool }) => (
        <ToolButton
          key={id}
          id={id}
          icon={icon}
          tooltip={tooltip}
          isActive={tool.type === id}
          onClick={() => onChange(panelTool)}
        />
      )),
    [onChange, tool],
  );

  return <div className='timeline-tools'>{renderTools}</div>;
};
