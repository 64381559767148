import React from 'react';
import { Tooltip } from '../../_tools/tooltip/TooltipV2';
import { Signal } from '../../../../../common/validation/analyzer.validation';

export const SignalInfo: React.FC<{ signal: Signal }> = ({ signal }) => (
  <div className='row-signal-info'>
    <Tooltip
      position='top'
      data={{
        Name: signal.name,
        UUID: signal.uuid,
      }}
    >
      {signal.name}
    </Tooltip>
  </div>
);
