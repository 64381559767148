import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * NoDataMessage component is responsible to display a message if there is no data
 * to display in the anomaly detection component.
 *
 * @param {string | null} selectedAnomalyDetection - the selected anomaly detection
 * @param {Todo[]} anomalyModels - the list of anomaly models
 * @param {boolean} isLoading - is the data still loading
 * @returns {JSX.Element | null} the component to display or null if there is data
 */
export const NoDataMessage: React.FC<{
  selectedAnomalyDetection: string | null;
  anomalyModels: any[];
  isLoading: boolean;
}> = ({ selectedAnomalyDetection, anomalyModels, isLoading }) => {
  if (!selectedAnomalyDetection || anomalyModels.length > 0 || isLoading) return null;

  return (
    <div className='info-message'>
      <FormattedMessage
        tagName='b'
        id='machine.schedule.no_data_to_display'
        defaultMessage='No data to display'
      />
    </div>
  );
};
