import React from 'react';
import { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { DROP_INDICATOR_GAP } from '../../../schema/type/panelSignal';

export const DropIndicatorWrapper: React.FC<{ edge: Edge }> = ({ edge }) => (
  <div style={{ position: 'absolute', left: 0, right: 0, [edge]: 0 }}>
    <DropIndicator edge={edge} gap={DROP_INDICATOR_GAP} />
  </div>
);
