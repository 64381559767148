"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "redux-act";
import { put, call, takeLatest } from "redux-saga/effects";
import * as aiAppsApi from "../../core/api/aiApps";
import { sendNotification } from "./notifications.module";
import notificationMessage from "../../messages/notification.message";
import { NOTIFICATION_EVENT, NOTIFICATION_ERROR } from "../../core/notifications";
import { stringifyObject } from "../../../../common/helpers/string.helper";
export const syncAnomalyModel = createAction(
  "sync anomalyModel",
  (appId, body) => ({ appId, body })
);
export const syncAnomalyModelSuccess = createAction(
  "sync anomalyModel - success",
  (item) => item
);
export const syncAnomalyModelFail = createAction(
  "sync anomalyModel - failed",
  (error) => error
);
export const reducer = {
  [syncAnomalyModel.getType()]: (state) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      loaded: false,
      loading: true,
      error: null
    })
  }),
  [syncAnomalyModelSuccess.getType()]: (state) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      loaded: true,
      loading: false,
      error: null
    })
  }),
  [syncAnomalyModelFail.getType()]: (state, error) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      loaded: false,
      loading: false,
      error
    })
  })
};
export function* syncAnomalyModelSaga(action) {
  const {
    payload: { appId, body }
  } = action;
  const { response, error } = yield call(aiAppsApi.syncAnomalies, appId, body);
  if (response) {
    yield put(syncAnomalyModelSuccess(response));
    yield put(
      sendNotification(
        notificationMessage.successfully_synced_event,
        notificationMessage.successfully_synced_event_description,
        NOTIFICATION_EVENT
      )
    );
  } else {
    yield put(syncAnomalyModelFail(error));
    yield put(
      sendNotification(
        notificationMessage.server_error,
        __spreadProps(__spreadValues({}, notificationMessage.something_happened), {
          values: { error: stringifyObject(error) || "" }
        }),
        NOTIFICATION_ERROR
      )
    );
  }
}
export function* watchSyncAnomalyModel() {
  yield takeLatest(syncAnomalyModel, syncAnomalyModelSaga);
}
