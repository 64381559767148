import { BooleanSignalData } from 'client/js/schema/signalData';
import { BitRulerBox } from 'client/js/schema/type/panelSignal';
import React, { useMemo } from 'react';
import { ReferenceAreaProps } from 'recharts';
import { calculateBitRulerBoxRange } from './Helper';
import { formatDuration } from '../../helper/time';
import { isNotUndefined } from '../../helper/type';

interface Props {
  offset: number;
  viewBox: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  label: string;
}

const BitRulerBoxLabel: React.FC<Props> = ({ viewBox, label }) => {
  const { x, y, width, height } = viewBox;
  const hh = height / 2;
  const xOffset = 5;
  const arrowLength = 12;
  const arrowWidth = 3;
  return (
    <svg>
      <defs>
        <filter x='-1%' y='-1%' width='102%' height='102%' id='solid'>
          <feFlood floodColor='black' result='bg' />
          <feMerge>
            <feMergeNode in='bg' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <line
        x1={x + xOffset}
        x2={x + width - xOffset}
        y1={hh}
        y2={hh}
        stroke='black'
        strokeWidth={2}
      />
      <polygon
        fill='black'
        stroke='black'
        strokeWidth={2}
        points={`
          ${x + xOffset},${hh}
          ${x + arrowLength},${hh - arrowWidth}
          ${x + arrowLength},${hh + arrowWidth}`}
      />
      <polygon
        fill='black'
        stroke='black'
        strokeWidth={2}
        points={`
          ${x + width - xOffset},
          ${hh} ${x + width - arrowLength},${hh - arrowWidth}
          ${x + width - arrowLength},${hh + arrowWidth}`}
      />
      <text
        x={x + width / 2}
        y={y + height / 2 + 5}
        fontSize={12}
        textAnchor='middle'
        fontFamily='monospace'
        fill='white'
        filter='url(#solid)'
      >
        {label}
      </text>
    </svg>
  );
};

const createReferenceAreaProps = (
  addon: BitRulerBox,
  signalData: BooleanSignalData,
): [number, ReferenceAreaProps] | undefined => {
  const { start, end } = calculateBitRulerBoxRange(addon.unixNano, signalData);

  if (start && end) {
    const label = formatDuration(end.systime - start.systime);
    return [
      addon.id,
      {
        x1: start.systime,
        x2: end.systime,
        label: (props) => <BitRulerBoxLabel {...props} label={label} />,
        fillOpacity: 0,
      },
    ];
  }
};

export const useBitRulerBoxes = (panelSignal, signalData) =>
  useMemo(() => {
    if (signalData === null) return [];
    if (panelSignal.type !== 'bool') return [];
    return panelSignal.addons
      .map((addon) => createReferenceAreaProps(addon, signalData))
      .filter(isNotUndefined);
  }, [panelSignal.addons, panelSignal.type, signalData]);
