"use strict";
import { useDispatch, useSelector } from "react-redux";
import * as selector from "../../../redux/selectors/aiApps.selector";
import { openModal } from "../../../redux/modules/modals.module";
import { fetchCycleDetectionDetails } from "../../../redux/modules/aiApps.cycleDetection.details.fetch.module";
import { CYCLE_DETECTION_ADD } from "../../../redux/constants/modals.constants";
export const useCycleDetections = () => {
  const dispatch = useDispatch();
  const aiAppsDetails = useSelector(selector.getAiAppsDetails);
  const cycleDetectionDetails = useSelector(selector.getCycleDetectionDetails);
  const handleOpenCycleDetectionModal = () => {
    dispatch(openModal(CYCLE_DETECTION_ADD));
  };
  const handleViewCycleDetectionDetails = (row) => {
    dispatch(fetchCycleDetectionDetails(row.id));
  };
  return {
    aiAppsDetails,
    cycleDetectionDetails,
    handleOpenCycleDetectionModal,
    handleViewCycleDetectionDetails
  };
};
