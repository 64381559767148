import React, { memo, useEffect, useRef, useState } from 'react';
import invariant from 'tiny-invariant';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { ColumnType } from './util/constants';
import ColumnHeader from '../column/header/ColumnHeader';
import NoCardsContainer from '../column/cards/NoCards.container';
import LoadMoreContainer from '../column/loadMore/LoadMore.container';

import { Card } from './card/Card';

export const Column = memo(function Column({ column }: { column: ColumnType }) {
  const { columnId, isDropAllowed } = column;
  const columnRef = useRef<HTMLDivElement | null>(null);
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);

  useEffect(() => {
    invariant(columnRef.current);
    return dropTargetForElements({
      element: columnRef.current,
      getData: () => ({ columnId }),
      canDrop: (args) => args.source.data.type === 'card',
      onDragEnter: () => setIsDraggingOver(isDropAllowed),
      onDragLeave: () => setIsDraggingOver(false),
      onDragStart: () => setIsDraggingOver(isDropAllowed),
      onDrop: () => setIsDraggingOver(false),
    });
  }, [columnId, isDropAllowed]);

  return (
    <div className='maintenance--col'>
      <div
        className={`maintenance--col--in ${isDraggingOver ? 'maintenance--col--in_is-over' : ''}`}
        ref={columnRef}
      >
        <ColumnHeader
          title={column.translatedTitle}
          cardsCount={column.cardsCount}
          type={column.columnId}
        />

        {column.items.map((item) => (
          <Card
            item={item}
            key={item.id}
            isDropAllowed={isDropAllowed}
            columnTitle={column.title}
          />
        ))}
        {column.cardsCount === 0 && <NoCardsContainer type={column.columnId} />}
        {column.moreAvailable && <LoadMoreContainer />}
      </div>
    </div>
  );
});
