import React from 'react';
import { Dimensions } from '../helpers';
import { TodoFunction } from '../../../../../../../common/types/common';
import { ArrayButton } from './ArrayButton';
import { isUndefined } from 'lodash';

interface Props {
  dimensions: Dimensions;
  updateState: TodoFunction;
  dimensionsCount: number;
}

/**
 * Defines all the dimensions of a multidimensional array. This includes all the ARRAY [] OF statements in the definition.
 */
export const ArrayButtons: React.FC<Props> = ({ dimensions, updateState, dimensionsCount }) => {
  /**
   * Update state by sending it to the parent
   */
  const updateChild = (index: number | undefined) => (indices, newValue) => {
    if (isUndefined(index)) updateState({ index: indices, newValue });
    else updateState({ index: [index, ...indices], newValue });
  };

  /**
   * Add a child by adding an array with default values at an index that doesn't exist yet.
   */
  const addChild = (e) => {
    e.preventDefault();
    updateChild(dimensions.length)([], dimensionsCount === 2 ? [0, 0] : [[0, 0]]);
  };

  /**
   * Removes a child by setting its value to undefined.
   */
  const removeChild = (index: number) => (e) => {
    e.preventDefault();
    updateChild(index)([], undefined);
  };

  /**
   * If there are only two dimensions, the outer array is only 2d instead of 3d
   */
  const dimensionsElements =
    dimensionsCount === 2 && dimensions.length !== 0 ? (
      <div className='dimensionsVerticalCenterFlex'>
        <span
          className='dimensionButtons dimensionMinus button red'
          onClick={removeChild(0)}
        ></span>
        <ArrayButton
          key={`arrayButton-${undefined}}`}
          dimensions={dimensions as unknown as number[][]}
          updateParent={updateChild(undefined)}
        />
      </div>
    ) : (
      dimensions?.map((item, index) => (
        <div className='dimensionsVerticalCenterFlex' key={`arrayButton-${index}}`}>
          <span
            className='dimensionButtons dimensionMinus button red'
            onClick={removeChild(index)}
          ></span>
          <ArrayButton dimensions={item} updateParent={updateChild(index)} />
        </div>
      ))
    );

  /**
   * Use map to display each dimension and give them an update function that is aware of its index.
   * Additionally, draw minus buttons next to each button, and at the bottom a plus button next to an element that
   * displays the type, or the word TYPE if the type is not yet defined.
   */
  return (
    <div style={{ fontSize: '20px' }}>
      {dimensionsElements}
      <div className='dimensionsVerticalCenterFlex'>
        {(dimensionsCount >= 3 || dimensions.length < 1) && (
          <span className='dimensionButtons dimensionPlus button green' onClick={addChild}></span>
        )}
      </div>
    </div>
  );
};
