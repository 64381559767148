import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import TopBarLogoutButtonContainer from './TopBarLogoutButton';

export function TopBarProfileDropdown({
  profileLink,
  adminPageLink,
  hasAdminAccess,
  open,
  toggle,
}) {
  const className = classNames('topMenu', {
    in: open,
  });

  return (
    <div style={{ display: 'block' }}>
      <div className={className}>
        <ul className='topMenu--list'>
          <li className='topMenu--item'>
            <Link to={profileLink} className='topMenu--link' onClick={toggle}>
              <span className='icon icon-person' />
              <FormattedMessage id='topBar.profile.profile' defaultMessage='Profile' />
            </Link>
          </li>
          {hasAdminAccess && (
            <li className='topMenu--item'>
              <Link to={adminPageLink} className='topMenu--link' onClick={toggle}>
                <span className='icon icon-admin' />
                <FormattedMessage id='topBar.profile.admin' defaultMessage='Admin' />
              </Link>
            </li>
          )}
          <li className='topMenu--item'>
            <TopBarLogoutButtonContainer />
          </li>
        </ul>
      </div>
    </div>
  );
}

TopBarProfileDropdown.propTypes = {
  profileLink: PropTypes.string.isRequired,
  adminPageLink: PropTypes.string.isRequired,
  hasAdminAccess: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default TopBarProfileDropdown;
