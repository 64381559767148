"use strict";
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useState, useEffect } from "react";
export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  getFileName
}) => {
  const [url, setFileUrl] = useState("");
  const download = () => __async(void 0, null, function* () {
    var _a;
    preDownloading();
    try {
      const res = yield apiDefinition();
      if (res.status === 200) {
        const fileContentBlob = yield res.blob();
        const createdObjUrl = URL.createObjectURL(new Blob([fileContentBlob]));
        const anchor = document.createElement("a");
        anchor.href = createdObjUrl;
        anchor.download = getFileName();
        anchor.click();
        setFileUrl(createdObjUrl);
        postDownloading();
      } else {
        const errorMessage = (_a = res.error) != null ? _a : "Error downloading File";
        onError(errorMessage);
      }
    } catch (error) {
      console.error("Error downloading File:", error);
      onError(`Error downloading File - ${error}`);
    }
  });
  useEffect(() => {
    const cleanup = () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
    return cleanup;
  }, [url]);
  return { download };
};
