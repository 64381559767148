"use strict";
import { getMachinesSchema } from "../../schema/machines";
import {
  apiRequest,
  postFormDataRequest,
  putFormDataRequest,
  putApiRequest,
  postApiRequest,
  deleteApiRequest,
  apiRequestWithSchema
} from "./_tools";
export const search = (query = "") => apiRequest(`/api/machines/search?q=${query}`);
export const getAll = () => apiRequestWithSchema("/api/machines", getMachinesSchema);
export const getMachine = (machineId) => apiRequest(`/api/machines/${machineId}`);
export const syncMachineToAltasigma = (machineId) => postApiRequest(`/api/machines/${machineId}/altasigma/sync`);
export const getMachineOee = (machineId) => apiRequest(`/api/machines/${machineId}/oee`);
export const createMachine = (data) => postFormDataRequest("/api/machines", data);
export const updateMachine = (machineId, data) => putFormDataRequest(`/api/machines/${machineId}`, data);
export const deleteMachinePhoto = (machineId) => deleteApiRequest(`/api/machines/${machineId}/photo`);
export const addNewAssistant = (machineId, data) => postApiRequest(`/api/machines/${machineId}/assistant`, data);
export const removeAssistant = (machineId, userId) => deleteApiRequest(`/api/machines/${machineId}/assistant/${userId}`);
export const setMachineCalendars = (machineId, data) => postApiRequest(`/api/machines/${machineId}/calendars`, data);
export const getDataPoints = (machineId) => apiRequest(`/api/machines/${machineId}/subscriptions`);
export const subscribeToDataPoints = (machineId, dataPoints) => postApiRequest(`/api/machines/${machineId}/subscriptions`, { dataPoints });
export const updateSubscription = (machineId, subscriptionId, payload) => putApiRequest(`/api/machines/${machineId}/subscriptions/${subscriptionId}`, payload);
export const deleteSubscription = (machineId, subscriptionId) => deleteApiRequest(`/api/machines/${machineId}/subscriptions/${subscriptionId}`);
export const getTools = (machineId) => apiRequest(`/api/machines/${machineId}/tools`);
