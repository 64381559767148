"use strict";
export const anomalyInitialState = {
  list: {}
};
export const anomlayTestState = {
  list: {
    23456: {
      id: 23456,
      event: {
        info: true,
        payload: {
          anomaly: true
        }
      },
      loaded: true,
      loading: false,
      error: null
    }
  }
};
