import React from 'react';
import { Sub, SubTrigger, Portal, SubContent } from '@radix-ui/react-context-menu';
import { IntlShape } from 'react-intl';
import { MenuItem } from '.';
import * as helper from './helper';
import analyzerMessages from '../../../messages/analyzer.messages';
import { CHART_TYPES, ChartType } from '../../../../../common/validation/analyzer.validation';

interface Props {
  chartType: ChartType;
  setChartType: (chartType: ChartType) => void;
  formatMessage: IntlShape['formatMessage'];
}

export const ChartTypeMenu: React.FC<Props> = ({ chartType, setChartType, formatMessage }) => (
  <Sub>
    <SubTrigger className={`${helper.subTriggerClass} flex flex-row justify-between`}>
      <div>{formatMessage(analyzerMessages.chartType)}</div>
      <i className='fa fa-chevron-right' />
    </SubTrigger>
    <Portal>
      <SubContent sideOffset={2} className={helper.contentClass}>
        {CHART_TYPES.map((selectableChartType) => (
          <MenuItem
            key={selectableChartType}
            className={`${helper.itemClass} capitalize gap-1`}
            onSelect={() => {
              setChartType(selectableChartType);
            }}
          >
            <div className='flex-grow'>{formatMessage(analyzerMessages[selectableChartType])}</div>
            <i
              className={
                chartType === selectableChartType
                  ? 'fa  fa-check visible'
                  : 'fa  fa-check  invisible'
              }
            />
          </MenuItem>
        ))}
      </SubContent>
    </Portal>
  </Sub>
);
