"use strict";
import { z } from "zod";
import { errorSchema } from "./authorized";
import { locationTypeSchema } from "./locations";
const inventoryMachineSchema = z.any();
const inventoryDatatronSchema = z.any();
const pathItemSchema = z.string().min(1);
const undefinedLocationSchema = z.object({
  id: z.literal("undefined"),
  name: z.literal("Undefined Location"),
  path: z.array(pathItemSchema).length(0),
  type: z.undefined(),
  INVENTORY_MACHINES: z.array(inventoryMachineSchema),
  INVENTORY_DATATRONS: z.array(inventoryDatatronSchema)
});
const normalLocationSchema = z.object({
  id: z.string().min(1),
  name: z.string().min(1),
  type: locationTypeSchema,
  path: z.array(pathItemSchema),
  INVENTORY_MACHINES: z.array(inventoryMachineSchema),
  INVENTORY_DATATRONS: z.array(inventoryDatatronSchema)
});
const inventoryItemSchema = z.union([
  undefinedLocationSchema,
  normalLocationSchema
]);
export const getInventorySchema = z.union([
  errorSchema,
  z.array(inventoryItemSchema)
]);
