"use strict";
export const locationInitialState = {
  _status: {
    loaded: false,
    loading: false,
    error: ""
  },
  filter: void 0,
  filterChildren: []
};
export const locationTestState = {
  id: "MjE0ODY2MzgwNzQ4OTE=",
  name: "Test location",
  type: "city",
  _status: {
    loaded: false,
    loading: false,
    error: ""
  },
  filter: "",
  filterChildren: []
};
