"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { cloneDeep, forEach, isArray } from "lodash";
export const applyFormValuesOntoConfig = (config, formValues, fields) => {
  const newConfig = cloneDeep(config);
  const mapConfigFields = fields.reduce(
    (result, item) => __spreadProps(__spreadValues({}, result), { [item._key]: item }),
    {}
  );
  forEach(formValues, (value, key) => {
    const field = mapConfigFields[key];
    const parent = field._parent || [];
    let i = 0;
    let toUpdate = newConfig;
    while (i < parent.length) {
      const currentParent = parent[i++];
      if (!toUpdate[currentParent])
        toUpdate[currentParent] = {};
      toUpdate = toUpdate[currentParent];
    }
    if (value !== void 0 && value !== null)
      toUpdate[key] = value;
  });
  return newConfig;
};
export const removeUnnecessaryKeys = (config = {}, allowedKeys = []) => {
  const newConfig = cloneDeep(config);
  forEach(config, (value, key) => {
    if (typeof value === "object" && !isArray(value)) {
      newConfig[key] = removeUnnecessaryKeys(value, allowedKeys);
    } else if (!(allowedKeys.indexOf(key) >= 0))
      delete newConfig[key];
  });
  return newConfig;
};
export const getInitialValue = (configField) => {
  if (!(configField.value === void 0 || configField.value === null))
    return configField.value;
  if (configField.field.default !== void 0)
    return configField.field.default;
  return null;
};
const numberParser = (value) => {
  if (value || value === 0)
    return Number.parseFloat(value);
  return null;
};
const integerParser = (value) => {
  if (value || value === 0)
    return Number.parseInt(value, 10);
  return null;
};
const defaultParser = (value) => value;
export const getParser = (type) => {
  if (type === "number")
    return numberParser;
  if (type === "integer")
    return integerParser;
  return defaultParser;
};
