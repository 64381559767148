import React from 'react';

type Props = {
  location: string[];
};

export const LocationBreadcrumbsReadOnly: React.FC<Props> = ({ location }) => (
  <ul className='menu breadcrumps-menu'>
    <li>
      <div className='read-only  breadcrumps-title breadcrumps-home'>
        <span className='icon-home' />
        {location.length > 0 && <span className='fa fa-chevron-right' />}
      </div>
    </li>
    {location.map((loc, index) => (
      <li key={index}>
        <div className='read-only breadcrumps-title'>
          {loc}
          {index < location.length - 1 && <span className='fa fa-chevron-right' />}
        </div>
      </li>
    ))}
  </ul>
);
