"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { isEmpty } from "lodash";
import { z } from "zod";
export function jsonToZod(jsonSchema) {
  const schemaMap = {};
  function traverse(schema) {
    if (schema.hasOwnProperty("$ref")) {
      const refPath = schema.$ref.replace("#/definitions/", "");
      if (!jsonSchema.definitions || !jsonSchema.definitions[refPath]) {
        throw new Error(`Schema reference ${refPath} is not defined.`);
      }
      if (!schemaMap[refPath]) {
        schemaMap[refPath] = traverse(jsonSchema.definitions[refPath]);
      }
      return schemaMap[refPath];
    }
    if (schema.enum) {
      return z.enum(schema.enum);
    }
    if (schema.oneOf) {
      const unions = schema.oneOf.map(traverse);
      return z.union(unions);
    }
    if (schema.type === "array") {
      return z.array(traverse(schema.items));
    }
    if (schema.type === "integer") {
      return z.number().int();
    }
    if (schema.type === "number") {
      return z.number();
    }
    if (schema.type === "string") {
      return z.string();
    }
    if (schema.type === "boolean") {
      return z.boolean();
    }
    if (schema.type === "object" && schema.additionalProperties === true && !schema.properties) {
      return z.string();
    }
    const properties = {};
    if (schema.properties) {
      for (const propName in schema.properties) {
        properties[propName] = traverse(schema.properties[propName]);
      }
    }
    return z.object(properties);
  }
  const filteredOneOf = jsonSchema.oneOf.filter(
    (obj) => obj.$ref === "#/definitions/simpleDatapoint"
  );
  jsonSchema.oneOf = filteredOneOf;
  const mainSchema = traverse(jsonSchema);
  return { mainSchema, schemaMap };
}
function isOneOfSchema(schema) {
  return Array.isArray(schema.oneOf);
}
function createZodSchemaForOneOf(oneOfSchema, callCreateZodSchemaFromJSON) {
  const findCommonEnumField = () => {
    const allFields = oneOfSchema.flatMap((schema) => Object.keys(schema.properties));
    const uniqueFields = [...new Set(allFields)];
    return uniqueFields.find(
      (field) => oneOfSchema.every(
        (schema) => schema.properties[field] && schema.properties[field].enum && schema.properties[field].enum.length > 0
      )
    );
  };
  const commonEnumField = findCommonEnumField();
  if (!commonEnumField) {
    return z.any();
  }
  const schemas = oneOfSchema.map((schema) => {
    const properties = schema.properties;
    const enumValues = properties[commonEnumField].enum;
    const required = new Set(schema.required || []);
    let schemaObj = {};
    for (const [key, value] of Object.entries(properties)) {
      if (key === commonEnumField) {
        schemaObj[key] = z.enum(enumValues);
      } else {
        const zodSchema = callCreateZodSchemaFromJSON(value);
        schemaObj[key] = required.has(key) ? zodSchema : zodSchema.optional();
      }
    }
    return z.object(schemaObj);
  });
  return z.discriminatedUnion(commonEnumField, schemas);
}
export function createBaseZodSchemaFromJSON(jsonSchema) {
  if (jsonSchema.type === "object") {
    const shape = {};
    const required = new Set(jsonSchema.required || []);
    for (const [key, value] of Object.entries(jsonSchema.properties)) {
      if (isOneOfSchema(value)) {
        const fieldSchema = createZodSchemaForOneOf(value.oneOf, createBaseZodSchemaFromJSON);
        shape[key] = required.has(key) ? fieldSchema : fieldSchema.optional();
      } else if (key === "custom" && (value == null ? void 0 : value.additionalProperties) === true) {
      } else {
        const fieldSchema = createBaseZodSchemaFromJSON(value);
        shape[key] = required.has(key) ? fieldSchema : fieldSchema.optional();
      }
    }
    return z.object(shape);
  }
  if (jsonSchema.type === "string") {
    let schema = z.string();
    if (jsonSchema.format === "ipv4") {
      schema = schema.ip({ version: "v4" });
    }
    if (jsonSchema.pattern) {
      schema = schema.regex(new RegExp(jsonSchema.pattern));
    }
    return schema;
  }
  if (jsonSchema.type === "integer" || jsonSchema.type === "number") {
    let schema = jsonSchema.type === "integer" ? z.number().int() : z.number();
    if (jsonSchema.minimum !== void 0) {
      schema = schema.min(jsonSchema.minimum);
    }
    if (jsonSchema.maximum !== void 0) {
      schema = schema.max(jsonSchema.maximum);
    }
    return schema;
  }
  if (jsonSchema.type === "boolean") {
    return z.boolean();
  }
  if (jsonSchema.enum) {
    if (jsonSchema.enum.every((item) => typeof item === "number")) {
      return z.number().refine((val) => jsonSchema.enum.includes(val));
    }
    return z.enum(jsonSchema.enum);
  }
  if (jsonSchema.type === "array") {
    if (jsonSchema.items) {
      const itemSchema = createBaseZodSchemaFromJSON(jsonSchema.items);
      return z.array(itemSchema).refine((arr) => arr.every((item) => itemSchema.safeParse(item).success), {
        message: `Satisfy the schema: ${JSON.stringify(jsonSchema.items)}`,
        path: []
      });
    }
  }
  return z.any();
}
export function createZodSchemaForAllOf(parsedSchema) {
  const { allOf } = parsedSchema;
  const findAllProperties = allOf.find((item) => item.properties);
  const baseSchema = createBaseZodSchemaFromJSON(__spreadProps(__spreadValues({}, findAllProperties), { type: "object" }));
  const oneOfSchema = allOf.find((item) => item.oneOf);
  if (oneOfSchema) {
    const oneOfZodSchema = createZodSchemaForOneOf(oneOfSchema.oneOf, createBaseZodSchemaFromJSON);
    return baseSchema.and(oneOfZodSchema);
  }
  return baseSchema;
}
export function createZodSchemaFromJSON(jsonSchema) {
  if (jsonSchema.allOf) {
    return createZodSchemaForAllOf(jsonSchema);
  }
  if (jsonSchema.properties && jsonSchema.properties.custom && jsonSchema.properties.internal) {
    return createBaseZodSchemaFromJSON(jsonSchema.properties.custom);
  }
  if (isEmpty(jsonSchema)) {
    return z.any();
  }
  return createBaseZodSchemaFromJSON(jsonSchema);
}
export function createDataPointSchemaFromJSO(jsonSchema) {
  return createBaseZodSchemaFromJSON(jsonSchema);
}
export function findAdditionalPropertiesAttributes(schema) {
  const additionalPropsAttributes = [];
  function traverse(obj, parentKey = "") {
    if (typeof obj !== "object" || obj === null)
      return;
    if (obj.type === "object" && obj.additionalProperties === true && (!obj.properties || Object.keys(obj.properties).length === 0)) {
      additionalPropsAttributes.push(parentKey);
    }
    if (obj.properties) {
      Object.entries(obj.properties).forEach(([key, value]) => {
        traverse(value, key);
      });
    }
    if (obj.definitions) {
      Object.entries(obj.definitions).forEach(([key, value]) => {
        traverse(value, key);
      });
    }
    if (Array.isArray(obj.oneOf)) {
      obj.oneOf.forEach((item) => {
        traverse(item);
      });
    }
  }
  traverse(schema);
  return additionalPropsAttributes.filter((attr) => attr !== "");
}
export function findCustomAttributeIndices(tableExcelHeaderNoNull, customAttributes) {
  return tableExcelHeaderNoNull.reduce((indices, header, index) => {
    const matchConditions = [
      // Exact match
      customAttributes.includes(header),
      // Includes custom
      customAttributes.some(
        (customAttr) => header.toLowerCase().includes(customAttr.toLowerCase())
      ),
      // Starts with custom
      customAttributes.some(
        (customAttr) => header.toLowerCase().startsWith(customAttr.toLowerCase())
      )
    ];
    if (matchConditions.some((condition) => condition)) {
      indices.push(index);
    }
    return indices;
  }, []);
}
