"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { fetchDeviceCommandHistory } from "../../core/api/datatrons";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { getDatatron } from "../selectors/datatron.selector";
import { AppReducerMapBuilder } from "./util";
import { quickCommandSuccess, quickCommandFailure } from "./datatron.devices.quickCommands.module";
import { transformErrorToMessageString } from "../../../../common/utils";
export const fetchCommandHistory = createAction(
  "fetch device command history",
  (deviceId, queryParameters) => ({ payload: { deviceId, queryParameters } })
);
export const setCommandHistory = createAction(
  "set device command history",
  ({ commands, total }) => ({ payload: { commands, total } })
);
export const reSetCommandHistory = createAction("re-set device command history");
export const reducer = AppReducerMapBuilder.new().add(fetchCommandHistory, (state, { deviceId }) => __spreadProps(__spreadValues({}, state), {
  modals: __spreadProps(__spreadValues({}, state.modals), {
    commandState: __spreadProps(__spreadValues({}, state.modals.commandState), {
      deviceId,
      submitting: true
    })
  })
})).add(setCommandHistory, (state, { commands, total }) => __spreadProps(__spreadValues({}, state), {
  datatron: __spreadProps(__spreadValues({}, state.datatron), {
    deviceCommands: commands,
    deviceCommandTotal: total
  })
})).add(reSetCommandHistory, (state) => __spreadProps(__spreadValues({}, state), {
  datatron: __spreadProps(__spreadValues({}, state.datatron), {
    deviceCommands: [],
    deviceCommandTotal: 0
  })
})).build();
export function* fetchCommandHistorySaga({ payload: { deviceId, queryParameters } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const queryString = new URLSearchParams(queryParameters).toString();
  const { response, error } = yield call(
    fetchDeviceCommandHistory,
    datatron.id,
    deviceId,
    queryString
  );
  if (response) {
    yield put(setCommandHistory(response));
    yield put(quickCommandSuccess(deviceId));
  } else {
    yield put(reSetCommandHistory());
    const errorString = transformErrorToMessageString(error);
    yield put(quickCommandFailure(deviceId, errorString));
  }
}
export function* wachCommandHistory() {
  yield takeEvery(fetchCommandHistory, fetchCommandHistorySaga);
}
