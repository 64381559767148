import React, { useMemo } from 'react';
import ReactSelect from 'react-select';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { find } from 'lodash';

import { InputError } from '../../index';
import commonCoreMessages from '../../../../messages/common.core.messages';
import { Todo } from '../../../../../../common/types/common';
import { customStyles } from './select.styles';

const getOptionLabel = (option) => option?.label;
const getOptionValue = (option) => option?.value || option?.id || option;

type ReactSelectProps = React.ComponentProps<typeof ReactSelect>;

interface Props extends ReactSelectProps {
  input: Todo;
  meta: Todo;
  options: Todo[];
  onChange: NonNullable<ReactSelectProps['onChange']>;
  translateOptionsLabels?: boolean;
  disabled?: boolean;
}

export const Select: React.FC<Props> = ({
  input,
  meta,
  options,
  onChange,
  onInputChange,
  translateOptionsLabels = false,
  disabled,
  ...reactSelectProps
}) => {
  const isError = meta.touched && meta.error;
  const className = classnames('Select', {
    error: isError,
    'select-disabled': disabled,
  });

  const { formatMessage } = useIntl();
  const finalOptions = translateOptionsLabels
    ? options.map((item) => ({
        ...item,
        label: formatMessage(item.label),
      }))
    : options;
  const placeholder = formatMessage(commonCoreMessages.selectPlaceholder);

  const selectedValue = useMemo(() => {
    if (!input.value) {
      return null;
    }

    if (typeof input.value === 'object') {
      return find(finalOptions, (item) => item.value === input.value.value);
    }
    return find(finalOptions, (item) => item.value === input.value);
  }, [finalOptions, input.value]);

  return (
    <div style={{ width: '100%' }} onClick={(event) => event.stopPropagation()}>
      <ReactSelect
        styles={customStyles}
        backspaceRemovesValue={false}
        className={className}
        isDisabled={!!disabled}
        formatOptionLabel={getOptionLabel}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onChange={onChange}
        onInputChange={onInputChange}
        options={finalOptions}
        placeholder={placeholder}
        value={selectedValue}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...reactSelectProps}
      />
      <div className='error-line'>
        <InputError error={meta.error} />
      </div>
    </div>
  );
};

export default Select;
