import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Link } from 'react-router-dom';

import noDoubleClick from '../../../../_tools/stopPropagation';
import { getMachineLink, getLocationLink } from '../../../../index/routes';
import EventComment from '../../../../events/event/details/EventComment';
import EventCommentInputContainer from '../../../../events/event/details/EventCommentInput.container';
import AnomalyDetails from '../../../../_tools/anomaly/AnomalyDetails';

function EventInfo({
  event,
  resolve = () => {},
  canResolve = false,
  canSeeAnomaly = false,
  anomaly,
  isAnomalyLoaded,
  canCreateNewComments,
}) {
  const comments = event.comments.slice();
  comments.reverse();
  return (
    <div className='maintenance--item--top'>
      {canResolve && (
        <a className='wrench hide' onClick={noDoubleClick}>
          <span className='icon-wrench' onClick={resolve} />
        </a>
      )}

      <a className='maintenance--item--title'>{event.title}</a>
      <div className='maintenance--item--top--row'>
        <div className='maintenance--item--top--left'>
          <div className='maintenance-machine'>
            <Link onClick={noDoubleClick} to={getMachineLink(event.machine.id)} className='links'>
              <span className='icon icon-cog' />
              <span className='links-text'>{event.machine.name}</span>
            </Link>
          </div>
        </div>
        <div className='maintenance--item--top--right'>
          <div className='maintenance-location'>
            <Link
              onClick={noDoubleClick}
              to={getLocationLink(event.machine.location.id)}
              className='links'
            >
              <span className='icon icon-location' />
              <span className='links-text'>{event.machine.location.name}</span>
            </Link>
          </div>
        </div>
      </div>

      <div className='maintenance--item--top--row'>
        <div className='maintenance--item--top--left'>
          <div className='maintenance-date'>
            <span className='icon icon-calendar' />
            <FormattedDate value={event.timestamp} />
          </div>
        </div>
        <div className='maintenance--item--top--right'>
          <div className='maintenance--item--time'>
            <span className='icon icon-time-filled' />
            <FormattedTime value={event.timestamp} />
          </div>
        </div>
      </div>
      <div className='maintenance--text--sm '>
        <p className='multiline-ellipsis-line-clamp-2'>{event.details}</p>
      </div>
      <div className='maintenance--text'>
        <p>{event.details}</p>
        <div className='feedbacks'>
          <div className='feedback-write'>
            {canSeeAnomaly && isAnomalyLoaded && (
              <div className='feedback--item'>
                <AnomalyDetails payload={anomaly} />
              </div>
            )}
            {canCreateNewComments && <EventCommentInputContainer eventId={event.id} />}
            {comments.map((comment) => (
              <EventComment key={comment.id} comment={comment} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

EventInfo.propTypes = {
  event: PropTypes.object.isRequired,
  isAnomalyLoaded: PropTypes.bool,
  anomaly: PropTypes.object,
  canCreateNewComments: PropTypes.bool.isRequired,
};

export default EventInfo;
