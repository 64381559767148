"use strict";
import { defineMessages } from "react-intl";
import * as recurrentEventTypes from "../../../common/enums/recurrent.event.types";
const messages = defineMessages({
  shiftCalendars: {
    id: "calendars.group.shift",
    defaultMessage: "Shifts"
  },
  scheduleCalendars: {
    id: "calendars.group.schedule",
    defaultMessage: "Schedule"
  },
  repeatTypeTitle: {
    id: "calendars.event.recurrence.type.title",
    defaultMessage: "repeat:"
  },
  newRepeatTypeTitle: {
    id: "calendars.event.recurrence.new_type.title",
    defaultMessage: "New Repeat:"
  },
  repeatEndTitle: {
    id: "calendars.event.recurrence.until.title",
    defaultMessage: "until:"
  },
  repeatNone: {
    id: "calendars.event.recurrence.type.none",
    defaultMessage: "None"
  },
  repeatDaily: {
    id: "calendars.event.recurrence.type.daily",
    defaultMessage: "Daily"
  },
  repeatWeekly: {
    id: "calendars.event.recurrence.type.weekly",
    defaultMessage: "Weekly"
  },
  repeatMonthly: {
    id: "calendars.event.recurrence.type.monthly",
    defaultMessage: "Monthly"
  },
  repeatYearly: {
    id: "calendars.event.recurrence.type.yearly",
    defaultMessage: "Yearly"
  },
  deleteCurrentEntryOnly: {
    id: "calendars.events.modals.only_selected_entry",
    defaultMessage: "Only selected entry"
  },
  deleteCurrentAndFutureEntries: {
    id: "calendars.events.modals.selected_and_future_entries",
    defaultMessage: "Selected and future entries"
  },
  deleteAllEntries: {
    id: "calendars.events.modals.all_entries",
    defaultMessage: "All entries"
  }
});
export const getRepeatTypeMessage = (type = null) => {
  switch (type) {
    case recurrentEventTypes.DAILY:
      return messages.repeatDaily;
    case recurrentEventTypes.WEEKLY:
      return messages.repeatWeekly;
    case recurrentEventTypes.MONTHLY:
      return messages.repeatMonthly;
    case recurrentEventTypes.YEARLY:
      return messages.repeatYearly;
    default:
      return messages.repeatNone;
  }
};
export default messages;
