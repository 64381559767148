"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { formValueSelector } from "redux-form";
import { AI_APP_FORM_NAME, AI_APP_PROPS } from "../constants/aiApp.form.constants";
import {
  AI_APP_CYCLE_DETECTION_FORM,
  AI_APP_CYCLE_DETECTION_PROPS
} from "../constants/aiApp.cycleDetection.form.constants";
import {
  AI_APP_ANOMALY_FORM_NAME,
  AI_APP_ANOMALY_PROPS
} from "../constants/aiApp.anomalyModule.form.constants";
const createAiAppFormSelector = () => formValueSelector(AI_APP_FORM_NAME);
export const getFormFields = (state) => createAiAppFormSelector()(state, ...AI_APP_PROPS);
const createAiAppCycleDetectionFormSelector = () => formValueSelector(AI_APP_CYCLE_DETECTION_FORM);
export const getAIAppCycleDetectionFormFields = (state) => {
  const formFields = createAiAppCycleDetectionFormSelector()(
    state,
    ...AI_APP_CYCLE_DETECTION_PROPS
  );
  if (formFields.typeUuid && Array.isArray(formFields.typeUuid) && formFields.typeUuid.length > 0) {
    return __spreadProps(__spreadValues({}, formFields), {
      typeUuid: formFields.typeUuid[0].value
    });
  } else if (formFields.typeUuid && Array.isArray(formFields.typeUuid) && formFields.typeUuid.length === 0) {
    const _a = formFields, { typeUuid } = _a, restFields = __objRest(_a, ["typeUuid"]);
    return restFields;
  }
  return formFields;
};
const createAiAppAnomalyFormSelector = () => formValueSelector(AI_APP_ANOMALY_FORM_NAME);
export const getAIAppAnomalyFormFields = (state) => createAiAppAnomalyFormSelector()(state, ...AI_APP_ANOMALY_PROPS);
