import React from 'react';
import { PrintSignalChart } from './PrintSignalChart';
import { PanelSignal } from '../../../schema/type/panelSignal';

interface Props {
  panelSignals: PanelSignal[];
  panelId: number;
}

export const PrintPanelSignal: React.FC<Props> = ({ panelSignals, panelId }) => {
  const sortedPanelSignals = [...panelSignals].sort((a, b) => a.order - b.order);

  return (
    <>
      {sortedPanelSignals.map((panelSignal) => (
        <div key={panelSignal.id} className='print-signal-container'>
          <span className='print-signal-name'>
            <span className='font-bold'>{panelSignal.signal.name} </span>
          </span>

          <PrintSignalChart
            key={`${panelSignal.id}-chart`}
            panelSignal={panelSignal}
            panelId={panelId}
          />
        </div>
      ))}
    </>
  );
};
