import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import commonMessages from '../../../messages/common.messages';
import Modal from '../../_tools/modal/Modal';
import { ANALYZER_DISPLAY_SESSION_OR_ANOMALY_DATA } from '../../../redux/constants/modals.constants';
import { closeModal } from '../../../redux/modules/modals.module';
import { getModalPayload } from '../../../redux/selectors/modals.selector';
import { AppState } from '../../../redux/state/app.state';
import { ANALYZER_DETAILS_FIELDS } from '../../../redux/constants/analyzer.form.constants';
import analyzerMessages from '../../../messages/analyzer.messages';

type AttributeData = { [key: string]: unknown };

const modalPayloadSelector = (state: AppState) =>
  getModalPayload(state, ANALYZER_DISPLAY_SESSION_OR_ANOMALY_DATA);

export const DisplaySessionOrAnomalyDataModal: React.FC = () => {
  const { formatMessage, formatDate } = useIntl();
  const dispatch = useDispatch();
  const { data: attributeData }: { data: AttributeData } = useSelector(modalPayloadSelector);

  const close = useCallback(() => {
    dispatch(closeModal(ANALYZER_DISPLAY_SESSION_OR_ANOMALY_DATA));
  }, [dispatch]);

  const isPrimitive = (value: any) =>
    value === null ||
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean';

  const dateFields = ['createdAt', 'updatedAt', 'modifiedAt', 'anomalyFrom', 'referenceFrom'];

  // Function to format value for display
  const formatValue = (key: string, value: unknown) => {
    if (value === null) return '--';
    if (key === 'id') return 'N/A';
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (typeof value === 'string' && dateFields.includes(key))
      return formatDate(value, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      });

    return String(value);
  };

  return (
    <Modal isOpen onClose={close} contentLabel='Session/Anomaly Details'>
      <div className='modal--header' onClick={close}>
        <span className='modal--close'>&times;</span>
      </div>
      <div className='modal--content'>
        <h3>
          {formatMessage(commonMessages.details)} <i className='fa fa-info'></i>
        </h3>

        <div className='display-session-anomaly-data'>
          <div className='display-session-anomaly-data__container'>
            {Object.entries(attributeData)
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              .filter(([key, value]) => ANALYZER_DETAILS_FIELDS.includes(key) && isPrimitive(value))
              .map(([key, value], index) => (
                <div
                  key={key}
                  className={`display-session-anomaly-data__row ${
                    index % 2 === 0
                      ? 'display-session-anomaly-data__row--even'
                      : 'display-session-anomaly-data__row--odd'
                  }`}
                >
                  <div className='display-session-anomaly-data__key-cell'>
                    {formatMessage(analyzerMessages[key])}
                  </div>
                  <div className='display-session-anomaly-data__value-cell'>
                    {formatValue(key, value)}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className='line' />
        <div className='text-center'>
          <button type='button' className='button__cancel' onClick={close}>
            {formatMessage(commonMessages.cancel)}
          </button>
        </div>
      </div>
    </Modal>
  );
};
