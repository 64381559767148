"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { cloneDeep } from "lodash";
import { createAction } from "@reduxjs/toolkit";
import { put, call, takeEvery } from "redux-saga/effects";
import * as api from "../../core/api";
import { searchInitialState } from "../state/search.state";
export const searchDevices = createAction("search devices", (id) => ({ payload: id }));
export const searchDevicesSuccess = createAction("search Devices - success", (results) => ({
  payload: results
}));
export const searchDevicesFail = createAction("search Devices - fail", (error) => ({
  payload: error
}));
export const resetDevices = createAction("reset Devices");
export const reducer = {
  [searchDevices.type]: (state) => __spreadProps(__spreadValues({}, state), {
    search: __spreadProps(__spreadValues({}, state.search), {
      loaded: false,
      loading: true,
      error: null
    })
  }),
  [searchDevicesSuccess.type]: (state, devices) => __spreadProps(__spreadValues({}, state), {
    search: __spreadProps(__spreadValues({}, state.search), {
      loading: false,
      loaded: true,
      results: __spreadProps(__spreadValues({}, state.search.results), {
        devices
      })
    })
  }),
  [searchDevicesFail.type]: (state, error) => __spreadProps(__spreadValues({}, state), {
    search: __spreadProps(__spreadValues({}, state.search), {
      loaded: false,
      loading: false,
      error
    })
  }),
  [resetDevices.type]: (state) => __spreadProps(__spreadValues({}, state), {
    search: cloneDeep(searchInitialState)
  })
};
export function* searchDevicesSaga({ payload: id }) {
  const { response, error } = yield call(api.datatrons.getDevices, id);
  if (response) {
    yield put(searchDevicesSuccess(response));
  } else {
    yield put(searchDevicesFail(error));
  }
}
export function* watchSearchDevices() {
  yield takeEvery(searchDevices, searchDevicesSaga);
}
