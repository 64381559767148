import React, { forwardRef, memo } from 'react';

type BoardProps = {
  children: React.ReactNode;
};

const Board = forwardRef<HTMLDivElement, BoardProps>(({ children }, ref) => (
  <div className='board' ref={ref}>
    {children}
  </div>
));

export default memo(Board);
