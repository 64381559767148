"use strict";
export const profileInitialState = {
  isLocationModalOpen: false,
  loading: false,
  loaded: false,
  _update: {
    loading: false,
    loaded: false,
    error: null
  },
  user: null
};
export const profileTestState = {
  isLocationModalOpen: false,
  preSavedLocationPath: null,
  loading: false,
  loaded: false,
  error: null,
  user: {
    id: 1,
    location: {
      id: 2,
      name: "Moscow"
    },
    locale: "en",
    roles: []
  },
  _update: {
    loading: false,
    loaded: false,
    error: null
  }
};
