"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "redux-act";
import { put, call, takeLatest, delay, select, take } from "redux-saga/effects";
import * as aiAppsApi from "../../core/api/aiApps";
import { getAiAppsDetails } from "../selectors/aiApps.selector";
import { CYCLE_DETECTION_PULLING_FREQUENCY_IN_MS } from "../../../../common/constants/aiApp";
import { RUNNING_STATUS } from "../constants/aiApp.cycleDetection.form.constants";
export const fetchAiAppDetails = createAction("fetch aiApp details", (aiAppId) => aiAppId);
export const fetchAiAppDetailsSuccess = createAction(
  "fetch aiApp details - success",
  (aiApps) => aiApps
);
export const fetchAiAppDetailsFail = createAction("fetch aiApp details - failed", (error) => error);
export const resetAiAppDetails = createAction("reset aiApp details");
export const reducer = {
  [fetchAiAppDetails.getType()]: (state) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      loaded: false,
      loading: true,
      error: null
    })
  }),
  [fetchAiAppDetailsSuccess.getType()]: (state, aiApp) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      details: aiApp,
      loaded: true,
      loading: false,
      error: null
    })
  }),
  [fetchAiAppDetailsFail.getType()]: (state, error) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      loaded: false,
      loading: false,
      error
    })
  }),
  [resetAiAppDetails.getType()]: (state) => __spreadProps(__spreadValues({}, state), {
    aiApps: __spreadProps(__spreadValues({}, state.aiApps), {
      details: null
    })
  })
};
export function* fetchAiAppDetailsSaga({ payload: aiAppId }) {
  const { response, error } = yield call(aiAppsApi.fetchOne, aiAppId);
  if (response) {
    yield put(fetchAiAppDetailsSuccess(response));
  } else {
    yield put(fetchAiAppDetailsFail(error));
  }
}
export function* pollAiAppDetailsSaga(aiAppId) {
  while (true) {
    yield delay(CYCLE_DETECTION_PULLING_FREQUENCY_IN_MS);
    const { response, error } = yield call(aiAppsApi.fetchOne, aiAppId);
    if (response) {
      yield put(fetchAiAppDetailsSuccess(response));
      const aiAppDetails = yield select(getAiAppsDetails);
      const hasInProgress = aiAppDetails.cycleDetections.some(
        (item) => item.training_status === RUNNING_STATUS
      );
      if (!hasInProgress) {
        break;
      }
    } else {
      yield put(fetchAiAppDetailsFail(error));
      break;
    }
  }
}
export function* watchFetchAiAppDetailsSuccess() {
  while (true) {
    const { payload: aiApp } = yield take(fetchAiAppDetailsSuccess.getType());
    const hasInProgress = aiApp.cycleDetections.some(
      (item) => item.training_status === RUNNING_STATUS
    );
    if (hasInProgress) {
      yield call(pollAiAppDetailsSaga, aiApp.id);
    }
  }
}
export function* watchFetchAIAppDetails() {
  yield takeLatest(fetchAiAppDetails, fetchAiAppDetailsSaga);
}
