"use strict";
export const events = "events";
export const kpi = "kpi";
export const maintenance = "maintenance";
export const dataPoints = "dataPoints";
export const datatronStats = "datatronStats";
export const REPORT_TYPES = [
  events,
  kpi,
  maintenance,
  dataPoints,
  datatronStats
];
export default REPORT_TYPES;
