import React from 'react';
import { Field } from 'redux-form';
import { useIntl } from 'react-intl';

import BooleanRadioContainer from '../radio/BooleanRadio.container';
import CustomSelectContainer from '../select/CustomSelect.container';
import FormField from '../../formField/FormField';
import labels from '../../../../messages/dynamicField.messages';
import { getParser } from '../../../../core/common/dynamicField';
import { Todo } from '../../../../../../common/types/common';
import { isValidJSON } from '../../../modals/datatronPage/dataPointForm/helpers';
import TextareaFormField from '../../textarea/TextareaFormField';
import { shouldRenderField } from '../../../../redux/modules/datatron.devices.add.module';

const getLabelMessage = (key) => labels[key] ?? key;

interface Props {
  field: Todo;
  formValues: Todo;
}

// Try to parse the given value as JSON.
// If this fails, we return the original string.
// Used for text fields for custom configurations that will later be provided as JSON.
const parseJSONOrPass = (value: string): object | string => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

/**
 * Normalize a string value into an array of numbers.
 * The string should be a comma-separated list of numbers.
 * Values that are not valid numbers are ignored.
 * @param {string} value
 * @returns {number[]}
 */
const normalizeArray = (value: string): number[] => {
  const trimmedValues = value.split(',').map((item) => item.trim());
  const numericValues = trimmedValues.filter((item) => !isNaN(Number(item)));
  return numericValues.map(Number);
};

export const DynamicField: React.FC<Props> = ({ field, formValues }) => {
  let showLabel = true;
  let component: Todo = null;
  const key = field._key;
  const { formatMessage } = useIntl();
  const label = labels[key] ? formatMessage(labels[key]) : key;

  if (!shouldRenderField(field, formValues)) {
    return null;
  }

  switch (field.type) {
    case 'boolean': {
      showLabel = false;
      component = (
        <div className='like-label'>
          <div className='radio-item-cols'>
            <span>{label}</span>
            <Field name={key} component={BooleanRadioContainer} required={field._required} />
          </div>
        </div>
      );
      break;
    }
    case 'string': {
      showLabel = false;
      component = (
        <FormField
          label={getLabelMessage(key)}
          name={key}
          _defaultValue={field.default}
          type={field.format === 'password' ? 'password' : 'text'}
          required={field._required}
        />
      );
      break;
    }
    case 'integer':
    case 'number': {
      showLabel = false;

      const additionalProps: { min?: number; max?: number } = {};
      if (field.maximum) additionalProps.max = field.maximum;
      if (field.minimum) additionalProps.min = field.minimum;

      component = (
        <FormField
          label={getLabelMessage(key)}
          name={key}
          type='number'
          parse={getParser(field.type)}
          _defaultValue={field.default}
          required={field._required}
          {...additionalProps}
        />
      );
      break;
    }
    case 'enum': {
      const options = (field.enum || []).map((item) => ({
        label: item,
        value: item,
      }));

      component = (
        <Field
          name={key}
          component={CustomSelectContainer}
          options={options}
          required={field._required}
        />
      );
      break;
    }
    case 'array': {
      showLabel = false;
      component = (
        <TextareaFormField
          label={getLabelMessage(key)}
          name={key}
          _defaultValue={field.default}
          cols='30'
          rows='10'
          parse={getParser(field.type)}
          normalize={normalizeArray}
          required={field._required}
        />
      );
      break;
    }
    case 'object': {
      // Used, for example, with arbitrary custom configs.
      // These are marked with allowed additional properties.
      // We use custom formatting and normalization as the configuration needs to be provided as JSON but still being editable as text.
      if (field.additionalProperties === true) {
        showLabel = false;
        component = (
          <FormField
            label={getLabelMessage(key)}
            name={key}
            _defaultValue={field.default || {}}
            type={'text'}
            required={field._required}
            component={'textarea'}
            format={(v) => (typeof v === 'object' ? JSON.stringify(v, null, 2) : v)}
            normalize={parseJSONOrPass}
            validate={(v) =>
              typeof v === 'object' || isValidJSON(v) ? undefined : 'errors.field.invalid_json'
            }
          />
        );
      }
      break;
    }
    default:
      break;
  }

  if (!showLabel) return component;

  return (
    <label>
      <span>{label}</span>
      {component}
    </label>
  );
};

export default DynamicField;
