"use strict";
export const AI_APP_ANOMALY_FORM_NAME = "AI_APP_ANOMALY_FORM_NAME";
export const CYCLE_DETECTION_ID = "cycle_detection_id";
export const CYCLE_START_SIGNAL_UUID = "cycle_start_data_point_uuid";
export const CYCLE_START_DATAPOINT = "cycle_start_data_point";
export const NUM_EPOCHS = "num_epochs";
export const BATCH_SIZE = "batch_size";
export const MIN_BATCH_SIZE = 16;
export const MAX_BATCH_SIZE = 256;
export const MIN_NUM_EPOCHS = 10;
export const MAX_NUM_EPOCHS = 200;
export const AI_APP_ANOMALY_PROPS = [
  CYCLE_DETECTION_ID,
  CYCLE_START_DATAPOINT,
  NUM_EPOCHS,
  BATCH_SIZE
];
export const TRAINING_STATUS = "training_status";
export const ANOMALY_MODEL_HEADER = [
  TRAINING_STATUS,
  CYCLE_START_DATAPOINT,
  NUM_EPOCHS,
  BATCH_SIZE
];
