import React, { forwardRef } from 'react';
import { useFormattedValue, CursorDateTimeUnit } from './helper';

interface CursorInputSegmentProps {
  unit: CursorDateTimeUnit;
  value: number;
  incCursorUnixNano: (deltaMs: number) => void;
  onKeyLeft?: () => void;
  onKeyRight?: () => void;
  disabled?: boolean;
}

export const CursorDateInputInputSegment = forwardRef<HTMLDivElement, CursorInputSegmentProps>(
  ({ unit, value, incCursorUnixNano, onKeyLeft, onKeyRight, disabled = false }, ref) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (disabled) return;

      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          incCursorUnixNano(1);
          break;
        case 'ArrowDown':
          event.preventDefault();
          incCursorUnixNano(-1);
          break;
        case 'ArrowLeft':
          event.preventDefault();
          onKeyLeft?.();
          break;
        case 'ArrowRight':
          event.preventDefault();
          onKeyRight?.();
          break;
      }
    };

    const formattedValue = useFormattedValue(value, unit);
    const tabIndex = disabled ? undefined : 0;
    const className = `input-cursor-display ${disabled ? 'disabled' : ''}`;

    return (
      <div ref={ref} className={className} tabIndex={tabIndex} onKeyDown={handleKeyDown}>
        {formattedValue}
      </div>
    );
  },
);
