"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { EMPTY_RESULTS, EMPTY_QUERIES } from "../constants/search.constants";
import { DATE_FROM, DATE_TO } from "../../../../common/constants";
export const dashboardInitialState = {
  events: [],
  filter: "",
  expanded: {},
  categoryFilters: [],
  oee: {
    availability: 0,
    performance: 0,
    quality: 0
  },
  itemFilters: __spreadValues({}, EMPTY_RESULTS),
  canApplyItemFilters: false,
  filterQuery: __spreadValues({}, EMPTY_QUERIES),
  dateFilters: {
    [DATE_FROM]: null,
    [DATE_TO]: null
  },
  showCalendar: false,
  loadEventsFrom: 0,
  loadEventsTo: 10
};
export const dashboardTestState = {
  events: [
    {
      id: 0,
      link: "/app/events/0",
      title: "Disk array OXO2 latency warning",
      status: "fatal",
      datetime: new Date(2013, 2, 2, 1, 10),
      message: "message",
      machine: {
        id: 8845377,
        name: "Robotron-S2300",
        departmentPath: "3rd Floor",
        link: "/app/inventory/machines/123",
        image: "/img/position.png"
      }
    }
  ],
  oee: {
    availability: 0.4,
    performance: 0.5,
    quality: 0.6
  },
  filter: "",
  expanded: {},
  categoryFilters: ["fatal"],
  itemFilters: __spreadValues({}, EMPTY_RESULTS),
  canApplyItemFilters: true,
  filterQuery: __spreadValues({}, EMPTY_QUERIES),
  dateFilters: {
    [DATE_FROM]: null,
    [DATE_TO]: null
  },
  showCalendar: true,
  isUpdating: false,
  loadEventsFrom: 0,
  loadEventsTo: 10
};
