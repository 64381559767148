"use strict";
export const info = "info";
export const fatal = "fatal";
export const error = "error";
export const anomaly = "anomaly";
export const warning = "warning";
export const maintenanceInbox = [
  fatal,
  anomaly
];
export const EVENT_CATEGORIES = [
  info,
  fatal,
  error,
  anomaly,
  warning
];
export default EVENT_CATEGORIES;
