import React from 'react';
import { FormattedMessage } from 'react-intl';
import Counter from '../../common/counter/Counter';

interface CycleDetectionHeaderProps {
  cycleDetectionsCount: number;
  onAddCycleDetection: () => void;
}

export const CycleDetectionHeader: React.FC<CycleDetectionHeaderProps> = React.memo(
  ({ cycleDetectionsCount, onAddCycleDetection }) => (
    <div className='table--title'>
      <FormattedMessage tagName='b' id='aiApp.config.cycleDetection' />
      <Counter value={cycleDetectionsCount} />
      <button
        className='add-anomaly-btn btn btn-success dropdown-toggle button light-grey-white'
        onClick={onAddCycleDetection}
      >
        <span className='fa fa-plus me-2' />
        <FormattedMessage tagName='b' id='common.add' />{' '}
        <FormattedMessage tagName='b' id='aiApp.config.cycleDetection' />
      </button>
    </div>
  ),
);
