"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { find } from "lodash";
import { createAction } from "@reduxjs/toolkit";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";
import * as api from "../../core/api";
import {
  getDataPointFromListById,
  getDataPointsOfDevice,
  getDatatron,
  getDeviceById
} from "../selectors/datatron.selector";
import { compareById, removeObjectFromList, replaceObjectInList } from "../../../../common/helpers/object";
import { closeModal } from "./modals.module";
import { sendNotification } from "./notifications.module";
import { DP_ARCHIVE_DATA_POINT } from "../constants/modals.constants";
import notificationMessage from "../../messages/notification.message";
import { highlightDataPoint } from "./datatron.devices.dataPoints.highlight.module";
const HIDE_DELAY_IN_MILLISECONDS = 1e3;
export const archiveDataPoint = createAction(
  "archive data point",
  (deviceId, dataPointId) => ({ payload: { deviceId, dataPointId } })
);
export const archiveDataPointSuccess = createAction(
  "archive data point - success",
  (deviceId, dataPointId) => ({ payload: { deviceId, dataPointId } })
);
export const archiveDataPointFail = createAction(
  "archive data point - fail",
  (deviceId, dataPointId, error) => ({ payload: { deviceId, dataPointId, error } })
);
export const reducer = {
  [archiveDataPoint.type]: (state, { deviceId, dataPointId }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const dataPoints = getDataPointsOfDevice(device);
    const dataPoint = getDataPointFromListById(dataPoints, dataPointId);
    if (!dataPoint)
      return state;
    const newDataPoint = __spreadProps(__spreadValues({}, dataPoint), {
      _archive: {
        loading: true,
        loaded: false,
        error: null
      }
    });
    const newDevice = __spreadProps(__spreadValues({}, device), {
      dataPoints: __spreadProps(__spreadValues({}, device.dataPoints), {
        list: replaceObjectInList(
          getDataPointsOfDevice(device),
          newDataPoint,
          compareById
        )
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById
          )
        })
      })
    });
  },
  [archiveDataPointSuccess.type]: (state, { deviceId, dataPointId }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const dataPointToRemove = find(
      getDataPointsOfDevice(device),
      { id: dataPointId }
    );
    if (!dataPointToRemove)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      dataPoints: __spreadProps(__spreadValues({}, device.dataPoints), {
        list: removeObjectFromList(
          getDataPointsOfDevice(device),
          dataPointToRemove,
          compareById
        )
      }),
      archivedDataPoints: __spreadProps(__spreadValues({}, device.archivedDataPoints), {
        list: [
          ...device.archivedDataPoints.list,
          dataPointToRemove
        ]
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById
          )
        })
      })
    });
  },
  [archiveDataPointFail.type]: (state, { deviceId, dataPointId, error }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const dataPoints = getDataPointsOfDevice(device);
    const dataPoint = getDataPointFromListById(dataPoints, dataPointId);
    if (!dataPoint)
      return state;
    const newDataPoint = __spreadProps(__spreadValues({}, dataPoint), {
      _archive: {
        loading: false,
        loaded: false,
        error
      }
    });
    const newDevice = __spreadProps(__spreadValues({}, device), {
      dataPoints: __spreadProps(__spreadValues({}, device.dataPoints), {
        list: replaceObjectInList(
          dataPoints,
          newDataPoint,
          compareById
        )
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(
            state.datatron.devices.list,
            newDevice,
            compareById
          )
        })
      })
    });
  }
};
export function* archiveDataPointSaga({ payload: { deviceId, dataPointId } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const { response, error } = yield call(
    api.datatrons.archiveDataPoint,
    {
      datatronId: datatron.id,
      deviceId,
      dataPointId
    }
  );
  if (response) {
    yield put(closeModal(DP_ARCHIVE_DATA_POINT));
    yield put(highlightDataPoint(dataPointId));
    yield put(sendNotification(notificationMessage.datapoint_archived_title));
    yield delay(HIDE_DELAY_IN_MILLISECONDS);
    yield put(archiveDataPointSuccess(deviceId, dataPointId));
  } else {
    yield put(archiveDataPointFail(deviceId, dataPointId, error));
  }
}
export function* watchArchiveDataPoint() {
  yield takeEvery(archiveDataPoint, archiveDataPointSaga);
}
