import React, { useEffect } from 'react';
import AssignModalContainer from './column/assignModal/AssignModal.container';
import ColumnContainer from './columns/Columns.container';
import { ErrorModal } from './column/errorModal/ErrorModal';
import Loader from '../_tools/loader/Loader';
import { onMaintenanceEnter } from '../index/routeHandlers';

interface MaintenanceProps {
  isLoading: boolean;
  isAssignModalOpen: boolean;
  isErrorModalOpen: boolean;
  errorMessage: string;
  showError: (errorMsg: string) => void;
}

const Maintenance: React.FC<MaintenanceProps> = ({
  isLoading,
  isAssignModalOpen,
  isErrorModalOpen,
  errorMessage,
  showError,
}) => {
  useEffect(() => {
    onMaintenanceEnter();
  }, []);

  useEffect(() => {
    if (errorMessage) {
      showError(errorMessage);
    }
  }, [errorMessage, showError]);

  return (
    <div>
      {!isLoading && <ColumnContainer />}
      <Loader show={isLoading} fullHeight solidBackground />
      {isAssignModalOpen && <AssignModalContainer />}
      {isErrorModalOpen && <ErrorModal errorMessage='errorMessage' />}
    </div>
  );
};

export default Maintenance;
