import React, { useEffect, useRef } from 'react';
import invariant from 'tiny-invariant';
import { Edge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { reorderWithEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge';

import Board from '../column/board/Board';
import { Column } from '../column/Column';
import SubHeader from './SubHeader';
import ScheduledHeader from './ScheduledHeader';
import Scheduled from './Scheduled';
import { Todo } from '../../../../../common/types/common';
import {
  CreateActivity,
  MoveActivity,
  COLUMN_INBOX,
} from '../../../redux/constants/column.type.constants';

interface Props {
  data: Todo;
  moveActivity: MoveActivity;
  createActivity: CreateActivity;
}

export const Columns: React.FC<Props> = ({ data, moveActivity, createActivity }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    invariant(ref.current);
    return monitorForElements({
      onDragStart() {},
      onDrag() {},
      onDrop(args) {
        const { location, source } = args;
        const id = source.data.id;
        invariant(typeof id === 'string');
        const [, startColumnRecord] = location.initial.dropTargets;
        const sourceId = startColumnRecord.data.columnId;
        invariant(typeof sourceId === 'string');
        const sourceColumn = data.columnMap[sourceId];

        const itemIndex = sourceColumn.items.findIndex((item) => item.id === id);
        const item = sourceColumn.items[itemIndex];

        if (location.current.dropTargets.length === 1) {
          const [destinationColumnRecord] = location.current.dropTargets;
          const destinationId = destinationColumnRecord.data.columnId;

          invariant(typeof destinationId === 'string');
          const destinationColumn = data.columnMap[destinationId];
          invariant(destinationColumn);

          if (destinationColumn.isDropAllowed === false) {
            return;
          }

          const updated = reorderWithEdge({
            list: sourceColumn.items,
            startIndex: itemIndex,
            indexOfTarget: sourceColumn.items.length - 1,
            closestEdgeOfTarget: null,
            axis: 'vertical',
          });

          const listPosition = updated.map((obj: Todo, index) => ({
            id: obj.id,
            state: destinationColumn.columnId,
            position: index + 1,
          }));

          if (sourceColumn === destinationColumn) {
            moveActivity(id, destinationColumn.columnId, listPosition);
          } else if (sourceColumn.columnId === COLUMN_INBOX) {
            createActivity(id, destinationColumn.columnId, destinationColumn.items.length + 1);
            return;
          } else {
            const updatedMap = {
              ...data.columnMap,
              [sourceColumn.columnId]: {
                ...sourceColumn,
                items: sourceColumn.items.filter((i) => i.id !== id),
              },
              [destinationColumn.columnId]: {
                ...destinationColumn,
                items: [...destinationColumn.items, item],
              },
            };
            const listSourcePosition = updatedMap[sourceColumn.columnId].items.map(
              (obj: Todo, index) => ({
                id: obj.id,
                state: sourceColumn.columnId,
                position: index + 1,
              }),
            );

            const listDestinationPosition = updatedMap[destinationColumn.columnId].items.map(
              (obj: Todo, index) => ({
                id: obj.id,
                state: destinationColumn.columnId,
                position: index + 1,
              }),
            );

            moveActivity(
              id,
              destinationColumn.columnId,
              listSourcePosition.concat(listDestinationPosition),
            );
          }
        }

        // dropping in a column (relative to a card)
        if (location.current.dropTargets.length === 2) {
          const [destinationCardRecord, destinationColumnRecord] = location.current.dropTargets;
          const destinationColumnId = destinationColumnRecord.data.columnId;
          invariant(typeof destinationColumnId === 'string');
          const destinationColumn = data.columnMap[destinationColumnId];
          if (destinationColumn.isDropAllowed === false) {
            return;
          }

          const indexOfTarget = destinationColumn.items.findIndex(
            (activity: Todo) => activity.id === destinationCardRecord.data.id,
          );
          const closestEdgeOfTarget: Edge | null = extractClosestEdge(destinationCardRecord.data);

          if (sourceColumn === destinationColumn) {
            const updated = reorderWithEdge({
              list: sourceColumn.items,
              startIndex: itemIndex,
              indexOfTarget,
              closestEdgeOfTarget,
              axis: 'vertical',
            });
            const listPosition = updated.map((obj: Todo, index) => ({
              id: obj.id,
              state: destinationColumn.columnId,
              position: index + 1,
            }));

            moveActivity(id, destinationColumn.columnId, listPosition);
            return;
          }

          const updatedSourceColumn: Todo = {
            ...sourceColumn,
            items: sourceColumn.items.filter((i) => i !== item),
          };
          const updated: Todo[] = Array.from(destinationColumn.items);
          const destinationIndex =
            closestEdgeOfTarget === 'bottom' ? indexOfTarget + 1 : indexOfTarget;
          updated.splice(destinationIndex, 0, item);

          const updatedDestinationColumn: Todo = {
            ...destinationColumn,
            items: updated,
          };

          const listSourcePosition = updatedSourceColumn.items.map((obj: Todo, index) => ({
            id: obj.id,
            state: sourceColumn.columnId,
            position: index + 1,
          }));

          const listDestinationPosition = updatedDestinationColumn.items.map(
            (obj: Todo, index) => ({
              id: obj.id,
              state: destinationColumn.columnId,
              position: index + 1,
            }),
          );

          if (sourceColumn.columnId === COLUMN_INBOX) {
            moveActivity(
              id,
              destinationColumn.columnId,
              listDestinationPosition.filter((destItem) => destItem.id !== id),
            );
            createActivity(id, destinationColumn.columnId, destinationIndex + 1);
            return;
          }

          moveActivity(
            id,
            destinationColumn.columnId,
            listSourcePosition.concat(listDestinationPosition),
          );
        }
      },
    });
  }, [data, createActivity, moveActivity]);

  return (
    <div>
      <div className='col--3 maintenances-wrap'>
        <div className='col--3--center'>
          <div className='subheader'>
            <div className='subheader-in'>
              <SubHeader />
            </div>
          </div>
          <div className='maintenances-wrap-body'>
            <Board ref={ref}>
              {data.orderedColumnIds.map((columnId) => (
                <Column column={data.columnMap[columnId]} key={columnId} />
              ))}
            </Board>
          </div>

          <div className='col--3--right col--3--right__hide'>
            <ScheduledHeader />
            <Scheduled />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Columns;
