import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * A component that displays an error message if `error` is not null.
 *
 * @remarks
 * The message is taken from the `validation.file.error.default_message` message ID.
 *
 * @param {string | null} error - an error message to show
 * @returns {JSX.Element} a div with the class 'error-message'
 */
export const ErrorMessage: React.FC<{ error: string | null }> = ({ error }) => {
  if (!error) return null;
  return (
    <div className='error-message'>
      <FormattedMessage
        id='validation.file.error.default_message'
        defaultMessage='An error occurred'
      />
    </div>
  );
};
