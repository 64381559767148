import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import FormatSelector from './options/FormatSelector';
import SelectDateRange from './warnings/SelectDateRange';
import NoDataToExport from './warnings/NoDataToExport';
import { apiRequest } from '../../../core/api/_tools';
import { useDownloadFile } from '../../../core/common/hooks/useDownloadFile';

interface DownloadProps {
  downloadButtonMessage: string;
  dateFiltersSelected: boolean;
  isExportFileEmpty: boolean;
  downloadProps: Record<string, any>;
  fileFormat: string;
  askForFileFormat: boolean;
  onFileFormatChange: (format: string) => void;
  generateExportUrl: (props: Record<string, any>) => string;
  afterDownloadButtonClick?: () => void;
  close: () => void;
}

export const Download: FC<DownloadProps> = ({
  downloadProps,
  afterDownloadButtonClick,
  fileFormat,
  onFileFormatChange,
  askForFileFormat,
  downloadButtonMessage,
  generateExportUrl,
  dateFiltersSelected,
  close,
  isExportFileEmpty,
}) => {
  const apiDefinition = async () => {
    const url = generateExportUrl({
      ...downloadProps,
      fileFormat,
      localDateTime: moment().format('YYYYMMDD-HHmm'),
    });
    return apiRequest(url);
  };

  const postDownloading = () => {
    if (afterDownloadButtonClick) afterDownloadButtonClick();
  };

  const onError = (msg: string) => {
    console.error(msg);
  };

  const getFileName = () => `file-${moment().format('YYYYMMDD-HHmm')}.${fileFormat}`;

  const { download } = useDownloadFile({
    apiDefinition,
    preDownloading: () => {},
    postDownloading,
    onError,
    getFileName,
  });

  return (
    <div className='export--line'>
      {!dateFiltersSelected && <SelectDateRange />}
      {dateFiltersSelected && isExportFileEmpty && <NoDataToExport />}
      {askForFileFormat && <FormatSelector value={fileFormat} onChange={onFileFormatChange} />}
      <div className='line' />
      <div className='central-form'>
        <button className='button grey' onClick={close}>
          <FormattedMessage defaultMessage='Cancel' id='common.Cancel' />
        </button>
        <button
          className='button green'
          onClick={download}
          disabled={!dateFiltersSelected || isExportFileEmpty}
        >
          {downloadButtonMessage}
        </button>
      </div>
    </div>
  );
};

export default Download;
