import React from 'react';
import { getModalPayload } from '../../../../redux/selectors/modals.selector';
import {
  DP_ADD_DATA_POINT,
  DP_EDIT_DATA_POINT,
} from '../../../../redux/constants/modals.constants';
import { useDispatch, useSelector } from 'react-redux';
import { useDatapointForm } from './formHook';
import BasicModal from '../../core/BasicModal';
import { closeModal } from '../../../../redux/modules/modals.module';
import ModalLine from '../../core/ModalLine';
import commonCoreMessages from '../../../../messages/common.core.messages';
import { FormattedMessage, useIntl } from 'react-intl';
import EditModalFooter from '../../core/footers/EditModalFooter';
import { clearNewDataPointError } from '../../../../redux/modules/datatron.devices.dataPoints.create.module';
import { ReadOnlyDataPoint } from './ReadOnlyDataPoint';
import { AppState } from '../../../../redux/state/app.state';

interface Props {
  isEditForm: boolean;
}

export const DataPointForm: React.FC<Props> = ({ isEditForm }) => {
  /**
   * Get modal payload based on the form type
   */
  const res = useSelector((state: AppState) => {
    if (isEditForm) return getModalPayload(state, DP_EDIT_DATA_POINT);
    else return getModalPayload(state, DP_ADD_DATA_POINT);
  });

  const { deviceType, formType } = res;

  const reduxDispatch = useDispatch();
  const close = () => {
    if (isEditForm) reduxDispatch(closeModal(DP_EDIT_DATA_POINT));
    else reduxDispatch(closeModal(DP_ADD_DATA_POINT));

    reduxDispatch(clearNewDataPointError());
  };

  /**
   * Call a custom react hook that handles all parts of the form, including inputs and submission
   */
  const {
    state,
    makeStandardFields,
    makeSpecialFields,
    handleSubmit,
    isSubmitDisabled,
    parentDataPoint,
  } = useDatapointForm({
    schema: deviceType.parsedDataPointSchema,
    formType,
    isEditForm,
  });

  const { formatMessage } = useIntl();

  const title = formatMessage(commonCoreMessages.dataPoint);

  return (
    <BasicModal isOpen={true} close={close} modalClass='modal--xlg'>
      <div className='modal--content'>
        <h3>{title}</h3>
        <div className='form'>
          {parentDataPoint && (
            <>
              <FormattedMessage
                id='datapoint.parent.array.definition'
                defaultMessage='Parent Array'
                tagName='span'
              />
              <div className='form-inline'>
                <ReadOnlyDataPoint dataPoint={parentDataPoint} />
              </div>
              <ModalLine />
              <FormattedMessage
                id='datapoint.main.array.definition'
                defaultMessage='Define Array Index'
                tagName='span'
              />
            </>
          )}
          <div className='form-inline'>{state.standard.map(makeStandardFields)}</div>

          <div>{state.special.map(makeSpecialFields)}</div>
        </div>
        <ModalLine />
        <EditModalFooter
          onSaveClick={handleSubmit}
          onCancelClick={close}
          isSaveButtonDisabled={isSubmitDisabled}
          isSaveButtonLoading={false}
        />
      </div>
    </BasicModal>
  );
};
