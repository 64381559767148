import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ColumnExpandCollapseContainer from '../../../column/card/actions/ColumnExpandCollapse.container';
import CommentingContainer from '../../../column/card/actions/Commenting.container';
import ColumnEventInfoContainer from '../../../column/card/event/EventInfo.container';
import ColumnDialogMenu from '../../../column/card/actions/ColumnDialogMenu';

export const CARD_HEIGHT = 174;

const InboxEvent = ({
  event,
  isFatal,
  isWarning,
  isInfo,
  isAnomaly,
  isOpened,
  isError,
  collapseExpand,
  canSeeAnomaly,
  fetchAnomaly,
}) => {
  const node = useRef(null);

  const expandCard = () => {
    if (canSeeAnomaly) {
      fetchAnomaly(event.id);
    }
    collapseExpand(event.id);
  };

  const className = classNames('maintenance--item', {
    info: isInfo,
    alert: isFatal,
    warning: isWarning,
    anomaly: isAnomaly,
    error: isError,
    opened: isOpened,
  });

  return (
    <div className={className} ref={node} onClick={expandCard}>
      <ColumnExpandCollapseContainer eventId={event.id} />
      <ColumnDialogMenu canHide canAssign={false} canResolve={false} eventId={event.id} />
      <ColumnEventInfoContainer event={event} canSeeAnomaly={canSeeAnomaly} />
      <div className='maintenance--item--bottom'>
        <ul>
          <li />
          <CommentingContainer commentsCount={event.comments.length} eventId={event.id} />
        </ul>
      </div>
    </div>
  );
};

InboxEvent.propTypes = {
  event: PropTypes.object.isRequired,
  isFatal: PropTypes.bool.isRequired,
  isWarning: PropTypes.bool.isRequired,
  isInfo: PropTypes.bool.isRequired,
  isAnomaly: PropTypes.bool.isRequired,
  isOpened: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  collapseExpand: PropTypes.func.isRequired,
  canSeeAnomaly: PropTypes.bool.isRequired,
  fetchAnomaly: PropTypes.func.isRequired,
};

export default InboxEvent;
