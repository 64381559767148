import React from 'react';
import { useDispatch } from 'react-redux';
import Table, { TableRow } from '../../common/table/table';
import NoSelectedPlaceholder from '../components/NoSelectedPlaceholder';
import { FormattedMessage, useIntl } from 'react-intl';
import { openModal } from '../../../redux/modules/modals.module';
import { ANOMALY_MODEL_ADD } from '../../../redux/constants/modals.constants';
import commonMessages from '../../../messages/common.messages';
import aiAppConfigMessages from '../../../messages/aiApp.config.messages';
import {
  ANOMALY_MODEL_HEADER,
  TRAINING_STATUS,
} from '../../../redux/constants/aiApp.anomalyModule.form.constants';
import { triggerAnomalyModel } from '../../../redux/modules/aiApps.anomalyModel.trigger.module';
import {
  CREATED_STATUS,
  COMPLETED_STATUS,
} from '../../../redux/constants/aiApp.cycleDetection.form.constants';
import AnomalyModel from './AnomalyModel';
import Counter from '../../common/counter/Counter';
import { useAnomalyModelSection } from '../hooks/useAnomalyModelSection';
import { ErrorMessage } from '../components/ErrorMessage';
import { NoDataMessage } from '../components/NoDataMessage';

/**
 * A React functional component that renders the Anomaly Model Section.
 *
 * This component displays the details of an anomaly model, including its status, duration, start and end dates, candidates, and anomaly models.
 * It also allows users to trigger the anomaly model and view details.
 *
 * @return {JSX.Element} The JSX element representing the Anomaly Model Section.
 */
const AnomalyDetection: React.FC = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const {
    cycleDetectionDetails,
    selectedAnomalyDetection,
    anomalyModels,
    isLoading,
    error,
    handleViewAnomalyModelDetails,
  } = useAnomalyModelSection();

  if (!cycleDetectionDetails) return <NoSelectedPlaceholder />;

  const {
    id: cycleDetectionId,
    anomaly_detections: anomalyDetections,
    candidates,
  } = cycleDetectionDetails;

  const handleOpenAnomalyModelModal = () => {
    dispatch(openModal(ANOMALY_MODEL_ADD));
  };

  const renderAnomalyModelActions = (row: TableRow) => (
    <div className='td-buttons' key={row.id}>
      {isLoading && row.id === selectedAnomalyDetection ? (
        <button className='button button__xs light-grey-white' disabled>
          <i className='fa fa-spinner fa-spin' />
        </button>
      ) : row[TRAINING_STATUS] === CREATED_STATUS ? (
        <button
          className='button button__xs light-grey-white'
          title={formatMessage(commonMessages.run)}
          onClick={() => dispatch(triggerAnomalyModel(cycleDetectionId, row.id))}
        >
          <i className='fa fa-terminal' />
        </button>
      ) : row[TRAINING_STATUS] === COMPLETED_STATUS ? (
        <button
          className='button button__xs light-grey-white'
          title={formatMessage(commonMessages.details)}
          onClick={() => handleViewAnomalyModelDetails(row)}
        >
          <i className='fa fa-eye' />
        </button>
      ) : null}
    </div>
  );

  return (
    <div className='container mt-5'>
      <div className='table--title'>
        <FormattedMessage tagName='b' {...aiAppConfigMessages.anomalyDetection} />
        <Counter value={anomalyDetections.length} />
        <button
          className='add-anomaly-btn btn btn-success dropdown-toggle button light-grey-white'
          onClick={handleOpenAnomalyModelModal}
          disabled={candidates.length === 0}
        >
          <span className='fa fa-plus me-2' />
          <FormattedMessage tagName='b' {...commonMessages.add} />{' '}
          <FormattedMessage tagName='b' {...aiAppConfigMessages.anomalyDetection} />
        </button>
      </div>
      <div className='table-responsive'>
        <Table
          headers={ANOMALY_MODEL_HEADER}
          rows={anomalyDetections}
          renderActions={renderAnomalyModelActions}
          hasStatus={true}
          selectedId={selectedAnomalyDetection}
        />
      </div>
      {selectedAnomalyDetection &&
        !isLoading &&
        anomalyModels.map((anomalyModel, index) => (
          <AnomalyModel key={index} anomaly={anomalyModel} />
        ))}
      {ErrorMessage({ error })}
      {NoDataMessage({ selectedAnomalyDetection, anomalyModels, isLoading })}
    </div>
  );
};

export default AnomalyDetection;
