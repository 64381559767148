"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { getSignalData } from "../../../core/api/analyzer";
import {
  MIN_TIME_RANGE_OFFSET_MS,
  TIME_RANGE_OFFSET_MS,
  MAX_DATA_POINT_DISPLAY as maxData
} from "../../helper/signalData";
import { downSampleWithPrecision } from "../panel/Helper";
import { dateToUnixNano } from "../../helper/time";
const waitMinTime = (minDuration = 100) => new Promise((resolve) => {
  setTimeout(resolve, minDuration);
});
const prepareExtendedTimeRange = (timeRange) => ({
  from: timeRange.fromDate.getTime() - TIME_RANGE_OFFSET_MS,
  to: timeRange.toDate.getTime() + TIME_RANGE_OFFSET_MS
});
const filterDataByTimeRange = (data, fromDate, toDate) => {
  const fromDateWithMinOffset = new Date(fromDate.getTime() - MIN_TIME_RANGE_OFFSET_MS);
  const toDateWithMinOffset = new Date(toDate.getTime() + MIN_TIME_RANGE_OFFSET_MS);
  const fromTimeNano = dateToUnixNano(fromDateWithMinOffset);
  const toTimeNano = dateToUnixNano(toDateWithMinOffset);
  return data.filter((point) => point.systime >= fromTimeNano && point.systime <= toTimeNano);
};
const processSignalResponse = (response, timeRange) => {
  const _a = response.data, { data } = _a, signalMetadata = __objRest(_a, ["data"]);
  const filteredData = filterDataByTimeRange(data, timeRange.fromDate, timeRange.toDate);
  return {
    // @ts-ignore
    signalData: response.status === 200 ? downSampleWithPrecision(__spreadValues({ data: filteredData }, signalMetadata), maxData) : null,
    // @ts-ignore
    error: response.status !== 200 ? `Error ${JSON.stringify(response.error)}` : null,
    prevRangeFrom: timeRange.fromDate.getTime(),
    prevRangeTo: timeRange.toDate.getTime()
  };
};
const handleFetchError = (error) => ({
  error: error instanceof Error ? error.message : "An unknown error occurred",
  signalData: null,
  prevRangeFrom: 0,
  prevRangeTo: 0
});
const isRangeWithinExistingData = (newFrom, newTo, existingFrom, existingTo) => (
  // Case 1: New range is fully within existing range
  newFrom >= existingFrom && newTo <= existingTo || // Case 2: Existing range is very close to new range
  Math.abs(existingFrom - newFrom) < TIME_RANGE_OFFSET_MS && Math.abs(existingTo - newTo) < TIME_RANGE_OFFSET_MS
);
export const useChartData = (signalId, timeRange, setIsLoading) => {
  const [state, setState] = useState({
    error: null,
    signalData: null,
    prevRangeFrom: 0,
    prevRangeTo: 0
  });
  const isMounted = useRef(true);
  const abortControllerRef = useRef(null);
  let signalResponse = null;
  const latestFetchRef = useRef(null);
  const debouncedFetchData = useCallback(
    debounce(
      (currentSignalId, currentTimeRange, triggerGetData) => __async(void 0, null, function* () {
        if (!isMounted.current)
          return;
        setIsLoading(true);
        if (!triggerGetData) {
          if (signalResponse) {
            const fetchResult = processSignalResponse(signalResponse, currentTimeRange);
            yield waitMinTime();
            if (isMounted.current) {
              setState(fetchResult);
              setIsLoading(false);
            }
          }
          return;
        }
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
        abortControllerRef.current = new AbortController();
        try {
          const extendedTimeRange = prepareExtendedTimeRange(currentTimeRange);
          const response = yield getSignalData(
            currentSignalId,
            extendedTimeRange.from,
            extendedTimeRange.to,
            abortControllerRef.current.signal
          );
          if (!isMounted.current)
            return;
          signalResponse = response;
          const fetchResult = processSignalResponse(response, currentTimeRange);
          if (isMounted.current) {
            setState(fetchResult);
            latestFetchRef.current = {
              signalId: currentSignalId,
              fromTime: currentTimeRange.fromDate.getTime(),
              toTime: currentTimeRange.toDate.getTime()
            };
          }
        } catch (error) {
          if (isMounted.current) {
            const errorResult = handleFetchError(error);
            setState(errorResult);
            latestFetchRef.current = null;
          }
        } finally {
          if (isMounted.current) {
            setIsLoading(false);
          }
        }
      }),
      50
    ),
    [setState, setIsLoading]
  );
  useEffect(() => {
    isMounted.current = true;
    if (!signalId || !timeRange)
      return;
    const shouldSkipFetch = latestFetchRef.current && latestFetchRef.current.signalId === signalId && isRangeWithinExistingData(
      timeRange.fromDate.getTime(),
      timeRange.toDate.getTime(),
      latestFetchRef.current.fromTime,
      latestFetchRef.current.toTime
    );
    if (shouldSkipFetch) {
      debouncedFetchData(signalId, timeRange, false);
      return;
    }
    debouncedFetchData(signalId, timeRange, true);
    return () => {
      isMounted.current = false;
      debouncedFetchData.cancel();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        abortControllerRef.current = null;
      }
    };
  }, [signalId, timeRange, debouncedFetchData]);
  return state;
};
