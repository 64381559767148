"use strict";
export const CURSOR_UNITS = ["h", "min", "s", "ms100", "ms10", "ms"];
export const UNIT_TO_NANO = {
  h: 60 * 60 * 1e3,
  min: 60 * 1e3,
  s: 1e3,
  ms100: 100,
  ms10: 10,
  ms: 1
};
