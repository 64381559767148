"use strict";
export const OFF = "off";
export const FIVE_SECONDS = "5sec";
export const TEN_SECONDS = "10sec";
export const FIVE_MIN = "5min";
export const HALF_HOUR = "30min";
export const ONE_HOUR = "1hour";
export const DEFAULT_AUTO_REFRESH = OFF;
export const AUTO_REFRESH_OPTIONS = [OFF, FIVE_SECONDS, TEN_SECONDS, FIVE_MIN, HALF_HOUR, ONE_HOUR];
export default AUTO_REFRESH_OPTIONS;
