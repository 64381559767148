import React from 'react';
import { useDispatch } from 'react-redux';

import { injectIntl, WrappedComponentProps } from 'react-intl';
import TabsBar from '../../common/tabsBar/TabsBar';
import commonMessages from '../../../messages/common.messages';
import { TabCommands } from '../../../schema/type/session';
import { formatLocalDate } from '../../helper/time';
import { openModal } from '../../../redux/modules/modals.module';
import {
  ANALYZER_ANOMALY_CREATE_SESSION,
  ANALYZER_ANOMALY_PANEL_ADD,
  ANALYZER_DISPLAY_SESSION_OR_ANOMALY_DATA,
  ANALYZER_SESSION_COMMAND,
} from '../../../redux/constants/modals.constants';
import analyzerMessages from '../../../messages/analyzer.messages';

interface AnomalyActionsProps extends WrappedComponentProps {
  anomaly: {
    name: string;
    id: number;
    datatronUuid: string;
    anomalyFrom: string;
    devicesUuids: string[];
    archived: boolean;
  };
}

export const AnomalyActions: React.FC<AnomalyActionsProps> = ({ intl, anomaly }) => {
  const dispatch = useDispatch();

  const tabName = `${intl.formatMessage(analyzerMessages.anomalyDetection)}: ${formatLocalDate(new Date(anomaly.anomalyFrom))}`;
  const mainButtons = [
    {
      title: tabName,
      isActive: true,
      onClick: () => {},
    },
  ];

  const extraButtons = [
    {
      title: intl.formatMessage(commonMessages.save),
      icon: 'fa fa-save',
      isActive: true,
      color: 'light-grey-white',
      onClick: () =>
        dispatch(
          openModal(ANALYZER_ANOMALY_CREATE_SESSION, {
            id: anomaly.id,
            datatronUuid: anomaly.datatronUuid,
            devicesUuids: anomaly.devicesUuids,
            name: tabName,
          }),
        ),
    },
    {
      title: intl.formatMessage(commonMessages.details),
      icon: 'fa fa-info',
      isActive: true,
      color: 'light-grey-white',
      onClick: () => {
        dispatch(
          openModal(ANALYZER_DISPLAY_SESSION_OR_ANOMALY_DATA, {
            data: anomaly,
          }),
        );
      },
    },
    {
      title: `${intl.formatMessage(commonMessages.add)} ${intl.formatMessage(commonMessages.panel)}`,
      isActive: true,
      onClick: () => dispatch(openModal(ANALYZER_ANOMALY_PANEL_ADD)),
      color: 'light-grey-white',
      icon: 'fa fa-plus',
    },
    !anomaly.archived
      ? {
          title: intl.formatMessage(commonMessages.archive),
          icon: 'fa fa-archive',
          isActive: true,
          color: 'light-grey-white',
          onClick: () =>
            dispatch(
              openModal(ANALYZER_SESSION_COMMAND, {
                id: anomaly.id,
                command: TabCommands.archiveAnomaly,
              }),
            ),
        }
      : {
          title: intl.formatMessage(commonMessages.unarchive),
          icon: 'fa fa-undo',
          isActive: true,
          color: 'light-grey-white',
          onClick: () =>
            dispatch(
              openModal(ANALYZER_SESSION_COMMAND, {
                id: anomaly.id,
                command: TabCommands.unarchiveAnomaly,
              }),
            ),
        },
  ];

  return <TabsBar list={mainButtons} extraButtons={extraButtons} disableScroll />;
};

export default injectIntl(AnomalyActions);
